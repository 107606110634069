import css from '@styled-system/css';
import styled from 'styled-components';
import { compose, flexbox, space } from 'styled-system';

import theme from '../../tokens';

export const ListStyle = styled('')(
  css({
    listStyle: 'none inside none',
  }),
  compose(space, flexbox),
);

export const ListItem = styled('li')(
  css({
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.gray500,
    minHeight: '32px',
    font: theme.fonts.textRegularMedium,
  }),
);

export const Counter = styled('span')(
  css({
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20px',
    height: '20px',
    marginRight: '8px',
    color: theme.colors.gray300,
    font: theme.fonts.textBoldMedium,
  }),
);
