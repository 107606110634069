import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import TrashIcon from 'assets/system/svgs/trash.svg';

import { Accordion, Button } from 'components/system/core';
import { Input, Date as InputDate } from 'components/system/form';

import { formDefaultValues, modalOptions } from 'lib/contexts/recommendedPrice';
import handleInputMask from 'lib/core/handleInputMask';

import ImportModal from 'components/contexts/recommendedPrice/ImportModal';

import * as S from './PriceFormStyles';

const PriceForm = ({ isOpen, handleCloseModal }) => {
  const { control, errors } = useFormContext();
  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: 'recommendedPrice',
  });

  const filters = useSelector((store) => store.priceRecommendation.filters);

  const setSelectOptions = (selectData = [], selector = 'name') => {
    if (selectData.length < 1) {
      return selectData;
    }

    return selectData.map((selectItem) => {
      return {
        value: selectItem.id,
        label: selectItem[selector],
      };
    });
  };

  const cityOptions = setSelectOptions(filters?.counties);
  const clientOptions = setSelectOptions(filters?.clientsManagement);
  const destinyOptions = setSelectOptions(filters?.destinations, 'code');
  const mesoRegionOptions = setSelectOptions(filters?.mesoregions, 'code');
  const originOptions = setSelectOptions(filters?.origins, 'code');
  const plantOptions = setSelectOptions(filters?.powerPlants);
  const productOptions = setSelectOptions(filters?.merchandisesManagement);
  const stateOptions = setSelectOptions(filters?.states);

  const formOptions = {
    cityOptions,
    clientOptions,
    destinyOptions,
    mesoRegionOptions,
    originOptions,
    plantOptions,
    productOptions,
    stateOptions,
    modalOptions,
  };

  const handleAddNewRecommendedPrice = () => {
    append(formDefaultValues);
  };

  const handleRemoveRecommendedPrice = (index) => {
    remove(index);
  };

  const handleInsertFormFields = (recommendedPrices) => {
    const fieldsLength = fields?.length || 0;

    if (recommendedPrices?.length === 0) {
      remove(0);
    }

    const defaultLine = fields.findIndex(
      (field) => field?.product === 'DEFAULT',
    );

    if (defaultLine >= 0) {
      remove(defaultLine);
    }

    recommendedPrices.forEach((price, index) => {
      insert(index + fieldsLength, {
        ...price,
      });
    });
  };

  const fieldsError = errors?.recommendedPrice;

  return (
    <S.FormContainer data-testid="price-form">
      <S.FieldsWrapper>
        {fields.map((field, index) => {
          return (
            <Accordion
              title={`Recomendação de Preço (${index + 1})`}
              key={field.id}
              open
            >
              <S.RecommendationLine>
                <S.InputGrid>
                  <Controller
                    control={control}
                    name={`recommendedPrice.${index}.endDate`}
                    defaultValue={field.endDate}
                    rules={{ required: false }}
                    render={({ ...props }) => (
                      <InputDate
                        {...props}
                        format="dd/MM/yyyy"
                        label="Vigência final"
                        locale="pt-BR"
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={`recommendedPrice.${index}.movementDate`}
                    rules={{ required: true }}
                    defaultValue={field.movementDate}
                    render={({ ...props }) => (
                      <InputDate
                        {...props}
                        format="MM/y"
                        label="Data de movimentação"
                        locale="pt-BR"
                        maxDetail="year"
                        required
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={`recommendedPrice.${index}.product`}
                    rules={{ required: true }}
                    defaultValue={field.product}
                    render={({ ...props }) => (
                      <Input
                        {...props}
                        type="select"
                        label="Produto"
                        placeholder="Selecione"
                        options={productOptions}
                        required
                        status={fieldsError?.[index]?.product && 'error'}
                        statusMessage={fieldsError?.[index]?.product?.message}
                        autocomplete
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={`recommendedPrice.${index}.modal`}
                    rules={{ required: true }}
                    defaultValue={field.modal}
                    render={({ ...props }) => (
                      <Input
                        {...props}
                        type="select"
                        label="Modal"
                        placeholder="Selecione"
                        options={modalOptions}
                        required
                        status={fieldsError?.[index]?.modal && 'error'}
                        statusMessage={fieldsError?.[index]?.modal?.message}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={`recommendedPrice.${index}.client`}
                    rules={{ required: false }}
                    defaultValue={field.client}
                    render={({ ...props }) => (
                      <Input
                        {...props}
                        type="select"
                        label="Cliente"
                        placeholder="Selecione"
                        options={clientOptions}
                        autocomplete
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={`recommendedPrice.${index}.origin`}
                    rules={{ required: true }}
                    defaultValue={field.origin}
                    render={({ ...props }) => (
                      <Input
                        {...props}
                        type="select"
                        label="Origem"
                        placeholder="Selecione"
                        options={originOptions}
                        required
                        status={fieldsError?.[index]?.origin && 'error'}
                        statusMessage={fieldsError?.[index]?.origin?.message}
                        autocomplete
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={`recommendedPrice.${index}.destiny`}
                    rules={{ required: false }}
                    defaultValue={field.destiny}
                    render={({ ...props }) => (
                      <Input
                        {...props}
                        type="select"
                        label="Destino"
                        placeholder="Selecione"
                        options={destinyOptions}
                        autocomplete
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={`recommendedPrice.${index}.state`}
                    rules={{ required: false }}
                    defaultValue={field.state}
                    render={({ ...props }) => (
                      <Input
                        {...props}
                        type="select"
                        label="Estado"
                        placeholder="Selecione"
                        options={stateOptions}
                        autocomplete
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={`recommendedPrice.${index}.city`}
                    rules={{ required: false }}
                    defaultValue={field.city}
                    render={({ ...props }) => (
                      <Input
                        {...props}
                        type="select"
                        label="Cidade"
                        placeholder="Selecione"
                        options={cityOptions}
                        autocomplete
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={`recommendedPrice.${index}.plant`}
                    rules={{ required: false }}
                    defaultValue={field.plant}
                    render={({ ...props }) => (
                      <Input
                        {...props}
                        type="select"
                        label="Usina"
                        placeholder="Selecione"
                        options={plantOptions}
                        autocomplete
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={`recommendedPrice.${index}.mesoRegion`}
                    rules={{ required: false }}
                    defaultValue={field.mesoRegion}
                    render={({ ...props }) => (
                      <Input
                        {...props}
                        type="select"
                        label="Mesorregião"
                        placeholder="Selecione"
                        options={mesoRegionOptions}
                        autocomplete
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={`recommendedPrice.${index}.tariff`}
                    rules={{ required: true }}
                    defaultValue={field.tariff}
                    render={({ ...props }) => (
                      <Input
                        {...props}
                        type="text"
                        label="Tarifa cliente"
                        prefix="R$"
                        onInput={(event) => handleInputMask(event, 'currency')}
                        required
                        status={fieldsError?.[index]?.tariff && 'error'}
                        statusMessage={fieldsError?.[index]?.tariff?.message}
                      />
                    )}
                  />
                </S.InputGrid>

                <S.DeleteButton
                  icon={TrashIcon}
                  onClick={() => handleRemoveRecommendedPrice(index)}
                />
              </S.RecommendationLine>
            </Accordion>
          );
        })}
      </S.FieldsWrapper>

      <S.ActionsWrapper>
        <Button
          name="Nova Recomendação de preço"
          type="button"
          action={handleAddNewRecommendedPrice}
        />
      </S.ActionsWrapper>

      <ImportModal
        isOpen={isOpen}
        onClose={handleCloseModal}
        handleInsertFormFields={handleInsertFormFields}
        formOptions={formOptions}
      />
    </S.FormContainer>
  );
};

export default PriceForm;
