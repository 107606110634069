import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { Button, Flex } from 'components/system/core';
import { Container, Content, Header } from 'components/system/interface';

import useDimensions from 'hooks/useDimensions';
import { formDefaultValues } from 'lib/contexts/recommendedPrice';
import { isInProgress, isPending } from 'lib/core/request';

import { PriceRecommendationActions } from 'store/priceRecommendation/priceRecommendation.ducks';

import * as ROUTES from 'constants/urls';

import PriceForm from 'components/contexts/recommendedPrice/PriceForm';
import Loading from 'components/core/Loading';

const formSchema = Yup.object().shape({
  recommendedPrice: Yup.array().of(
    Yup.object().shape({
      endDate: Yup.date().nullable().notRequired(),
      movementDate: Yup.date().required('Campo requerido'),
      product: Yup.string().required('Campo requerido'),
      modal: Yup.string().required('Campo requerido'),
      client: Yup.string().notRequired(),
      origin: Yup.string().required('Campo requerido'),
      destiny: Yup.string().notRequired(),
      state: Yup.string().notRequired(),
      city: Yup.string().notRequired(),
      plant: Yup.string().notRequired(),
      mesoRegion: Yup.string().notRequired(),
      tariff: Yup.string().required('Campo requerido'),
    }),
  ),
});

const RecommendedPrice = () => {
  const dimensions = useDimensions();
  const dispatch = useDispatch();
  const methods = useForm({
    defaultValues: formDefaultValues,
    resolver: yupResolver(formSchema),
  });

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    dispatch(PriceRecommendationActions.priceRecommendationFilter());
  }, [dispatch]);

  const priceRecommendationRequest = useSelector(
    (store) => store.priceRecommendation.priceRecommendationRequest,
  );

  const breadcrumb = [
    {
      text: 'Preço Recomendado',
      href: `#${ROUTES.PRICE_RECOMMENDATION}`,
    },
  ];

  const sendRecommendedPrice = (data) => {
    if (!data?.recommendedPrice || methods.errors?.recommendedPrice) {
      return;
    }

    const formattedPriceRecommendations = data.recommendedPrice.map((price) => {
      const value = Number(price.tariff.replace(',', '.'));
      const movementYear = new Date(price.movementDate).getFullYear();
      const movementMonth = new Date(price.movementDate).getMonth() + 1;

      return {
        value,
        movementYear,
        movementMonth,
        modalId: Number(price.modal),
        merchandiseManagementId: Number(price.product),
        endPeriod: price?.endDate ?? null,
        clientManagementId: price?.client ? Number(price.client) : null,
        operationalAreaOriginId: Number(price.origin),
        operationalAreaDestinationId: price?.destiny
          ? Number(price.destiny)
          : null,
        mesoregionId: price?.mesoRegion ? Number(price.mesoRegion) : null,
        stateId: price?.state ? Number(price.state) : null,
        countyId: price?.city ? Number(price.city) : null,
        powerPlantId: price?.plant ? Number(price.plant) : null,
      };
    });

    const payload = {
      priceRecommendations: [...formattedPriceRecommendations],
    };

    dispatch(PriceRecommendationActions.createPriceRecommendation(payload));
    methods.reset();
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const isLoading =
    isInProgress(priceRecommendationRequest.PRICE_RECOMMENDATION_FILTER) ||
    isPending(priceRecommendationRequest.CREATE_PRICE_RECOMMENDATION);

  return (
    <FormProvider {...methods}>
      <Content
        height={dimensions.height}
        data-testid="recommended-price-content"
      >
        <Container>
          <form
            onSubmit={methods.handleSubmit(sendRecommendedPrice)}
            data-testid="recommended-price-form"
          >
            <Flex justifyContent="space-between" alignItems="center">
              <Header
                bread={breadcrumb}
                tag="header"
                title="Preço Recomendado"
              />

              <Flex spaceChildren="16px">
                <Button
                  name="Importar arquivo"
                  type="button"
                  variant="secondary"
                  action={handleOpenModal}
                />
                <Button name="Salvar" type="submit" />
              </Flex>
            </Flex>

            {isLoading ? (
              <Loading />
            ) : (
              <PriceForm isOpen={isOpen} handleCloseModal={handleCloseModal} />
            )}
          </form>
        </Container>
      </Content>
    </FormProvider>
  );
};

export default RecommendedPrice;
