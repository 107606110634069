import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import IcOption from 'assets/icons/table/options.svg';

import { Button, Popover } from 'components/system/core';
import { Text } from 'components/system/typography';

import { MessagesActions } from 'store/messages/messages.ducks';
import { ProfileActions } from 'store/profile/profile.ducks';

import { history } from 'constants/routes';
import * as ROUTES from 'constants/urls';

import * as S from 'components/core/Table/CellWithActions/CellWithActions.styles';

const CellWithActions = ({ row }) => {
  const profile = row.original;
  const dispatch = useDispatch();
  const handleEditProfile = () => {
    history.push(ROUTES.EDIT_PROFILE(profile.id));
  };

  const handleRemoveProfile = () => {
    if (profile.totalUsers > 0) {
      dispatch(
        MessagesActions.open({
          title: 'Existem usuários atrelados',
          type: 'warning',
          contentText:
            'Para a exclusão, o perfil não poderá possuir nenhum usuário atrelado.',
        }),
      );
    } else {
      dispatch(ProfileActions.setDeleteProfile({ profile, modalOpen: true }));
    }
  };

  const Options = (
    <S.OptionsContainer>
      <S.OptionItem data-testid="test-edit" onClick={handleEditProfile}>
        <Text size="medium" style={{ fontWeight: '400' }}>
          Editar perfil
        </Text>
      </S.OptionItem>

      <S.OptionItem data-testid="test-decline" onClick={handleRemoveProfile}>
        <Text size="medium" style={{ fontWeight: '400' }}>
          Excluir perfil
        </Text>
      </S.OptionItem>
    </S.OptionsContainer>
  );

  return (
    <S.ContentCell data-testid="cell-with-action">
      <Popover content={Options} position="left center">
        <Button
          data-testid="test-button"
          icon={IcOption}
          name=""
          variant="tertiary"
        />
      </Popover>
    </S.ContentCell>
  );
};

CellWithActions.propTypes = {
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      totalUsers: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CellWithActions;
