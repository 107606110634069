import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, EmptyState } from 'components/system/core';

import Loading from 'components/core/Loading';
import Table from 'components/core/Table';
import TableContainer from 'components/core/TableContainer';

import { getFilterParams } from 'lib/contexts/resendItems';
import { isFinished, isPending } from 'lib/core/request';

import usePagination from 'hooks/usePagination';

import { ResendItemsActions } from 'store/resendItems/resendItems.ducks';

import Columns from './Columns';

const ResendItems = () => {
  const { resendItemsRequests, resendItemsList, filter } = useSelector(
    ({ resendItems }) => resendItems,
  );
  const { segmentRequests } = useSelector(({ segment }) => segment);
  const { activeContractRequests } = useSelector(
    ({ activeContracts }) => activeContracts,
  );

  const dispatch = useDispatch();

  const { paginationSize, setValuePage } = usePagination();

  const loading =
    isPending(activeContractRequests.ACTIVE_CONTRACT) ||
    isPending(resendItemsRequests.RESEND_ITEMS_LIST_REQUEST) ||
    isPending(resendItemsRequests.GET_ITEMS_FOR_FILTER_REQUEST) ||
    isPending(segmentRequests.LIST_SEGMENT_ACTIVE);

  const onPageChange = (PageIndex) => {
    const { pageSize } = resendItemsList;
    const params = getFilterParams({
      ...filter,
      pageIndex: PageIndex,
      pageSize,
    });

    dispatch(ResendItemsActions.resendItemsListRequest(params, filter));
  };

  const onPerPageChange = (PageSize) => {
    const params = getFilterParams({
      ...filter,
      pageSize: PageSize,
    });

    setValuePage(PageSize);
    dispatch(ResendItemsActions.resendItemsListRequest(params, filter));
  };

  return (
    <TableContainer>
      {loading && <Loading />}
      {resendItemsList?.items?.length > 0 ? (
        <Table
          data-testid="resendItemlist-test"
          width="100%"
          footer
          customList={paginationSize}
          pageIndex={resendItemsList.currentPage}
          pageCount={resendItemsList.totalPages}
          totalItems={resendItemsList.totalCount}
          onPageChange={onPageChange}
          onPerPageChange={onPerPageChange}
          columns={Columns}
          data={resendItemsList?.items}
          sortBy={['createdAt']}
        />
      ) : (
        isFinished(resendItemsRequests.RESEND_ITEMS_LIST_REQUEST) && (
          <Card>
            <EmptyState condensed={false} title="Nenhum item encontrado." />
          </Card>
        )
      )}
    </TableContainer>
  );
};

export default ResendItems;
