import { addDays, addMonths, toDate } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Card, Flex } from 'components/system/core';
import { Input, Date as InputDate } from 'components/system/form';
import { Column as Col, Container, Row } from 'components/system/interface';
import Tokens from 'components/system/tokens';
import { Heading, Text } from 'components/system/typography';

import { getFormData, validateLocalId } from 'lib/contexts/indices';
import handleInputMask from 'lib/core/handleInputMask';
import { isPending } from 'lib/core/request';

import Loading from 'components/core/Loading';
import { SpacingTop } from 'components/core/Spacing';

import { DieselActions } from 'store/diesel/diesel.ducks';
import { IndicesActions } from 'store/indices/indices.ducks';

import * as S from './DieselForm.styles';

const DieselOther = ({ suppliersList }) => {
  const dispatch = useDispatch();

  const { handleSubmit, errors, control, getValues, setValue } = useForm({});
  const [existField, setExistField] = useState(true);

  const { indicesRequests } = useSelector(({ indices }) => indices);

  const reload = () => {
    dispatch(DieselActions.clearSupplierList());
  };

  const onSubmit = (data) => {
    const getKey = suppliersList[0].key;
    const formData = getFormData(data);
    dispatch(
      IndicesActions.dieselReadjustmentStart(formData, false, getKey, reload),
    );
  };

  const validInput = () => {
    const values = validateLocalId(getValues());
    setExistField(!values.length > 0);
  };

  const setEndDate = (id, value) => {
    const endDate = toDate(addDays(addMonths(value, 1), -1));
    setValue(`date.${id}.endDate`, endDate);
  };

  const isLoading = isPending(indicesRequests.DIESEL_READJUSTMENT_START);

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="form-test">
      {!isLoading &&
        suppliersList.map((supplier) => (
          <SpacingTop md key={supplier.id}>
            <Card style={{ height: 'auto' }}>
              <S.CardHeader>
                <Heading
                  tag="h5"
                  color={Tokens.colors.gray300}
                  variant={['bold']}
                >
                  {supplier.name}
                </Heading>
              </S.CardHeader>

              <S.ContainerForm>
                <Flex>
                  <S.GroupTitle>Período de apuração</S.GroupTitle>
                </Flex>

                <Flex>
                  <S.Group>
                    <div>
                      <div>
                        <S.Label>Data Inicial</S.Label>
                      </div>

                      <div>
                        <Controller
                          data-testid={`test-date.${supplier.id}`}
                          name={`date.${supplier.id}.startDate`}
                          rules={{ required: true }}
                          defaultValue={null}
                          control={control}
                          render={({ onChange, value }) => (
                            <S.ContainerDate>
                              <InputDate
                                format="dd/MM/yyyy"
                                selected={value}
                                value={value}
                                name={`date.${supplier.id}.startDate`}
                                locale="pt-BR"
                                onChange={(e) => {
                                  onChange(e);
                                  setEndDate(supplier.id, e);
                                }}
                              />
                            </S.ContainerDate>
                          )}
                        />

                        {errors.date && (
                          <S.MessageErrors>
                            <Text color={Tokens.colors.red400}>
                              Campo requerido
                            </Text>
                          </S.MessageErrors>
                        )}
                      </div>
                    </div>
                  </S.Group>

                  <S.Group>
                    <div>
                      <div>
                        <S.Label>Data Final</S.Label>
                      </div>

                      <div>
                        <Controller
                          name={`date.${supplier.id}.endDate`}
                          rules={{ required: true }}
                          defaultValue={null}
                          control={control}
                          render={({ onChange, value }) => (
                            <S.ContainerDate>
                              <InputDate
                                format="dd/MM/yyyy"
                                selected={value}
                                value={value}
                                name={`date.${supplier.id}.endDate`}
                                locale="pt-BR"
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            </S.ContainerDate>
                          )}
                        />
                        {errors.date && (
                          <S.MessageErrors>
                            <Text color={Tokens.colors.red400}>
                              Campo requerido
                            </Text>
                          </S.MessageErrors>
                        )}
                      </div>
                    </div>
                  </S.Group>
                </Flex>

                <Flex>
                  <Container>
                    {supplier.types.map((type) => (
                      <Row key={type.id}>
                        <SpacingTop md>
                          <S.Label>{type.name}</S.Label>

                          {type.locals.map((local) => {
                            const { id } = local;
                            const idSupplier = supplier.id;

                            return (
                              <Col desktop="3" key={id}>
                                <Controller
                                  name={`localId.${idSupplier}.${id}`}
                                  defaultValue={null}
                                  control={control}
                                  render={({ onChange, value }) => (
                                    <Input
                                      label={local.name}
                                      key={id}
                                      required
                                      value={value}
                                      type="input"
                                      onInput={(event) =>
                                        handleInputMask(event, 'decimal')
                                      }
                                      prefix="%"
                                      height="40px"
                                      id={`localId.${idSupplier}.${id}`}
                                      group={local.name}
                                      onChange={(e) => {
                                        onChange(e);
                                        validInput();
                                      }}
                                    />
                                  )}
                                />
                              </Col>
                            );
                          })}
                        </SpacingTop>
                      </Row>
                    ))}
                  </Container>
                </Flex>

                <SpacingTop md>
                  <Button
                    name="SALVAR"
                    type="submit"
                    test-dataid="test-button"
                    isDisabled={existField}
                  />
                </SpacingTop>
              </S.ContainerForm>
            </Card>
          </SpacingTop>
        ))}

      {isLoading && <Loading />}
    </form>
  );
};

DieselOther.propTypes = {
  suppliersList: PropTypes.array.isRequired,
};

DieselOther.defaultProps = {};

export default DieselOther;
