import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Flex } from 'components/system/core';
import { Container, Content, Header } from 'components/system/interface';

import NewItemsContent from 'components/contexts/newItems/newItemsList/NewItems';

import * as ROUTES from 'constants/urls';

import useDimensions from 'hooks/useDimensions';

import { getFilterParams } from 'lib/contexts/newItems';

import { NewItemsActions } from 'store/newItems/newItems.ducks';

const breadcrumb = [
  {
    text: 'Formalizações',
    href: `#${ROUTES.CONTRACTS}`,
  },
  {
    text: 'Novos itens',
    href: null,
  },
];

const NewItemsList = () => {
  const dimensions = useDimensions();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      NewItemsActions.newItemsListRequest(
        getFilterParams({ pageIndex: 1, pageSize: 25 }),
      ),
    );
  }, [dispatch]);

  return (
    <Content height={dimensions.height}>
      <Container>
        <Flex>
          <Header bread={breadcrumb} tag="header" title="Novos Itens" />
        </Flex>
        <NewItemsContent />
      </Container>
    </Content>
  );
};

export default NewItemsList;
