import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import 'overlayscrollbars/styles/overlayscrollbars.css';
import PropTypes from 'prop-types';
import React from 'react';

import { ScrollBarContainerStyle } from './styles';

const ScrollBar = ({
  children,
  overflowX,
  overflowY,
  overflow,
  width,
  height,
  ...attrs
}) => {
  const scrollOptions = {
    scrollbars: {
      autoHide: 'move',
    },
    overflowBehavior: {
      x: overflowX || overflow ? 'scroll' : 'hidden',
      y: overflowY || overflow ? 'scroll' : 'hidden',
    },
  };
  return (
    <ScrollBarContainerStyle>
      <OverlayScrollbarsComponent
        className="vli-scrollbar-theme"
        options={scrollOptions}
        style={{ width, height }}
        data-testid="ds-scrollbar"
        {...attrs}
      >
        {children}
      </OverlayScrollbarsComponent>
    </ScrollBarContainerStyle>
  );
};

ScrollBar.propTypes = {
  children: PropTypes.node,
  overflowX: PropTypes.bool,
  overflowY: PropTypes.bool,
  overflow: PropTypes.bool,
  width: PropTypes.any,
  height: PropTypes.any,
};

ScrollBar.defaultProps = {
  children: '',
  overflowX: false,
  overflowY: false,
  overflow: false,
  width: '100%',
  height: 'auto',
};

export default ScrollBar;
