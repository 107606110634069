import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Card, Flex } from 'components/system/core';
import { Container, Content, Header } from 'components/system/interface';

import FilterDs from 'components/core/FilterDs';
import Loading from 'components/core/Loading';

import ReportChart from 'components/contexts/contractsControl/ReportChart';
import ReportList from 'components/contexts/contractsControl/ReportList';

import * as ROUTES from 'constants/urls';
import useDimensions from 'hooks/useDimensions';

import { ContractsControlActions } from 'store/contractsControl/contractsControl.ducks';

import { DataFilter, getFilterParams } from 'lib/contexts/contractsControl';
import { isPending } from 'lib/core/request';

import { subMonths } from 'date-fns';
import * as S from './ContractsControlStyles';

const ContractsUpload = () => {
  const dimensions = useDimensions();
  const dispatch = useDispatch();

  const { contractsControlRequests, reportData, filter } = useSelector(
    ({ contractsControl }) => contractsControl,
  );

  const initialFilter = useMemo(() => {
    return {
      ...filter,
      StartDate: subMonths(new Date(), 6),
      EndDate: new Date(),
    };
  }, [filter]);

  const [dataFilter, setDataFilter] = useState(initialFilter);

  const breadcrumb = [
    {
      text: 'Formalizações',
      href: `#${ROUTES.CONTRACTS}`,
    },
    {
      text: 'Controle de contratos',
      href: null,
    },
  ];

  useEffect(() => {
    const params = getFilterParams(initialFilter);

    dispatch(ContractsControlActions.getContractsControlReport(params));
  }, [dispatch, initialFilter]);

  const isLoading =
    isPending(contractsControlRequests.GET_CONTRACTS_CONTROL_REPORT) ||
    isPending(contractsControlRequests.DOWNLOAD_CONTRACTS_CONTROL_REPORT);

  const handleFilterAction = (filterData) => {
    const params = getFilterParams(filterData);

    dispatch(ContractsControlActions.getContractsControlReport(params));
  };

  const onConfirm = (filterData) => {
    setDataFilter(filterData);
    handleFilterAction(filterData);
  };

  const onClean = () => {
    setDataFilter(initialFilter);
    handleFilterAction(initialFilter);
  };

  const onFilterByChart = (filterData) => {
    setDataFilter(filterData);
    handleFilterAction(filterData);
  };

  const handleDownloadReport = () => {
    const params = getFilterParams(dataFilter);

    dispatch(ContractsControlActions.downloadContractsControlReport(params));
  };

  return (
    <Content
      data-testid="contract-control-page-test"
      height={dimensions.height}
    >
      <Container>
        <Flex justifyContent="space-between" alignItems="center">
          <Header
            bread={breadcrumb}
            tag="header"
            title="Controle de contratos"
          />

          <Button name="baixar relatório" action={handleDownloadReport} />
        </Flex>

        {isLoading ? (
          <Loading />
        ) : (
          <S.Sections>
            <Card>
              <S.CardHeader>
                <h2>Contratos vigentes no último semestre</h2>

                <FilterDs
                  defaultSelectedItems={dataFilter}
                  listItems={DataFilter()}
                  onConfirm={onConfirm}
                  onClean={onClean}
                />
              </S.CardHeader>

              <ReportChart data={reportData} handleFilter={onFilterByChart} />
            </Card>

            <ReportList data={reportData} />
          </S.Sections>
        )}
      </Container>
    </Content>
  );
};

export default ContractsUpload;
