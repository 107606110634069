import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import CheckboxIcon from 'assets/system/svgs/checkbox.svg';

import { IconSource } from './styles';

const Icon = ({
  className,
  src,
  size,
  'aria-label': label,
  color,
  fill,
  ...attrs
}) => {
  const classes = classNames(className);

  return (
    <IconSource
      src={src}
      className={classes}
      data-testid="ds-icon-test"
      beforeInjection={(svg) => {
        svg.setAttribute('width', size);
        svg.setAttribute('height', size);
      }}
      size={size}
      fill={fill}
      {...attrs}
    />
  );
};

Icon.propTypes = {
  src: PropTypes.any,
  className: PropTypes.string,
  size: PropTypes.number,
  'aria-label': PropTypes.string,
};

Icon.defaultProps = {
  src: CheckboxIcon,
  'aria-label': 'icon',
  size: 24,
  className: '',
};

export default Icon;
