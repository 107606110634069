import css from '@styled-system/css';
import styled from 'styled-components';
import { compose, flexbox, layout, space } from 'styled-system';

import theme from '../../tokens';

export const CardStyle = styled('')(
  css({
    display: 'flex',
    flexDirection: 'column',
    bg: theme.colors.grayWhite,
    boxShadow: theme.effects.cardShadow,
    borderRadius: '4px',
    padding: '12px',
    font: theme.fonts.textRegularMedium,
    '> header.card-title': {
      font: theme.fonts.textBoldSmall,
      textTransform: 'uppercase',
      color: theme.colors.gray500,
      mb: '12px',
      '&.has-divider': {
        mb: '0px',
      },
    },
  }),
  compose(space, flexbox, layout),
);
