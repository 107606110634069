import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { Button, Flex } from 'components/system/core';
import { Input } from 'components/system/form';

import { railwayItemStatus } from 'lib/core/utility';
import { RailwayItemsHistoryActions } from 'store/railwayItemsHistory/railwayItemsHistory.ducks';

import * as S from './CloseItemModalStyles';

const CloseItemModal = ({ onClose, isOpen }) => {
  const dispatch = useDispatch();

  const { railwayItems } = useSelector(
    ({ railwayItemsHistory }) => railwayItemsHistory,
  );

  const validationSchema = Yup.object().shape({
    justification: Yup.string()
      .min(15, 'A justificativa deve ter mínimo de 15 caracteres')
      .max(256, 'A justificativa deve ter maxima de 256 caracteres')
      .required('A justificativa é um campo requerido'),
  });

  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      justification: '',
    },
  });

  const callback = () => {
    dispatch(
      RailwayItemsHistoryActions.listRailwayItemsHistory({
        code: railwayItems.code,
        status: railwayItemStatus.encerrado,
      }),
    );
  };

  const onSubmit = (data) => {
    const id = railwayItems?.historyList?.[0]?.railwayItemId;

    const payload = {
      railwayItemId: id,
      justification: data.justification,
    };

    dispatch(RailwayItemsHistoryActions.closeRailwayItem(payload, callback));

    onClose();
  };

  return (
    <S.ModalContainer
      title={`Encerrar Item ${railwayItems.code}`}
      isOpen={isOpen}
      onClose={onClose}
      width="400px"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="justification"
          control={control}
          render={({ ...field }) => (
            <Input
              {...field}
              type="textarea"
              label="Justificativa"
              placeholder="Justifique aqui"
              required
              status={errors?.justification && 'error'}
              statusMessage={
                errors?.justification && errors.justification.message
              }
              maxLength={256}
            />
          )}
        />

        <Flex marginTop="16px" justifyContent="flex-end">
          <Button
            data-testid="cancel-button-test"
            variant="secondary"
            name="CANCELAR"
            onClick={onClose}
            marginRight="8px"
          />

          <Button
            data-testid="submit-button-test"
            variant="primary"
            name="CONFIRMAR"
            type="submit"
          />
        </Flex>
      </form>
    </S.ModalContainer>
  );
};

CloseItemModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isClose: PropTypes.bool.isRequired,
};

export default CloseItemModal;
