import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loading from 'components/core/Loading';
import Table from 'components/core/Table/Table';

import { isFinished, isPending } from 'lib/core/request';
import { CustomerActions } from 'store/customer/customer.ducks';

import { Card, EmptyState } from 'components/system/core';

import { Columns } from './Columns';

import * as S from './ClientTable.styles';

const ClientsTable = () => {
  const { customerList, customerRequests, editMode } = useSelector(
    ({ customer }) => customer,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(CustomerActions.listCustomer());
  }, [dispatch]);

  const isLoading =
    isPending(customerRequests.LIST_CUSTOMER) &&
    isPending(customerRequests.UPDATE_CUSTOMER);

  return (
    <>
      {isLoading && <Loading />}

      <S.TableContainer>
        {customerList.length > 0 ? (
          <Table
            width="100%"
            tableType="responsive"
            footer={false}
            data={customerList}
            columns={Columns}
            isEditMode={editMode}
          />
        ) : (
          isFinished(customerRequests.LIST_CUSTOMER) && (
            <Card>
              <EmptyState
                condensed={false}
                title="Nenhum cliente encontrado."
              />
            </Card>
          )
        )}
      </S.TableContainer>
    </>
  );
};

export default ClientsTable;
