import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import FolderIcon from 'assets/system/svgs/folder.svg';

import { Icon } from 'components/system/core';
import { Label } from 'components/system/form';

import {
  Field,
  FieldContainer,
  FileGroup,
  InputGroup,
  SelectedFiles,
  StatusText,
} from './styles';

const MultipleFileInput = ({
  name,
  label,
  value,
  placeholder,
  required,
  width,
  disabled,
  error,
  errorMessage,
  onChange,
  onBlur,
  ref,
  ...attrs
}) => {
  const [filesTitle, setFilesTitle] = useState([]);
  const [focus, setFocus] = useState(false);
  const [valueField, setValueField] = useState('');

  const inputRef = useRef(ref);
  const inputGroupRef = useRef(null);

  const handleInputOnChange = (e) => {
    const filesArray = Array.from(e.target.files);

    if (filesArray.length > 0) {
      const filesNames = filesArray.map((file) => file.name);

      setFilesTitle((oldState) => [...oldState, ...filesNames]);
    } else {
      setFilesTitle([]);
    }

    onChange(filesArray);
  };

  useEffect(() => {
    if (!inputRef.current) return;
    const inputNode = inputRef.current;
    const inputGroupNode = inputGroupRef.current;

    if (inputNode && inputGroupNode) {
      inputGroupNode.addEventListener('click', () => {
        if (!focus) {
          inputNode.focus();
          setFocus(true);
        }
      });

      inputNode.addEventListener('focus', () => setFocus(true));
      inputNode.addEventListener('focusout', () => {
        setFocus(false);
      });
    }
  }, [inputRef, valueField, focus]);

  useEffect(() => {
    setValueField(value);
  }, [value]);

  return (
    <>
      <InputGroup width={width} ref={inputGroupRef}>
        {label && (
          <Label>
            {label}
            {!required && <span> (Opcional)</span>}
          </Label>
        )}

        <FieldContainer disabled={disabled} focus={focus}>
          <Field
            data-testid={`file-input-${name.replace(' ', '-').toLowerCase()}`}
            type="file"
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            ref={inputRef}
            focus={focus}
            id={`file-input-${name.replace(' ', '-').toLowerCase()}`}
            onChange={handleInputOnChange}
            onBlur={onBlur}
            multiple
            {...attrs}
          />
        </FieldContainer>

        <FileGroup disabled={disabled}>
          <label htmlFor={`file-input-${name.replace(' ', '-').toLowerCase()}`}>
            <span>{placeholder}</span>
            <Icon src={FolderIcon} />
          </label>
        </FileGroup>
        {error && <StatusText>{errorMessage}</StatusText>}
      </InputGroup>

      {filesTitle.length > 0 && (
        <SelectedFiles>
          <span>Arquivos selecionados:</span>

          {filesTitle.map((title) => (
            <small key={title}>{title}</small>
          ))}
        </SelectedFiles>
      )}
    </>
  );
};

MultipleFileInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
  width: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
};

MultipleFileInput.defaultProps = {
  label: '',
  required: false,
  width: 'auto',
  disabled: false,
  error: '',
  errorMessage: '',
  onBlur: () => {},
};

export default MultipleFileInput;
