import PropTypes from 'prop-types';
import React from 'react';
import ReactDOMServer from 'react-dom/server';

import Tokens from 'components/system/tokens';
import { Text } from 'components/system/typography';

import Tooltip from 'components/core/Tooltip';

import TooltipInfo from './TooltipInfo';

const CellContractedValue = ({
  cell: { value },
  informative,
  transformedData,
  row,
  onClick,
  align,
  color,
  opacity,
  pointer = true,
}) => {
  const { original } = row;
  const cursor = pointer ? 'pointer' : 'auto';

  const handleOnClick = () => {
    if (original) {
      onClick(original);
    }
    return null;
  };

  return (
    <Tooltip
      title={ReactDOMServer.renderToString(
        <TooltipInfo data={original.monthlyContractValueServiceTooltip} />,
      )}
      id={row.id}
      place="top"
    >
      <Text
        size="medium"
        data-testid={`cell-table-${value}`}
        style={{ cursor, textAlign: align, fontWeight: '400' }}
        onClick={handleOnClick}
        color={color}
        opacity={opacity}
      >
        {value !== undefined && (transformedData(value) || '-')} {informative}
      </Text>
    </Tooltip>
  );
};

CellContractedValue.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  informative: PropTypes.string,
  transformedData: PropTypes.func,
  onClick: PropTypes.func,
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      historyOrigin: PropTypes.number,
      tooltip: PropTypes.arrayOf(PropTypes.object),
      flagFarol: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

CellContractedValue.defaultProps = {
  cell: {
    value: '',
  },
  row: {
    id: 0,
    original: {
      id: 1,
      flagFarol: Tokens.colors.grayBlack,
    },
  },
  informative: '',
  transformedData: (text) => text,
  onClick: () => {},
};

export default CellContractedValue;
