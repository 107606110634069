import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, EmptyState } from 'components/system/core';

import Loading from 'components/core/Loading';
import Table from 'components/core/Table';
import TableContainer from 'components/core/TableContainer';

import { getFilterManagerParams } from 'lib/contexts/notification';
import { isFinished, isPending } from 'lib/core/request';

import { EmailNotificationManagerActions } from 'store/emailNotificationsManager/emailNotificationManager.ducks';

import usePagination from 'hooks/usePagination';

import Columns from './Columns';

const ManagerList = () => {
  const dispatch = useDispatch();
  const {
    filter,
    emailNotificationManagerList,
    emailNotificationManagerRequests,
  } = useSelector(({ emailNotificationManager }) => emailNotificationManager);
  const { paginationSize, setValuePage } = usePagination();
  const onPageChange = (PageIndex) => {
    const { pageSize } = emailNotificationManagerList;
    const params = getFilterManagerParams({
      pageIndex: PageIndex,
      pageSize,
      ...filter,
    });
    dispatch(
      EmailNotificationManagerActions.listEmailNotificationManager(params),
    );
  };
  const onPerPageChange = (PageSize) => {
    const { pageIndex } = emailNotificationManagerList;

    const params = getFilterManagerParams({
      pageIndex,
      pageSize: PageSize,
      ...filter,
    });

    dispatch(
      EmailNotificationManagerActions.listEmailNotificationManager(params),
    );
    setValuePage(PageSize);
  };

  useEffect(() => {
    dispatch(EmailNotificationManagerActions.listEmailNotificationManager(''));
  }, [dispatch]);

  return (
    <TableContainer data-testid="contractcontainer-test">
      {isPending(
        emailNotificationManagerRequests.LIST_EMAIL_NOTIFICATION_MANAGER,
      ) && <Loading />}
      {emailNotificationManagerList.items?.length ? (
        <Table
          data-testid="contractlist-test"
          width="100%"
          columns={Columns}
          data={emailNotificationManagerList.items}
          onPageChange={onPageChange}
          onPerPageChange={onPerPageChange}
          footer
          customList={paginationSize}
          pageIndex={emailNotificationManagerList.currentPage}
          pageCount={emailNotificationManagerList.totalPages}
          totalItems={emailNotificationManagerList.totalCount}
        />
      ) : (
        isFinished(emailNotificationManagerRequests.LIST_EMAIL_NOTIFICATION) &&
        emailNotificationManagerList.items?.length === 0 && (
          <Card>
            <EmptyState condensed={false} title="Nenhuma base encontrada." />
          </Card>
        )
      )}
    </TableContainer>
  );
};

export default ManagerList;
