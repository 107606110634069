import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import IcOption from 'assets/icons/table/options.svg';

import { Button, Popover } from 'components/system/core';
import Tokens from 'components/system/tokens';
import { Text } from 'components/system/typography';

import { history } from 'constants/routes';
import * as ROUTES from 'constants/urls';

import { UserActions } from 'store/user/user.ducks';

import * as S from 'components/core/Table/CellWithActions/CellWithActions.styles';

const CellWithActions = ({ row }) => {
  const dispatch = useDispatch();
  const user = row.original;
  const handleEditUser = () => {
    history.push(ROUTES.EDIT_USER(user.id));
  };

  const handleRemoveProfile = () => {
    dispatch(UserActions.setDeleteUser({ user, modalOpen: true }));
  };

  const Options = (
    <S.OptionsContainer>
      <S.OptionItem data-testid="test-edit" onClick={handleEditUser}>
        <Text size="medium" style={{ fontWeight: '400' }}>
          Editar Usuário
        </Text>
      </S.OptionItem>

      <S.OptionItem
        data-testid="test-decline"
        color={Tokens.colors.gray300}
        onClick={handleRemoveProfile}
      >
        <Text
          size="medium"
          style={{ fontWeight: '400' }}
          color={Tokens.colors.grayBlack}
        >
          Inativar/Reativar
        </Text>
      </S.OptionItem>
    </S.OptionsContainer>
  );

  return (
    <S.ContentCell data-testid="cell-with-action">
      <Popover content={Options} position="left center">
        <Button
          data-testid="test-button"
          icon={IcOption}
          name=""
          variant="tertiary"
        />
      </Popover>
    </S.ContentCell>
  );
};

CellWithActions.propTypes = {
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CellWithActions;
