import React from 'react';

import * as utility from 'lib/core/utility';

import { history } from 'constants/routes';
import * as ROUTES from 'constants/urls';

import CellTable from 'components/core/Table/CellTable';
import CellTruncateTable from 'components/core/Table/CellTruncateTable';

import CellStatusTable from '../../CellStatusTable';
import CellWithActions from '../../CellWithActions';

export const clickRow = (row) => {
  if (row?.history?.canRedirectToHistoryPage) {
    history.push(
      ROUTES.RAILWAY_ITEMS_HISTORY_PARAMS(
        row.railwayItemId,
        row.status.toLowerCase(),
      ),
    );
  }
};

const Columns = [
  {
    Header: 'Item',
    accessor: 'railwayItemCode',
    align: 'start',
    width: 80,
    Cell: (props) => <CellTable {...props} onClick={clickRow} />,
  },
  {
    Header: 'Origem/Destino',
    accessor: 'originDestination',
    align: 'start',
    minWidth: 50,
    Cell: (props) => (
      <CellTruncateTable {...props} onClick={clickRow} numberTruncate={100} />
    ),
  },
  {
    Header: 'Observação',
    accessor: 'observation',
    align: 'start',
    width: 200,
    Cell: (props) => <CellTable {...props} onClick={clickRow} />,
  },
  {
    Header: 'Produto',
    accessor: 'productUnicomMerchandiseTacito',
    align: 'start',
    width: 200,
    Cell: (props) => <CellTable {...props} onClick={clickRow} />,
  },
  {
    Header: 'Data de Aplicação',
    accessor: 'dateApplication',
    align: 'start',
    minWidth: 80,
    Cell: (props) => (
      <CellTable
        {...props}
        transformedData={utility.formatDate}
        onClick={clickRow}
      />
    ),
  },
  {
    Header: 'Contratado',
    accessor: 'valueContracted',
    align: 'start',
    minWidth: 40,
    Cell: (props) => (
      <CellTable
        {...props}
        transformedData={utility.formatMoney}
        onClick={clickRow}
      />
    ),
  },
  {
    Header: 'Flink',
    accessor: 'valueRetrieved',
    align: 'start',
    minWidth: 40,
    Cell: (props) => (
      <CellTable
        {...props}
        transformedData={utility.formatMoney}
        onClick={clickRow}
      />
    ),
  },
  {
    Header: 'Calculado',
    accessor: 'calculatedValue',
    align: 'start',
    minWidth: 40,
    Cell: (props) => (
      <CellTable
        {...props}
        transformedData={utility.formatMoney}
        onClick={clickRow}
      />
    ),
  },
  {
    Header: 'Status Preço',
    accessor: 'statusText',
    align: 'start',
    Cell: (props) => <CellStatusTable {...props} onClick={clickRow} />,
  },
  {
    Header: 'Ações',
    accessor: 'action',
    align: 'end',
    width: 50,
    Cell: (props) => <CellWithActions {...props} />,
  },
];

export default Columns;
