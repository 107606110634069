import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loading } from 'components/system/core';

import { FormalizationRequestActions } from 'store/formalizationRequest/formalizationRequest.ducks';

import { isPending } from 'lib/core/request';
import { capitalizeFirstString, formatMoney, simpleId } from 'lib/core/utility';

import SalesData from './Sections/SalesData';

import * as S from './ServiceModalStyles';

const ServiceModal = ({ isOpen, onClose, saleId }) => {
  const dispatch = useDispatch();

  const { services: saleServices, salesRequest } = useSelector(
    ({ formalizationRequest }) => formalizationRequest,
  );

  useEffect(() => {
    if (isOpen) {
      dispatch(FormalizationRequestActions.servicesBySaleId(saleId));
    }
  }, [saleId, dispatch, isOpen]);

  const isLoading = isPending(salesRequest.SERVICES_BY_SALE_ID);

  const columns = saleServices?.groupedServices[0]?.serviceValues;
  const hasMicroRegion = saleServices?.groupedServices?.some(
    (service) => service?.microRegion,
  );
  let countOfServices = saleServices?.groupedServices[0]?.serviceValues?.length;
  countOfServices = hasMicroRegion ? countOfServices + 1 : countOfServices;

  const formatDate = (date) => {
    if (date) return format(new Date(date), 'MM/yyyy', { locale: ptBR });

    return '-';
  };

  return (
    <S.ModalContainer
      title="Abertura de preço por serviço"
      isOpen={isOpen}
      onClose={onClose}
      width="1150px"
    >
      <S.Scroll>
        {isLoading && (
          <Loading fitParent message="Por favor, espere alguns segundos..." />
        )}

        {saleServices && (
          <S.Wrapper>
            <SalesData
              modalName={saleServices.modalName}
              subSegment={saleServices.subSegment}
              segment={saleServices.segment}
              originName={saleServices.originName}
              destinyName={saleServices.destinyName}
              customerName={saleServices.customerName}
              productName={saleServices.productName}
            />

            <S.Table data-testid="opening-table-test">
              <S.TableLine
                columnsNumber={countOfServices + 1}
                hasMicroRegion={hasMicroRegion}
              >
                <S.TableColumn>
                  <span>Período</span>
                </S.TableColumn>

                {hasMicroRegion && (
                  <S.TableColumn>
                    <span>Micro/Destinação</span>
                  </S.TableColumn>
                )}

                {columns.map((service) => {
                  return (
                    <S.TableColumn key={`column-${service.name}`}>
                      <span>{capitalizeFirstString(service.name)}</span>
                    </S.TableColumn>
                  );
                })}

                <S.TableColumn>
                  <span>Total do frete</span>
                </S.TableColumn>
              </S.TableLine>

              {saleServices?.groupedServices.map((service) => (
                <S.TableLine
                  columnsNumber={countOfServices + 1}
                  hasMicroRegion={hasMicroRegion}
                  key={`service-${simpleId()}`}
                >
                  <S.TableColumn>
                    <small>{formatDate(service.period)}</small>
                  </S.TableColumn>

                  {service.microRegion && (
                    <S.TableColumn>
                      <small>{service.microRegion}</small>
                    </S.TableColumn>
                  )}

                  {service.serviceValues.map((serviceValue, index) => (
                    <S.TableColumn key={`${serviceValue + index}`}>
                      <small>{formatMoney(serviceValue.value)}</small>
                    </S.TableColumn>
                  ))}

                  <S.TableColumn>
                    <small>{formatMoney(service.serviceTotalValue)}</small>
                  </S.TableColumn>
                </S.TableLine>
              ))}
            </S.Table>
          </S.Wrapper>
        )}
      </S.Scroll>
    </S.ModalContainer>
  );
};

ServiceModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  saleId: PropTypes.number.isRequired,
};

export default ServiceModal;
