import styled from 'styled-components';

import Tokens from 'components/system/tokens';

export const Table = styled.table`
  min-width: 200px;
  max-width: 280px;
  table-layout: fixed;
  border-collapse: collapse;
`;
export const TBody = styled.tbody`
  display: block;
  width: 100%;
  overflow: auto;
`;
export const Thead = styled.thead`
  tr {
    display: block;
    border-bottom: 0.5px solid #d1d1d1;
  }
`;
export const Tr = styled.tr`
  :hover {
    background-color: ${Tokens.colors.accentPrimaryLight};
  }
`;

export const Th = styled.th`
  width: 100px;
  padding: ${Tokens.spacing.xs};
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
  text-align: left;
`;

export const Td = styled.td`
  text-align: left;
  width: 100px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin: 0px 4px;
  padding: ${Tokens.spacing.xs};
`;
