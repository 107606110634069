import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import ArrowUpIcon from 'assets/system/svgs/up.svg';

import Icon from '../icon';

import { BackToTopStyle } from './styles';

const BackToTop = ({ scrollElement, ...attrs }) => {
  const [active, setActive] = useState(false);
  const [pagePositionY, setPagePositionY] = useState(0);

  const handleScroll = useCallback(
    (evt) => {
      let scrollTop = 0;

      if (scrollElement) {
        scrollTop = evt.target.scrollTop;
      } else {
        scrollTop = evt.target.querySelector('html').scrollTop;
      }

      setPagePositionY(scrollTop);
    },
    [scrollElement],
  );

  useEffect(() => {
    if (scrollElement) {
      const getParentElement = document.querySelector(scrollElement);
      getParentElement.addEventListener('scroll', handleScroll);
      return () => getParentElement.removeEventListener('scroll', handleScroll);
    }
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll, scrollElement]);

  useEffect(() => {
    if (!pagePositionY) {
      setActive(false);
    }
  }, [pagePositionY]);

  const setButtonAndScroll = () => {
    if (pagePositionY && !active) {
      setActive(true);
      if (scrollElement) {
        const getScrollElement = document.querySelector(scrollElement);
        getScrollElement.scroll({ top: 0, behavior: 'smooth' });
      } else {
        window.scroll({ top: 0, behavior: 'smooth' });
      }
    }
  };

  return (
    <BackToTopStyle
      data-testid="ds-back-to-top"
      variant={(!pagePositionY && 'top') || (active && 'active')}
      onClick={setButtonAndScroll}
      {...attrs}
    >
      <Icon src={ArrowUpIcon} />
    </BackToTopStyle>
  );
};

BackToTop.propTypes = {
  scrollElement: PropTypes.string,
};

BackToTop.defaultProps = {
  scrollElement: '',
};

export default BackToTop;
