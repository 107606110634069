import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import IcOption from 'assets/icons/table/options.svg';

import { Button, Popover } from 'components/system/core';
import { Text } from 'components/system/typography';

import { history } from 'constants/routes';
import * as ROUTES from 'constants/urls';

import { ReportContractItemsActions } from 'store/reportContractItems/reportContractItems.ducks';

import * as S from 'components/core/Table/CellWithActions/CellWithActions.styles';

const CellWithActions = ({ row: { original } }) => {
  const dispatch = useDispatch();

  const handleSettings = () => {
    history.push(ROUTES.REPORT_CONTRACT_ITEMS_UPDATE(original.contractId));
  };

  const callback = () =>
    dispatch(ReportContractItemsActions.listReportContractItems(''));

  const handleChangeServices = () => {
    dispatch(
      ReportContractItemsActions.updateReportServices(
        original.contractId,
        callback,
      ),
    );
  };

  const handleChangePiscofins = () => {
    dispatch(
      ReportContractItemsActions.updateReportPiscofins(
        original.contractId,
        callback,
      ),
    );
  };

  const handleChangeHistoric = () => {
    dispatch(
      ReportContractItemsActions.updateReportHistoric(
        original.contractId,
        callback,
      ),
    );
  };

  const Options = (
    <S.OptionsContainer>
      <S.OptionItem data-testid="test-option-settings" onClick={handleSettings}>
        <Text size="medium" style={{ fontWeight: '400' }}>
          Gerenciar
        </Text>
      </S.OptionItem>

      {original.subSegmentName !== 'GRÃOS' && (
        <>
          <S.OptionItem
            data-testid="test-option-services"
            onClick={handleChangeServices}
          >
            <Text size="medium" style={{ fontWeight: '400' }}>
              {!original.enabledServices
                ? 'Exibir quebra de serviços'
                : 'Ocultar quebra de serviços'}
            </Text>
          </S.OptionItem>

          <S.OptionItem
            data-testid="test-option-piscofins"
            onClick={handleChangePiscofins}
          >
            <Text size="medium" style={{ fontWeight: '400' }}>
              {!original.enabledPiscofins
                ? 'Exibir tarifa Piscofins'
                : 'Ocultar tarifa Piscofins'}
            </Text>
          </S.OptionItem>

          <S.OptionItem
            data-testid="test-option-historic"
            onClick={handleChangeHistoric}
          >
            <Text size="medium" style={{ fontWeight: '400' }}>
              {!original.enabledHistoric
                ? 'Exibir histórico'
                : 'Ocultar histórico'}
            </Text>
          </S.OptionItem>
        </>
      )}
    </S.OptionsContainer>
  );

  return (
    <S.ContentCell data-testid="cell-with-action">
      <Popover content={Options} position="left center">
        <Button
          data-testid="test-action-button"
          icon={IcOption}
          name=""
          variant="tertiary"
        />
      </Popover>
    </S.ContentCell>
  );
};

CellWithActions.propTypes = {
  row: {
    original: {
      enabledServices: PropTypes.bool,
    },
  },
};

CellWithActions.defaultProps = {
  row: {
    original: {
      enabledServices: false,
    },
  },
};

export default CellWithActions;
