/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Card, Flex } from 'components/system/core';
import { Input, Date as InputDate } from 'components/system/form';
import Tokens from 'components/system/tokens';
import { Heading, Text } from 'components/system/typography';

import handleInputMask from 'lib/core/handleInputMask';
import { IndicesActions } from 'store/indices/indices.ducks';

import * as S from './InflationaryCard.styles';

const InflationaryCard = () => {
  const dispatch = useDispatch();

  const [enableButton, setEnableButton] = useState(true);
  const {
    handleSubmit,
    errors,
    control,
    getValues,

    setValue,
  } = useForm();
  const { inflationaryList } = useSelector(({ indices }) => indices);
  const enableSaveBtn = () => {
    const formValues = getValues();
    let state = true;

    if (formValues.readjustment && formValues.date)
      Object.keys(formValues.readjustment).forEach((key) => {
        if (formValues.readjustment[key] !== '' && formValues.readjustment[key])
          state = false;
      });

    setEnableButton(state);
  };
  const reload = () => {
    setValue('date', null);
    const data = getValues();
    Object.keys(data.readjustment).forEach((property) => {
      if (data.readjustment[property]) {
        setValue(`readjustment.${property}`, ' ');
      }
    });
    setEnableButton(true);
  };
  const onSubmit = (data) => {
    const { date } = data;
    const readjustment = [];

    Object.keys(data.readjustment).forEach((property) => {
      if (data.readjustment[property] !== '' && data.readjustment[property])
        readjustment.push({
          percentage: parseFloat(
            data.readjustment[property].replaceAll('.', '').replace(',', '.'),
          ),
          inflationIndicatorId: property,
          startPeriod: new Date(date),
        });
    });

    dispatch(
      IndicesActions.inflationaryReadjustmentStart(readjustment, reload),
    );
  };

  const renderInput = ({ item, name }) => (
    <Controller
      name={`${name}.${item.id}`}
      control={control}
      defaultValue={null}
      render={({ onChange, value }) => (
        <Input
          label={item.name}
          key={item.id}
          value={value}
          required
          type="input"
          id={`${name}-${item.id}`}
          onInput={(event) => handleInputMask(event, 'decimal')}
          prefix="%"
          height="40px"
          group={item.name}
          onChange={(e) => {
            onChange(e);
            enableSaveBtn();
          }}
        />
      )}
    />
  );

  return (
    <Card style={{ height: '150px' }} data-testid="test-inflation">
      <S.CardHeader>
        <Heading tag="h5" color={Tokens.colors.gray300} variant={['bold']}>
          Índices inflacionários
        </Heading>
      </S.CardHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <S.ContainerForm>
          <Flex>
            <S.Group>
              <div>
                <div>
                  <S.Label>Data</S.Label>
                </div>
                <div>
                  <Controller
                    name="date"
                    defaultValue={null}
                    rules={{ required: true }}
                    control={control}
                    render={({ onChange, value }) => (
                      <S.ContainerDate>
                        <InputDate
                          format="MM/y"
                          selected={value}
                          value={value}
                          locale="pt-BR"
                          maxDetail="year"
                          onChange={(e) => onChange(e)}
                        />
                      </S.ContainerDate>
                    )}
                  />
                  {errors.date && (
                    <S.MessageErrors>
                      <Text color={Tokens.colors.red400}>Campo requerido</Text>
                    </S.MessageErrors>
                  )}
                </div>
              </div>
            </S.Group>
            {inflationaryList.map((item) => (
              <S.Group key={item.id}>
                <div>{renderInput({ item, name: 'readjustment' })}</div>
              </S.Group>
            ))}
            <S.Group>
              <S.ContainerButton>
                <Button name="SALVAR" disabled={enableButton} type="submit" />
              </S.ContainerButton>
            </S.Group>
          </Flex>
        </S.ContainerForm>
      </form>
    </Card>
  );
};

InflationaryCard.propTypes = {};

InflationaryCard.defaultProps = {};

export default InflationaryCard;
