import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Flex } from 'components/system/core';
import { Text } from 'components/system/typography';

import { useDispatch, useSelector } from 'react-redux';

import {
  DataFilter,
  getFilterParams,
  setBaseDieselLocalTypeNameOptions,
} from 'lib/contexts/newItems';

import { ActiveContractActions } from 'store/activeContracts/activeContracts.ducks';
import { LocalActions } from 'store/local/local.ducks';
import { NewItemsActions } from 'store/newItems/newItems.ducks';
import { SegmentActions } from 'store/segment/segment.ducks';

import FilterDS from 'components/core/FilterDs';
import InputSelect from 'components/form/InputSelect';

import { history } from 'constants/routes';
import * as ROUTES from 'constants/urls';

import * as S from './FilterNewItems.styles';

const FilterNewItems = ({ options }) => {
  const dispatch = useDispatch();
  const { itemsForFilter } = useSelector(({ newItems }) => newItems);
  const { data } = useSelector(({ local }) => local);
  const { segmentProfileList: segmentList } = useSelector(
    ({ segment }) => segment,
  );
  const { activeContractList } = useSelector(
    ({ activeContracts }) => activeContracts,
  );
  const [viewScenario, setViewScenario] = useState(options[0].value);
  const [dataFilter, setDataFilter] = useState({});

  useEffect(() => {
    dispatch(NewItemsActions.getItemsForFilterRequest());
    dispatch(LocalActions.localFilteredList('withDeleted=false'));
    dispatch(SegmentActions.listSegmentActive());
    dispatch(ActiveContractActions.activeContract());
  }, [dispatch]);

  const filterAction = (filterData) => {
    const params = getFilterParams(filterData);
    dispatch(NewItemsActions.newItemsListRequest(params));
  };

  const onChange = (value) => {
    setViewScenario(value);
    filterAction(value);
  };
  const onConfirm = (dataParams) => {
    setDataFilter(dataParams);
    filterAction(dataParams);
    dispatch(NewItemsActions.getItemsForFilterRequest());
  };
  const onClean = () => {
    setDataFilter({});
    filterAction({});
    dispatch(NewItemsActions.getItemsForFilterRequest());
  };
  return (
    <Flex justifyContent="space-between">
      <S.WrapperSelectFilter>
        <Text
          size="large"
          style={{
            fontWeight: '400',
          }}
        >
          Exibindo
        </Text>
        <S.SpaceSelect>
          <InputSelect
            value={viewScenario}
            label=""
            options={options}
            placeholder={options[0].label}
            width="220px"
            onChange={onChange}
          />
        </S.SpaceSelect>
      </S.WrapperSelectFilter>

      <S.WrapperFilter data-testid="dts-filter">
        <Flex>
          <FilterDS
            defaultSelectedItems={dataFilter}
            listItems={
              data.length &&
              DataFilter(
                activeContractList,
                segmentList,
                itemsForFilter,
                setBaseDieselLocalTypeNameOptions(data),
              )
            }
            onConfirm={onConfirm}
            onClean={onClean}
          />
          <S.CreateButton
            onClick={() => history.push(ROUTES.NEW_ITEM)}
            name="Criar"
            type="button"
          />
        </Flex>
      </S.WrapperFilter>
    </Flex>
  );
};

FilterNewItems.defaultProps = {
  options: [
    {
      label: 'Inserção manual',
      value: 'manual',
    },
  ],
};

FilterNewItems.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

export default FilterNewItems;
