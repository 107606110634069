import PropTypes from 'prop-types';
import React from 'react';

import ErrorIcon from 'assets/system/svgs/error.svg';
import SuccessIcon from 'assets/system/svgs/success.svg';
import WarningIcon from 'assets/system/svgs/warning.svg';

import Icon from '../icon';

import { AlertStyle } from './styles';

const Alert = ({ title, text, type }) => {
  const alertType =
    (type === 'error' && 'error') || (type === 'warning' && 'warning');

  const alertIcons = {
    success: SuccessIcon,
    error: ErrorIcon,
    warning: WarningIcon,
  };

  return (
    <AlertStyle data-testid="ds-alert" variant={alertType}>
      <div>
        <Icon src={alertIcons[alertType]} width={18} height={18} />
      </div>
      <div>
        <h2>{title}</h2>
        <p>{text}</p>
      </div>
    </AlertStyle>
  );
};

Alert.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
};

Alert.defaultProps = {
  title: '',
  text: '',
  type: 'success',
};

export default Alert;
