import PropTypes from 'prop-types';
import React from 'react';

import { Button } from 'components/system/core';

import Modal from 'components/core/modal';

const ModalConfirmOnly = ({
  closeModal,
  confirmAction,
  titleConfirm,
  title,
  message,
}) => {
  const actions = () => (
    <Button
      variant="primary"
      data-testid="btn-confirm"
      name={titleConfirm}
      onClick={confirmAction}
    />
  );
  return (
    <Modal
      data-testid="modal-test"
      title={title}
      onClose={closeModal}
      actions={actions}
    >
      <div
        style={{ fontWeight: 400 }}
        dangerouslySetInnerHTML={{ __html: message }}
      />
    </Modal>
  );
};
ModalConfirmOnly.defaultProps = {
  title: '',
  titleConfirm: 'OK',
  message: '',
  closeModal: () => {},
  confirmAction: () => {},
};

ModalConfirmOnly.propTypes = {
  title: PropTypes.string,
  titleConfirm: PropTypes.string,
  message: PropTypes.string,
  closeModal: PropTypes.func,
  confirmAction: PropTypes.func,
};

export default ModalConfirmOnly;
