import styled from 'styled-components';

import { Modal } from 'components/system/core';
import Tokens from 'components/system/tokens';

export const InputWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 16px;

  margin: ${Tokens.spacing.sm} 0;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  margin-top: ${Tokens.spacing.sm};
`;

export const CustomModal = styled(Modal)`
  #divider {
    display: none;
  }

  form {
    width: 430px;
  }
`;
