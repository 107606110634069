import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { CloseIcon } from 'assets/system/svgs/close.svg';
import Icon from '../icon';

import { CloseButton, Container, ItemContainer } from './styles';

const LightBox = ({ open, children, ...attrs }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <Container id="lightbox" data-testid="ds-lightbox" open={isOpen} {...attrs}>
      <CloseButton onClick={() => setIsOpen(false)}>
        <Icon src={CloseIcon} />
      </CloseButton>
      <ItemContainer>{children}</ItemContainer>
    </Container>
  );
};

LightBox.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node,
};

LightBox.defaultProps = {
  open: true,
  children: '',
};

export default LightBox;
