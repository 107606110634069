import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Content } from 'components/system/interface';

import Form from 'components/contexts/profile/form';
import Loading from 'components/core/Loading';

import { getDataRoles } from 'lib/contexts/permissions';
import { isPending } from 'lib/core/request';

import useDimensions from 'hooks/useDimensions';

import { ProfileActions } from 'store/profile/profile.ducks';

import * as ROUTES from 'constants/urls';

const breadcrumb = [
  {
    text: 'Perfis',
    href: `#${ROUTES.PROFILE_LIST}`,
  },
  {
    text: 'Novo Perfil',
    href: `#${ROUTES.NEW_PROFILE}`,
  },
];

const CreateProfile = () => {
  const dimensions = useDimensions();
  const dispatch = useDispatch();

  const { privilegesSchema, profilesRequests } = useSelector(
    ({ profile }) => profile,
  );

  useEffect(() => {
    dispatch(ProfileActions.fetchPrivilegesSchema());
  }, [dispatch]);

  const onSubmit = (values) => {
    const payload = getDataRoles(values);
    dispatch(ProfileActions.createProfile(payload));
  };

  const loading =
    isPending(profilesRequests.FETCH_PRIVILEGES_SCHEMA) ||
    isPending(profilesRequests.CREATE_PROFILE);

  return (
    <Content data-testid="content" height={dimensions.height?.toString()}>
      <Container>
        {loading && <Loading />}

        {!loading && (
          <Form
            breadcrumb={breadcrumb}
            onSubmit={onSubmit}
            method="create"
            roles={privilegesSchema}
          />
        )}
      </Container>
    </Content>
  );
};

export default CreateProfile;
