/* eslint-disable react/prop-types */
import React from 'react';

import { DotCircle } from './styles';

const CustomDot = ({ carouselItems, onClick, ...rest }) => {
  const { index, active } = rest;
  return (
    <DotCircle
      type="button"
      data-testid="ds-carousel-dots"
      active={active}
      onClick={() => onClick()}
    >
      {React.Children.toArray(carouselItems)[index]}
    </DotCircle>
  );
};

export default CustomDot;
