import styled from 'styled-components';

import Tokens from 'components/system/tokens';

export const InputContainer = styled.div`
  .react-date-picker__wrapper {
    min-width: 0 !important;

    border-color: ${(props) =>
      props.status === 'error'
        ? Tokens.colors.red300
        : Tokens.colors.gray200} !important;
  }
`;
