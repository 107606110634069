import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Flex } from 'components/system/core';

import {
  DataFilter,
  getFilterParams,
  setFilterDefault,
} from 'lib/contexts/indices';

import { IndicesActions } from 'store/indices/indices.ducks';
import { IndicesHistoryActions } from 'store/indicesHistory/indicesHistory.ducks';

import FilterDS from './FilterDS';

import * as S from './IndicesHistoryFilter.styles';

const FilterContracts = () => {
  const dispatch = useDispatch();
  const [dataFilter, setDataFilter] = useState({});

  const { suppliersList, inflationaryList } = useSelector(
    ({ indices }) => indices,
  );

  useEffect(() => {
    const data = setFilterDefault(inflationaryList);
    setDataFilter(data);

    dispatch(IndicesHistoryActions.setFilterData(data));
  }, [inflationaryList]);

  useEffect(() => {
    dispatch(IndicesActions.listSuppliers());
  }, [dispatch]);

  const getAll = (name, isSelected) => {
    if (name === 'WithDeleted') {
      dispatch(
        IndicesActions.getAllSuppliers({
          filterFields: { WithDeleted: !isSelected },
        }),
      );
    }
  };

  const filterAction = (filterData) => {
    const params = getFilterParams(filterData);
    dispatch(IndicesHistoryActions.listIndicesHistory(params));
  };

  const onConfirm = (data) => {
    setDataFilter(data);
    filterAction(data);
  };

  const onClean = () => {
    setDataFilter({});
    filterAction({});
    dispatch(IndicesHistoryActions.listIndicesHistoryWithInflation());
  };

  useEffect(() => {
    dispatch(IndicesHistoryActions.setFilterData(dataFilter));
  }, [dataFilter]);

  return (
    <Flex>
      <S.WrapperFilter data-testid="indices-history-filter-test">
        <FilterDS
          defaultSelectedItems={dataFilter}
          listItems={DataFilter(suppliersList, inflationaryList)}
          onConfirm={onConfirm}
          onClean={onClean}
          getAll={getAll}
        />
      </S.WrapperFilter>
    </Flex>
  );
};

export default FilterContracts;
