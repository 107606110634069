import React from 'react';

import { Input } from 'components/system/form';
import Tokens from 'components/system/tokens';

import CellTable from 'components/core/Table/CellTable';

import { EditCellSchema } from 'lib/core/tableCellSchemas';

const CellEditSelect = (props) => {
  const {
    cell: { value },
    row,
    align,
    transformedData,
    options,
    onChange,
    color,
  } = props;

  return row.original.editMode ? (
    <Input
      size="medium"
      data-testid={`cell-table-${value.id}`}
      style={{ cursor: 'pointer', textAlign: align }}
      color={color}
      type="select"
      placeholder={value[0]?.name}
      value={value}
      options={options}
      onChange={(option) => onChange(option)}
    />
  ) : (
    <CellTable {...props} transformedData={transformedData} />
  );
};

CellEditSelect.propTypes = EditCellSchema;

CellEditSelect.defaultProps = {
  cell: {
    value: '',
  },
  row: {
    index: 0,
    original: {
      id: 0,
      editMode: false,
    },
  },
  color: Tokens.colors.grayBlack,
  transformedData: (text) => text,
  align: 'start',
  options: [{ label: 'Teste', value: '1' }],
  defaultSelect: null,
  onChange: () => {},
};

export default CellEditSelect;
