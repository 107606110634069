import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Input, Label } from 'components/system/form';

import Accordion from 'components/core/Accordion';

import handleInputMask from 'lib/core/handleInputMask';

import * as S from '../RegisterStyles';

const PriceOpeningSales = () => {
  const { selectedContract } = useSelector(
    ({ contractUpload }) => contractUpload,
  );

  const { control, errors, register } = useFormContext();

  let itemIndex = 0;

  return (
    <S.Sales data-testid="price-opening-sales-test">
      <h3>
        Vendas referentes ao contrato selecionado (
        {selectedContract.sales.length})
      </h3>

      <S.SalesList>
        {selectedContract.sales.map((sale) => {
          const saleTitle = (
            <S.SaleItem>
              <S.SaleTitleColumn>
                <Label>Origem/Destino</Label>
                <S.SaleItemValue>{sale.originDestination}</S.SaleItemValue>
              </S.SaleTitleColumn>

              <S.SaleTitleColumn>
                <Label>Produto</Label>
                <S.SaleItemValue>{sale.product}</S.SaleItemValue>
              </S.SaleTitleColumn>

              <S.SaleTitleColumn>
                <Label>Base de diesel</Label>
                <S.SaleItemValue>{sale.local}</S.SaleItemValue>
              </S.SaleTitleColumn>

              <S.SaleTitleColumn>
                <Label>Modal</Label>
                <S.SaleItemValue>{sale.modal}</S.SaleItemValue>
              </S.SaleTitleColumn>
            </S.SaleItem>
          );

          return (
            <Accordion
              key={`sale-key-${sale.saleId}`}
              title={saleTitle}
              variant="none"
            >
              <S.SaleContainer>
                {sale.validityPrices.map((validityPrice) => {
                  return validityPrice.prices.map((price) => {
                    return (
                      <S.Prices key={`price-${price.priceId}`}>
                        <h4>{price.mesoregionName}</h4>

                        <S.PriceGrid>
                          {price.microregions.map((microRegion) => {
                            const fieldName = `items[${itemIndex}]`;
                            itemIndex += 1;

                            return (
                              <S.PriceItem
                                key={`micro-region-${microRegion.microRegionId}`}
                              >
                                <input
                                  style={{ display: 'none' }}
                                  type="number"
                                  name={`${fieldName}.microregionId`}
                                  value={microRegion.microRegionId}
                                  ref={register}
                                  data-testid="input-micro-id"
                                />

                                <input
                                  style={{ display: 'none' }}
                                  type="number"
                                  name={`${fieldName}.saleId`}
                                  value={sale.saleId}
                                  ref={register}
                                  data-testid="input-sale-id"
                                />

                                <input
                                  style={{ display: 'none' }}
                                  type="number"
                                  name={`${fieldName}.priceId`}
                                  value={price.priceId}
                                  ref={register}
                                  data-testid="input-price-id"
                                />

                                <S.SaleItemColumn>
                                  <Label>Microrregião</Label>
                                  <S.SaleItemValue>
                                    {microRegion.microregionName}
                                  </S.SaleItemValue>
                                </S.SaleItemColumn>

                                <S.SaleItemColumnInput>
                                  <Controller
                                    control={control}
                                    name={`${fieldName}.itemCode`}
                                    defaultValue=""
                                    render={({ ...field }) => (
                                      <Input
                                        {...field}
                                        width="200px"
                                        label="Número Item ferroviário"
                                        placeholder="Número item"
                                        onInput={(e) =>
                                          handleInputMask(e, 'digits')
                                        }
                                        required
                                      />
                                    )}
                                  />

                                  {errors?.items && (
                                    <span>
                                      {
                                        errors.items[itemIndex - 1]?.itemCode
                                          .message
                                      }
                                    </span>
                                  )}
                                </S.SaleItemColumnInput>
                              </S.PriceItem>
                            );
                          })}
                        </S.PriceGrid>
                      </S.Prices>
                    );
                  });
                })}
              </S.SaleContainer>
            </Accordion>
          );
        })}
      </S.SalesList>
    </S.Sales>
  );
};

export default PriceOpeningSales;
