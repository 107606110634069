import css from '@styled-system/css';
import styled from 'styled-components';
import { compose, layout, position, variant } from 'styled-system';

import theme from '../../tokens';

export const InputGroup = styled('div')(
  css({
    position: 'relative',
    width: 'auto',
    boxSizing: 'content-box',
    '> input': {
      boxSizing: 'content-box',
    },
  }),
  compose(layout),
);

export const Label = styled('label')(
  css({
    color: theme.colors.gray400,
    font: theme.fonts.textRegularSmall,
    display: 'block',
    marginBottom: '4px',
    transitionProperty: 'color',
    transitionDuration: '.5s',
    transitionTimingFunction: 'cubic-bezier(.5, 0, 0, 1)',
    span: {
      color: theme.colors.gray200,
    },
  }),
  ({ disabled }) =>
    disabled &&
    css({
      color: theme.colors.gray300,
      pointerEvents: 'none',
    }),
);

const makelongshadow = () => {
  const val = '5px 0 0 -8px red';

  return val;
};

export const InputRange = styled('input')(
  css({
    appearance: 'none',
    height: '22px',
    background: 'transparent',
    margin: '10px 0',
    width: '100%',
    '::-webkit-slider-runnable-track': {
      content: '',
      width: '100%',
      height: '4px',
      cursor: 'pointer',
      animate: '0.2s',
      background: '#CCCCCC',
      pointerEvents: 'none',
    },
    '::-webkit-slider-thumb': {
      appearance: 'none',
      border: '0px solid #336EAE',
      height: '16px',
      width: '16px',
      borderRadius: '2px',
      backgroundColor: '#336EAE',
      cursor: 'pointer',
      marginTop: '-7px',
      boxShadow: `${makelongshadow()}`,
    },
    '::-moz-range-progress': {
      backgroundColor: '#3071A9',
    },
    '::-moz-range-track': {
      width: '100%',
      height: '4px',
      cursor: 'pointer',
      animate: '0.2s',
      background: '#CCCCCC',
      borderRadius: '1px',
      border: '2px solid #D7E5F4',
    },
    '::-moz-range-thumb': {
      border: '0px solid #336EAE',
      height: '16px',
      width: '16px',
      borderRadius: '2px',
      background: '#336EAE',
      cursor: 'pointer',
    },
  }),

  compose(layout),
);

export const Field = styled('')(
  css({
    backgroundColor: 'white',
    color: theme.colors.gray500,
    font: theme.fonts.textRegularMedium,
    position: 'relative',
    width: '100%',
    height: '40px',
    border: 'none',
    borderRadius: '4px',
    overflow: 'hidden',
    outline: 'none',
    padding: '0px 10px 0px 10px',
    transitionProperty: 'color, background-color, border-color',
    transitionDuration: '.5s',
    transitionTimingFunction: 'cubic-bezier(.5, 0, 0, 1)',
    transitionDelay: '0s, 0s, 0s, .1s',
    willChange: 'border',
    '&::placeholder': {
      color: theme.colors.gray200,
      transitionProperty: 'opacity. transform',
      transitionDuration: '1s',
      transitionTimingFunction: 'cubic-bezier(.5, 0, 0, 1)',
      zIndex: 2,
    },
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar:hover': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track:hover': {
      width: '8px',
    },
    '&:hover::-webkit-scrollbar-corner': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '1px',
      background: theme.colors.accentPrimaryLight,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.colors.accentPrimaryDark,
    },
    '&.overflowX': {
      overflowX: 'scroll',
    },
    '&.overflowY': {
      overflowY: 'scroll',
    },
    '&.overflow': {
      overflow: 'hidden',
    },
  }),
  ({ statusInput }) =>
    statusInput &&
    css({
      padding: '0px 10px',
    }),
  ({ focus }) =>
    focus &&
    css({
      border: 'none',
      '&::placeholder': {
        opacity: '0',
        transform: 'translateX(5px)',
      },
    }),
  ({ disabled }) =>
    disabled &&
    css({
      backgroundColor: theme.colors.gray100,
      color: theme.colors.gray300,
      borderColor: theme.colors.gray200,
      pointerEvents: 'none',
    }),
  ({ type }) =>
    type === 'file' &&
    css({
      position: 'absolute',
      top: '0',
      left: '0',
      opacity: '0',
      visibility: 'hidden',
    }),
  ({ type }) =>
    type === 'textarea' &&
    css({
      padding: '10px',
      resize: 'none',
      minHeight: '80px',
      height: '100%',
      overflow: 'auto',
    }),
  ({ type }) =>
    type === 'number' &&
    css({
      '-moz-appearance': 'textfield',
      transitionProperty: 'color, background-color, border-color, padding',
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    }),
  ({ type, focus, incrementButtons }) =>
    type === 'number' &&
    focus &&
    incrementButtons &&
    css({
      padding: '0 30px',
      transitionDelay: '0s',
    }),
  ({ type }) =>
    type === 'text' &&
    css({
      border: '1px solid transparent',
      padding: '0px 10px 0px 10px',
    }),
  ({ type }) =>
    type === 'color' &&
    css({
      padding: '5px calc(100% - 36px) 5px 10px',
    }),
  compose(layout),
);

export const FieldIcon = styled('div')(
  css({
    display: 'inherit',
    margin: '0 10px',
    pointerEvents: 'none',
  }),
  ({ type }) =>
    type === 'color' &&
    css({
      position: 'absolute',
      right: 0,
    }),
  compose(position),
);

export const FileGroup = styled(Field)(
  css({
    padding: '0',
    position: 'absolute',
    background: 'transparent',
    label: {
      cursor: 'pointer',
      font: theme.fonts.textRegularSmall,
      zIndex: 3,
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      height: '100%',
      padding: '0 10px 0 0',
      span: {
        zIndex: 4,
        color: theme.colors.gray500,
        font: theme.fonts.textRegularSmall,
        whiteSpace: 'nowrap',
        display: 'block',
        width: 'calc(100% - 40px)',
        paddingLeft: '12px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        transitionProperty: 'color',
        transitionDuration: '.5s',
        transitionTimingFunction: 'cubic-bezier(.5, 0, 0, 1)',
      },
      svg: {
        flexShrink: '0',
        path: {
          transitionProperty: 'fill',
          transitionDuration: '.5s',
          transitionTimingFunction: 'cubic-bezier(.5, 0, 0, 1)',
        },
      },
    },
  }),
  ({ fileTitle }) =>
    !fileTitle &&
    css({
      label: {
        span: {
          color: theme.colors.gray200,
        },
      },
    }),
  ({ disabled }) =>
    disabled &&
    css({
      label: {
        span: {
          color: theme.colors.gray300,
        },
        'svg path': {
          fill: theme.colors.gray300,
        },
      },
    }),
);

export const ColorText = styled('span')(
  css({
    color: theme.colors.gray500,
    font: theme.fonts.textRegularMedium,
    position: 'absolute',
    left: '40px',
    textTransform: 'uppercase',
    zIndex: '2',
  }),
);

export const StatusText = styled('div')(
  css({
    position: 'relative',
    font: theme.fonts.textRegularSmall,
    lineHeight: '1.4',
    display: 'block',
    textAlign: 'right',
    marginTop: '5px',
    zIndex: '1',
  }),
  variant({
    variants: {
      success: {
        color: theme.colors.green400,
      },
      error: {
        color: theme.colors.red400,
      },
      warning: {
        color: theme.colors.yellow400,
      },
      info: {
        color: theme.colors.blue400,
      },
      help: {
        color: theme.colors.pink300,
      },
    },
  }),
);

export const IncrementButtons = styled('button')(
  css({
    backgroundColor: 'transparent',
    position: 'absolute',
    top: 'calc(50% + 2px)',
    border: 'none',
    outline: 'none',
    cursor: 'text',
    zIndex: '2',
    opacity: '0',
    transitionProperty: 'opacity',
    transitionDuration: '.5s',
    transitionTimingFunction: 'cubic-bezier(.5, 0, 0, 1)',
    'svg path': {
      fill: theme.colors.accentPrimaryDark,
      transitionProperty: 'fill',
      transitionDuration: '.5s',
      transitionTimingFunction: 'cubic-bezier(.5, 0, 0, 1)',
    },
    '&:hover svg path': {
      fill: theme.colors.gray500,
    },
  }),
  ({ focus }) =>
    focus &&
    css({
      opacity: '1',
      cursor: 'pointer',
      transitionDelay: '.1s',
    }),
);

export const DecrementButton = styled(IncrementButtons)(
  css({
    left: '10px',
  }),
);

export const IncrementButton = styled(IncrementButtons)(css({}));

export const Prefix = styled('label')(
  css({
    font: theme.fonts.textRegularSmall,
    color: theme.colors.gray300,
    marginLeft: '10px',
    zIndex: '1',
  }),
);

export const SpanFix = styled('span')(
  css({
    fontStyle: 'normal',
    font: theme.fonts.textRegularSmall,
    color: theme.colors.gray600,
    marginRight: '10px',
  }),
);

export const FieldContainer = styled('div')(
  css({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    color: theme.colors.gray500,
    font: theme.fonts.textRegularMedium,
    position: 'relative',
    width: '100%',
    height: '40px',
    border: `1px solid ${theme.colors.gray200}`,
    borderRadius: '4px',
    overflow: 'hidden',
    outline: 'none',
    padding: '0px',
    transitionProperty: 'color, background-color, border-color',
    transitionDuration: '.5s',
    transitionTimingFunction: 'cubic-bezier(.5, 0, 0, 1)',
    transitionDelay: '0s, 0s, 0s, .1s',
    willChange: 'border',
    zIndex: 1,
  }),
  ({ height }) =>
    height &&
    css({
      height,
    }),
  ({ noBorder }) =>
    noBorder &&
    css({
      border: '1px solid #0000',
    }),
  ({ focus }) =>
    focus &&
    css({
      border: `1px solid ${theme.colors.accentPrimaryDark}`,
    }),
  ({ disabled }) =>
    disabled &&
    css({
      backgroundColor: theme.colors.gray100,
      color: theme.colors.gray300,
      borderColor: theme.colors.gray200,
      pointerEvents: 'none',
    }),
);
