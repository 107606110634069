import styled from 'styled-components';

import Tokens from 'components/system/tokens';

export const FieldContainer = styled.div`
  min-width: 420px;

  display: flex;
  gap: ${Tokens.spacing.sm};

  & > div {
    flex: 1;
  }
`;

export const EventsContainer = styled.div`
  display: flex;
  justify-content: center;

  margin-top: ${Tokens.spacing.sm};
`;

export const ErrorText = styled.span`
  font: ${Tokens.fonts.textRegularSmall};
  color: ${Tokens.colors.red300};
`;

export const LoadingText = styled.span`
  font: ${Tokens.fonts.textRegularSmall};
  color: ${Tokens.colors.accentPrimaryDark};
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${Tokens.spacing.xs};
`;

export const LoadingWrapper = styled.div`
  position: relative;

  width: 24px;
  height: 24px;
`;
