import styled from 'styled-components';

import { Card, Modal } from 'components/system/core';
import Tokens from 'components/system/tokens';

export const ModalContainer = styled(Modal)`
  width: 100%;
`;

export const Scroll = styled.div`
  width: 100%;
  height: 480px;

  position: relative;

  margin-top: ${Tokens.spacing.sm};

  max-height: 480px;
  overflow-y: auto;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: ${Tokens.colors.accentPrimaryLight};
  }

  &::-webkit-scrollbar-thumb {
    background: ${Tokens.colors.accentPrimaryDark};
  }
`;

export const Wrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Title = styled.h2`
  font: ${Tokens.fonts.textBoldLarge};
  color: ${Tokens.colors.gray500};
`;

export const Section = styled.div`
  width: 100%;
  padding: ${Tokens.spacing.sm};

  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const LineWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 16px;

  & > span {
    font: ${Tokens.fonts.textRegularMedium};
    color: ${Tokens.colors.accentPrimaryDark};
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
`;

export const Box = styled.div`
  & + & {
    border-top: 1px solid ${Tokens.colors.gray200};
    padding-top: 8px;
  }

  & > small {
    font: ${Tokens.fonts.textRegularSmall};
    color: ${Tokens.colors.accentPrimaryDark};
  }
`;

// SalesData

export const CardContainer = styled(Card)`
  width: 100%;
  padding: ${Tokens.spacing.sm};
`;

export const CardWrapper = styled(ColumnWrapper)`
  margin-top: ${Tokens.spacing.md};
`;

// Price

export const PriceContainer = styled(LineWrapper)`
  width: auto;

  gap: 12px;

  & > small {
    font: ${Tokens.fonts.textRegularSmall};
    color: ${Tokens.colors.accentPrimaryDark};
  }
`;

// Volume

export const VolumeContainer = styled(PriceContainer)``;
