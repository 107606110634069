import styled from 'styled-components';

import { Accordion } from 'components/system/core';
import Tokens from 'components/system/tokens';

export const ExpandedAccordion = styled(Accordion)`
  width: 100%;
  margin-bottom: ${Tokens.spacing.sm};
`;

export const TariffTable = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: ${Tokens.spacing.sm};
`;

export const TariffTableItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Tokens.spacing.xs};

  span {
    text-align: center;
    color: ${Tokens.colors.gray400};
    font-weight: bold;
    text-transform: capitalize;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    border: 1px solid ${Tokens.colors.gray200};
    border-radius: 4px;
    overflow: hidden;
    outline: none;
    background-color: ${Tokens.colors.gray100};
    color: ${Tokens.colors.gray300};
    pointer-events: none;
  }
`;
