import css from '@styled-system/css';
import styled from 'styled-components';
import { variant } from 'styled-system';

import theme from '../../tokens';

export const AlertStyle = styled('div')(
  css({
    width: '100%',
    padding: theme.spacing.sm,
    borderRadius: '4px',
    display: 'flex',
    bg: theme.colors.green100,
    boxShadow: theme.effects.cardShadow,
    '& div:nth-of-type(1)': {
      marginRight: theme.spacing.sm,
      position: 'relative',
      transform: 'translateY(3px)',
    },
    '& div:nth-of-type(2)': {
      '& > h2': {
        color: theme.colors.gray500,
        fontSize: '1rem',
        lineHeight: '1.5rem',
      },
      '& > p': {
        color: theme.colors.gray400,
        fontSize: '0.75rem',
        lineHeight: '1rem',
      },
    },
  }),
  variant({
    variants: {
      warning: {
        bg: theme.colors.yellow100,
      },
      error: {
        bg: theme.colors.red100,
      },
    },
  }),
);
