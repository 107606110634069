import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import InfoIcon from 'assets/system/svgs/info.svg';

import { Button, Loading } from 'components/system/core';

import { FormalizationRequestActions } from 'store/formalizationRequest/formalizationRequest.ducks';
import { FormulasActions } from 'store/formulas/formulas.ducks';

import { isPending } from 'lib/core/request';

import * as S from './ConfirmEditModalStyles';

const ConfirmEditModal = ({ isOpen, onClose, reviewData, payload }) => {
  const dispatch = useDispatch();

  const { formulasItems, formulasRequests } = useSelector(
    ({ formulas }) => formulas,
  );

  const isLoading = isPending(formulasRequests.FORMULAS_ITEMS_REQUEST);

  useEffect(() => {
    if (isOpen) {
      dispatch(FormulasActions.formulasItemsRequest());
    }
  }, [dispatch, isOpen]);

  const handleSendEditData = () => {
    dispatch(FormalizationRequestActions.formalizationEditRequest(payload));

    onClose();
  };

  const getFormulaName = (formulaId) => {
    if (formulasItems) {
      const foundFormula = formulasItems.find((item) => item.id === formulaId);

      return foundFormula?.name;
    }

    return '-';
  };

  return (
    <S.ModalContainer
      title="Solicitação de formalização fora do padrão"
      isOpen={isOpen}
      onClose={onClose}
      width="775px"
    >
      <S.Wrapper>
        <S.Info>
          <InfoIcon />
          As seguintes informações estão fora do padrão e deverão passar pela
          validação do time de Pricing para avançar para a próxima etapa:
        </S.Info>

        {isLoading ? (
          <Loading fitParent message="Por favor, espere alguns segundos..." />
        ) : (
          <S.FieldsContainer>
            {reviewData?.diesel && (
              <S.FieldBox data-testid="review-field-diesel">
                <span>Diesel - %</span>
                <small>{reviewData.diesel}</small>
              </S.FieldBox>
            )}

            {reviewData?.dieselFormulaRailway && (
              <S.FieldBox data-testid="review-field-formula-diesel">
                <span>Reajuste de diesel - Ferrovia</span>
                <small>{getFormulaName(reviewData.dieselFormulaRailway)}</small>
              </S.FieldBox>
            )}

            {reviewData?.inflationFormulaRailway && (
              <S.FieldBox data-testid="review-field-formula-inflation-railway">
                <span>Reajuste de inflação - Ferrovia</span>
                <small>
                  {getFormulaName(reviewData.inflationFormulaRailway)}
                </small>
              </S.FieldBox>
            )}

            {reviewData?.inflationFormulaHarbor && (
              <S.FieldBox data-testid="review-field-formula-inflation-harbor">
                <span>Reajuste de inflação - Porto</span>
                <small>
                  {getFormulaName(reviewData.inflationFormulaHarbor)}
                </small>
              </S.FieldBox>
            )}
          </S.FieldsContainer>
        )}

        <S.ActionsContainer>
          <Button
            variant="tertiary"
            name="Revisar"
            type="button"
            onClick={onClose}
          />
          <Button name="Confirmar" type="button" onClick={handleSendEditData} />
        </S.ActionsContainer>
      </S.Wrapper>
    </S.ModalContainer>
  );
};

ConfirmEditModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  reviewData: PropTypes.object.isRequired,
};

export default ConfirmEditModal;
