import css from '@styled-system/css';
import styled from 'styled-components';
import { compose, space } from 'styled-system';

import theme from '../../tokens';

export const CheckboxContainer = styled('label')(
  css({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
    font: theme.fonts.textRegularMedium,
    marginBottom: 0,
    minHeight: '32px',
    label: {
      font: theme.fonts.textRegularMedium,
      color: theme.colors.gray500,
      paddingLeft: '12px',
      display: 'block',
      userSelect: 'none',
      cursor: 'pointer',
      ':first-letter': {
        textTransform: 'capitalize',
        userSelect: 'none',
      },
      bold: {
        font: theme.fonts.textBoldMedium,
      },
    },
  }),
  compose(space),
);

export const CheckboxStyle = styled('div')(
  css({
    position: 'relative',
    width: '18px',
    height: '18px',
    input: {
      all: 'unset',
      position: 'absolute',
      width: '100%',
      height: '100%',
      bg: theme.colors.grayWhite,
      marginRight: '10px',
      border: '2px solid',
      borderRadius: '4px',
      borderColor: theme.colors.gray200,
      display: 'inline-block',
      transition: 'all 150ms',
      boxSizing: 'border-box',
      cursor: 'pointer',
      '&:disabled, &:disabled ~ .checked-icon': {
        cursor: 'not-allowed',
      },
      '&:focus': {
        border: '2px solid',
        borderColor: theme.colors.gray200,
        boxShadow: `0 0 0 2px ${theme.colors.accentPrimaryLight}`,
      },
      '~ .checked-icon': {
        display: 'none',
      },
      '&:checked': {
        '~ .checked-icon': {
          display: 'block',
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          width: '12px',
          height: '10px',
          path: {
            fill: theme.colors.gray200,
          },
        },
        '&:not(:disabled)': {
          borderColor: theme.colors.accentPrimaryDark,
          '~ .checked-icon': {
            cursor: 'pointer',
            path: {
              fill: theme.colors.accentPrimaryDark,
            },
          },
        },
      },
    },
  }),
);
