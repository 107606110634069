import PropTypes from 'prop-types';
import React from 'react';

import ChevronLeftIcon from 'assets/system/svgs/chevron/left.svg';
import ChevronRightIcon from 'assets/system/svgs/chevron/right.svg';

import Icon from '../../icon';

import { ArrowButton } from './styles';

const Arrow = ({ left, onClick }) => (
  <ArrowButton
    type="button"
    data-testid="ds-carousel-arrow"
    onClick={() => onClick()}
    left={left}
  >
    {left ? <Icon src={ChevronLeftIcon} /> : <Icon src={ChevronRightIcon} />}
  </ArrowButton>
);

Arrow.propTypes = {
  left: PropTypes.bool,
};

Arrow.defaultProps = {
  left: false,
};

export default Arrow;
