import css from '@styled-system/css';
import styled from 'styled-components';
import { compose, flexbox, layout, space, variant } from 'styled-system';

import theme from '../../tokens';

export const FilterStyle = styled('div')(
  css({
    display: 'flex',
  }),
  compose(space, flexbox, layout),
);

export const ButtonFilter = styled('button')(
  css({
    width: '32px',
    height: '32px',
    display: 'block',
    backgroundColor: 'transparent',
    border: 'none',
    ':hover': {
      background: '#D7E5F4',
      borderRadius: '4px',
      cursor: 'pointer',
    },
  }),
  variant({
    variants: {
      focus: {
        background: '#D7E5F4',
        borderRadius: '4px',
      },
    },
  }),
);

export const ItemCounter = styled('div')(
  css({
    font: theme.fonts.textRegularSmall,
    position: 'relative',
    margin: '-9px 18px -7px',
    width: '16px',
    height: '16px',
    color: 'white',
    background: '#BB581C',
    borderRadius: '100%',
  }),
);

export const OptionsList = styled('ul')(
  css({
    position: 'relative',
    userSelect: 'none',
    minWidth: '264px',
    bg: theme.colors.grayWhite,
    marginTop: '10px',
    borderRadius: '4px',
    boxShadow:
      '0px -1px 2px rgba(0, 0, 0, 0.08), 0px 2px 3px rgba(0, 0, 0, 0.12)',
    maxHeight: '264px',
    overflow: 'auto',
    right: '0px',
    '::-webkit-scrollbar': {
      width: '4px',
      borderRadius: '4px',
    },
    '::-webkit-scrollbar-track': {
      background: 'transparent',
      '&:hover': {
        width: '10px',
        cursor: 'pointer',
        bg: theme.colors.accentPrimaryLight,
      },
    },
    '::-webkit-scrollbar-thumb': {
      bg: theme.colors.accentPrimaryDark,
    },
  }),
);

export const GroupItem = styled('li')(
  css({
    position: 'relative',
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    padding: '4px 11px',
    cursor: 'pointer',
    '&:hover': {
      bg: `${theme.colors.accentPrimaryLight}50`,
      input: { borderColor: theme.colors.accentPrimaryDark },
      label: { color: theme.colors.accentPrimaryDark },
    },
    label: {
      cursor: 'pointer',
    },
  }),
  ({ selected }) =>
    selected &&
    css({
      label: { color: theme.colors.accentPrimaryDark },
    }),
  ({ focused }) =>
    focused && css({ bg: `${theme.colors.accentPrimaryLight}50` }),
);

export const GroupTitle = styled('li')(
  css({
    display: 'flex',
    userSelect: 'none',
    alignItems: 'center',
    font: theme.fonts.textBoldSmall,
    textTransform: 'uppercase',
    color: theme.colors.gray300,
    height: '32px',
    paddingLeft: 2,
  }),
);

export const Group = styled('ul')(
  css({
    position: 'relative',
    userSelect: 'none',
    listStyle: 'none inside none',
    margin: '0 0 16px 0',
    padding: 0,
  }),
);

export const GroupWrapper = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
`;

export const ComponentWrapper = styled.div`
  padding: 4px 11px;
`;
