import React from 'react';

import { formatDate, getMonth } from 'lib/core/utility';

import CellTable from 'components/core/Table/CellTable';

import CellDestination from '../CellDestination';
import CellEndPeriod from '../CellEndPeriod';
import CellPriceRecommended from '../CellPriceRecommended';

const getMonthName = (value) => {
  return getMonth[value];
};

const Columns = [
  {
    Header: 'Ano',
    accessor: 'operationYear',
    align: 'start',
    width: 50,
    Cell: (props) => <CellTable {...props} />,
  },
  {
    Header: 'Mês',
    accessor: 'operationMonth',
    align: 'start',
    width: 50,
    Cell: (props) => <CellTable {...props} transformedData={getMonthName} />,
  },
  {
    Header: 'Produto',
    accessor: 'merchandiseManagement',
    align: 'start',
    Cell: (props) => <CellTable {...props} />,
  },
  {
    Header: 'Modal',
    accessor: 'modal',
    align: 'start',
    Cell: (props) => <CellTable {...props} />,
  },
  {
    Header: 'Cliente',
    accessor: 'clientManagement',
    align: 'start',
    Cell: (props) => <CellTable {...props} />,
  },
  {
    Header: 'Origem',
    accessor: 'operationalAreaOrigin',
    align: 'start',
    width: 50,
    Cell: (props) => <CellTable {...props} />,
  },
  {
    Header: 'Destino',
    width: 50,
    accessor: 'operationalAreaDestination',
    align: 'start',
    Cell: (props) => <CellTable {...props} />,
  },
  {
    Header: 'Destinação',
    align: 'start',
    Cell: (props) => <CellDestination {...props} />,
  },
  {
    Header: 'Data Solicitação',
    accessor: 'createAt',
    align: 'start',
    Cell: (props) => <CellTable {...props} transformedData={formatDate} />,
  },
  {
    Header: 'Data Vig. Final',
    align: 'start',
    width: 200,
    Cell: (props) => <CellEndPeriod {...props} />,
  },
  {
    Header: 'Prec. Rec.',
    align: 'start',
    width: 200,
    Cell: (props) => <CellPriceRecommended {...props} />,
  },
];

export default Columns;
