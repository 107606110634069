import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import CheckIcon from 'assets/system/svgs/check.svg';
import CombinedShape from 'assets/system/svgs/combinedShape';

import theme from '../../tokens';
import Text from '../../typography/text';
import Icon from '../icon';

import { TimelineStyle } from './styles';

const TimeLineStatus = {
  disabled: 'disabled',
  finished: 'finished',
  active: 'active',
};

const TimelineOrientation = {
  vertical: 'vertical',
  horizontal: 'horizontal',
};

const TimeLineVertical = ({
  children,
  title,
  date,
  hours,
  status,
  onClick,
  ...attrs
}) => {
  const getStatus = useMemo(() => TimeLineStatus[status] || '', [status]);

  return (
    <TimelineStyle
      id="timeline"
      data-testid="timeline"
      className={status}
      {...attrs}
    >
      <div className="time">
        <strong className="date">{date}</strong>
        <strong className="hours">{hours}</strong>
      </div>
      <div className="line" onClick={onClick} role="presentation">
        {getStatus !== TimeLineStatus.active && (
          <Icon src={CheckIcon} size={16} fill={theme.colors.grayWhite} />
        )}
        {getStatus === TimeLineStatus.active && <CombinedShape />}
        <div className="vertical-line" />
      </div>
      <section className="timeline-container">
        <header>
          <h1>{title}</h1>
        </header>
        <div className="content">{children}</div>
      </section>
    </TimelineStyle>
  );
};

TimeLineVertical.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  date: PropTypes.string,
  hours: PropTypes.string,
  status: PropTypes.string,
  onClick: PropTypes.func,
};

TimeLineVertical.defaultProps = {
  children: '',
  title: '',
  date: '',
  hours: '',
  status: '',
  onClick: () => {},
};

const TimeLineHorizontal = ({
  children,
  title,
  date,
  hours,
  status,
  onClick,
  days,
  ...attrs
}) => {
  const getStatus = useMemo(() => TimeLineStatus[status] || '', [status]);

  return (
    <TimelineStyle id="timeline" className={status} {...attrs}>
      <div className="timeline-horizontal">
        <section className="timeline-container">
          <header>
            <h1>{title}</h1>
          </header>
        </section>
        <div className="line" onClick={onClick} role="presentation">
          <div className="vertical-line" />
          <div className="icon-line">
            {getStatus !== TimeLineStatus.active && (
              <Icon src={CheckIcon} size={16} fill={theme.colors.grayWhite} />
            )}
            {getStatus === TimeLineStatus.active && <CombinedShape />}
            <div>
              <Text>{days}</Text>
              <div className="horizontal-line" />
            </div>
          </div>
        </div>
        <div className="time">
          <strong className="date">{date}</strong>
          <strong className="hours">{hours}</strong>
        </div>
        <div className="content">{children}</div>
      </div>
    </TimelineStyle>
  );
};

TimeLineHorizontal.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  date: PropTypes.string,
  hours: PropTypes.string,
  status: PropTypes.string,
  onClick: PropTypes.func,
  days: PropTypes.string.isRequired,
};

TimeLineHorizontal.defaultProps = {
  children: '',
  title: '',
  date: '',
  hours: '',
  status: '',
  onClick: () => {},
};

const Timeline = ({ orientation, children, ...attrs }) => {
  if (orientation === TimelineOrientation.horizontal) {
    return <TimeLineHorizontal {...attrs}>{children}</TimeLineHorizontal>;
  }

  return <TimeLineVertical {...attrs}>{children}</TimeLineVertical>;
};

Timeline.propTypes = {
  children: PropTypes.node,
  orientation: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  hours: PropTypes.string,
  status: PropTypes.string,
  onClick: PropTypes.func,
  days: PropTypes.string.isRequired,
};

Timeline.defaultProps = {
  children: '',
  orientation: 'vertical',
  title: '',
  date: '',
  hours: '',
  status: '',
  onClick: () => {},
};

export default Timeline;
