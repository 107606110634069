import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from 'components/system/core';
import { Input } from 'components/system/form';

import { FormalizationRequestActions } from 'store/formalizationRequest/formalizationRequest.ducks';

import * as S from './DisapproveModalStyles';

const DisapproveModal = ({ isOpen, onClose, formalizationId }) => {
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState(null);

  const handleReproveRequest = () => {
    if (inputValue.length === 0) {
      setInputError('Campo obrigatório');

      return;
    }
    const payload = {
      formalizationId,
      justification: inputValue,
    };

    dispatch(FormalizationRequestActions.disapproveRequest(payload));

    onClose();
  };

  const handleOnChange = (value) => {
    setInputValue(value);
    setInputError(null);
  };

  return (
    <S.ModalContainer
      title="Reprovação de solicitação de formalização"
      isOpen={isOpen}
      onClose={onClose}
      width="600px"
    >
      <S.Wrapper>
        <S.Info>
          <span>
            Informe a justificativa da reprovação da solicitação de
            formalização.
          </span>
        </S.Info>

        <S.FieldsContainer>
          <Input
            name="justification"
            type="textarea"
            label="Justificativa"
            placeholder="Justifique aqui"
            required
            status={inputError && 'error'}
            statusMessage={inputError}
            maxLength={250}
            onChange={handleOnChange}
            value={inputValue}
          />
        </S.FieldsContainer>

        <S.ActionsContainer>
          <Button
            variant="tertiary"
            name="Cancelar"
            type="button"
            onClick={onClose}
          />
          <Button
            name="Confirmar"
            type="button"
            onClick={handleReproveRequest}
          />
        </S.ActionsContainer>
      </S.Wrapper>
    </S.ModalContainer>
  );
};

DisapproveModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  formalizationId: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default DisapproveModal;
