import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import OptionIcon from 'assets/icons/table/options.svg';
import CloseIcon from 'assets/system/svgs/close.svg';

import { Button, Icon, Popover } from 'components/system/core';
import { Text } from 'components/system/typography';

import { ContractActions } from 'store/contract/contract.ducks';

import { canCloseContract } from 'lib/contexts/contracts';

import * as S from 'components/core/Table/CellWithActions/CellWithActions.styles';

const AdditionCellWithActions = ({ row }) => {
  const dispatch = useDispatch();

  const standardRowData = {
    ...row,
    original: {
      ...row.original,
      code: row.original.contractCode,
      id: row.original.contractId,
      endPeriod: row.original.contractEndPeriod,
    },
  };

  const handleOpenCloseContractModal = () => {
    dispatch(
      ContractActions.setJustifyModalOpen({
        row: standardRowData.original,
        modalOpen: true,
      }),
    );
  };

  const Options = (
    <S.OptionsContainer>
      {canCloseContract({ details: standardRowData.original }) && (
        <S.OptionItem
          data-testid="test-option-close"
          onClick={handleOpenCloseContractModal}
        >
          <Icon src={CloseIcon} />
          <Text size="medium" style={{ fontWeight: '400' }}>
            Encerrar formalização
          </Text>
        </S.OptionItem>
      )}
    </S.OptionsContainer>
  );

  return (
    <S.ContentCell data-testid="cell-with-action">
      <Popover content={Options} position="left center">
        <Button
          data-testid="cell-with-action-option-button"
          icon={OptionIcon}
          name=""
          variant="tertiary"
        />
      </Popover>
    </S.ContentCell>
  );
};

AdditionCellWithActions.propTypes = {
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      showInflationReport: PropTypes.bool.isRequired,
      endPeriod: PropTypes.string,
      editMode: PropTypes.bool,
      canDeleteContract: PropTypes.bool,
    }).isRequired,
  }),
};

AdditionCellWithActions.defaultProps = {
  row: {
    index: 0,
    original: {
      id: 0,
      editMode: false,
      endPeriod: null,
      canDeleteContract: false,
    },
  },
};

export default AdditionCellWithActions;
