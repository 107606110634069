import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  useBlockLayout,
  useExpanded,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { useSticky } from 'react-table-sticky';

import Ascending from 'assets/system/svgs/ascending.svg';
import Descending from 'assets/system/svgs/descending.svg';
import Fixed from 'assets/system/svgs/fixed.svg';

import { Footer } from '../../interface';
import IconDS from '../icon';
import Scrollbar from '../scrollbar';

import {
  Container,
  FixedIcon,
  Icon,
  IconContainer,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from './styles';

const TableComponent = ({
  columns,
  data,
  onUpdateTable,
  tableAttrs,
  sortBy,
  width,
  height,
  renderRowSubComponent,
  footer,
  footerAttrs,
  disableHeaderSort,
  customList,
  onHover,
  ...attrs
}) => {
  const selectedValue =
    customList.length && customList.find((select) => select.selected);
  const [inputText, setInputText] = useState({
    value: customList.length ? selectedValue.value : 25,
  });
  const [skipPageReset, setSkipPageReset] = useState(false);

  const updateData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    const newData = data.map((row, index) => {
      if (index === rowIndex) {
        return { ...data[rowIndex], [columnId]: value };
      }
      return row;
    });
    onUpdateTable(newData, rowIndex, columnId);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    setPageSize,
    visibleColumns,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, sortBy },
      autoResetPage: !skipPageReset,
      updateData,
      disableSortRemove: true,
      disableMultiRemove: true,
    },
    useSortBy,
    useExpanded,
    usePagination,
    useBlockLayout,
    useSticky,
    useRowSelect,
  );

  useEffect(() => {
    setPageSize(inputText.value);
  }, [inputText.value, setPageSize]);

  const renderOrderIcon = (column) => {
    if (column.isSorted && column.isSortedDesc) {
      return <IconDS src={Descending} />;
    }
    if (column.isSorted && !column.isSortedDesc) {
      return <IconDS src={Ascending} />;
    }
    return null;
  };

  const renderHeaders = () =>
    headerGroups.map((headerGroup) => (
      <Tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map((column) => (
          <Th
            {...column.getHeaderProps([
              !disableHeaderSort && column.getSortByToggleProps(),
              { style: { width: column.width, justifyContent: column.align } },
            ])}
          >
            <IconContainer align={column.align}>
              <span>{column.render('Header')}</span>
              {renderOrderIcon(column) !== null && (
                <Icon>{renderOrderIcon(column)}</Icon>
              )}
              {column.sticky && (
                <FixedIcon>
                  <IconDS src={Fixed} />
                </FixedIcon>
              )}
            </IconContainer>
          </Th>
        ))}
      </Tr>
    ));

  const renderBody = () =>
    page.map((row) => {
      prepareRow(row);
      return (
        <React.Fragment key={page.indexOf(row)}>
          <Tr {...row.getRowProps()}>
            {row.cells.map((cell) => (
              <Td
                {...cell.getCellProps([
                  {
                    style: {
                      width: cell.column.width,
                      justifyContent:
                        cell.column.cellAlign || cell.column.align,
                    },
                  },
                ])}
              >
                {cell.render('Cell')}
              </Td>
            ))}
          </Tr>
          {row.isExpanded && (
            <tr>
              <td colSpan={visibleColumns.length}>
                {renderRowSubComponent({ row })}
              </td>
            </tr>
          )}
        </React.Fragment>
      );
    });

  return (
    <Container {...attrs} data-testid="ds-table" id="table">
      <Scrollbar width={width} height={height} overflow>
        <Table {...getTableProps()} {...tableAttrs}>
          <Thead>{renderHeaders()}</Thead>
          <Tbody onHover={onHover} {...getTableBodyProps()}>
            {renderBody()}
          </Tbody>
        </Table>
      </Scrollbar>
      {footer && (
        <Footer
          onPageChange={(newPage) => gotoPage(newPage - 1)}
          totalPages={pageCount}
          currentPage={pageIndex + 1}
          totalItems={data.length}
          customList={customList}
          onChangePageSize={(option) => {
            setInputText(option);
            setPageSize(option.value);
          }}
          style={{ marginTop: '1em' }}
          {...footerAttrs}
        />
      )}
    </Container>
  );
};

TableComponent.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  onUpdateTable: PropTypes.func,
  tableAttrs: PropTypes.object,
  renderRowSubComponent: PropTypes.func,
  footerAttrs: PropTypes.object,
  sortBy: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool,
    }),
  ),
  width: PropTypes.string,
  height: PropTypes.string,
  footer: PropTypes.bool,
  disableHeaderSort: PropTypes.bool,
  customList: PropTypes.array,
  onHover: PropTypes.bool,
};

TableComponent.defaultProps = {
  onUpdateTable: () => {},
  columns: [],
  data: [],
  tableAttrs: {},
  footerAttrs: {},
  sortBy: [],
  width: '1136px',
  height: '560px',
  renderRowSubComponent: () => {},
  footer: true,
  disableHeaderSort: false,
  customList: [],
  onHover: false,
};

export default TableComponent;
