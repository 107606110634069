import PropTypes from 'prop-types';
import React from 'react';

import CloseIcon from 'assets/system/svgs/close.svg';
import Logo from 'assets/system/svgs/logo.svg';

import { Heading } from '../../typography';
import Icon from '../icon';

import {
  CloseStyle,
  FooterNotification,
  NotificationContainer,
  NotificationContent,
  NotificationHeader,
  NotificationItem,
} from './styles';

const NotificationPush = ({
  children,
  title,
  onClose,
  isOpen,
  footerDescription,
  logo,
  ...attrs
}) => (
  <NotificationContainer data-testid="ds-notification-push">
    <NotificationContent className={isOpen && 'open'} {...attrs}>
      <NotificationItem className="logo">
        {!logo && <Icon size={64} src={Logo} />}
      </NotificationItem>
      <NotificationItem>
        <NotificationHeader>
          <Heading size={16}>{title}</Heading>
          <CloseStyle onClick={onClose}>
            <Icon src={CloseIcon} />
          </CloseStyle>
        </NotificationHeader>
        {children}
        <FooterNotification>{footerDescription}</FooterNotification>
      </NotificationItem>
    </NotificationContent>
  </NotificationContainer>
);

NotificationPush.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  footerDescription: PropTypes.string,
  logo: PropTypes.any,
};

NotificationPush.defaultProps = {
  children: '',
  title: '',
  onClose: () => {},
  isOpen: false,
  logo: '',
  footerDescription: '',
};

export default NotificationPush;
