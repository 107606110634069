import css from '@styled-system/css';
import styled from 'styled-components';
import { compose, flexbox, layout, space, variant } from 'styled-system';

import theme from '../../../tokens';

export const Container = styled('div')(
  css({
    width: '285px',
    height: '100vh',
    background: '#fff',
    position: 'fixed',
    top: '0',
    right: '-290px',
    zIndex: '-1',
    '-webkit-transition': 'right 0.3s ease',
    '-o-transition': 'right 0.3s ease',
    '-moz-transition': 'right 0.3s ease',
    transition: 'right 0.3s ease',
    padding: theme.spacing.sm,
    paddingRight: theme.spacing.xss,
    display: 'flex',
    flexDirection: 'column',
  }),
  variant({
    variants: {
      open: {
        right: '0',
        zIndex: '1000',
      },
    },
  }),
  compose(space, flexbox, layout),
);

export const Close = styled('div')(
  css({
    margin: '0 5px 0 auto',
    ':hover': {
      cursor: 'pointer',
      opacity: '0.5',
    },
    svg: {
      path: {
        fill: theme.colors.accentPrimaryDark,
      },
    },
  }),
);

export const Header = styled('div')(
  css({
    display: 'flex',
    marginBottom: theme.spacing.sm,
  }),
);

export const Content = styled('div')(
  css({
    padding: '0 14px 20px 0',
    flexGrow: '1',
    height: 'fit-content',
    overflowX: 'auto',
    '> *': {
      marginBottom: theme.spacing.xs,
    },
    '::-webkit-scrollbar': {
      width: theme.spacing.xss,
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },

    '::-webkit-scrollbar-thumb': {
      background: theme.colors.accentPrimaryDark,
    },
  }),
);

export const Footer = styled('div')(
  css({
    background: '#fff',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing.sm,
    width: '245px',
    'button:first-child': {
      marginRight: theme.spacing.sm,
    },
  }),
);

export const Title = styled('p')(
  css({
    color: theme.colors.gray500,
    fontSize: '18px',
    fontWeight: '600',
  }),
);

export const MoreFilter = styled('div')(
  css({
    display: 'flex',
    marginTop: theme.spacing.sm,
    svg: {
      cursor: 'pointer',
      marginRight: theme.spacing.xs,
    },
    '> p': {
      cursor: 'pointer',
      margin: '0',
      fontSize: '12px',
      fontWeight: '400',
      color: theme.colors.accentPrimaryDark,
    },
  }),
);
