import styled from 'styled-components';

import Tokens from 'components/system/tokens';

export const Title = styled.h2`
  font: ${Tokens.fonts.headingBoldH6};
  color: ${Tokens.colors.gray500};

  margin-bottom: ${Tokens.spacing.sm};
`;

export const ItemsList = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 32px;
`;
