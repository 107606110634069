import styled from 'styled-components';

import Tokens from 'components/system/tokens';

export const TotalInfo = styled.div`
  width: 100%;

  font: ${Tokens.fonts.textRegularMedium};
  font-weight: 400;
  color: ${Tokens.colors.gray500};

  margin-bottom: ${Tokens.spacing.md};
`;
