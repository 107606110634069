import PropTypes from 'prop-types';
import React from 'react';

import theme from 'components/system/tokens';

const CombinedShape = ({ width, height, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3337 3.39845C11.4411 3.11962 11.5 2.81668 11.5 2.5C11.5 1.11929 10.3807 0 9 0C7.61929 0 6.5 1.11929 6.5 2.5C6.5 2.81669 6.55888 3.11962 6.6663 3.39845C3.94771 4.3595 2 6.95229 2 10V13L0 17H2H6.55001C6.51722 17.1616 6.5 17.3288 6.5 17.5C6.5 18.8807 7.61929 20 9 20C10.3807 20 11.5 18.8807 11.5 17.5C11.5 17.3288 11.4828 17.1616 11.45 17H16H18L16 13V10C16 6.95229 14.0523 4.3595 11.3337 3.39845ZM10.1561 3.09503C10.2481 2.91673 10.3 2.71443 10.3 2.5C10.3 1.78203 9.71797 1.2 9 1.2C8.28203 1.2 7.7 1.78203 7.7 2.5C7.7 2.71443 7.75192 2.91673 7.84386 3.09503C8.21995 3.03252 8.60618 3 9 3C9.39382 3 9.78005 3.03252 10.1561 3.09503ZM7.79963 17C7.73545 17.1539 7.7 17.3228 7.7 17.5C7.7 18.218 8.28203 18.8 9 18.8C9.71797 18.8 10.3 18.218 10.3 17.5C10.3 17.3228 10.2646 17.1539 10.2004 17H7.79963ZM14.8 13.28L16.06 15.8L14.8 15.8H3.2L1.94 15.8L3.2 13.28V10C3.2 6.79675 5.79675 4.2 9 4.2C12.2033 4.2 14.8 6.79675 14.8 10V13.28Z"
      fill={color}
    />
  </svg>
);

CombinedShape.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

CombinedShape.defaultProps = {
  width: '18px',
  height: '20px',
  color: theme.colors.grayWhite,
};

export default CombinedShape;
