import styled from 'styled-components';

import { Flex } from 'components/system/core';
import Tokens from 'components/system/tokens';

export const Step = styled.span`
  font: ${Tokens.fonts.textBoldMedium};
  color: ${Tokens.colors.gray400};
`;

export const ContainerFlex = styled(Flex)`
  gap: ${Tokens.spacing.md};
  flex-wrap: wrap;

  & + div {
    margin-top: ${Tokens.spacing.md};
  }
`;

export const ModalButton = styled.button`
  appearance: none;
  border: 0;
  background-color: transparent;

  font: ${Tokens.fonts.textRegularMedium};
  color: ${Tokens.colors.accentPrimaryDark};

  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    color: ${Tokens.colors.blue500};
  }
`;
