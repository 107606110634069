import PropTypes from 'prop-types';
import React from 'react';

import Divider from '../../divider';

import { ModalContentStyle } from '../styles';

const ModalContent = ({ children, ...attrs }) => (
  <ModalContentStyle {...attrs}>
    <Divider
      style={{ marginBottom: '16px', marginTop: '16px' }}
      position="center"
    />
    {children}
  </ModalContentStyle>
);

ModalContent.propTypes = {
  children: PropTypes.node,
  divider: PropTypes.bool,
};

ModalContent.defaultProps = {
  children: '',
  divider: false,
};
export default ModalContent;
