import React from 'react';
import { useSelector } from 'react-redux';

import { Card } from 'components/system/core';

import Chart from 'components/core/Chart';

import { isFinished } from 'lib/core/request';

const IndicesHistoryChart = () => {
  const {
    indicesHistoryRequests,
    indicesHistoryList: { chart, list },
  } = useSelector(({ indicesHistory }) => indicesHistory);

  return (
    isFinished(indicesHistoryRequests.LIST_INDICES_HISTORY) && (
      <Card
        data-testid="indices-history-chart-test"
        title="PRODUTIVIDADE DOS SETORES NO PRIMEIRO SEMESTRE"
        style={{ height: '100%', width: '100%' }}
        headerStyle={{ borderBottom: 'none' }}
      >
        <div style={{ width: '98%', height: '100%' }}>
          <Chart
            data={chart.monthlyValues}
            labels={list.names}
            tickFormatter={(tickValue) => `${tickValue}%`}
            formatter={(value) => `${value}%`}
            fontSize={12}
            height={300}
          />
        </div>
      </Card>
    )
  );
};

export default IndicesHistoryChart;
