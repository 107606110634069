import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { canEditNewItem } from 'lib/contexts/newItems';
import { NewItemsActions } from 'store/newItems/newItems.ducks';

import { Button, Icon, Popover } from 'components/system/core';
import { Text } from 'components/system/typography';

import EditItemIcon from 'assets/icons/edit-item.svg';
import IcOption from 'assets/icons/table/options.svg';

import * as S from 'components/core/Table/CellWithActions/CellWithActions.styles';

const CellWithActions = ({ row }) => {
  const { original } = row;

  const dispatch = useDispatch();
  const { changeEdit, rowEdit } = useSelector(({ newItems }) => newItems);

  const handleEditCell = () => {
    if (original && canEditNewItem()) {
      dispatch(NewItemsActions.setEditRow({ row: original, editMode: true }));
    }
  };

  const reloadNewItems = () => {
    dispatch(NewItemsActions.newItemsListRequest());
  };

  const handleConfirmEdit = () => {
    if (original) {
      if (changeEdit) {
        if (original.hasBeenApproved) {
          dispatch(NewItemsActions.setEditModalOpen({ modalOpen: true }));
        } else {
          const payload = {
            id: rowEdit.id,
            newContractCode: rowEdit.codeContract,
            codeItem: rowEdit.codeItem,
          };

          dispatch(
            NewItemsActions.linkNewContractRequest(payload, reloadNewItems),
          );
        }
      } else {
        dispatch(NewItemsActions.setEditRow({ row: {}, editMode: false }));
      }
    }
  };

  const actionIsDisabled = !original.status;

  const Options = (
    <S.OptionsContainer>
      <S.OptionItem
        data-testid="test-option-edit"
        onClick={handleEditCell}
        disabled={actionIsDisabled}
      >
        <Icon src={EditItemIcon} />
        <Text size="medium" style={{ fontWeight: '400' }}>
          Editar
        </Text>
      </S.OptionItem>
    </S.OptionsContainer>
  );

  return !row.original.editMode ? (
    <S.ContentCell data-testid="cell-with-action">
      <Popover content={Options} position="left center">
        <Button
          data-testid="test-popover-option"
          icon={IcOption}
          name=""
          variant="tertiary"
        />
      </Popover>
    </S.ContentCell>
  ) : (
    <S.Flex direction="">
      <Button
        onClick={handleConfirmEdit}
        data-testid="test-confirm-edit"
        name="Ok"
        variant="primary"
      />
    </S.Flex>
  );
};

CellWithActions.propTypes = {
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      codeContract: PropTypes.string,
      editMode: PropTypes.bool,
    }).isRequired,
  }),
};

CellWithActions.defaultProps = {
  row: {
    index: 0,
    original: {
      id: 0,
      editMode: false,
      codeContract: '',
    },
  },
};

export default CellWithActions;
