import css from '@styled-system/css';
import styled from 'styled-components';
import { compose, layout, space } from 'styled-system';

import theme from '../../tokens';

export const LabelStyle = styled('label')(
  css({
    font: theme.fonts.textRegularSmall,
    color: theme.colors.gray400,
    display: 'flex',
    span: {
      margin: '0 4px',
      position: 'relative',
      ':first-of-type': {
        margin: '0px',
      },
      '&.informative': {
        color: theme.colors.gray200,
      },
    },
  }),
  compose(layout, space),
);
