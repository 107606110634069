import PropTypes from 'prop-types';
import React from 'react';
import Popup from 'reactjs-popup';

import * as S from './styles';

const arrowStyle = { color: '#0000' };

const Popover = ({ children, content, position, ...attrs }) => (
  <S.PopoverWrapper data-testid="ds-popover">
    <Popup
      trigger={<div>{children}</div>}
      position={position}
      {...{ arrowStyle }}
      {...attrs}
    >
      <S.Card>{content}</S.Card>
    </Popup>
  </S.PopoverWrapper>
);

Popover.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  position: PropTypes.string,
};

Popover.defaultProps = {
  position: 'left center',
};

export default Popover;
