import styled from 'styled-components';

import { Modal } from 'components/system/core';

export const ModalContainer = styled(Modal)`
  width: 100%;
`;

export const Wrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Actions = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;
