/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import ChevronDownIcon from 'assets/system/svgs/chevron/down.svg';
import ClearIcon from 'assets/system/svgs/clear.svg';

import Icon from '../../core/icon';
import Checkbox from '../checkbox';
import Label from '../label';

import theme from '../../tokens';
import useDimensions from '../../util/useDimensions';

import {
  Container,
  Group,
  GroupItem,
  OptionsList,
  SearchInput,
  Tag,
  Tags,
} from './styles';

const Multiselect = ({
  listItems,
  label: labelValue,
  required,
  keyValue,
  onChange,
  name,
  ...attrs
}) => {
  const dimensions = useDimensions();

  const tagsRef = useRef(null);
  const selectRef = useRef(null);
  const searchRef = useRef(null);
  const optionsRef = useRef(null);

  const [search, setSearch] = useState('');
  const [placeholder, setPlaceholder] = useState('');

  const [openOptions, setOpenOptions] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState(() => {
    if (listItems) {
      const selected = listItems
        .reduce(
          (acc, curr) => (curr?.data ? [...acc, ...curr.data] : [...acc, curr]),
          [],
        )
        .filter((item) => item.selected);

      return selected;
    }

    return [];
  });

  const [tags, setTags] = useState({
    freeSpace: null,
    itemsToFit: null,
    clippedItems: 0,
  });

  const allValues = useMemo(() => {
    if (listItems && keyValue) {
      const values = [];

      listItems.forEach((listItem) => {
        if (listItem.data?.length) {
          listItem.data.forEach((item) => {
            values.push(item.value);
          });
        } else {
          values.push(listItem.value);
        }
      });

      return values;
    }

    return [];
  }, [listItems, keyValue]);

  const getFreeSpace = () => {
    const searchWidth = searchRef.current.getBoundingClientRect().width;
    const tagsWidth = tagsRef.current.getBoundingClientRect().width;

    return searchWidth - tagsWidth;
  };

  const calcFreeSpace = () => {
    const freeSpace = getFreeSpace();

    return setTags({ ...tags, freeSpace });
  };

  const calcTagsAmount = () => {
    const searchWidth = searchRef.current.getBoundingClientRect().width;
    const tagsChildren = tagsRef.current.children;
    let tagsAmount = 0;
    let tagsWidthSum = 0;

    for (let i = 0; i < selectedItems.length; i += 1) {
      if (tagsChildren[i]) {
        tagsWidthSum += tagsChildren[i].getBoundingClientRect().width;

        if (searchWidth - tagsWidthSum > 150) {
          tagsAmount += 1;
        }
      }
    }

    return tagsAmount;
  };

  const fitTags = () => {
    const { itemsToFit, clippedItems } = tags;
    const tagsAmount = calcTagsAmount();

    if (selectedItems.length !== tagsAmount || clippedItems) {
      if (clippedItems) {
        if (selectedItems.length < itemsToFit + clippedItems) {
          setTags({
            ...tags,
            clippedItems: clippedItems - 1,
          });
        } else {
          setTags({
            ...tags,
            clippedItems: clippedItems + 1,
          });
        }
      } else {
        setTags({
          ...tags,
          itemsToFit: tagsAmount,
          clippedItems: selectedItems.length - tagsAmount,
        });
      }
    }
  };

  const addItem = (value) => {
    setSearch('');
    searchRef.current.focus();

    const setItems = (oldState) => {
      const added = [...oldState, value];
      return added;
    };

    setSelectedItems(setItems);
  };

  const removeItem = (item) => {
    const filterItems = ({ value }) => value[keyValue] !== item.value[keyValue];

    const setItems = (oldState) => {
      const filtered = oldState.filter(filterItems);
      return filtered;
    };

    setSelectedItems(setItems);
  };

  const getItemLabel = useCallback(
    (label) => {
      if (search && label.toLowerCase().includes(search)) {
        const parsedLabel = label.toLowerCase();
        parsedLabel.charAt(0).toUpperCase();
        return parsedLabel;
      }
      return label;
    },
    [search],
  );

  const renderOption = (item, label, values) => {
    const itemSelected = selectedItems.some(
      ({ value: selectedItem }) =>
        selectedItem[keyValue] === item.value[keyValue],
    );
    const indexValue = values.findIndex(
      (value) => value[keyValue] === item.value[keyValue],
    );

    return (
      <GroupItem
        option
        aria-selected={itemSelected}
        tabIndex={indexValue}
        key={`vli-group-item-${indexValue}`}
        id={`vli-group-item-${indexValue}`}
        onClick={() => {
          if (!itemSelected) {
            addItem(item);
          } else {
            removeItem(item);
          }
        }}
      >
        <Checkbox
          label={getItemLabel(label)}
          key={`${label}-${item.value[keyValue]}`}
          id={`${label}-${item.value[keyValue]}`}
          checked={itemSelected}
          group={label}
          onChange={({ target: { checked } }) => {
            if (checked) addItem(item);
            else removeItem(item);
          }}
        />
      </GroupItem>
    );
  };

  const renderOptions = ({ label, data, value }) => {
    if (data?.length) {
      return (
        <Group title={label} key={label}>
          {data.map((item) => renderOption(item, item.label, allValues))}
        </Group>
      );
    }
    if (value) {
      return renderOption({ label, value }, label, allValues);
    }
    return null;
  };

  const renderTags = (item, i) => {
    if (i < tags.itemsToFit || tags.itemsToFit === null) {
      return (
        <Tag key={i}>
          {item.label}
          <Icon
            className="clear-button"
            src={ClearIcon}
            size={16}
            onClick={(e) => {
              e.stopPropagation();
              removeItem(item);
            }}
          />
        </Tag>
      );
    }
    return null;
  };

  const filtering = useCallback(
    ({ label, data, value }) => {
      if (data) {
        return {
          label,
          data: data.filter((item) =>
            item.label.toLowerCase().includes(search),
          ),
        };
      }
      if (label.toLowerCase().includes(search)) {
        return { label, value };
      }
      return null;
    },
    [search],
  );

  useEffect(() => {
    calcFreeSpace();
    const tagsAmount = calcTagsAmount();

    setTags({
      ...tags,
      itemsToFit: tagsAmount,
      clippedItems: selectedItems.length - tagsAmount,
    });
  }, [dimensions.width]);

  useEffect(() => {
    calcFreeSpace();
    fitTags();
    onChange(selectedItems);

    if (selectedItems.length) {
      setPlaceholder('');
    } else {
      setPlaceholder(attrs?.placeholder);
    }
  }, [selectedItems]);

  useEffect(() => {
    if (search.length > 0) {
      const filteredOptions = listItems
        .map(filtering)
        .filter((item) => item !== null);
      setFilteredItems(filteredOptions);
    }
  }, [search, listItems, filtering]);

  useEffect(() => {
    const selectNode = selectRef.current;
    if (selectNode) {
      document.addEventListener('click', (e) => {
        if (e.target !== selectNode && !selectNode.contains(e.target)) {
          setOpenOptions(false);
        }
      });
    }
  }, []);

  return (
    <>
      <div>
        {labelValue && (
          <Label
            informative={!required && '(Opcional)'}
            style={{ marginBottom: theme.spacing.xss }}
          >
            {labelValue}
          </Label>
        )}
      </div>
      <Container
        role="combobox"
        aria-haspopup="listbox"
        aria-owns="vli-optional-list"
        aria-expanded={search.length > 0}
        ref={selectRef}
        data-testid="ds-multiselect"
      >
        <Tags
          ref={tagsRef}
          spaceChildren={2}
          alignRight={search.length > 0}
          onClick={() => searchRef.current.focus()}
        >
          {search.length === 0 && selectedItems.map(renderTags)}
          {search.length > 0 && selectedItems.length > 0 && (
            <Tag>+{selectedItems.length}</Tag>
          )}
          {tags.clippedItems > 0 && search.length === 0 && (
            <Tag>+{tags.clippedItems}</Tag>
          )}
        </Tags>
        <SearchInput
          name={name}
          role="searchbox"
          aria-controls="vli-optional-list"
          aria-autocomplete="both"
          ref={searchRef}
          type="text"
          value={search}
          onFocus={() => {
            setOpenOptions(true);
          }}
          openOptions={openOptions}
          onChange={({ target: { value } }) => setSearch(value.toLowerCase())}
          {...attrs}
          placeholder={placeholder}
        />
        <Icon
          className="drop-options--trigger"
          onClick={() => {
            if (openOptions) setOpenOptions(false);
            else {
              searchRef.current.focus();
              setOpenOptions(true);
            }
          }}
          src={ChevronDownIcon}
          size={16}
        />
        {openOptions && (
          <OptionsList ref={optionsRef} id="vli-optional-list" role="listbox">
            {search.length === 0
              ? listItems.map(renderOptions)
              : filteredItems.map(renderOptions)}
          </OptionsList>
        )}
      </Container>
    </>
  );
};

Multiselect.propTypes = {
  listItems: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        data: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.object,
          }),
        ),
      }),
    ),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.object,
      }),
    ),
  ]).isRequired,
  label: PropTypes.string,
  keyValue: PropTypes.string,
  required: PropTypes.bool,
  sections: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
};

Multiselect.defaultProps = {
  label: '',
  required: false,
  sections: true,
  keyValue: 'id',
  onChange: () => {},
  name: '',
};

export default Multiselect;
