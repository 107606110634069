import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Flex, Tabs } from 'components/system/core';
import { Container, Content, Header } from 'components/system/interface';

import HistoryModal from 'components/contexts/local/History/History';
import RefineryList from 'components/contexts/local/refinery/refineryList/Table';
import StationList from 'components/contexts/local/station/stationList/Table';

import * as ROUTES from 'constants/urls';

import useDimensions from 'hooks/useDimensions';

import { LocalActions } from 'store/local/local.ducks';

const Local = () => {
  const dimensions = useDimensions();
  const dispatch = useDispatch();

  const { historyModalOpen, localData } = useSelector(({ local }) => local);

  const breadcrumb = [
    {
      text: 'Lista base de diesel',
      href: `#${ROUTES.LOCAL}`,
    },
  ];

  return (
    <Content height={dimensions.height}>
      <HistoryModal
        isOpen={historyModalOpen}
        onClose={() =>
          dispatch(LocalActions.setHistoryModalOpen({ modalOpen: false }))
        }
        rowData={localData}
      />

      <Container>
        <Flex>
          <Header
            bread={breadcrumb}
            tag="header"
            title="Lista base de diesel"
          />
        </Flex>
        <Tabs position="start">
          <li
            label="Posto"
            style={{ marginRight: '16px' }}
            data-testid="tab-station"
          >
            <StationList />
          </li>

          <li label="Refinaria" data-testid="tab-refinery">
            <RefineryList />
          </li>
        </Tabs>
      </Container>
    </Content>
  );
};

export default Local;
