import css from '@styled-system/css';
import styled from 'styled-components';
import { variant } from 'styled-system';

import theme from '../../../tokens';

export const ToastContainerStyle = styled('ul')(
  css({
    listStyle: 'none',
    position: 'fixed',
    top: '37px',
    right: '10px',
    display: 'grid',
    maxWidth: '300px',
    height: '0',
    zIndex: '100',
  }),
);

export const ToastContentStyle = styled('div')(
  css({
    background: theme.colors.grayWhite,
    borderLeft: `8px solid ${theme.colors.accentPrimaryDark}`,
    borderRadius: '4px',
    boxShadow: `3px 3px 7px 0px ${theme.colors.gray200}`,
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingRight: '8px',
    paddingLeft: '24px',
    marginBottom: '20px',
    width: '100%',
    '&.error': {
      paddingLeft: '16px',
      borderLeft: `8px solid ${theme.colors.red300}`,
    },
    '&.warning': {
      paddingLeft: '16px',
      borderLeft: `8px solid ${theme.colors.yellow300}`,
    },
    '&.success': {
      paddingLeft: '16px',
      borderLeft: `8px solid ${theme.colors.green300}`,
    },
  }),
);

export const ToastItemStyle = styled('li')(
  css({
    transition: '0.4s',
    position: 'relative',
    right: '-110%',
    opacity: '0',
    minWidth: '300px',
    '&.open': {
      right: '10px',
      opacity: '1',
      transition: '0.4s',
    },
  }),
);

export const ToastContentContainer = styled('div')(
  css({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  }),
  variant({
    variants: {
      withIcon: {
        maxWidth: 'calc(100% - 36px)',
      },
    },
  }),
);

export const ToastHeader = styled('div')(
  css({
    display: 'flex',
    width: '100%',
    minHeight: '24px',
    padding: '0px',
    paddingRight: '8px',
    marginBottom: '8px',
    '& > h1': {
      font: theme.fonts.textBoldLarge,
      maxWidth: 'calc(100% - 30px)',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
);

export const CloseStyle = styled('div')(
  css({
    position: 'absolute',
    right: '0px',
    background: 'transparent',
    border: '0',
    top: '0px',
    cursor: 'pointer',
    ':hover': {
      cursor: 'pointer',
      opacity: '0.5',
    },
  }),
);

export const ToastChildren = styled('div')(
  css({
    color: theme.colors.gray400,
    width: '100%',
    padding: 0,
    margin: 0,
    textAlign: 'left',
    '> *': {
      textAlign: 'left',
    },
  }),
);
