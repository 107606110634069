import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Flex } from 'components/system/core';

import Modal from 'components/core/modal';

import InputSelect from 'components/form/InputSelect';
import { isPending } from 'lib/core/request';
import { FormalizationRequestActions } from 'store/formalizationRequest/formalizationRequest.ducks';
import { SegmentActions } from 'store/segment/segment.ducks';

const SegmentModal = ({ onClose, formalizationId }) => {
  const dispatch = useDispatch();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      segmentId: '',
    },
  });

  const { allSegments, segmentRequests } = useSelector(
    ({ segment }) => segment,
  );

  const sendFormalizationWithSegment = (data) => {
    if (data.segmentId) {
      dispatch(
        FormalizationRequestActions.sendForFormalizationRequest(
          { id: formalizationId, segmentId: data.segmentId },
          () => {},
        ),
      );
    }

    onClose();
  };

  useEffect(() => {
    dispatch(SegmentActions.listAllSegments());
  }, [dispatch]);

  const isLoading = isPending(segmentRequests.LIST_ALL_SEGMENTS);

  const segmentInputOptions = useMemo(() => {
    if (allSegments.length > 0) {
      return allSegments.map((segment) => {
        return {
          label: segment.segmentName,
          value: segment.segmentId,
        };
      });
    }

    return [];
  }, [allSegments]);

  return (
    <Modal
      width="100%"
      title="Solicitação de formalização sem segmento"
      onClose={onClose}
    >
      <Flex marginBottom="8px" width="100%">
        Para envio de solicitação para formalização necessário vincular um
        segmento.
      </Flex>

      {!isLoading && (
        <form
          onSubmit={handleSubmit(sendFormalizationWithSegment)}
          data-testid="add-segment-form"
        >
          <Controller
            name="segmentId"
            control={control}
            rules={{ required: true }}
            render={({ ...field }) => (
              <InputSelect
                {...field}
                width="100%"
                label=""
                placeholder="Selecione"
                options={segmentInputOptions}
                required
              />
            )}
          />

          <Flex justifyContent="flex-end" marginTop="16px" spaceChildren="16px">
            <Button
              name="Cancelar"
              onClick={onClose}
              variant="secondary"
              type="button"
            />
            <Button name="Confirmar" type="submit" />
          </Flex>
        </form>
      )}
    </Modal>
  );
};

SegmentModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  formalizationId: PropTypes.number.isRequired,
};

export default SegmentModal;
