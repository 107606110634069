import styled from 'styled-components';

import { Card } from 'components/system/core';
import Tokens from 'components/system/tokens';

export const WrapperCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: ${Tokens.spacing.xs};

  width: 100%;
  margin-top: ${Tokens.spacing.sm};
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${Tokens.spacing.sm};

  h3 {
    font: ${Tokens.fonts.headingBoldH6};
    color: ${Tokens.colors.gray500};
    span {
      color: ${Tokens.colors.gray300};
    }
  }

  button {
    appearance: none;
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    font: ${Tokens.fonts.textRegularSmall};
    color: ${Tokens.colors.accentPrimaryDark};

    cursor: pointer;

    text-decoration: underline;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  gap: ${Tokens.spacing.xs};

  button {
    text-transform: none;
    font-weight: 600;
  }
`;

export const Tag = styled.span`
  padding: ${Tokens.spacing.xss};
  border-radius: ${Tokens.spacing.xs};

  background-color: ${Tokens.colors.accentPrimaryLight};
  color: ${Tokens.colors.accentPrimaryDark};
  font: ${Tokens.fonts.textBoldMedium};
`;
