import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Content } from 'components/system/interface';

import Form from 'components/contexts/user/form';
import Loading from 'components/core/Loading';

import { ProfileActions } from 'store/profile/profile.ducks';
import { SegmentActions } from 'store/segment/segment.ducks';
import { UserActions } from 'store/user/user.ducks';

import { getDataSend } from 'lib/contexts/user';
import { isPending } from 'lib/core/request';

import useDimensions from 'hooks/useDimensions';

import * as ROUTES from 'constants/urls';

const breadcrumb = [
  {
    text: 'Usuários',
    href: `#${ROUTES.USER_LIST}`,
  },
  {
    text: 'Novo Usuário',
    href: `#${ROUTES.NEW_USER}`,
  },
];

const CreateUser = () => {
  const dimensions = useDimensions();

  const dispatch = useDispatch();

  const { usersRequests } = useSelector(({ user }) => user);
  const { data: profiles, profilesRequests } = useSelector(
    ({ profile }) => profile,
  );

  const { segmentProfileList: segmentList, segmentRequests } = useSelector(
    ({ segment }) => segment,
  );

  useEffect(() => {
    dispatch(ProfileActions.fetchProfiles());
    dispatch(SegmentActions.listSegmentActive());
  }, [dispatch]);

  const onSubmit = (values) => {
    const data = getDataSend(values);

    dispatch(UserActions.createUser(data));
  };

  const isLoading =
    isPending(segmentRequests.LIST_SEGMENT_ACTIVE) ||
    isPending(profilesRequests.FETCH_PROFILES) ||
    isPending(usersRequests.CREATE_USER);

  return (
    <Content data-testid="content" height={dimensions.height?.toString()}>
      {isLoading && <Loading />}

      {!isLoading && (
        <Container>
          <Form
            breadcrumb={breadcrumb}
            profiles={profiles}
            segments={segmentList}
            onSubmit={onSubmit}
          />
        </Container>
      )}
    </Content>
  );
};

export default CreateUser;
