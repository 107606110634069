import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from 'react-date-picker';

import CalendarIcon from 'assets/system/svgs/calendar.svg';

import Icon from '../../core/icon';

import { Label } from '../input/styles';
import { CalendarStyle } from './styles';

const InputDate = ({
  isOpen,
  className,
  locale,
  width,
  value,
  range,
  onChange,
  label,
  ...attrs
}) => (
  <CalendarStyle
    className={className}
    width={width}
    spanStyle={!!value}
    variant={range && 'range'}
    data-testid="ds-input-date"
  >
    {label && <Label>{label}</Label>}
    {!range ? (
      <DatePicker
        isOpen={isOpen}
        value={value}
        locale={locale}
        onChange={onChange}
        calendarIcon={<Icon src={CalendarIcon} />}
        dayPlaceholder="DD"
        monthPlaceholder="MM"
        yearPlaceholder="AAAA"
        {...attrs}
      />
    ) : (
      <DateRangePicker
        isOpen={isOpen}
        value={value}
        locale={locale}
        onChange={onChange}
        calendarIcon={<Icon src={CalendarIcon} />}
        {...attrs}
      />
    )}
  </CalendarStyle>
);

InputDate.propTypes = {
  isOpen: PropTypes.bool,
  locale: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  className: PropTypes.string,
  range: PropTypes.bool,
  width: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
};

InputDate.defaultProps = {
  isOpen: false,
  locale: 'pt-BR',
  value: '',
  className: '',
  width: '100%',
  range: false,
  onChange: () => {},
  label: '',
};

export default InputDate;
