import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MessagesActions } from 'store/messages/messages.ducks';

import { Toast, ToastContainer } from 'components/system/core';
import Tokens from 'components/system/tokens';
import { Text } from 'components/system/typography';

const MessageToast = () => {
  const { open, type, autoClose, contentText, title } = useSelector(
    ({ messages }) => messages,
  );
  const dispatch = useDispatch();
  const setClose = () => {
    dispatch(MessagesActions.close());
  };

  return (
    <ToastContainer>
      <Toast
        error={type === 'error'}
        warning={type === 'warning'}
        success={type === 'success'}
        title={title}
        isOpen={open}
        autoClose={autoClose}
        delay={8000}
        onClose={() => setClose()}
      >
        <Text color={Tokens.colors.gray400}>{contentText}</Text>
      </Toast>
    </ToastContainer>
  );
};

export default MessageToast;
