import PropTypes from 'prop-types';
import React from 'react';

import { Flex } from 'components/system/core';

import { ReactComponent as OutsideLinkIcon } from 'assets/icons/outside-link.svg';

import * as S from './ExternalLink.styles';

const ExternalLink = ({ url, label }) => {
  return (
    <Flex alignItems="center">
      {label}
      <S.Link href={url} target="_blank" rel="noopener noreferrer">
        <OutsideLinkIcon />
      </S.Link>
    </Flex>
  );
};

ExternalLink.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default ExternalLink;
