import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getContractStatus, getOptionsFilter } from 'lib/contexts/contracts';
import { isFinished } from 'lib/core/request';
import * as utility from 'lib/core/utility';

import { EmptyState, Tabs } from 'components/system/core';

import Table from 'components/core/Table';
import TableContainer from 'components/core/TableContainer';

import { history } from 'constants/routes';
import * as ROUTES from 'constants/urls';

import FilterContracts from '../../FilterContracts';
import Columns from './Columns';

const DefaultTable = () => {
  const { id } = useParams();
  const { contractDetails, contractDetailRequests } = useSelector(
    ({ contractDetail }) => contractDetail,
  );
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const getOptions = getOptionsFilter(contractDetails.details);
    setOptions(getOptions);
  }, [contractDetails]);

  const list = contractDetails.details.railwayItems;
  const dataTable = list.map((obj) => {
    const newObj = {
      ...obj,
      headlight: obj.headlight,
      railwayItemCode: obj.railwayItemCode,
      originDestination: `${obj.originCode} -> ${obj.destinationCode}`,
      productUnicomMerchandiseTacito: obj.productUnicomMerchandiseTacito,
      dateApplication: obj.calculatedValue.dateApplication,
      valueContracted: obj.valueContracted,
      valueRetrieved: obj.validityRailwayItem.valueRetrieved,
      calculatedValue: obj.history.incentivizedRate
        ? obj.history.incentivizedRate
        : obj.calculatedValue.value,
      deltaPrice: obj.tariffDelta,
      history: obj.history,
      statusText: obj.history.statusText,
      statusTooltip: obj.history.statusTooltip,
    };
    return newObj;
  });

  return (
    <TableContainer data-testid="contractdetail-test">
      <FilterContracts
        totalTo={dataTable.length}
        id={id}
        disabled={
          !utility.validateAction(
            contractDetails.details.railwayItems,
            contractDetails.details.segmentId,
            contractDetails.details,
          )
        }
        options={options}
      />
      <Tabs
        position="start"
        onChange={(tabLabel) => {
          if (tabLabel && tabLabel !== contractDetails.details.code) {
            const relative = contractDetails?.details?.contractRelatives.find(
              (x) => x.contractCode === tabLabel,
            );

            const isClosed = getContractStatus({ details: relative });

            history.push(
              `${ROUTES.CONTRACT_DETAILS(relative.contractId, isClosed)}`,
            );
          }
        }}
      >
        {contractDetails?.details?.contractRelatives.map((x) => (
          <li
            key={x.contractId}
            label={x.contractCode}
            active={x.contractId === contractDetails.details.id}
          >
            {x.contractId === contractDetails.details.id &&
            dataTable.length > 0 ? (
              <Table
                width="100%"
                tableType="responsive"
                footer={false}
                columns={Columns}
                data={dataTable}
                sortBy={[{ id: 'accountManager', desc: false }]}
              />
            ) : (
              isFinished(contractDetailRequests.GET_CONTRACT_DETAIL) && (
                <EmptyState condensed={false} title="Nenhum item encontrado." />
              )
            )}
          </li>
        ))}
      </Tabs>
    </TableContainer>
  );
};

export default DefaultTable;
