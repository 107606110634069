import { useMsal } from '@azure/msal-react';
import React from 'react';
import { useDispatch } from 'react-redux';

import { ReactComponent as Logo } from 'assets/images/logo.svg';

import { Button } from 'components/system/core';

import { AuthActions } from 'store/auth/auth.ducks';

import { SET } from 'lib/core/localStorage';
import { apiRequest } from 'lib/core/msalConfig';

import * as S from './Login.styles';

const SHOULD_RELOAD_WINDOW = false;
const IS_FIRST_LOGIN = true;

const Login = () => {
  const { instance } = useMsal();

  const dispatch = useDispatch();

  function handleGetToken(response) {
    SET('access-token', response.idToken);
    dispatch(AuthActions.signIn(SHOULD_RELOAD_WINDOW, IS_FIRST_LOGIN));
  }

  const refresh = () => {
    localStorage.clear();

    instance.acquireTokenPopup(apiRequest).then(handleGetToken);
  };

  return (
    <S.LoginBackground>
      <S.LoginContainer>
        <S.LoginCard>
          <S.LoginLogo>
            <Logo />
          </S.LoginLogo>
          <S.LoginButtons>
            <Button
              variant="secondary"
              icon={false}
              name="Entrar"
              onClick={refresh}
            />
          </S.LoginButtons>
        </S.LoginCard>
      </S.LoginContainer>
    </S.LoginBackground>
  );
};

export default Login;
