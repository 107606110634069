import React from 'react';

import { Flex } from 'components/system/core';
import { Container, Content, Header } from 'components/system/interface';

import useDimensions from 'hooks/useDimensions';

import RecommendationTable from 'components/contexts/waitingRecommendation/RecommendationTable';

import * as ROUTES from 'constants/urls';

const WaitingRecommendation = () => {
  const dimensions = useDimensions();

  const breadcrumb = [
    {
      text: 'Aguardando recomendação',
      href: `#${ROUTES.WAITING_RECOMMENDATION}`,
    },
  ];

  return (
    <Content
      height={dimensions.height}
      data-testid="waiting-recommendation-content"
    >
      <Container>
        <Flex justifyContent="space-between" alignItems="center">
          <Header
            bread={breadcrumb}
            tag="header"
            title="Aguardando recomendação"
          />
        </Flex>

        <RecommendationTable />
      </Container>
    </Content>
  );
};

export default WaitingRecommendation;
