import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'components/system/typography';

import Tooltip from 'components/core/Tooltip';

const CellStatusTable = ({
  cell: { value },
  informative,
  transformedData,
  row,
  onClick,
}) => {
  const { original } = row;

  const handleOnClick = () => {
    if (original) {
      onClick(original);
    }
  };

  const tooltipContent = `
    <p>${original.statusTooltip}</p>

    ${
      original?.history?.approvingUser &&
      original?.history?.approvingDate &&
      `<div>
        <strong>Responsável pela aprovação:</strong>
        <br />
        <span>
          ${original.history.approvingUser} às 
          ${format(
            new Date(original.history.approvingDate),
            "HH:mm 'de' dd/MM/yyyy",
          )}
        </span>
        ${
          original?.history?.approveType
            ? `
            <br />
            <strong>Tipo de aprovação:</strong>
            <br />
            <span>${original.history.approveType}</span>
          `
            : ''
        }
      </div>`
    }
  `;

  return (
    <Tooltip title={tooltipContent} place="top" data-testid="status-tooltip">
      <Text
        size="medium"
        data-testid="status-tooltip-text"
        style={{ cursor: 'pointer', fontWeight: '400' }}
        onClick={handleOnClick}
      >
        {transformedData(value) || '-'} {informative}
      </Text>
    </Tooltip>
  );
};

CellStatusTable.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  informative: PropTypes.string,
  transformedData: PropTypes.func,
  onClick: PropTypes.func,
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      statusTooltip: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

CellStatusTable.defaultProps = {
  cell: {
    value: '',
  },
  informative: '',
  transformedData: (text) => text,
  onClick: () => {},
};

export default CellStatusTable;
