import styled from 'styled-components';

import Tokens from 'components/system/tokens';

export const TableContainer = styled.div`
  width: 100%;
  padding: ${Tokens.spacing.md} 0px;
  table {
    width: 100%;
    th {
      text-align: left;
    }
    th:last-child {
      text-align: right;
    }
  }
`;

export const NameGroup = styled.div`
  margin-top: ${Tokens.spacing.md};
`;
