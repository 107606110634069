import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import CheckboxIcon from 'assets/system/svgs/checkbox.svg';

import Icon from '../../core/icon';
import Label from '../label';

import { CheckboxContainer, CheckboxStyle } from './styles';

const Checkbox = ({
  id,
  checked,
  onChange,
  group,
  disabled,
  label,
  innerRef,
  ...attrs
}) => {
  const [isChecked, setChecked] = useState(checked);

  const handleChangeInput = (event) => {
    setChecked(!isChecked);

    if (onChange) {
      onChange(event);
    }

    return event;
  };

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  return (
    <CheckboxContainer
      {...attrs}
      htmlFor={id}
      onClick={(e) => e.stopPropagation()}
      data-testid="ds-checkbox"
    >
      <CheckboxStyle>
        <input
          id={id}
          onChange={(e) => !disabled && handleChangeInput(e)}
          name={group}
          checked={isChecked}
          disabled={disabled}
          type="checkbox"
          data-testid="ds-checkbox-input"
          ref={innerRef}
        />

        {isChecked && (
          <Icon src={CheckboxIcon} className="checked-icon" size={12} />
        )}
      </CheckboxStyle>

      <Label htmlFor={id}>{label}</Label>
    </CheckboxContainer>
  );
};

Checkbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  checked: PropTypes.bool,
  partiallyChecked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  group: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

Checkbox.defaultProps = {
  disabled: false,
  checked: false,
  partiallyChecked: false,
  innerRef: () => {},
};

export default Checkbox;
