import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import TextArea from 'components/form/TextArea';

import { Button } from 'components/system/core';
import { Radio } from 'components/system/form';

import { ContractActions } from 'store/contract/contract.ducks';

import * as S from './DefaultFlagModal.styles';

const DefaultFlagModal = () => {
  const { rowDefaultFlag, isDefaultFlagModalOpen } = useSelector(
    ({ contract }) => contract,
  );

  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    justification: Yup.string()
      .min(15, 'A justificativa deve ter minímo de 15 caracteres')
      .max(120, 'A justificativa deve ter maxima de 500 caracteres')
      .required('A justificativa é um campo requerido'),
    isDefault: Yup.mixed().required('Campo requerido'),
  });

  const { handleSubmit, errors, control } = useForm({
    defaultValues: {
      justification: '',
      isDefault: 'Sim',
    },
    resolver: yupResolver(validationSchema),
  });

  const closeModal = () => {
    dispatch(
      ContractActions.setDefaultFlagModalOpen({
        modalOpen: false,
        rowDefaultFlag: null,
      }),
    );
  };

  const callback = () => {
    dispatch(ContractActions.listContract(''));
  };

  const handleUpdateDefaultFlag = (data) => {
    const { justification, isDefault } = data;

    const payload = {
      contractId: rowDefaultFlag.id,
      isDefault: isDefault === 'Sim',
      observation: justification,
    };

    dispatch(ContractActions.updateDefaultFlag(payload, callback));

    closeModal();
  };

  return (
    <S.CustomModal
      title="Contrato padrão"
      isOpen={isDefaultFlagModalOpen}
      onClose={closeModal}
    >
      <form onSubmit={handleSubmit(handleUpdateDefaultFlag)}>
        <Controller
          name="isDefault"
          rules={{ required: true }}
          control={control}
          render={({ value, ...props }) => (
            <S.InputWrapper>
              <Radio
                label="Sim"
                value="Sim"
                checked={value === 'Sim'}
                {...props}
              />

              <Radio
                label="Não"
                value="Não"
                checked={value === 'Não'}
                {...props}
              />
            </S.InputWrapper>
          )}
        />

        <Controller
          name="justification"
          defaultValue=""
          rules={{ required: true }}
          control={control}
          render={({ ...props }) => (
            <TextArea
              {...props}
              label="Justificativa"
              statusMessage={errors.justification?.message}
              status={errors.justification && 'error'}
              hideCount
              required
            />
          )}
        />

        <S.ActionsWrapper>
          <Button
            data-testid="submit-button-test"
            name="Salvar"
            type="submit"
          />
        </S.ActionsWrapper>
      </form>
    </S.CustomModal>
  );
};

DefaultFlagModal.defaultProps = {};

DefaultFlagModal.propTypes = {};

export default DefaultFlagModal;
