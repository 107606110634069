import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'components/system/typography';

const CellDestination = ({ cell: { value }, row, onClick, align }) => {
  const { original } = row;

  const handleOnClick = () => {
    if (original) {
      onClick(original);
    }
    return null;
  };

  const getDestinationValue = () => {
    if (original?.powerPlant) {
      return original.powerPlant;
    }
    if (original?.mesoregion) {
      return original.mesoregion;
    }
    if (original?.county && original?.state) {
      return `${original.county}/${original.state}`;
    }
    return '-';
  };

  return (
    <Text
      size="medium"
      data-testid={`cell-table-${value}`}
      style={{ cursor: 'pointer', textAlign: align, fontWeight: '400' }}
      onClick={handleOnClick}
    >
      {getDestinationValue()}
    </Text>
  );
};

CellDestination.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  onClick: PropTypes.func,
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  align: PropTypes.string,
};

CellDestination.defaultProps = {
  cell: {
    value: '',
  },
  onClick: () => {},
  align: 'start',
};

export default CellDestination;
