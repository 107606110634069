import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { Text } from 'components/system/typography';

import Modal from 'components/core/modal';
import { Table, Td, Th, Thead, Tr } from 'components/core/Table/Table.styles';

import * as utility from 'lib/core/utility';

import * as S from '../IndicesHistoryTable/IndicesHistoryTable.styles';

const IndicesInflationHistoryAccumulateModal = ({
  list,
  indiceHistoryName,
  onClose,
}) => {
  const [indicesValues, setIndicesValues] = useState([]);
  const [accumulateValue, setAccumulateValue] = useState(0);

  const setSimpleTotalAccumulate = (array) => {
    const simple = array
      ?.map((n) => Number(n.replace(',', '.')))
      .reduce((accumulated, currentValue) => {
        return accumulated * (currentValue / 100 + 1);
      }, 1);

    const total = (simple - 1) * 100;
    const totalFormatted = total.toFixed(3).toString().replace('.', ',');
    setAccumulateValue(totalFormatted);
  };

  const setIndiceAccumulateMonthList = useCallback(() => {
    const monthlyList = list.monthValues
      ?.map((indice) =>
        indice.indexVariations.filter(
          (variationName) => variationName.name === indiceHistoryName,
        ),
      )
      .map((p) => p[0].percentage);

    setIndicesValues(monthlyList);
    setSimpleTotalAccumulate(monthlyList);
  }, [indiceHistoryName, list.monthValues]);

  useEffect(() => {
    setIndiceAccumulateMonthList();
  }, [list, indiceHistoryName, setIndiceAccumulateMonthList]);

  return (
    <Modal width="100%" title="Acúmulo da inflação" onClose={onClose}>
      <>
        <Text size="medium">Acúmulo dinâmico referente ao filtro aplicado</Text>
        <S.TableContainer data-testid="indices-inflation-history-accumulate-modal-table-test">
          <Table>
            <Thead>
              <Tr>
                <Th>Índices</Th>
                {list.months?.map((monthName) => (
                  <Th key={`th-indice-${monthName}`}>{monthName}</Th>
                ))}
                <Th>Acúmulo</Th>
              </Tr>
            </Thead>

            <tbody>
              <Tr>
                <Td>
                  <Text
                    size="medium"
                    style={{
                      fontWeight: '400',
                    }}
                  >
                    {indiceHistoryName}
                  </Text>
                </Td>

                {indicesValues?.map((month) => (
                  <Td key={`td-indice-${month}`}>
                    <span data-tip="">
                      <Text
                        size="medium"
                        style={{
                          fontWeight: '400',
                        }}
                      >
                        {utility.valstr(month, 'percent')}
                      </Text>
                    </span>
                  </Td>
                ))}

                <Td style={{ textAlign: 'center' }}>
                  <Text
                    size="medium"
                    style={{
                      fontWeight: '400',
                    }}
                    data-testid="accumulate-value-indices"
                  >
                    {accumulateValue}%
                  </Text>
                </Td>
              </Tr>
            </tbody>
          </Table>
        </S.TableContainer>
      </>
    </Modal>
  );
};

IndicesInflationHistoryAccumulateModal.propTypes = {
  list: PropTypes.object,
  indiceHistoryName: PropTypes.string,
  onClose: PropTypes.func,
};

IndicesInflationHistoryAccumulateModal.defaultProps = {
  list: {},
  indiceHistoryName: '',
  onClose: () => {},
};

export default IndicesInflationHistoryAccumulateModal;
