import PropTypes from 'prop-types';
import React from 'react';

import { LabelStyle } from './styles';

const Label = ({ informative, children, ...attrs }) => (
  <LabelStyle data-testid="ds-label" {...attrs}>
    <span>{children}</span>
    {informative && <span className="informative">{informative}</span>}
  </LabelStyle>
);

Label.propTypes = {
  children: PropTypes.node,
  informative: PropTypes.string,
};

Label.defaultProps = {
  children: '',
  informative: '',
};

export default Label;
