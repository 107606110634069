import css from '@styled-system/css';
import styled from 'styled-components';
import { compose, layout, space } from 'styled-system';

import theme from '../../tokens';

export const RadioContainer = styled('div')(
  css({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    minHeight: '32px',
    input: {
      opacity: '0',
      width: '100%',
      height: '100%',
      top: '0',
      left: '0',
      position: 'absolute',
      zIndex: '1',
      '&:not(:disabled)': {
        cursor: 'pointer',
      },
    },
    label: {
      font: theme.fonts.textRegularMedium,
      color: theme.colors.gray500,
    },
  }),
);

export const RadioStyle = styled('div')(
  css({
    position: 'relative',
    width: '18px',
    height: '18px',
    minWidth: '18px',
    borderRadius: '100%',
    marginRight: '12px',
    background: theme.colors.grayWhite,
    border: `3px solid ${theme.colors.grayWhite}`,
    boxShadow: `0px 0px 0px 2px ${theme.colors.gray200}`,
    '&:not(.disabled).checked': {
      background: theme.colors.accentPrimaryDark,
      border: `3px solid ${theme.colors.grayWhite}`,
      boxShadow: `0px 0px 0px 2px ${theme.colors.accentPrimaryDark}`,
    },
    transition: '0.3s',
  }),
  compose(layout, space),
);
