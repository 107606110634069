import PropTypes from 'prop-types';
import React from 'react';

import InfoIcon from 'assets/system/svgs/info.svg';
import ArrowRightIcon from 'assets/system/svgs/right.svg';
import ShipIcon from 'assets/system/svgs/ship.svg';
import TrainIcon from 'assets/system/svgs/train.svg';

import { Icon } from 'components/system/core';

import { CompensationTypes } from 'lib/contexts/formalizationRequest';

import * as S from '../TakeOrPayModalStyles';

const Compensation = ({ salesCommitments }) => {
  const groupedByCompensationGroup = (data) => {
    return data
      .filter((dataItem) => dataItem.compensationGroup !== null)
      .reduce((acc, item) => {
        const groupKey = item.compensationGroup;

        if (!acc[groupKey]) {
          acc[groupKey] = [];
        }

        acc[groupKey].push(item);

        return acc;
      }, {});
  };

  return (
    <S.CompensationContainer data-testid="compensation-test">
      <S.Title>Compensação de fluxos</S.Title>

      <S.GridFlow>
        {salesCommitments?.map((sale) => {
          return (
            <S.FlowByModal
              key={`comp-${sale.modalId}`}
              hasDivider={salesCommitments.length > 1}
            >
              <div>
                {sale.modalId === 1 ? (
                  <Icon src={TrainIcon} />
                ) : (
                  <Icon src={ShipIcon} />
                )}

                <S.Title>Fluxo de {sale.modalName}:</S.Title>
              </div>

              <S.DataLine>
                <S.DataLineItem>
                  <span>Tipo de compensação</span>
                  <small>{sale?.compensationTypeName || '-'}</small>
                </S.DataLineItem>
                <S.DataLineItem>
                  <span>Proporcionalidade</span>
                  <small>{sale?.proportionality || '-'}</small>
                </S.DataLineItem>
              </S.DataLine>

              {sale?.compensationTypeId === CompensationTypes.ALL_THE_FLOWS && (
                <S.DataLine>
                  <S.DataLineItem>
                    <span>Fluxos que serão compensados:</span>
                    {sale?.salesCommitmentFlows?.map((flow) => (
                      <small
                        key={`comp-${sale.modalId}-${flow.originName}-${flow.destinyName}-line`}
                        data-testid={`compensation-all-flows-test-${sale.modalId}`}
                      >
                        {sale.modalName} - {flow.originName} ➝{' '}
                        {flow.destinyName} - Cliente: {flow.customer}
                      </small>
                    ))}
                  </S.DataLineItem>
                </S.DataLine>
              )}

              {sale?.compensationTypeId === CompensationTypes.BY_A_FLOW && (
                <S.DataLine>
                  <S.DataLineItem>
                    <span>Fluxo que será compensado:</span>
                    {sale?.salesCommitmentFlows
                      ?.filter((saleCommitment) => saleCommitment.isCompensated)
                      ?.map((flow) => (
                        <small
                          key={`comp-${sale.modalId}-${flow.originName}-${flow.destinyName}-line`}
                          data-testid={`compensation-by-flows-test-${sale.modalId}`}
                        >
                          {sale.modalName} - {flow.originName} ➝{' '}
                          {flow.destinyName} - Cliente: {flow.customer}
                        </small>
                      ))}
                  </S.DataLineItem>
                </S.DataLine>
              )}

              {sale?.compensationTypeId === CompensationTypes.BY_A_FLOW && (
                <S.DataLine>
                  <S.DataLineItem>
                    <span>Fluxos que compensarão o fluxo selecionado:</span>

                    {sale?.salesCommitmentFlows
                      ?.filter(
                        (saleCommitment) =>
                          saleCommitment.isCompensated === false,
                      )
                      ?.map((flow) => (
                        <S.FlowCard
                          key={`comp-${sale.modalId}-${flow.originName}-${flow.destinyName}-flow`}
                          data-testid={`compensation-by-flows-card-test-${sale.modalId}`}
                        >
                          <S.FlowCardItem>
                            <span>Origem e destino:</span>
                            <small>{flow.originName}</small>
                            <Icon src={ArrowRightIcon} />
                            <small>{flow.destinyName}</small>
                          </S.FlowCardItem>

                          <S.FlowCardItem>
                            <span>Cliente:</span>
                            <small>{flow.customer}</small>
                          </S.FlowCardItem>

                          <S.FlowCardItem>
                            <span>Produto:</span>
                            <small>{flow.product}</small>
                          </S.FlowCardItem>
                        </S.FlowCard>
                      ))}
                  </S.DataLineItem>
                </S.DataLine>
              )}

              {sale?.compensationTypeId === CompensationTypes.BY_FLOW_GROUP && (
                <S.DataLine>
                  <S.DataLineItem>
                    <span>Fluxos que compensarão o fluxo selecionado:</span>

                    {Object.entries(
                      groupedByCompensationGroup(sale?.salesCommitmentFlows),
                    )?.map(([groupKey, groupData]) => (
                      <S.FlowCardGroup
                        key={`comp-${sale.modalId}-group${groupKey}`}
                        data-testid={`compensation-by-group-test-${sale.modalId}`}
                      >
                        <S.FlowCardGroupHeader>
                          <h2>Grupo {groupKey}</h2>
                        </S.FlowCardGroupHeader>

                        <S.FlowCardGroupContent>
                          <S.FlowInfo>
                            <Icon src={InfoIcon} />

                            <span>
                              A compensação dos fluxos e vendas compensam entre
                              si.
                            </span>
                          </S.FlowInfo>

                          <S.FlowCardGroupList>
                            {groupData?.map((item) => (
                              <S.FlowTag
                                key={`comp-${sale.modalId}-group${groupKey}-${item.originName}-${item.destinyName}-flow`}
                                data-testid={`compensation-by-group-tag-test-${sale.modalId}`}
                              >
                                {sale.modalName} - {item.originName} ➝{' '}
                                {item.destinyName} - Cliente: {item.customer}
                              </S.FlowTag>
                            ))}
                          </S.FlowCardGroupList>
                        </S.FlowCardGroupContent>
                      </S.FlowCardGroup>
                    ))}
                  </S.DataLineItem>
                </S.DataLine>
              )}
            </S.FlowByModal>
          );
        })}
      </S.GridFlow>
    </S.CompensationContainer>
  );
};

Compensation.propTypes = {
  salesCommitments: PropTypes.array.isRequired,
};

export default Compensation;
