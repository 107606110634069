import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Flex } from 'components/system/core';
import { Input } from 'components/system/form';
import { Column as Col, Row } from 'components/system/interface';

import Loading from 'components/core/Loading';
import ModalConfirm from 'components/core/ModalConfirm';

import { ActiveContractActions } from 'store/activeContracts/activeContracts.ducks';
import { NewItemsActions } from 'store/newItems/newItems.ducks';

import handleInputMask from 'lib/core/handleInputMask';
import { isPending } from 'lib/core/request';
import * as utility from 'lib/core/utility';

import * as S from './styles';

const GrainFrom = () => {
  const dispatch = useDispatch();

  const { activeContractList, activeContractRequests } = useSelector(
    ({ activeContracts }) => activeContracts,
  );
  const { newItemsRequests, location, itemTariff, isOpen } = useSelector(
    ({ newItems }) => newItems,
  );

  const { handleSubmit, errors, getValues, control, watch, setValue } = useForm(
    {
      defaultValues: {
        contractId: '',
        codeItem: '',
        originDestiny: '',
        product: '',
        description: '',
        fareValues: '',
      },
    },
  );

  useEffect(() => {
    dispatch(ActiveContractActions.activeContract());
  }, [dispatch]);

  const contractId = watch('contractId');
  const description = watch('description');
  const originDestiny = watch('originDestiny');

  useEffect(() => {
    if (contractId) {
      dispatch(NewItemsActions.listOriginDestinyRequest(contractId));

      setValue('originDestiny', '');
      setValue('description', '');
    }
  }, [contractId, dispatch, setValue]);

  const grainContracts = activeContractList?.filter(
    (contract) => contract.segment.name === 'GRÃOS',
  );

  const contractOptions = grainContracts?.map((contract) => ({
    label: contract.code,
    value: contract.id,
  }));

  const originDestinyOptions = location?.originDestiny?.map((od) => ({
    label: `${od.originCode} -> ${od.destinyCode}`,
    value: `${od.originName}/${od.destinyName}`,
  }));

  const descriptionOptions = location?.microregionGrouping?.map((micro) => ({
    label: micro.groupMicroregionName,
    value: micro.groupMicroregionId,
  }));

  useEffect(() => {
    if (contractId && originDestiny) {
      const payload = {
        ContractId: contractId,
        GroupedMigroregionId: description || null,
        OriginName: originDestiny.split('/')[0],
        DestinyName: originDestiny.split('/')[1],
      };

      dispatch(NewItemsActions.getTariffRequest(payload));
    }
  }, [contractId, description, originDestiny, dispatch, getValues]);

  const loading =
    isPending(activeContractRequests.ACTIVE_CONTRACT) ||
    isPending(newItemsRequests.CREATE_ITEM_REQUEST) ||
    isPending(newItemsRequests.CREATE_GRAIN_ITEM_REQUEST);

  const onSubmit = (data) => {
    const payload = {
      contractId: data.contractId,
      productName: data.product,
      codeItem: data.codeItem,
      originName: data.originDestiny.split('/')[0],
      destinyName: data.originDestiny.split('/')[1],
      groupedMigroregionId: data?.description || null,
    };

    dispatch(NewItemsActions.createGrainItemRequest(payload));
    dispatch(
      NewItemsActions.setModalOpen({
        modalOpen: false,
      }),
    );
  };

  const modalConfirm = (
    <ModalConfirm
      closeModal={() =>
        dispatch(
          NewItemsActions.setModalOpen({
            modalOpen: false,
          }),
        )
      }
      confirmAction={handleSubmit(onSubmit)}
      title={`Deseja vincular o item ferroviário ${getValues(
        'codeItem',
      )} ao contrato?`}
      message="Ao vincular, o item estará presente na listagem de itens ferroviários do contrato com possibilidade de aprovação e reprovação de preços futuros"
    />
  );

  return (
    <form
      data-testid="create-new-grain-form-test"
      onSubmit={handleSubmit(onSubmit)}
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          <S.ExpandedAccordion title="Novos Itens" open>
            <Row>
              <Col desktop={2}>
                <Controller
                  name="contractId"
                  control={control}
                  rules={{ required: true }}
                  render={({ ...field }) => (
                    <Input
                      {...field}
                      type="select"
                      options={contractOptions}
                      label="Contrato*"
                      placeholder="Selecione"
                      status={errors.contractId && 'error'}
                      statusMessage={errors.contractId && 'Campo requerido'}
                      required
                    />
                  )}
                />
              </Col>

              <Col desktop={2}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="codeItem"
                  render={({ ...field }) => (
                    <Input
                      {...field}
                      type="text"
                      placeholder="Número item"
                      label="Número item ferroviário*"
                      onInput={(event) => handleInputMask(event, 'digits')}
                      status={errors.codeItem && 'error'}
                      statusMessage={errors.codeItem && 'Campo requerido'}
                      required
                    />
                  )}
                />
              </Col>

              <Col desktop={2}>
                <Controller
                  name="originDestiny"
                  control={control}
                  rules={{ required: true }}
                  render={({ ...field }) => (
                    <Input
                      {...field}
                      type="select"
                      options={originDestinyOptions}
                      label="Origem e Destino*"
                      placeholder="Selecione"
                      status={errors.originDestiny && 'error'}
                      statusMessage={errors.originDestiny && 'Campo requerido'}
                      required
                    />
                  )}
                />
              </Col>

              <Col desktop={2}>
                <Controller
                  name="description"
                  control={control}
                  rules={{ required: descriptionOptions?.length !== 0 }}
                  render={({ ...field }) => (
                    <Input
                      {...field}
                      type="select"
                      options={descriptionOptions}
                      label="Descrição"
                      placeholder="Selecione"
                      status={errors.description && 'error'}
                      statusMessage={errors.description && 'Campo requerido'}
                      required={descriptionOptions?.length !== 0}
                      disabled={descriptionOptions?.length === 0}
                    />
                  )}
                />
              </Col>
            </Row>

            <Row>
              <Col desktop={2}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="product"
                  render={({ ...field }) => (
                    <Input
                      {...field}
                      type="text"
                      placeholder="Produto"
                      label="Produto*"
                      status={errors.product && 'error'}
                      statusMessage={errors.product && 'Campo requerido'}
                      required
                    />
                  )}
                />
              </Col>
            </Row>
          </S.ExpandedAccordion>

          <S.ExpandedAccordion title="Tarifa contratada" open>
            {itemTariff.length > 0 && (
              <S.TariffTable>
                {itemTariff.map((item) => {
                  const tariffValue = utility.formatMoney(item.contractValue);
                  const tariffLabel = format(
                    new Date(item.applicationDate),
                    'MMMM',
                    { locale: ptBR },
                  );

                  return (
                    <S.TariffTableItem key={`tariff-${utility.simpleId()}`}>
                      <span>{tariffLabel}</span>
                      <div>{tariffValue}</div>
                    </S.TariffTableItem>
                  );
                })}
              </S.TariffTable>
            )}
          </S.ExpandedAccordion>

          <Flex justifyContent="flex-end" alignItems="center">
            <Button
              name="Vincular"
              type="button"
              action={(e) => {
                e.preventDefault();
                dispatch(
                  NewItemsActions.setModalOpen({
                    modalOpen: true,
                  }),
                );
              }}
            />
          </Flex>
        </>
      )}

      {isOpen && modalConfirm}
    </form>
  );
};

export default GrainFrom;
