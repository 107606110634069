import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import FolderIcon from 'assets/system/svgs/folder.svg';
import { Icon } from 'components/system/core';

import * as S from './InputFile.styles';

const InputFile = ({
  placeholder,
  onChange,
  value,
  disabled,
  name,
  accept,
  required,
  width,
}) => {
  const [fileTitle, setFileTitle] = useState('');
  useEffect(() => {
    if (value?.name) {
      setFileTitle(value.name);
    } else {
      setFileTitle('');
    }
  }, [value]);

  const handleOnchange = (e) => {
    onChange(e);
  };
  return (
    <S.Field width={width}>
      <input
        id={`vli-${name.replace(' ', '-').toLowerCase()}`}
        type="file"
        accept={accept}
        name={name}
        required={required}
        disabled={disabled}
        onChange={handleOnchange}
      />

      <label
        data-testid="input-file-label"
        htmlFor={`vli-${name.replace(' ', '-').toLowerCase()}`}
      >
        <span>{fileTitle || <span>{placeholder}</span>}</span>
        <Icon src={FolderIcon} />
      </label>
    </S.Field>
  );
};
InputFile.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  width: PropTypes.string,
};

InputFile.defaultProps = {
  name: '',
  value: '',
  placeholder: 'Selecione o arquivo',
  required: false,
  accept: '',
  width: '200px',
  disabled: false,
  onChange: () => {},
};

export default InputFile;
