import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ReportsIcon from 'assets/system/svgs/reports.svg';

import { Icon } from 'components/system/core';
import Tokens from 'components/system/tokens';
import { Text } from 'components/system/typography';

import { RailwayItemsHistoryActions } from 'store/railwayItemsHistory/railwayItemsHistory.ducks';

import Chart from 'components/core/Chart';
import Headlight from 'components/core/headlight';
import Modal from 'components/core/modal';

import { getGraphValues, getTableValues } from 'lib/contexts/contracts';
import * as utility from 'lib/core/utility';

import Table from './Table';
import TooltipTable from './TooltipTable';

import * as S from './ModalTariff.styles';

const list = {
  names: ['Contratado', 'Calculado'],
};

const ModalTariff = () => {
  const dispatch = useDispatch();

  const { modalData: item, modalOpen } = useSelector(
    ({ railwayItemsHistory }) => railwayItemsHistory,
  );

  const closeModal = () => {
    dispatch(RailwayItemsHistoryActions.closeModalCurved());
  };

  const graphData = getGraphValues(item);
  const { calculated, predicted, retroactive } = getTableValues(item);

  return (
    <Modal
      open={modalOpen}
      className="modal"
      width="1100px"
      title="Tarifa em curva"
      onClose={closeModal}
    >
      <S.Container>
        <S.LeftCol>
          <S.Content>
            <S.CardHeadlight>
              <Text size="small" color={Tokens.colors.gray400}>
                Farol
              </Text>
              <S.ItemHeadLight>
                <Headlight
                  marginLeft="9px"
                  width="10px"
                  height="10px"
                  color={item.headlight}
                />
              </S.ItemHeadLight>
            </S.CardHeadlight>
            <S.CardContent>
              <Text size="small" color={Tokens.colors.gray400}>
                Validade Preço Início
              </Text>
              <Text
                size="medium"
                style={{
                  fontWeight: '400',
                }}
              >
                {utility.formatDate(item.startDate)}
              </Text>
            </S.CardContent>
            <S.CardContent>
              <Text size="small" color={Tokens.colors.gray400}>
                Validade Preço Final
              </Text>
              <Text
                size="medium"
                style={{
                  fontWeight: '400',
                }}
              >
                {utility.formatDate(item.endDate)}
              </Text>
            </S.CardContent>
            <S.CardContent>
              <Text size="small" color={Tokens.colors.gray400}>
                Data da Aplicação
              </Text>
              <Text
                size="medium"
                style={{
                  fontWeight: '400',
                }}
              >
                {utility.formatDate(item.applicationDate)}
              </Text>
            </S.CardContent>
            <S.CardContent>
              <Text size="small" color={Tokens.colors.gray400}>
                Data Início para Cálculo
              </Text>
              {item.priceNegotiationDate ? (
                <TooltipTable
                  content={
                    <Table data={retroactive} columns={['Mês', 'Calculado']} />
                  }
                >
                  <Icon src={ReportsIcon} />
                  <Text
                    size="medium"
                    style={{
                      fontWeight: '400',
                    }}
                  >
                    {utility.formatDate(item.priceNegotiationDate)}
                  </Text>
                </TooltipTable>
              ) : (
                <Text
                  size="medium"
                  style={{
                    fontWeight: '400',
                  }}
                >
                  -
                </Text>
              )}
            </S.CardContent>
          </S.Content>
          <S.Content>
            <S.CardHeadlight />
            <S.CardContent>
              <Text size="small" color={Tokens.colors.gray400}>
                Contratado
              </Text>
              <Text
                size="medium"
                style={{
                  fontWeight: '400',
                }}
              >
                {utility.formatMoney(item.contractValue)}
              </Text>
            </S.CardContent>
            <S.CardContent>
              <Text size="small" color={Tokens.colors.gray400}>
                Calculado
              </Text>
              <Text
                size="medium"
                style={{
                  fontWeight: '400',
                }}
              >
                {utility.formatMoney(item.calculatedValue)}
              </Text>
            </S.CardContent>
            <S.CardContent>
              <Text size="small" color={Tokens.colors.gray400}>
                Flink
              </Text>
              <Text
                size="medium"
                style={{
                  fontWeight: '400',
                }}
              >
                {utility.formatMoney(item.executedValue)}
              </Text>
            </S.CardContent>
            <S.CardContent>
              <Text size="small" color={Tokens.colors.gray400}>
                Status Flink
              </Text>
              <Text
                size="medium"
                style={{
                  fontWeight: '400',
                }}
              >
                {item.statusText}
              </Text>
            </S.CardContent>
          </S.Content>
          <S.ContainerGraph>
            <S.Content>
              <Chart
                data={graphData}
                labels={list.names}
                tickFormatter={(tickValue) =>
                  `${utility.formatMoneyGraph(tickValue)}`
                }
                formatter={(value) => `${utility.formatMoney(value)}`}
                fontSize={10}
                height={400}
              />
            </S.Content>
          </S.ContainerGraph>
        </S.LeftCol>
        <S.RightCol>
          <Table
            data={calculated}
            columns={['Mês', 'Contratado', 'Calculado']}
          />
          <Table data={predicted} columns={['Mês', 'Contratado', 'Previsto']} />
        </S.RightCol>
      </S.Container>
    </Modal>
  );
};

export default ModalTariff;
