import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Button, Flex } from 'components/system/core';

import Loading from 'components/core/Loading';

import { ActiveContractActions } from 'store/activeContracts/activeContracts.ducks';
import { LocalActions } from 'store/local/local.ducks';
import { NewItemsActions } from 'store/newItems/newItems.ducks';
import { ServicesActions } from 'store/services/services.ducks';

import {
  formDefaultValues,
  getNewItemDataSend,
  newItemsFormSchema,
} from 'lib/contexts/newItems';
import { isPending } from 'lib/core/request';

import ConfirmModal from './ConfirmModal';
import ContractedTariffSection from './sections/ContractedTariff';
import NewItemsSection from './sections/NewItems';
import ServicesSection from './sections/Services';

const Form = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const { activeContractRequests } = useSelector(
    ({ activeContracts }) => activeContracts,
  );
  const { localRequests } = useSelector(({ local }) => local);
  const { newItemsRequests, isOpen, draftNewItem } = useSelector(
    ({ newItems }) => newItems,
  );
  const { servicesRequests } = useSelector((state) => state.services);

  const [isCurvedFare, setIsCurvedFare] = useState(false);
  const [segmentId, setSegmentId] = useState('');
  const [inCurveDataFromTable, setInCurveDataFromTable] = useState([]);

  const formMethods = useForm({
    defaultValues: formDefaultValues,
    resolver: yupResolver(newItemsFormSchema),
  });

  const { handleSubmit, setValue } = formMethods;

  useEffect(() => {
    dispatch(ActiveContractActions.activeContract());
    dispatch(LocalActions.localFilteredList('withDeleted=false'));
    dispatch(ServicesActions.listServices());

    if (id) {
      dispatch(NewItemsActions.getNewItemRequest(id));
    }
  }, [dispatch, id]);

  const loading =
    isPending(localRequests.LOCAL_FILTERED_LIST) ||
    isPending(activeContractRequests.ACTIVE_CONTRACT) ||
    isPending(newItemsRequests.CREATE_ITEM_REQUEST) ||
    isPending(servicesRequests.LIST_SERVICES);

  const handleSaveDraft = (formData, status) => {
    const formDataSend = getNewItemDataSend(
      formData,
      segmentId,
      status,
      inCurveDataFromTable,
    );

    if (id) {
      dispatch(
        NewItemsActions.updateItemRequest({
          id,
          ...formDataSend,
        }),
      );
    } else {
      dispatch(NewItemsActions.createItemRequest(formDataSend));
    }

    dispatch(
      NewItemsActions.setModalOpen({
        modalOpen: false,
      }),
    );
  };

  const handleUpdateFormWithDraftData = useCallback(() => {
    setValue('fareType', draftNewItem?.fareType);

    if (draftNewItem.fareType === 0) {
      setValue(
        'fareValues',
        draftNewItem.fareValues
          ? String(draftNewItem.fareValues[0].value).replace('.', ',')
          : '',
      );
    }

    setIsCurvedFare(draftNewItem?.fareType !== 0);
    setSegmentId(draftNewItem?.segment.id);
  }, [draftNewItem, setValue, setIsCurvedFare, setSegmentId]);

  useEffect(() => {
    if (draftNewItem) {
      handleUpdateFormWithDraftData();
    }
  }, [draftNewItem, handleUpdateFormWithDraftData]);

  return (
    <FormProvider {...formMethods}>
      {isOpen && <ConfirmModal handleSaveDraft={handleSaveDraft} />}

      {loading ? (
        <Loading />
      ) : (
        <form data-testid="create-new-default-form-test">
          <NewItemsSection
            setIsCurvedFare={setIsCurvedFare}
            setSegmentId={setSegmentId}
          />

          <ContractedTariffSection
            isCurvedFare={isCurvedFare}
            setInCurveDataFromTable={setInCurveDataFromTable}
          />

          <ServicesSection isCurvedFare={isCurvedFare} />

          <Flex justifyContent="flex-end" alignItems="center">
            <Button
              name="Salvar rascunho"
              variant="tertiary"
              style={{ marginRight: '5px' }}
              action={handleSubmit((e) => handleSaveDraft(e, 0))}
            />
            <Button
              name="Vincular"
              type="submit"
              action={(e) => {
                e.preventDefault();
                dispatch(
                  NewItemsActions.setModalOpen({
                    modalOpen: true,
                  }),
                );
              }}
            />
          </Flex>
        </form>
      )}
    </FormProvider>
  );
};

export default Form;
