import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import IcOption from 'assets/icons/table/options.svg';

import { Button, Popover } from 'components/system/core';
import Tokens from 'components/system/tokens';
import { Text } from 'components/system/typography';

import { SegmentActions } from 'store/segment/segment.ducks';

import { ACTIONS, ROLES, canExecuteAction } from 'lib/contexts/permissions';
import { GET } from 'lib/core/localStorage';

import { history } from 'constants/routes';
import * as ROUTES from 'constants/urls';

import * as S from 'components/core/Table/CellWithActions/CellWithActions.styles';

const CellWithActions = ({ row }) => {
  const dispatch = useDispatch();
  const segment = row.original;

  const { roles } = GET('roles');

  const canUpdateSegment = canExecuteAction(
    ROLES.SEGMENT,
    ACTIONS.UPDATE,
    roles,
  );
  const canChangeSegmentStatus = canExecuteAction(
    ROLES.SEGMENT,
    ACTIONS.INACTIVE,
    roles,
  );

  const handleEditSegment = () => {
    history.push(ROUTES.EDIT_SEGMENT(segment.id));
  };

  const handleChangeSegmentStatus = () => {
    const callback = () => dispatch(SegmentActions.listSegment());

    dispatch(SegmentActions.updateSegmentStatus(segment.id, callback));
  };

  const Options = (
    <S.OptionsContainer>
      {canUpdateSegment && (
        <S.OptionItem data-testid="test-edit" onClick={handleEditSegment}>
          <Text size="medium" style={{ fontWeight: '400' }}>
            Editar segmento
          </Text>
        </S.OptionItem>
      )}

      {canChangeSegmentStatus && (
        <S.OptionItem
          data-testid="test-decline"
          color={Tokens.colors.gray300}
          onClick={handleChangeSegmentStatus}
        >
          <Text
            size="medium"
            style={{ fontWeight: '400' }}
            color={Tokens.colors.grayBlack}
          >
            {segment?.active ? 'Desativar segmento' : 'Ativar segmento'}
          </Text>
        </S.OptionItem>
      )}
    </S.OptionsContainer>
  );

  return (
    <S.ContentCell data-testid="cell-with-action">
      <Popover content={Options} position="left center">
        <Button
          data-testid="test-button"
          icon={IcOption}
          name=""
          variant="tertiary"
          isDisabled={!canUpdateSegment && !canChangeSegmentStatus}
        />
      </Popover>
    </S.ContentCell>
  );
};

CellWithActions.propTypes = {
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CellWithActions;
