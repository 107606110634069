import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Flex } from 'components/system/core';

import FilterDS from 'components/core/FilterDs';

import { DataFilter, getFilterItemParams } from 'lib/contexts/requestsList';

import { RequestsListActions } from 'store/requestsList/requestsList.ducks';

import * as S from './FilterRequestsStyles';

const FilterRequests = ({ ...props }) => {
  const dispatch = useDispatch();

  const { filter } = useSelector(({ requestsList }) => requestsList);

  const { clientCount, totalCount } = useSelector(
    ({ requestsList }) => requestsList?.requestsListItems,
  );
  const { proposalsCustomerList } = useSelector(({ customer }) => customer);
  const { formalizationsUserList } = useSelector(({ user }) => user);
  const { attendantList } = useSelector(({ attendant }) => attendant);

  const [dataFilter, setDataFilter] = useState(filter.filterData);

  const filterAction = (filterData) => {
    const params = getFilterItemParams(filterData);
    dispatch(RequestsListActions.requestsListRequest(params, filterData));
  };

  const onConfirm = (filterData) => {
    const newFilter = {
      ...filter,
      ...filterData,
    };
    setDataFilter(newFilter);
    filterAction(newFilter);
  };

  const onClean = () => {
    setDataFilter({});
    filterAction({});
  };

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <S.TextHeader>
        Total de <strong data-testid="total-count-test">{totalCount}</strong>{' '}
        formalizações de{' '}
        <strong data-testid="client-count-test">{clientCount}</strong> clientes
      </S.TextHeader>

      <S.WrapperFilter {...props}>
        <FilterDS
          defaultSelectedItems={dataFilter}
          listItems={DataFilter(
            proposalsCustomerList,
            formalizationsUserList,
            attendantList,
          )}
          onConfirm={onConfirm}
          onClean={onClean}
        />
      </S.WrapperFilter>
    </Flex>
  );
};

export default FilterRequests;
