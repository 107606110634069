import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, EmptyState } from 'components/system/core';

import Loading from 'components/core/Loading';
import Table from 'components/core/Table';
import TableContainer from 'components/core/TableContainer';

import { getFilterParams } from 'lib/contexts/notification';
import { isFinished, isPending } from 'lib/core/request';

import { EmailNotificationClientActions } from 'store/emailNotificationsClient/emailNotificationClient.ducks';

import usePagination from 'hooks/usePagination';

import Columns from './Columns';

const ClientList = () => {
  const dispatch = useDispatch();
  const {
    emailNotificationClientList,
    filter,
    emailNotificationClientRequests,
  } = useSelector(({ emailNotificationClient }) => emailNotificationClient);
  const { paginationSize, setValuePage } = usePagination();

  const onPageChange = (PageIndex) => {
    const { pageSize } = emailNotificationClientList;
    const params = getFilterParams({
      pageIndex: PageIndex,
      pageSize,
      ...filter,
    });
    dispatch(
      EmailNotificationClientActions.listEmailNotificationClient(params),
    );
  };
  const onPerPageChange = (PageSize) => {
    const { pageIndex } = emailNotificationClientList;
    const params = getFilterParams({
      pageIndex,
      pageSize: PageSize,
      ...filter,
    });
    dispatch(
      EmailNotificationClientActions.listEmailNotificationClient(params),
    );
    setValuePage(PageSize);
  };

  useEffect(() => {
    dispatch(EmailNotificationClientActions.listEmailNotificationClient(''));
  }, [dispatch]);

  return (
    <TableContainer data-testid="contractcontainer-test">
      {isPending(
        emailNotificationClientRequests.LIST_EMAIL_NOTIFICATION_CLIENT,
      ) && <Loading />}
      {emailNotificationClientList.items?.length ? (
        <Table
          data-testid="contractlist-test"
          width="100%"
          columns={Columns}
          data={emailNotificationClientList.items}
          onPageChange={onPageChange}
          onPerPageChange={onPerPageChange}
          footer
          customList={paginationSize}
          pageIndex={emailNotificationClientList.currentPage}
          pageCount={emailNotificationClientList.totalPages}
          totalItems={emailNotificationClientList.totalCount}
        />
      ) : (
        isFinished(
          emailNotificationClientRequests.LIST_EMAIL_NOTIFICATION_CLIENT,
        ) &&
        emailNotificationClientList.items?.length === 0 && (
          <Card>
            <EmptyState condensed={false} title="Nenhum contrato encontrado." />
          </Card>
        )
      )}
    </TableContainer>
  );
};

export default ClientList;
