import PropTypes from 'prop-types';
import React from 'react';

import { Button } from 'components/system/core';

import { GET } from 'lib/core/localStorage';

const FormalizationAction = ({
  role: roleId,
  key,
  shouldCheckRole,
  ...props
}) => {
  const { roles } = GET('roles');

  const hasRole = roles?.find((role) => role.id === roleId);

  if (!hasRole && shouldCheckRole) return <></>;

  return <Button {...props} data-testid={key} />;
};

FormalizationAction.propTypes = {
  variant: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  role: PropTypes.number.isRequired,
  shouldCheckRole: PropTypes.bool,
};

FormalizationAction.defaultProps = {
  onClick: null,
  shouldCheckRole: true,
};

export default FormalizationAction;
