import css from '@styled-system/css';
import styled from 'styled-components';

import Tokens from 'components/system/tokens';

export const CardHeader = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;
export const BoxContainer = styled.div`
  overflow: auto;
`;
export const ContainerRadio = styled.div`
  label:first-child {
    margin-right: 10px;
  }
  label {
    font-weight: 400 !important;
  }
`;
export const GroupTitle = styled('span')(
  css({
    display: 'flex',
    userSelect: 'none',
    alignItems: 'center',
    font: Tokens.fonts.textRegularMedium,
    fontWeight: '400 !important;',
    color: '#666',
    height: '60px',
  }),
);

export const Group = styled('ul')(
  css({
    position: 'relative',
    userSelect: 'none',
    listStyle: 'none inside none',
    margin: `0 ${Tokens.spacing.sm} ${Tokens.spacing.sm} ${Tokens.spacing.xss}`,
    padding: 0,
    display: 'flex',
    paddingRight: 2,
  }),
);
