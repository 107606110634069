import styled from 'styled-components';

import { Modal } from 'components/system/core';

export const ModalContainer = styled(Modal)`
  width: 100%;

  #divider {
    display: none;
    margin: 0;
  }
`;
