import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { Accordion, Button } from 'components/system/core';

import Loading from 'components/core/Loading';
import InputSelect from 'components/form/InputSelect';

import { ActiveContractActions } from 'store/activeContracts/activeContracts.ducks';
import { ContractUploadActions } from 'store/contractUpload/contractUpload.ducks';

import { isPending } from 'lib/core/request';

import DefaultSales from './DefaultSales';
import PriceOpeningSales from './PriceOpeningSales';

import * as S from './RegisterStyles';

const validationSchema = yup.object().shape({
  formalizationId: yup.number().required('Contrato é um campo requerido'),
  parentId: yup.string().nullable(),
  items: yup.array().of(
    yup.object().shape({
      saleId: yup.number().required(),
      itemCode: yup.string().required('Campo requerido'),
      priceId: yup.number().nullable(),
      microregionId: yup.number().nullable(),
    }),
  ),
});

const TYPE_ADDITIVE_ID = 2;

const Register = () => {
  const dispatch = useDispatch();

  const methods = useForm({
    defaultValues: {
      formalizationId: 0,
      items: [],
    },
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, control, watch, reset } = methods;

  const {
    availableContracts,
    contractUploadRequests,
    selectedContract,
  } = useSelector(({ contractUpload }) => contractUpload);
  const { activeContractList, activeContractRequests } = useSelector(
    ({ activeContracts }) => activeContracts,
  );

  const [isAdditive, setIsAdditive] = useState(false);

  const formalizationId = watch('formalizationId');

  useEffect(() => {
    dispatch(ContractUploadActions.getAvailableContracts());
    dispatch(ActiveContractActions.activeContract('?additives=false'));
  }, [dispatch]);

  useEffect(() => {
    if (formalizationId && formalizationId !== 0) {
      const selected = availableContracts?.find(
        (contract) => contract.id === formalizationId,
      );

      if (selected?.instrumentType?.id === TYPE_ADDITIVE_ID) {
        setIsAdditive(true);
      } else {
        setIsAdditive(false);
      }

      if (selected?.code) {
        dispatch(
          ContractUploadActions.getAvailableContractDetail(selected.code),
        );
      }
    }
  }, [formalizationId, availableContracts, dispatch]);

  const onSubmit = (data) => {
    dispatch(ContractUploadActions.createContract(data));

    setIsAdditive(false);
    reset();
  };

  const availableContractsOptions = availableContracts?.map((contracts) => ({
    label: contracts.code,
    value: contracts.id,
  }));

  const referenceInstrumentOptions = activeContractList?.map(
    (activeContracts) => ({
      label: activeContracts.code,
      value: activeContracts.id,
    }),
  );

  const selectedFormalizationCustomer = availableContracts.find(
    (available) => available.id === formalizationId,
  )?.customer;

  const hasPriceOpening = selectedContract?.sales?.some(
    (sale) => sale.validityPrices && sale.validityPrices.length > 0,
  );

  const isGrainSegment = selectedContract?.segmentName === 'GRÃOS';

  const isLoading =
    isPending(contractUploadRequests.GET_AVAILABLE_CONTRACTS) ||
    isPending(activeContractRequests.ACTIVE_CONTRACT) ||
    isPending(contractUploadRequests.CREATE_CONTRACT);

  return (
    <Accordion
      title="Via entrada semiautomatica"
      key="contract-by-register"
      width="100%"
      open
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {isLoading ? (
            <Loading />
          ) : (
            <S.FormContainer>
              <S.FormLine>
                <Controller
                  control={control}
                  name="formalizationId"
                  render={({ ...field }) => (
                    <InputSelect
                      {...field}
                      width="250px"
                      label="Contrato"
                      placeholder="Selecione..."
                      options={availableContractsOptions}
                    />
                  )}
                />

                {isAdditive && (
                  <Controller
                    control={control}
                    name="parentId"
                    render={({ ...field }) => (
                      <InputSelect
                        {...field}
                        width="250px"
                        label="Instrumento referência"
                        placeholder="Selecione..."
                        options={referenceInstrumentOptions}
                        autocomplete
                      />
                    )}
                  />
                )}
              </S.FormLine>

              <S.FormLine>
                {selectedFormalizationCustomer && (
                  <S.FormSpanItem>
                    Cliente: {selectedFormalizationCustomer}
                  </S.FormSpanItem>
                )}
              </S.FormLine>

              {selectedContract && !hasPriceOpening && <DefaultSales />}
              {selectedContract && hasPriceOpening && isGrainSegment && (
                <PriceOpeningSales />
              )}

              <S.ButtonContainer>
                <Button
                  name="Salvar alterações"
                  type="submit"
                  isDisabled={formalizationId === 0}
                />
              </S.ButtonContainer>
            </S.FormContainer>
          )}
        </form>
      </FormProvider>
    </Accordion>
  );
};

export default Register;
