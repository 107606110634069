import PropTypes from 'prop-types';
import React from 'react';

import { PanelStyle } from './styles';

const Panel = ({ children, ...attrs }) => (
  <PanelStyle data-testid="ds-panel" {...attrs}>
    {children}
  </PanelStyle>
);

Panel.propTypes = {
  children: PropTypes.node,
};

Panel.defaultProps = {
  children: '',
};

export default Panel;
