import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';
import { modeEditRow, resetEditRow } from 'lib/contexts/contracts';
import { createActions, createReducer } from 'reduxsauce';

export const {
  Types: ContractTypes,
  Creators: ContractActions,
} = createActions({
  listContract: ['params', 'rawFilter'],
  listContractSuccess: ['payload'],
  setEditRow: ['payload'],
  exportContract: ['params'],
  exportContractSuccess: ['payload'],
  updateContract: ['payload', 'callback'],
  updateContractSuccess: [],
  contractRequestFailure: ['error'],
  setChangeContractRow: ['payload'],
  setEditModalOpen: ['payload'],
  resetContractRows: [],
  setReportModalOpen: ['payload'],
  exportDieselReport: ['params'],
  exportDieselReportSuccess: [],
  deleteContract: ['params', 'callback'],
  deleteContractSuccess: [],
  downloadSpreadsheet: ['params'],
  downloadSpreadsheetSuccess: [],
  closeContract: ['payload', 'callback'],
  closeContractSuccess: [],
  setDeleteModalOpen: ['payload'],
  setJustifyModalOpen: ['payload'],
  listContractRecipientsByUserId: ['userId'],
  listContractRecipientsByUserIdSuccess: ['payload'],
  listContractRecipientsByUserIdFailure: ['error'],
  addServices: ['payload', 'callback'],
  addServicesSuccess: [],
  addServicesFailure: ['error'],
  setDefaultFlagModalOpen: ['payload'],
  updateDefaultFlag: ['payload', 'callback'],
  updateDefaultFlagSuccess: [],
  updateDefaultFlagFailure: ['error'],
});

const INITIAL_STATE = {
  data: [],
  totalCount: 0,
  filter: {
    viewScenarioData: 'CurrentPrice',
    filterData: {},
  },
  totalClient: 0,
  rowEdit: null,
  editMode: false,
  changeEdit: false,
  changeEditOpen: false,
  error: null,
  isDieselReportModalOpen: false,
  rowReportDownload: null,
  isDeleteModalOpen: false,
  isJustifyModalOpen: false,
  isDefaultFlagModalOpen: false,
  rowDeleteId: null,
  rowJustify: null,
  rowDefaultFlag: null,
  recipients: [],
  contractRequests: {
    [ContractTypes.LIST_CONTRACT]: REQUEST_NOT_STARTED,
  },
};

const listContract = (state, { rawFilter }) => ({
  ...state,
  ...INITIAL_STATE,
  filter: { ...rawFilter },
  contractRequests: {
    ...INITIAL_STATE.contractRequests,
    [ContractTypes.LIST_CONTRACT]: REQUEST_PENDING,
  },
});

const listContractSuccess = (state, { payload }) => ({
  ...state,
  data: payload,
  totalCount: payload.items.length,
  totalClient: payload.totalCountClient,
  contractRequests: {
    ...state.contractRequests,
    [ContractTypes.LIST_CONTRACT]: REQUEST_RESOLVED,
  },
});

const exportContract = (state) => ({
  ...state,
  contractRequests: {
    ...state.contractRequests,
    [ContractTypes.EXPORT_CONTRACT]: REQUEST_PENDING,
  },
});

const exportContractSuccess = (state) => ({
  ...state,
  contractRequests: {
    ...state.contractRequests,
    [ContractTypes.EXPORT_CONTRACT]: REQUEST_RESOLVED,
  },
});

const contractRequestFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  contractRequests: {
    ...state.contractRequests,
    [ContractTypes.LIST_CONTRACT]: REQUEST_REJECTED,
    [ContractTypes.CLOSE_CONTRACT]: REQUEST_REJECTED,
    [ContractTypes.DELETE_CONTRACT]: REQUEST_REJECTED,
  },
});

const setEditRow = (state, { payload }) => ({
  ...state,
  data: {
    ...state.data,
    items: modeEditRow(state.data.items, payload.row, payload.editMode),
  },
  rowEdit: payload.row,
  editMode: payload.editMode,
});

const setChangeContractRow = (state, { payload }) => ({
  ...state,
  changeEdit: true,
  rowEdit: { ...state.rowEdit, [payload.field]: payload.value },
});

const setEditModalOpen = (state, { payload }) => ({
  ...state,
  changeEditOpen: payload.modalOpen,
});

const resetContractRows = (state) => ({
  ...state,
  data: {
    ...state.data,
    items: resetEditRow(state.data.items),
  },
  changeEdit: false,
  rowEdit: null,
  editMode: false,
});

const updateContract = (state) => ({
  ...state,
  contractRequests: {
    ...state.contractRequests,
    [ContractTypes.UPDATE_CONTRACT]: REQUEST_PENDING,
  },
});

const updateContractSuccess = (state) => ({
  ...state,
  contractRequests: {
    ...state.contractRequests,
    [ContractTypes.UPDATE_CONTRACT]: REQUEST_RESOLVED,
  },
});

const setReportModalOpen = (state, { payload }) => ({
  ...state,
  isDieselReportModalOpen: payload.modalOpen,
  rowReportDownload: payload.row,
});

const exportDieselReport = (state) => ({
  ...state,
  contractRequests: {
    ...state.contractRequests,
    [ContractTypes.EXPORT_DIESEL_REPORT]: REQUEST_PENDING,
  },
});

const exportDieselReportSuccess = (state) => ({
  ...state,
  contractRequests: {
    ...state.contractRequests,
    [ContractTypes.EXPORT_DIESEL_REPORT]: REQUEST_RESOLVED,
  },
});

const setDeleteModalOpen = (state, { payload }) => ({
  ...state,
  isDeleteModalOpen: payload.modalOpen,
  rowDelete: payload?.row,
});

const setJustifyModalOpen = (state, { payload }) => ({
  ...state,
  isJustifyModalOpen: payload.modalOpen,
  rowJustify: payload?.row,
});

const deleteContract = (state) => ({
  ...state,
  contractRequests: {
    ...state.contractRequests,
    [ContractTypes.DELETE_CONTRACT]: REQUEST_PENDING,
  },
});

const deleteContractSuccess = (state) => ({
  ...state,
  contractRequests: {
    ...state.contractRequests,
    [ContractTypes.DELETE_CONTRACT]: REQUEST_RESOLVED,
  },
});

const closeContract = (state) => ({
  ...state,
  contractRequests: {
    ...state.contractRequests,
    [ContractTypes.CLOSE_CONTRACT]: REQUEST_PENDING,
  },
});

const closeContractSuccess = (state) => ({
  ...state,
  contractRequests: {
    ...state.contractRequests,
    [ContractTypes.CLOSE_CONTRACT]: REQUEST_RESOLVED,
  },
});

const downloadSpreadsheet = (state) => ({
  ...state,
  contractRequests: {
    ...state.contractRequests,
    [ContractTypes.DOWNLOAD_SPREADSHEET]: REQUEST_PENDING,
  },
});

const downloadSpreadsheetSuccess = (state) => ({
  ...state,
  contractRequests: {
    ...state.contractRequests,
    [ContractTypes.DOWNLOAD_SPREADSHEET]: REQUEST_RESOLVED,
  },
});

const listContractRecipientsByUserId = (state) => ({
  ...state,
  contractRequests: {
    ...state.contractRequests,
    [ContractTypes.LIST_CONTRACT_RECIPIENTS_BY_USER_ID]: REQUEST_PENDING,
  },
});

const listContractRecipientsByUserIdSuccess = (state, { payload }) => ({
  ...state,
  recipients: payload,
  contractRequests: {
    ...state.contractRequests,
    [ContractTypes.LIST_CONTRACT_RECIPIENTS_BY_USER_ID]: REQUEST_RESOLVED,
  },
});

const listContractRecipientsByUserIdFailure = (
  state,
  { error: { response } },
) => ({
  ...state,
  error: response,
  contractRequests: {
    ...state.contractRequests,
    [ContractTypes.LIST_CONTRACT_RECIPIENTS_BY_USER_ID]: REQUEST_REJECTED,
  },
});

const addServices = (state) => ({
  ...state,
  contractRequests: {
    ...state.contractRequests,
    [ContractTypes.ADD_SERVICES]: REQUEST_PENDING,
  },
});

const addServicesSuccess = (state) => ({
  ...state,
  contractRequests: {
    ...state.contractRequests,
    [ContractTypes.ADD_SERVICES]: REQUEST_RESOLVED,
  },
});

const addServicesFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  contractRequests: {
    ...state.contractRequests,
    [ContractTypes.ADD_SERVICES]: REQUEST_REJECTED,
  },
});

const setDefaultFlagModalOpen = (state, { payload }) => ({
  ...state,
  isDefaultFlagModalOpen: payload.modalOpen,
  rowDefaultFlag: payload?.row,
});

const updateDefaultFlag = (state) => ({
  ...state,
  contractRequests: {
    ...state.contractRequests,
    [ContractTypes.UPDATE_DEFAULT_FLAG]: REQUEST_PENDING,
  },
});

const updateDefaultFlagSuccess = (state) => ({
  ...state,
  contractRequests: {
    ...state.contractRequests,
    [ContractTypes.UPDATE_DEFAULT_FLAG]: REQUEST_RESOLVED,
  },
});

const updateDefaultFlagFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  contractRequests: {
    ...state.contractRequests,
    [ContractTypes.UPDATE_DEFAULT_FLAG]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [ContractTypes.LIST_CONTRACT]: listContract,
  [ContractTypes.LIST_CONTRACT_SUCCESS]: listContractSuccess,
  [ContractTypes.EXPORT_CONTRACT]: exportContract,
  [ContractTypes.EXPORT_CONTRACT_SUCCESS]: exportContractSuccess,
  [ContractTypes.CONTRACT_REQUEST_FAILURE]: contractRequestFailure,
  [ContractTypes.SET_EDIT_ROW]: setEditRow,
  [ContractTypes.SET_CHANGE_CONTRACT_ROW]: setChangeContractRow,
  [ContractTypes.SET_EDIT_MODAL_OPEN]: setEditModalOpen,
  [ContractTypes.RESET_CONTRACT_ROWS]: resetContractRows,
  [ContractTypes.UPDATE_CONTRACT]: updateContract,
  [ContractTypes.UPDATE_CONTRACT_SUCCESS]: updateContractSuccess,
  [ContractTypes.SET_REPORT_MODAL_OPEN]: setReportModalOpen,
  [ContractTypes.EXPORT_DIESEL_REPORT]: exportDieselReport,
  [ContractTypes.EXPORT_DIESEL_REPORT_SUCCESS]: exportDieselReportSuccess,
  [ContractTypes.SET_DELETE_MODAL_OPEN]: setDeleteModalOpen,
  [ContractTypes.SET_JUSTIFY_MODAL_OPEN]: setJustifyModalOpen,
  [ContractTypes.DELETE_CONTRACT]: deleteContract,
  [ContractTypes.DELETE_CONTRACT_SUCCESS]: deleteContractSuccess,
  [ContractTypes.CLOSE_CONTRACT]: closeContract,
  [ContractTypes.CLOSE_CONTRACT_SUCCESS]: closeContractSuccess,
  [ContractTypes.DOWNLOAD_SPREADSHEET]: downloadSpreadsheet,
  [ContractTypes.DOWNLOAD_SPREADSHEET_SUCCESS]: downloadSpreadsheetSuccess,
  [ContractTypes.LIST_CONTRACT_RECIPIENTS_BY_USER_ID]: listContractRecipientsByUserId,
  [ContractTypes.LIST_CONTRACT_RECIPIENTS_BY_USER_ID_SUCCESS]: listContractRecipientsByUserIdSuccess,
  [ContractTypes.LIST_CONTRACT_RECIPIENTS_BY_USER_ID_FAILURE]: listContractRecipientsByUserIdFailure,
  [ContractTypes.ADD_SERVICES]: addServices,
  [ContractTypes.ADD_SERVICES_SUCCESS]: addServicesSuccess,
  [ContractTypes.ADD_SERVICES_FAILURE]: addServicesFailure,
  [ContractTypes.SET_DEFAULT_FLAG_MODAL_OPEN]: setDefaultFlagModalOpen,
  [ContractTypes.UPDATE_DEFAULT_FLAG]: updateDefaultFlag,
  [ContractTypes.UPDATE_DEFAULT_FLAG_SUCCESS]: updateDefaultFlagSuccess,
  [ContractTypes.UPDATE_DEFAULT_FLAG_FAILURE]: updateDefaultFlagFailure,
});
