import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ChevronDownIcon from 'assets/system/svgs/chevron/down.svg';

import Icon from '../icon';

import { AccordionStyle, AccordionTitle, BodyAccordion } from './styles';

const Accordion = ({ title, open, children, ...attrs }) => {
  const [isOpen, setIsOpen] = useState(open);

  return (
    <AccordionStyle id="accordion" data-testid="ds-accordion" {...attrs}>
      {title && (
        <AccordionTitle
          className=""
          variant={[isOpen && 'open']}
          onClick={() => setIsOpen(!isOpen)}
        >
          {title}
          <Icon className="toggle" src={ChevronDownIcon} viewBox="0 0 16 16" />
        </AccordionTitle>
      )}
      <BodyAccordion
        variant={[isOpen && 'open', !title && 'noTitle']}
        height={60}
      >
        {children}
      </BodyAccordion>
    </AccordionStyle>
  );
};

Accordion.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  open: PropTypes.bool,
};

Accordion.defaultProps = {
  title: '',
  children: '',
  open: false,
};

export default Accordion;
