import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { Button } from 'components/system/core';

import Modal from 'components/core/modal';

import { FormalizationRequestActions } from 'store/formalizationRequest/formalizationRequest.ducks';

const CancelModal = ({ onClose, formalizationId }) => {
  const dispatch = useDispatch();

  const handleCancelFormalization = () => {
    dispatch(FormalizationRequestActions.cancelFormalization(formalizationId));

    onClose();
  };

  const setModalActions = () => (
    <>
      <Button name="Desfazer" onClick={onClose} variant="secondary" />
      <Button name="Cancelar" onClick={handleCancelFormalization} />
    </>
  );

  return (
    <Modal
      width="100%"
      title="Cancelamento de solicitação"
      onClose={onClose}
      actions={setModalActions}
    >
      Tem certeza que deseja cancelar a solicitação de formalização?
    </Modal>
  );
};

CancelModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  formalizationId: PropTypes.number.isRequired,
};

export default CancelModal;
