import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import Tokens from 'components/system/tokens';

const customStyles = {
  control: (base) => ({
    ...base,
    height: 40,
    minHeight: 40,
  }),
  placeholder: (base) => ({
    ...base,
    color: Tokens.colors.gray500,
  }),
};
const Autocomplete = ({
  onChange,
  onInputChange,
  defaultOptions,
  placeholder,
}) => {
  const [options, setOptions] = useState(defaultOptions);
  useEffect(() => {
    setOptions(defaultOptions);
  }, [defaultOptions]);
  return (
    <div data-testid="autocomplete-testid">
      <Select
        isSearchable
        placeholder={placeholder}
        options={options}
        onInputChange={onInputChange}
        onChange={onChange}
        styles={customStyles}
        components={{ IndicatorSeparator: null }}
      />
    </div>
  );
};
Autocomplete.propTypes = {
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  defaultOptions: PropTypes.array,
  placeholder: PropTypes.string,
};
Autocomplete.defaultProps = {
  onChange: () => {},
  onInputChange: () => {},
  defaultOptions: [],
  placeholder: '',
};

export default Autocomplete;
