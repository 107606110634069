import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Avatar, Loading } from 'components/system/core';

import { GET } from 'lib/core/localStorage';
import { isPending } from 'lib/core/request';

import { CommentActions } from 'store/comments/comments.ducks';

import CommentItem from '../CommentItem';

import * as S from './CommentModalStyle';

const CommentModal = ({ formalizationId }) => {
  const dispatch = useDispatch();
  const { isModalOpen, comments: listComments, commentsRequest } = useSelector(
    ({ comments }) => comments,
  );

  const [comment, setComment] = useState('');
  const [charactersAmount, setCharactersAmount] = useState(0);

  const MAX_LENGTH = 3000;

  const { name } = GET('name');

  const handleInputComment = (event) => {
    setComment(event.target.value);
    setCharactersAmount(event.target.value.length);
  };

  const handleClose = () => {
    dispatch(CommentActions.setIsModalOpen({ isOpen: false }));
  };

  const handleCreateComment = () => {
    dispatch(
      CommentActions.commentCreate({
        formalizationId,
        commentDescription: comment,
      }),
    );

    setComment('');
  };

  useEffect(() => {
    dispatch(CommentActions.commentByFormalizationId(formalizationId));
  }, [formalizationId]);

  const isLoading =
    isPending(commentsRequest.COMMENT_CREATE) ||
    isPending(commentsRequest.COMMENT_BY_FORMALIZATION_ID);

  const reversedComments = useMemo(
    () => listComments?.reverse() ?? listComments,
    [listComments],
  );

  return (
    <S.CommentModal
      onClose={handleClose}
      isOpen={isModalOpen}
      title="Comentários"
      width="640px"
    >
      {reversedComments && (
        <S.Comments>
          {isLoading && (
            <div data-testid="comment-modal-loading">
              <Loading type="secondary" fitParent />
            </div>
          )}

          {reversedComments.map((commentItem) => (
            <CommentItem
              data={commentItem}
              onlineUser={name}
              key={commentItem.observationId}
            />
          ))}
        </S.Comments>
      )}

      <S.Footer>
        <S.Box>
          <Avatar userName={name} status="online" />
          <S.InputField>
            <textarea
              maxLength={MAX_LENGTH}
              placeholder="Escrever comentários"
              onChange={handleInputComment}
              value={comment}
              data-testid="modal-comment-input"
            />

            <button
              data-testid="modal-send-button"
              type="button"
              onClick={handleCreateComment}
            >
              Enviar
            </button>
          </S.InputField>
        </S.Box>

        <S.Characters>
          {charactersAmount}/{MAX_LENGTH}
        </S.Characters>
      </S.Footer>
    </S.CommentModal>
  );
};

CommentModal.propTypes = {
  formalizationId: PropTypes.string.isRequired,
};

export default CommentModal;
