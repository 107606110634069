import PropTypes from 'prop-types';
import React from 'react';

import { Modal } from 'components/system/core';

import Timeline from 'components/core/Timeline';

import * as utility from 'lib/core/utility';

import { ScrollBarContainer } from './History.styles';

const History = ({ isOpen, rowData, width, onClose }) => {
  return (
    <Modal
      width={width}
      isOpen={isOpen}
      onClose={onClose}
      title={`Histórico de alterações - ${rowData.name}`}
    >
      <ScrollBarContainer
        overflowX
        width="650px"
        className="vli-scrollbar-theme"
      >
        <div style={{ display: 'flex' }}>
          {rowData.historys
            ? rowData.historys.map((history) => (
                <Timeline
                  title={
                    history.suppliers.length > 1
                      ? history.suppliers.join(' - ')
                      : history.suppliers
                  }
                  date={utility.formatDate(history.createAt)}
                  hour={utility.formatHour(history.createAt)}
                  isActive={history.isActual}
                  name={history.userName}
                  key={history.id}
                />
              ))
            : 'Carregando...'}
        </div>
      </ScrollBarContainer>
    </Modal>
  );
};

History.propTypes = {
  rowData: PropTypes.array,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  width: PropTypes.string,
};

History.defaultProps = {
  rowData: {},
  width: '',
  onClose: () => {},
};

export default History;
