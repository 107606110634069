import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { Button } from 'components/system/core';
import { Checkbox, Date as InputDate, Switch } from 'components/system/form';
import Tokens from 'components/system/tokens';
import { Text } from 'components/system/typography';

import CustomMultiselect from 'components/form/CustomMultiselect';
import Multiselect from 'components/form/multiselect';

import {
  BlurBackground,
  ButtonContent,
  ButtonFilter,
  ContainerCheckBox,
  ContainerSwitchBox,
  ContanerInputDate,
  FilterStyle,
  GridContainer,
  Group,
  GroupTitle,
  ItemCounter,
  MessageError,
  OptionsList,
  WrapperButtons,
} from 'components/core/FilterDs/FilterDs.styles';

import { validateFilter } from 'lib/contexts/indices';
import FilterIcon from './FilterIcon';

const FilterDS = ({
  defaultSelectedItems,
  listItems,
  onChange,
  checkedOptions,
  onConfirm,
  onClean,
  getAll,
}) => {
  const optionsRef = useRef(null);
  const [openOptions, setOpenOptions] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});
  const [errors, setErrors] = useState({});
  const [countFilter, setCountFilter] = useState(0);

  const filterSelect = (selectedItemsCount) => {
    let count = 0;
    Object.values(selectedItemsCount).forEach((obj) => {
      if (Array.isArray(obj)) {
        if (obj.length > 0) count += 1;
      } else if (obj !== '' && !!obj) count += 1;
    });
    setCountFilter(count);
    return count;
  };

  useEffect(() => {
    setSelectedItems(defaultSelectedItems);
    filterSelect(defaultSelectedItems);
  }, [defaultSelectedItems]);

  useEffect(() => {
    checkedOptions(selectedItems);
  }, [checkedOptions, selectedItems]);

  const addItem = (name, value) => {
    setSelectedItems({ ...selectedItems, [name]: value });
    filterSelect({ ...selectedItems, [name]: value });
  };

  const removeItem = (name) => {
    const newSelected = selectedItems;
    delete newSelected[name];
    setSelectedItems(newSelected);
    filterSelect(newSelected);
  };

  const handleItem = (name, value, isSelected) => {
    if (isSelected) removeItem(name);
    else addItem(name, value);
    onChange(name, value, isSelected);
    getAll(name, isSelected);
  };

  const handleOnClickCancel = () => {
    setOpenOptions(false);
  };

  const handleOnClickClean = () => {
    onClean();
    setOpenOptions(false);
    setSelectedItems([]);
  };

  const handleOnClickConfirm = async () => {
    const validate = await validateFilter(selectedItems);
    if (!validate.isValid) {
      setErrors(validate.errors);
    } else {
      setErrors({});
      onConfirm(selectedItems);
      setOpenOptions(false);
    }
  };

  const getData = (data, name) => {
    const normalize = data.map((newData) => ({
      ...newData,
      selected:
        selectedItems[name]?.filter((f) => f.value.id === newData.value.id)
          .length > 0,
    }));

    return normalize;
  };

  const getDataGroup = (data, name) => {
    const normalize = data.map((group) => ({
      ...group,
      data: group.data.map((newData) => ({
        ...newData,
        selected:
          selectedItems[name]?.filter((f) => f.value.id === newData.value.id)
            .length > 0,
      })),
    }));
    return normalize;
  };

  const renderType = (type, data, name, label) => {
    switch (type) {
      case 'date':
        return (
          <ContanerInputDate error={errors && errors[name]}>
            <InputDate
              name={name}
              value={selectedItems[name]}
              onChange={(value) => {
                handleItem(name, value);
              }}
            />
            {errors && errors[name] && (
              <MessageError>
                <Text color={Tokens.colors.red400}>{errors[name]}</Text>
              </MessageError>
            )}
          </ContanerInputDate>
        );
      case 'checkbox':
        return data.map((item) => (
          <ContainerCheckBox key={`${label}-${item.data}`}>
            <Checkbox
              label={item.label}
              key={`${label}-${item.label}`}
              id={`${label}-${item.label}`}
              checked={selectedItems[`${name}_${item.data}`] === item.data}
              group={label}
              onChange={() =>
                handleItem(
                  `${name}_${item.data}`,
                  item.data,
                  selectedItems[`${name}_${item.data}`] === item.data,
                )
              }
            />
          </ContainerCheckBox>
        ));
      case 'switch':
        return data.map((item) => (
          <ContainerSwitchBox key={`${name}-${item.data}`}>
            <Switch
              label={item.label}
              key={`${name}-${item.data}`}
              id={`${name}-${item.data}`}
              checked={selectedItems[`${name}`] === item.data}
              group={label}
              onChange={() => {
                handleItem(
                  `${name}`,
                  item.data,
                  selectedItems[`${name}`] === item.data,
                );
              }}
            />
          </ContainerSwitchBox>
        ));
      case 'multiselect':
        return (
          <Multiselect
            width="264px"
            keyValue="id"
            name={name}
            selected={selectedItems[name]}
            listItems={getData(data, name)}
            sections
            onChange={(value) => handleItem(name, value)}
          />
        );
      case 'custom-multiselect':
        return (
          <CustomMultiselect
            width="264px"
            keyValue="id"
            name={name}
            selected={selectedItems[name]}
            listItems={getData(data, name)}
            sections
            onChange={(value) => handleItem(name, value)}
            canSelectAll
          />
        );
      default:
        return (
          <Multiselect
            width="264px"
            keyValue="id"
            name={name}
            selected={selectedItems[name]}
            listItems={getDataGroup(data, name)}
            sections
            onChange={(value) => handleItem(name, value)}
          />
        );
    }
  };

  const renderOptions = ({ label, data, type, name }) => {
    renderOptions.propTypes = {
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(PropTypes.object).isRequired,
      name: PropTypes.string.isRequired,
    };

    return (
      <div key={`frag-${label}`}>
        <GroupTitle key={`title-${label}`}>{label}</GroupTitle>
        <Group key={label}>{renderType(type, data, name, label)}</Group>
      </div>
    );
  };

  return (
    <>
      <FilterStyle data-testid="dts-filtermain">
        <ButtonFilter
          data-testid="btn-filter"
          variant={[(openOptions || selectedItems.length > 0) && 'focus']}
          onClick={() => {
            setOpenOptions(!openOptions);
            checkedOptions(selectedItems);
          }}
        >
          <ButtonContent>
            <FilterIcon />
            <Text
              color={Tokens.colors.accentPrimaryDark}
              size="large"
              style={{ marginLeft: '6px' }}
            >
              Critérios
            </Text>
          </ButtonContent>
          {countFilter > 0 && <ItemCounter>{countFilter}</ItemCounter>}
        </ButtonFilter>
      </FilterStyle>

      {openOptions && (
        <>
          <BlurBackground />
          <OptionsList ref={optionsRef}>
            <GridContainer>{listItems.map(renderOptions)}</GridContainer>

            <WrapperButtons>
              <div>
                <Button
                  name="limpar filtros"
                  variant="tertiary"
                  action={handleOnClickClean}
                />
                <Button
                  name="cancelar"
                  variant="secondary"
                  action={handleOnClickCancel}
                />
                <Button
                  name="aplicar"
                  variant="primary"
                  disabled={countFilter === 0}
                  action={handleOnClickConfirm}
                />
              </div>
            </WrapperButtons>
          </OptionsList>
        </>
      )}
    </>
  );
};

FilterDS.propTypes = {
  defaultSelectedItems: PropTypes.object,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.object),
    }),
  ).isRequired,
  onChange: PropTypes.func,
  checkedOptions: PropTypes.func,
  onConfirm: PropTypes.func,
  onClean: PropTypes.func,
  getAll: PropTypes.func,
};

FilterDS.defaultProps = {
  defaultSelectedItems: [],
  onChange: () => {},
  checkedOptions: () => {},
  onConfirm: () => {},
  onClean: () => {},
  getAll: () => {},
};

export default FilterDS;
