import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { Flex } from 'components/system/core';
import { Container, Content, Header } from 'components/system/interface';

import Loading from 'components/core/Loading';

import CancelModal from 'components/contexts/formalizationRequest/CancelModal';
import ConfirmEditModal from 'components/contexts/formalizationRequest/ConfirmEditModal';
import DisapproveModal from 'components/contexts/formalizationRequest/DisapproveModal';
import FlowDateModal from 'components/contexts/formalizationRequest/FlowDateModal/FlowDate';
import FormalizationAction from 'components/contexts/formalizationRequest/FormalizationAction';
import FormalizationAttachment from 'components/contexts/formalizationRequest/FormalizationAttachment';
import FormalizationCard from 'components/contexts/formalizationRequest/FormalizationCard/FormalizationCard';
import FormalizationComments from 'components/contexts/formalizationRequest/FormalizationComments';
import FormalizationForm from 'components/contexts/formalizationRequest/FormalizationForm/FormalizationForm';
import FormalizationItemsCard from 'components/contexts/formalizationRequest/FormalizationItemsCard';
import FormalizationService from 'components/contexts/formalizationRequest/FormalizationService';
import NewDocumentModal from 'components/contexts/formalizationRequest/NewDocumentModal';
import SegmentModal from 'components/contexts/formalizationRequest/SegmentModal';

import { FormalizationRequestActions } from 'store/formalizationRequest/formalizationRequest.ducks';
import { FormulasActions } from 'store/formulas/formulas.ducks';
import { MessagesActions } from 'store/messages/messages.ducks';

import {
  FormalizationStatus,
  FormalizationSteps,
  formalizationFormDefaultValues,
  getDataFromFormEdit,
  validateIndicesValues,
  validateRequiredFormValues,
} from 'lib/contexts/formalizationRequest';
import { handleIdPattern, removeIdMask } from 'lib/core/idPattern';
import { GET } from 'lib/core/localStorage';
import { flattenObject } from 'lib/core/object';
import { isPending } from 'lib/core/request';

import useDimensions from 'hooks/useDimensions';

import * as ROUTES from 'constants/urls';
import { FORMALIZATION_REQUEST } from 'constants/urls';

import {
  getEditButtonsDataWithRoles,
  getNetlexButtonsDataWithRoles,
  getViewButtonsDataWithRoles,
} from './utils';

import * as S from './FormalizationRequest.styles';

const EDIT_MODAL_INITIAL_STATE = {
  isVisible: false,
  data: null,
  payload: null,
};

const FormalizationRequest = () => {
  const dispatch = useDispatch();

  const breadcrumb = [
    {
      text: 'Formalizações',
      href: `#${ROUTES.CONTRACTS}`,
    },
    {
      text: 'Solicitações',
      href: `#${ROUTES.REQUESTS_LIST}`,
    },
  ];

  const { formulasRequests } = useSelector(({ formulas }) => formulas);
  const {
    formalizationRequestRequest,
    formalizationData,
    isAdditiveFill,
  } = useSelector(({ formalizationRequest }) => formalizationRequest);

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showSegmentModal, setShowSegmentModal] = useState(false);
  const [showSendModal, setShowSendModal] = useState(false);
  const [showDisapproveModal, setShowDisapproveModal] = useState(false);
  const [showNewDocModal, setShowNewDocModal] = useState(false);
  const [shouldResetForm, setShouldResetForm] = useState(false);
  const [formErrors, setFormErrors] = useState(null);
  const [editModal, setEditModal] = useState(EDIT_MODAL_INITIAL_STATE);

  const { handleSubmit, getValues, ...formMethods } = useForm({
    defaultValues: formalizationFormDefaultValues,
  });

  const { formalizationId, action } = useParams();
  const history = useHistory();
  const dimensions = useDimensions();

  const formalizationIdUnmasked = formalizationId.includes('S')
    ? removeIdMask(formalizationId)
    : formalizationId;

  const isLoading =
    isPending(formalizationRequestRequest.FORMALIZATION_BY_ID_REQUEST) ||
    isPending(formalizationRequestRequest.FORMALIZATION_EDIT_REQUEST) ||
    isPending(
      formalizationRequestRequest.FORMALIZATION_RETURN_TO_PLAINTIFF_REQUEST,
    ) ||
    isPending(formalizationRequestRequest.SEND_FOR_FORMALIZATION_REQUEST) ||
    isPending(formalizationRequestRequest.EXPECTED_DATE_REQUEST) ||
    isPending(
      formalizationRequestRequest.DRAFT_TO_REQUESTED_FORMALIZATION_REQUEST,
    ) ||
    isPending(formulasRequests.APPROVED_FORMULAS_ITEMS_REQUEST) ||
    isPending(formalizationRequestRequest.DISAPPROVE_REQUEST) ||
    isPending(formalizationRequestRequest.APPROVE_REQUEST);

  useEffect(() => {
    dispatch(
      FormalizationRequestActions.formalizationByIdRequest(
        formalizationIdUnmasked,
      ),
    );
  }, [dispatch, formalizationIdUnmasked]);

  useEffect(() => {
    dispatch(FormulasActions.approvedFormulasItemsRequest());
  }, [dispatch]);

  const handleUndoChanges = () => {
    setShouldResetForm(true);
    formMethods.clearErrors();

    history.push(FORMALIZATION_REQUEST('visualizar', formalizationId));
  };

  const handleReturnToEdit = () => {
    formMethods.clearErrors();
    history.push(FORMALIZATION_REQUEST('editar', formalizationId));
  };

  const handleEditFormalization = (data) => {
    formMethods.clearErrors();
    setFormErrors(null);
    const formValues = flattenObject(data);
    const payload = getDataFromFormEdit(formValues, formalizationIdUnmasked);

    dispatch(FormalizationRequestActions.formalizationEditRequest(payload));
  };

  const handleReturnToPlaintiffFormalization = () => {
    dispatch(
      FormalizationRequestActions.formalizationReturnToPlaintiffRequest(
        formalizationIdUnmasked,
      ),
    );
  };

  const handleSendForFormalization = () => {
    if (!formalizationData?.proposal?.segments) {
      setShowSegmentModal(true);
    } else {
      dispatch(
        FormalizationRequestActions.sendForFormalizationRequest(
          { id: formalizationIdUnmasked },
          () => {},
        ),
      );
    }
  };

  const handleOpenFlowDataModal = (modalType) => {
    setShowSendModal(modalType);

    dispatch(
      FormalizationRequestActions.expectedDateRequest(formalizationIdUnmasked),
    );
  };

  const handleApproveRequest = () => {
    dispatch(
      FormalizationRequestActions.approveRequest(formalizationIdUnmasked),
    );
  };

  const handleDownloadSummary = () => {
    dispatch(
      FormalizationRequestActions.downloadSummary(
        formalizationIdUnmasked,
        formalizationData?.formalization?.statusId,
      ),
    );
  };

  const handleCloseFlowDataModal = () => {
    setShowSendModal(false);
  };

  const handleOpenCancelModal = () => {
    setShowCancelModal(true);
  };

  const handleCloseCancelModal = () => {
    setShowCancelModal(false);
  };

  const handleCloseEditModal = () => {
    setEditModal(EDIT_MODAL_INITIAL_STATE);
  };

  const handleOpenDisapproveModal = () => {
    setShowDisapproveModal(true);
  };

  const handleCloseDisapproveModal = () => {
    setShowDisapproveModal(false);
  };

  const handleOpenNewDocModal = () => {
    setShowNewDocModal(true);
  };

  const handleCloseNewDocModal = () => {
    setShowNewDocModal(false);
  };

  const setFormRequiredErrors = useCallback(
    (errors) => {
      if (errors?.parametrics) {
        Object.keys(errors.parametrics).forEach((key) => {
          formMethods.setError(`parametrics.${key}`, {
            message: errors.parametrics[key],
            type: 'required',
          });
        });
      }

      if (errors?.formulas) {
        Object.keys(errors.formulas).forEach((key) => {
          formMethods.setError(`formulas.${key}`, {
            message: errors.formulas[key],
            type: 'required',
          });
        });
      }

      if (errors?.indices?.diesel) {
        formMethods.setError('indices.diesel', {
          message: errors.indices.diesel,
          type: 'required',
        });
      }
    },
    [formMethods],
  );

  const validateRequestedData = useCallback(
    (formValues) => {
      const requiredValidation = validateRequiredFormValues(
        formValues,
        isAdditiveFill,
      );
      const indicesValidation = validateIndicesValues(
        formValues,
        isAdditiveFill,
      );

      if (requiredValidation.errors || indicesValidation.indices) {
        setFormErrors({ ...indicesValidation });
        setFormRequiredErrors(requiredValidation.errors);

        return true;
      }

      return false;
    },
    [setFormRequiredErrors, isAdditiveFill],
  );

  const draftToRequestedFormalizationCallback = (actualStep = null) => {
    if (actualStep === FormalizationSteps.PricingRequested) {
      dispatch(
        MessagesActions.openModal({
          titleModal: 'Solicitação atualizada com sucesso',
          typeModal: 'success',
          contentModalText:
            'A solicitação de formalização possui informações fora do padrão do PGS e passará por alçada de Pricing.',
        }),
      );
    }
  };

  const handleDraftToRequestedFormalization = () => {
    const formValues = flattenObject(getValues());
    const hasErrors = validateRequestedData(formValues);

    if (!hasErrors) {
      dispatch(
        FormalizationRequestActions.draftToRequestedFormalizationRequest(
          formalizationIdUnmasked,
          draftToRequestedFormalizationCallback,
        ),
      );
    }
  };

  const handlers = {
    handleReturnToEdit,
    handleOpenFlowDataModal,
    handleReturnToPlaintiffFormalization,
    handleSendForFormalization,
    handleDraftToRequestedFormalization,
    handleOpenCancelModal,
    handleOpenDisapproveModal,
    handleApproveRequest,
    handleOpenNewDocModal,
  };

  const buttonActions = {
    editar: getEditButtonsDataWithRoles(formalizationData, handleUndoChanges),
    visualizar: getViewButtonsDataWithRoles(formalizationData, handlers),
  };

  const proposalCode = handleIdPattern(
    formalizationData?.proposal?.proposalCode,
    'P',
  );
  const userService = formalizationData?.formalization?.userService;
  const stepId = formalizationData?.formalization?.stepId;
  const statusId = formalizationData?.formalization?.statusId;
  const id = formalizationData?.formalization?.id;
  const hasAttachment = formalizationData?.formalization?.hasAttachment;
  const idNetlex = formalizationData.formalization?.idDocNetlex;
  const docNumberNetlex = formalizationData.formalization?.numberDocNetlex;

  const { name } = GET('name');
  const isItTheSameUser = name === userService;

  useEffect(() => {
    if (action === 'visualizar') {
      setShouldResetForm(false);
    }
  }, [action]);

  const postNetlexButtons = getNetlexButtonsDataWithRoles(formalizationData, {
    handleReturnToPlaintiffFormalization,
  });

  const downloadSummaryButton = (
    <FormalizationAction
      {...{
        variant: 'primary',
        name: 'Download Relatório',
        type: 'button',
        key: 'button-download-summary',
        onClick: handleDownloadSummary,
        role: 0,
        shouldCheckRole: false,
      }}
      key="button-download-summary"
    />
  );

  return (
    <>
      <Content height={dimensions.height?.toString()}>
        <Container>
          <FormProvider {...formMethods}>
            <S.Form onSubmit={handleSubmit(handleEditFormalization)}>
              <Flex justifyContent="space-between" alignItems="center">
                <Header
                  bread={breadcrumb}
                  tag="header"
                  title="Solicitações de Formalização"
                />

                <S.ActionButtons>
                  {stepId !== FormalizationSteps.Draft && downloadSummaryButton}

                  {stepId === FormalizationSteps.UnderReview ? (
                    <>
                      {isItTheSameUser &&
                        buttonActions[action]?.map((item) => (
                          <FormalizationAction {...item} key={item.key} />
                        ))}
                    </>
                  ) : (
                    statusId !== FormalizationStatus.Canceled &&
                    buttonActions[action]?.map((item) => (
                      <FormalizationAction {...item} key={item.key} />
                    ))
                  )}

                  {stepId === FormalizationSteps.SentToFormalization &&
                    postNetlexButtons.map((item) => (
                      <FormalizationAction {...item} key={item.key} />
                    ))}
                </S.ActionButtons>
              </Flex>

              {isLoading && <Loading />}

              {!isLoading && (
                <>
                  <FormalizationCard />

                  {stepId === FormalizationSteps.Draft &&
                    statusId !== FormalizationStatus.Canceled &&
                    !hasAttachment && (
                      <FormalizationAttachment formalizationId={id} />
                    )}

                  <FormalizationService />

                  {id && (
                    <FormalizationComments
                      formalizationId={id}
                      formalizationStep={stepId}
                      hasAttachment={hasAttachment}
                    />
                  )}

                  <FormalizationForm
                    resetForm={shouldResetForm}
                    formErrors={formErrors}
                  />

                  <FormalizationItemsCard />
                </>
              )}
            </S.Form>
          </FormProvider>
        </Container>
      </Content>

      {showSendModal && (
        <FlowDateModal
          modalType={showSendModal}
          proposalCode={proposalCode}
          onClose={handleCloseFlowDataModal}
        />
      )}

      {showCancelModal && (
        <CancelModal
          formalizationId={formalizationIdUnmasked}
          onClose={handleCloseCancelModal}
        />
      )}

      {editModal.isVisible && (
        <ConfirmEditModal
          isOpen={editModal.isVisible}
          onClose={handleCloseEditModal}
          reviewData={editModal.data}
          payload={editModal.payload}
        />
      )}

      {showDisapproveModal && (
        <DisapproveModal
          isOpen={showDisapproveModal}
          onClose={handleCloseDisapproveModal}
          formalizationId={id}
        />
      )}

      {showNewDocModal && (
        <NewDocumentModal
          isOpen={showNewDocModal}
          onClose={handleCloseNewDocModal}
          formalizationId={id}
          netlexDoc={docNumberNetlex}
          netlexId={idNetlex}
        />
      )}

      {showSegmentModal && (
        <SegmentModal
          formalizationId={formalizationIdUnmasked}
          onClose={() => setShowSegmentModal(false)}
        />
      )}
    </>
  );
};

export default FormalizationRequest;
