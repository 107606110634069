import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal } from 'components/system/core';

import Autocomplete from 'components/form/Autocomplete';

import { getFilterParams } from 'lib/contexts/user';
import { GET } from 'lib/core/localStorage';

import { FormalizationRequestActions } from 'store/formalizationRequest/formalizationRequest.ducks';
import { UserActions } from 'store/user/user.ducks';

import * as S from './TransferServiceModalStyles';

const TransferServiceModal = ({ isOpen, onClose, formalizationId, action }) => {
  const dispatch = useDispatch();

  const { data } = useSelector(({ user }) => user);

  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    dispatch(
      UserActions.fetchUsers(getFilterParams({ pageIndex: 1, pageSize: 25 })),
    );
  }, [dispatch]);

  const handleChangeUser = (value) => {
    setSelectedUser(value);
  };

  const handleInputChange = (value) => {
    dispatch(
      UserActions.fetchUsers(
        getFilterParams({ pageIndex: 1, pageSize: 25, name: value }),
      ),
    );
  };

  const handleTransferService = () => {
    dispatch(
      FormalizationRequestActions.transferService(
        formalizationId,
        selectedUser.value,
      ),
    );

    onClose();
  };

  const handleGetService = () => {
    const { userId } = GET('userId');

    dispatch(
      FormalizationRequestActions.transferService(formalizationId, userId),
    );

    onClose();
  };

  const selectOptions = data?.items?.map((item) => {
    return {
      label: item.userName,
      value: item.id,
    };
  });

  if (action === 'GET') {
    return (
      <Modal
        title="Deseja obter atendimento?"
        isOpen={isOpen}
        onClose={onClose}
      >
        <S.Wrapper>
          <p>
            Ao aplicar ação, a solicitação de formalização será atendida por
            você.
          </p>

          <S.Actions>
            <Button
              onClick={onClose}
              variant="secondary"
              name="Cancelar"
              type="button"
              key="button-get-cancel"
            />

            <Button
              onClick={handleGetService}
              name="Confirmar"
              type="button"
              key="button-get-confirm"
            />
          </S.Actions>
        </S.Wrapper>
      </Modal>
    );
  }

  return (
    <Modal
      title="Deseja transferir o atendimento da solicitação de formalização? "
      isOpen={isOpen}
      onClose={onClose}
    >
      <S.Wrapper>
        <p>
          Ao transferir o atendimento, você não será mais o responsável e o
          usuário indicado deverá revisar a solicitação de formalização.
        </p>

        <S.Box>
          <span>Informe o usuário</span>
          <Autocomplete
            autocomplete
            defaultOptions={selectOptions}
            onInputChange={handleInputChange}
            onChange={handleChangeUser}
            placeholder="Selecione"
            value={selectedUser}
          />
        </S.Box>

        <S.Actions>
          <Button
            onClick={onClose}
            variant="secondary"
            name="Cancelar"
            type="button"
            key="button-transfer-cancel"
          />

          <Button
            onClick={handleTransferService}
            name="Confirmar"
            type="button"
            key="button-transfer-confirm"
          />
        </S.Actions>
      </S.Wrapper>
    </Modal>
  );
};

TransferServiceModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  formalizationId: PropTypes.number.isRequired,
  action: PropTypes.string,
};

TransferServiceModal.defaultProps = {
  action: 'TRANSFER',
};

export default TransferServiceModal;
