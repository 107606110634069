import styled from 'styled-components';

import Tokens from 'components/system/tokens';

export const FormAction = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-end;

  padding: ${Tokens.spacing.sm} 0;
`;
