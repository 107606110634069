import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import EditItemIcon from 'assets/icons/edit-item.svg';
import ArrowLeftIcon from 'assets/system/svgs/left.svg';
import ArrowRightIcon from 'assets/system/svgs/right.svg';

import { Card, Flex, Icon, Tabs } from 'components/system/core';
import { Column as Col, Row } from 'components/system/interface';
import Tokens from 'components/system/tokens';
import { Heading, Text } from 'components/system/typography';

import Loading from 'components/core/Loading';

import { useScroll } from 'hooks/useScroll';

import { getItemStatusColor } from 'lib/contexts/contracts';
import { ACTIONS, canExecuteAction, ROLES } from 'lib/contexts/permissions';
import { GET } from 'lib/core/localStorage';
import { isPending } from 'lib/core/request';
import { capitalizeString, formatDate } from 'lib/core/utility';

import { RailwayItemActions } from 'store/railwayItem/railwayItem.ducks';

import RailwayItemEditModal from '../../contract/ContractDetail/Modals/RailwayItemEditModal';

import * as S from './DashboardHistory.styles';

const DashboardHistory = ({ breadcrumb = [{ href: '#' }], railwayItems }) => {
  const dispatch = useDispatch();

  const { isEditModalOpen, railwayItemsRequests } = useSelector(
    ({ railwayItem }) => railwayItem,
  );

  const { scrollY } = useScroll({
    element: document.getElementById('content'),
  });

  const openModal = () => {
    const id = railwayItems.historyList[0].railwayItemId;
    const {
      origin,
      originCode,
      destination,
      destinationCode,
      productUnicomMerchandiseTacito,
    } = railwayItems;

    dispatch(
      RailwayItemActions.setEditModalOpen({
        modalOpen: true,
        row: {
          id,
          origin,
          originCode,
          destination,
          destinationCode,
          productUnicomMerchandiseTacito,
        },
      }),
    );
  };

  const { roles } = GET('roles');
  const item = breadcrumb[breadcrumb.length - 1];
  const disabledTab = !railwayItems.methodAccumulation;
  const canEditRailwayItem = canExecuteAction(
    ROLES.RAILWAY_ITEMS,
    ACTIONS.UPDATE,
    roles,
  );

  return (
    <>
      {isPending(railwayItemsRequests.UPDATE) && <Loading />}
      <S.DashboardContainer data-testid="dashboard-test">
        <Row>
          <Col desktop="3">
            <Card
              style={{
                height: '100%',
                backgroundColor: getItemStatusColor(railwayItems),
              }}
            >
              <S.CardHeader>
                <Flex justifyContent="space-between">
                  <Heading
                    tag="h5"
                    color={Tokens.colors.gray300}
                    variant={['bold']}
                  >
                    Fluxo {`${railwayItems.hasTrigger ? 'com' : 'sem'}`} gatilho
                  </Heading>

                  {canEditRailwayItem ? (
                    <Icon
                      style={{ marginLeft: '12px', cursor: 'pointer' }}
                      src={EditItemIcon}
                      onClick={openModal}
                    />
                  ) : null}
                </Flex>
              </S.CardHeader>

              <S.CardContent>
                <Text size="small" color={Tokens.colors.gray400}>
                  Origem e destino
                </Text>

                <Flex>
                  <Text
                    size="medium"
                    style={{
                      fontWeight: '400',
                    }}
                  >
                    {railwayItems.origin} ({railwayItems.originCode})
                  </Text>

                  <Icon src={ArrowRightIcon} style={{ width: '40px' }} />

                  <Text
                    size="medium"
                    style={{
                      fontWeight: '400',
                    }}
                  >
                    {railwayItems.destination} ({railwayItems.destinationCode})
                  </Text>
                </Flex>
              </S.CardContent>

              {railwayItems.placeOriginCargo && (
                <S.CardContent>
                  <Text size="small" color={Tokens.colors.gray400}>
                    Local de Retirada da Carga
                  </Text>

                  <Text
                    size="medium"
                    style={{
                      fontWeight: '400',
                    }}
                  >
                    {railwayItems.placeOriginCargo}
                  </Text>
                </S.CardContent>
              )}

              <S.CardContent>
                <Flex spaceChildren="40px">
                  <div>
                    <Text size="small" color={Tokens.colors.gray400}>
                      Produto
                    </Text>

                    <Text
                      size="medium"
                      style={{
                        fontWeight: '400',
                      }}
                    >
                      {railwayItems.productUnicomMerchandiseTacito}
                    </Text>
                  </div>

                  <div>
                    <Text size="small" color={Tokens.colors.gray400}>
                      Observação
                    </Text>

                    <Text
                      size="medium"
                      style={{
                        fontWeight: '400',
                      }}
                    >
                      {railwayItems?.observation || '-'}
                    </Text>
                  </div>
                </Flex>
              </S.CardContent>

              <S.CardContent>
                <Text size="small" color={Tokens.colors.gray400}>
                  Base de Diesel
                </Text>

                <Text
                  size="medium"
                  style={{
                    fontWeight: '400',
                  }}
                >
                  {capitalizeString(railwayItems.local)}
                </Text>
              </S.CardContent>

              <Flex>
                <S.CardContent>
                  <Text size="small" color={Tokens.colors.gray400}>
                    Condição
                  </Text>

                  <Text
                    size="medium"
                    style={{
                      fontWeight: '400',
                    }}
                  >
                    {railwayItems.status}
                  </Text>
                </S.CardContent>

                {railwayItems.inactivatedDate && (
                  <S.CardContent style={{ marginTop: '8px' }}>
                    <Text size="small" color={Tokens.colors.gray400}>
                      Data de inativação
                    </Text>

                    <Text
                      size="medium"
                      style={{
                        fontWeight: '400',
                      }}
                    >
                      {railwayItems.inactivatedDate
                        ? formatDate(railwayItems.inactivatedDate)
                        : '-'}
                    </Text>
                  </S.CardContent>
                )}
              </Flex>
            </Card>
          </Col>

          <Col desktop="7" style={{ height: '100%' }}>
            <Card style={{ marginLeft: '15px', height: '100%' }}>
              <div style={{ position: 'absolute' }}>
                <Heading
                  tag="h5"
                  color={Tokens.colors.gray300}
                  variant={['bold']}
                >
                  Método de Cálculo
                </Heading>
              </div>

              <Tabs
                bordeLine={false}
                position={{ spaceBetween: 'spaceBetween' }}
              >
                <li label="Contrato">
                  <div>
                    <S.CardContent>
                      <Text size="small" color={Tokens.colors.gray400}>
                        Diesel
                      </Text>

                      <Text
                        size="medium"
                        style={{
                          fontWeight: '400',
                        }}
                        color={Tokens.colors.gray500}
                      >
                        {railwayItems.methodCalculate?.diesel
                          ? `Calculado = ${railwayItems.methodCalculate.diesel}`
                          : '-'}
                      </Text>
                    </S.CardContent>

                    <S.CardContent>
                      <Text size="small" color={Tokens.colors.gray500}>
                        Inflação
                      </Text>

                      <Text
                        size="medium"
                        style={{
                          fontWeight: '400',
                        }}
                        color={Tokens.colors.gray500}
                      >
                        {railwayItems.methodCalculate?.inflation
                          ? `Calculado = ${railwayItems.methodCalculate.inflation}`
                          : '-'}
                      </Text>
                    </S.CardContent>
                  </div>
                </li>

                <li label="Acúmulo" disabled={disabledTab}>
                  <S.CardContent>
                    <Text size="small" color={Tokens.colors.gray400}>
                      Diesel
                    </Text>

                    <Text
                      size="medium"
                      style={{
                        fontWeight: '400',
                      }}
                      color={Tokens.colors.gray500}
                    >
                      {railwayItems.methodAccumulation?.diesel
                        ? `${railwayItems.methodAccumulation.diesel}`
                        : '-'}
                    </Text>
                  </S.CardContent>

                  <S.CardContent>
                    <Text size="small" color={Tokens.colors.gray400}>
                      {' '}
                      Inflação
                    </Text>

                    <Text
                      size="medium"
                      style={{
                        fontWeight: '400',
                      }}
                      color={Tokens.colors.gray500}
                    >
                      {railwayItems.methodAccumulation?.inflation
                        ? `${railwayItems.methodAccumulation.inflation}`
                        : '-'}
                    </Text>
                  </S.CardContent>
                </li>
              </Tabs>
            </Card>
          </Col>
        </Row>

        <S.CardSticky className={`${scrollY > 260 ? 'openActive' : ''}`}>
          <S.CardStickyContent>
            <Link to={`${item?.href.replace('#', '')}`}>
              <Icon src={ArrowLeftIcon} className="ml-3" />
            </Link>

            <S.CardStickyFlex>
              <Text size="small"> Contrato</Text>
              <Text size="large"> {railwayItems.codeContract} </Text>
            </S.CardStickyFlex>

            <S.CardStickyFlex>
              <Text size="small"> Item</Text>
              <Text size="large"> {railwayItems.code} </Text>
            </S.CardStickyFlex>

            <S.CardStickyFlex>
              <Text size="small"> Origem e destino</Text>

              <S.OriginContainer>
                <span>
                  {railwayItems.origin} ({railwayItems.originCode})
                </span>
                <Icon src={ArrowRightIcon} />
                <span>
                  {railwayItems.destination} ({railwayItems.destinationCode})
                </span>
              </S.OriginContainer>
            </S.CardStickyFlex>

            <S.CardStickyFlex>
              <Text size="small"> Produto</Text>

              <Text
                size="medium"
                style={{
                  fontWeight: '400',
                }}
              >
                {railwayItems.productUnicomMerchandiseTacito}
              </Text>
            </S.CardStickyFlex>

            <S.CardStickyFlex>
              <Text size="small"> Base de Diesel</Text>

              <Text
                size="medium"
                style={{
                  fontWeight: '400',
                  textTransform: 'capitalize',
                }}
              >
                {railwayItems.local?.toLowerCase()}
              </Text>
            </S.CardStickyFlex>

            <S.CardStickyFlex>
              <Text size="small"> Condição</Text>

              <Text
                size="medium"
                style={{
                  fontWeight: '400',
                }}
              >
                {railwayItems.status}
              </Text>
            </S.CardStickyFlex>

            {railwayItems.inactivatedDate && (
              <S.CardStickyFlex>
                <Text size="small"> Data de inativação</Text>

                <Text
                  size="medium"
                  style={{
                    fontWeight: '400',
                  }}
                >
                  {railwayItems.inactivatedDate
                    ? formatDate(railwayItems.inactivatedDate, 'DD/MM/YYYY')
                    : '-'}
                </Text>
              </S.CardStickyFlex>
            )}
          </S.CardStickyContent>
        </S.CardSticky>

        {isEditModalOpen && <RailwayItemEditModal />}
      </S.DashboardContainer>
    </>
  );
};

export default DashboardHistory;
