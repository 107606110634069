import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { Button, Card, EmptyState } from 'components/system/core';

import { getPriceRecommendationRequestsParams } from 'lib/contexts/recommendedPrice';
import { isInProgress, isPending } from 'lib/core/request';

import usePagination from 'hooks/usePagination';
import useSearchParams from 'hooks/useSearchParams';

import { PriceRecommendationActions } from 'store/priceRecommendation/priceRecommendation.ducks';

import Loading from 'components/core/Loading';
import Table from 'components/core/Table';

import Columns from './Columns';

import * as S from './styles';

const formSchema = Yup.object().shape({
  recommendedPriceRequests: Yup.array().of(
    Yup.object().shape({
      endPeriod: Yup.date().notRequired(),
      value: Yup.string().notRequired(),
    }),
  ),
});

const RecommendationTable = () => {
  const dispatch = useDispatch();

  const { paginationSize, setValuePage } = usePagination();
  const [searchParams, setSearchParams] = useSearchParams();

  const methods = useForm({
    resolver: yupResolver(formSchema),
  });

  const page = searchParams.get('page');
  const size = searchParams.get('size');

  const onPageChange = (PageIndex) => {
    setSearchParams('page', PageIndex);

    const params = getPriceRecommendationRequestsParams({
      pageIndex: PageIndex,
      pageSize: Number(size),
    });

    dispatch(PriceRecommendationActions.priceRecommendationRequests(params));
  };

  const onPerPageChange = (PageSize) => {
    setSearchParams('size', PageSize);
    setSearchParams('page', 1);
    setValuePage(PageSize);

    const params = getPriceRecommendationRequestsParams({
      pageIndex: 1,
      pageSize: PageSize,
    });

    dispatch(PriceRecommendationActions.priceRecommendationRequests(params));
  };

  useEffect(() => {
    const paginationData = {};

    if (page) {
      paginationData.pageIndex = Number(page);
    }

    if (size) {
      paginationData.pageSize = Number(size);
      setValuePage(Number(size));
    }

    const params = getPriceRecommendationRequestsParams(paginationData);

    dispatch(PriceRecommendationActions.priceRecommendationRequests(params));
  }, [dispatch]);

  const { requests, priceRecommendationRequest } = useSelector(
    (store) => store.priceRecommendation,
  );

  const sendRecommendationRequests = (data) => {
    methods.clearErrors();

    if (data?.recommendedPriceRequests?.length === 0) {
      return;
    }

    const payload = {
      recommendedPriceRequests: [],
    };

    data.recommendedPriceRequests.forEach((recommendedItem, index) => {
      if (recommendedItem.endPeriod && recommendedItem.value) {
        payload.recommendedPriceRequests.push({
          ...recommendedItem,
          value: Number(recommendedItem.value.replace(',', '.')),
          id: requests.items[index].id,
        });
      }

      if (recommendedItem.endPeriod && !recommendedItem.value) {
        methods.setError(`recommendedPriceRequests.${index}.value`, {
          type: 'required',
        });
      }

      if (!recommendedItem.endPeriod && recommendedItem.value) {
        methods.setError(`recommendedPriceRequests.${index}.endPeriod`, {
          type: 'required',
        });
      }
    });

    if (!methods.errors?.recommendedPriceRequests) {
      if (payload.recommendedPriceRequests.length > 0) {
        dispatch(
          PriceRecommendationActions.sendPriceRecommendationRequests(payload),
        );
      }
    }
  };

  const isLoading =
    isInProgress(priceRecommendationRequest.PRICE_RECOMMENDATION_REQUESTS) ||
    isPending(priceRecommendationRequest.SEND_PRICE_RECOMMENDATION_REQUESTS);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...methods}>
      {requests?.items?.length > 0 && !isLoading ? (
        <form
          onSubmit={methods.handleSubmit(sendRecommendationRequests)}
          data-testid="waiting-recommendation-form"
        >
          <S.FormAction>
            <Button name="Enviar recomendações" type="submit" />
          </S.FormAction>

          <Table
            data-testid="waiting-recommendation-table"
            width="100%"
            columns={Columns}
            data={requests.items}
            customList={paginationSize}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
            pageIndex={requests.currentPage}
            pageCount={requests.totalPages}
            totalItems={requests.totalCount}
            footer
          />
        </form>
      ) : (
        <Card>
          <EmptyState
            condensed={false}
            title="Nenhuma solicitação de recomendação encontrada."
          />
        </Card>
      )}
    </FormProvider>
  );
};

export default RecommendationTable;
