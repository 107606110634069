import styled from 'styled-components';

import DCard from '../card';

export const PopoverWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Card = styled(DCard)`
  min-width: 150px;
  padding: 8px;
`;
