import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Button, Modal, Popover } from 'components/system/core';
import { Input } from 'components/system/form';
import { Text } from 'components/system/typography';

import OptionIcon from 'assets/icons/table/options.svg';

import { getGroupedRailwayItemsId } from 'lib/contexts/contracts';
import { ExecutedAction } from 'lib/contexts/historyRailwayItem';
import handleInputMask from 'lib/core/handleInputMask';
import * as utility from 'lib/core/utility';
import { ContractDetailActions } from 'store/contractDetail/contractDetail.ducks';

import * as S from 'components/core/Table/CellWithActions/CellWithActions.styles';

const CellWithActions = ({ row, isGrouped = false }) => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [newContractedValue, setNewContractedValue] = useState('');

  const callback = () => {
    dispatch(
      ContractDetailActions.getContractDetail({
        id,
        viewScenario: 'CurrentPrice',
      }),
    );
  };

  const handleOnClick = (type) => {
    dispatch(ContractDetailActions.setPriceAction(type));
  };

  const item = row.original;

  const idHistories = getGroupedRailwayItemsId(row.original);

  const {
    contractDetails: { details, segmentId },
  } = useSelector(({ contractDetail }) => contractDetail);

  const handleImmediateSending = () => {
    const data = {
      historyRailwayItems: isGrouped ? idHistories : [item.history.id],
    };

    dispatch(ContractDetailActions.sendImmediately(data, callback));
  };

  const isApproveButtonDisabled =
    (item.history?.status !== utility.enumStatus.RequestExecuted &&
      item.history?.lastExecutedAction === ExecutedAction.Approve) ||
    item.history?.status !== utility.enumStatus.WaitingForApproval;

  const Options = (
    <S.OptionsContainer>
      <S.OptionItem
        data-testid="test-approve-action"
        onClick={() => {
          handleOnClick({
            action: 'approve',
            modal: true,
            id: isGrouped ? null : row.original.id,
            item: isGrouped ? 'grouped' : row.original,
            groupedItems: isGrouped ? row.original : null,
          });
        }}
        disabled={isApproveButtonDisabled}
      >
        <Text size="large">Aprovar preço</Text>
      </S.OptionItem>

      <S.OptionItem
        data-testid="test-negociation-action"
        onClick={() => {
          handleOnClick({
            action: 'negociation',
            modal: true,
            id: isGrouped ? null : row.original.id,
            item: isGrouped ? 'grouped' : row.original,
            groupedItems: isGrouped ? row.original : null,
          });
        }}
        disabled={!item.history?.canFixPrice}
      >
        <Text size="large">Reajuste de preço</Text>
      </S.OptionItem>

      <S.OptionItem
        data-testid="test-immediate-sending-action"
        onClick={handleImmediateSending}
        disabled={!utility.validateImmediateSend(item)}
      >
        <Text size="large">Envio imediato</Text>
      </S.OptionItem>
    </S.OptionsContainer>
  );

  const handleGetPreviousMonth = () => {
    const data = item.childrens.map((child) => ({
      applicationDate: item.dateApplication,
      railwayItemId: child.railwayItemId,
    }));

    dispatch(ContractDetailActions.getPreviousMonth(data, callback));
  };

  const handleEnterManually = () => {
    const data = item.childrens.map((child) => ({
      applicationDate: item.dateApplication,
      railwayItemId: child.railwayItemId,
      contractedValue: Number(newContractedValue.replace(',', '.')),
    }));

    dispatch(ContractDetailActions.sendManuallyValue(data, callback));
  };

  const isZeroTariffDisabled =
    item.history?.status !== utility.enumStatus.WaitingForApproval;
  const isPreviousMonthDisabled =
    item.calculatedValue?.tariffPreviusMonthValue === 0;

  const ZeroTariffOptions = (
    <S.OptionsContainer>
      <S.OptionItem
        data-testid="test-previous-month-action"
        disabled={isZeroTariffDisabled || isPreviousMonthDisabled}
        onClick={handleGetPreviousMonth}
      >
        <Text size="large">Utilizar mês anterior</Text>
      </S.OptionItem>

      <S.OptionItem
        data-testid="test-enter-manually-action"
        disabled={isZeroTariffDisabled}
        onClick={() => setIsOpen(true)}
      >
        <Text size="large">Inserir manualmente</Text>
      </S.OptionItem>
    </S.OptionsContainer>
  );

  const isGrainSegment = details?.segment?.includes('GRÃOS');
  const isZeroTariff = isGrainSegment ? item.valueContracted === 0 : false;

  return (
    <S.ContentCell data-testid="cell-with-action">
      <Popover
        content={isZeroTariff ? ZeroTariffOptions : Options}
        position="left center"
      >
        <Button
          data-testid="test-popover-button"
          icon={OptionIcon}
          isDisabled={
            !utility.validateEdit(item, segmentId, details, false, isZeroTariff)
          }
          name=""
          variant="tertiary"
        />
      </Popover>

      {isOpen && (
        <Modal
          title="Inserir tarifa manualmente"
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          actions={
            <Button
              data-testid="test-send-button"
              isDisabled={!newContractedValue}
              name="Enviar"
              type="button"
              onClick={handleEnterManually}
            />
          }
        >
          <Input
            name="newContractedValue"
            label="Nova tarifa"
            required
            placeholder="$"
            value={newContractedValue}
            width="92px"
            onInput={(event) => handleInputMask(event, 'currency')}
            onChange={(value) => setNewContractedValue(value)}
          />
        </Modal>
      )}
    </S.ContentCell>
  );
};

CellWithActions.propTypes = {
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      history: PropTypes.shape({
        lastExecutedAction: PropTypes.any,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default CellWithActions;
