import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Flex } from 'components/system/core';
import { Text } from 'components/system/typography';

import * as utility from 'lib/core/utility';
import { ContractDetailActions } from 'store/contractDetail/contractDetail.ducks';

import InputSelect from 'components/form/InputSelect';

import * as S from './FilterContracts.styles';

const FilterContracts = ({
  options,
  totalTo,
  id,
  groupedItems = null,
  disabled,
}) => {
  const dispatch = useDispatch();
  const {
    contractDetails: { filter },
  } = useSelector(({ contractDetail }) => contractDetail);

  const [viewScenario, setViewScenario] = useState(options[0]);

  const totalFrom = 0;
  const pluEntity = 'itens ferroviários';
  const singEntity = 'item ferroviário';
  const pluFromEntity = 'Clientes';
  const singFromEntity = 'Cliente';

  const onChange = (e) => {
    dispatch(
      ContractDetailActions.getContractDetail({
        id,
        viewScenario: e,
      }),
    );
  };

  const setModalChange = (actionType) => {
    switch (actionType) {
      case 'approveAll':
        dispatch(
          ContractDetailActions.setPriceAction({
            action: 'approve',
            modal: true,
            id: null,
            item: groupedItems ? 'grouped-all' : 'all',
            groupedItems,
          }),
        );
        break;

      case 'sendAll':
        dispatch(
          ContractDetailActions.setPriceAction({
            action: 'send',
            modal: true,
            id: null,
            item: groupedItems ? 'grouped-all' : 'all',
            groupedItems,
          }),
        );
        break;

      default:
        dispatch(
          ContractDetailActions.setPriceAction({
            action: 'negociation',
            modal: true,
            id: null,
            item: groupedItems ? 'grouped-all' : 'all',
            groupedItems,
          }),
        );
        break;
    }
  };

  useEffect(() => {
    const getSelect = utility.findObject(options, 'value', filter.viewScenario);

    if (getSelect) {
      setViewScenario(getSelect);
    } else {
      setViewScenario(options[0]);
    }
  }, [filter, options]);

  const approveOptions = [
    {
      label: 'Aprovar preços',
      value: 'approveAll',
    },
    {
      label: 'Reajuste de preço',
      value: 'negociationAll',
    },
    {
      label: 'Envio imediato',
      value: 'sendAll',
    },
  ];

  return (
    <Flex justifyContent="space-between">
      <S.WrapperSelectFilter>
        <Text
          size="large"
          style={{
            fontWeight: '400',
          }}
        >
          Exibindo
        </Text>
        <S.SpaceSelect>
          <InputSelect
            value={viewScenario}
            options={options}
            name="filterScenario"
            placeholder={viewScenario?.label}
            width={180}
            onChange={onChange}
          />
        </S.SpaceSelect>
        <div>
          <Text
            size="large"
            style={{
              fontWeight: '400',
            }}
          >
            {utility.transformInfoLength(
              totalTo,
              pluEntity,
              singEntity,
              totalFrom,
              pluFromEntity,
              singFromEntity,
            )}
          </Text>
        </div>
      </S.WrapperSelectFilter>

      <Flex>
        <S.WrapperSelectFilter data-testid="dts-filter">
          <InputSelect
            options={approveOptions}
            placeholder="Ações em massa"
            name="approves"
            width={210}
            disabled={disabled}
            onChange={setModalChange}
          />
        </S.WrapperSelectFilter>
      </Flex>
    </Flex>
  );
};
FilterContracts.defaultProps = {
  totalTo: 0,
  id: '',
  options: [
    {
      label: 'Preço atual',
      value: 'CurrentPrice',
    },
    {
      label: 'Próxima aplicação',
      value: 'NextPrice',
    },
    {
      label: 'Inativos',
      value: 'Inactive',
    },
  ],
  disabled: true,
};
FilterContracts.propTypes = {
  id: PropTypes.string,
  totalTo: PropTypes.number,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
};

export default FilterContracts;
