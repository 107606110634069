import styled from 'styled-components';

import Tokens from 'components/system/tokens';

export const Container = styled.div`
  width: 1060px;
  height: 550px;
  display: flex;
`;

export const Content = styled.div`
  display: flex;
`;
export const ItemHeadLight = styled.div`
  margin-top: ${Tokens.spacing.xs};
`;

export const CardHeadlight = styled.div`
  display: inline-block;
  padding: 0;
  max-width: 60px;
  padding-right: ${Tokens.spacing.xs};
  margin: 1rem 0 0;
  width: 100%;
`;
export const CardContent = styled.div`
  display: inline-block;
  padding: 0;
  padding-right: ${Tokens.spacing.xs};
  margin: 1rem 0 0;
  width: 100%;
`;

export const RightCol = styled.div`
  width: 300px;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const LeftCol = styled.div`
  width: 760px;
`;

export const ContainerGraph = styled.div`
  margin-top: ${Tokens.spacing.lg};
`;
