import styled from 'styled-components';

import { Button } from 'components/system/core';

export const WrapperFilter = styled.div`
  position: relative;
  padding: 8px 0px;
`;

export const WrapperSelectFilter = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 0px;
`;

export const SpaceSelect = styled.div`
  padding: 0px 8px;
`;

export const CreateButton = styled(Button)`
  height: 32px;
  margin: 16px 0 0 16px;
`;
