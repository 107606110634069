import styled from 'styled-components';

import { Text } from 'components/system/typography';

export const TextHeader = styled(Text)`
  font-weight: 400;
  margin: 22px 0;
  font-size: 14px;
  line-height: 20px;
`;

export const WrapperFilter = styled.div`
  position: relative;
  padding: 8px 0px;
`;
