import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Container, Content } from 'components/system/interface';

import EmailForm from 'components/contexts/notifications/client/EmailForm';
import Loading from 'components/core/Loading';

import { isPending } from 'lib/core/request';

import useDimensions from 'hooks/useDimensions';

import { EmailNotificationClientActions } from 'store/emailNotificationsClient/emailNotificationClient.ducks';

const EmailNotificationClientCreate = () => {
  const dimensions = useDimensions();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { details, emailNotificationClientRequests } = useSelector(
    ({ emailNotificationClient }) => emailNotificationClient,
  );

  const callback = () => {
    dispatch(EmailNotificationClientActions.getEmailNotificationClient(id));
  };

  const sendForm = (data) => {
    dispatch(
      EmailNotificationClientActions.createEmailNotificationClient(
        data,
        callback,
      ),
    );
  };

  useEffect(() => {
    dispatch(EmailNotificationClientActions.getEmailNotificationClient(id));
  }, [dispatch, id]);

  const loading =
    isPending(emailNotificationClientRequests.GET_EMAIL_NOTIFICATION_CLIENT) ||
    isPending(
      emailNotificationClientRequests.CREATE_EMAIL_NOTIFICATION_CLIENT,
    ) ||
    isPending(emailNotificationClientRequests.RESEND_EMAIL_NOTIFICATION_CLIENT);

  return (
    <Content height={dimensions.height} data-testid="content">
      {loading ? (
        <Loading />
      ) : (
        <Container>
          {details && <EmailForm sendForm={sendForm} data={details} />}
        </Container>
      )}
    </Content>
  );
};

export default EmailNotificationClientCreate;
