import styled from 'styled-components';

import { Accordion, Card, Flex } from 'components/system/core';
import { Row } from 'components/system/interface';
import Tokens from 'components/system/tokens';
import { Text } from 'components/system/typography';

export const RowContainer = styled(Row)`
  padding: ${Tokens.spacing.sm};
  align-items: center;
  > div {
    margin-right: ${Tokens.spacing.sm};
  }
`;

export const ExpandedAccordion = styled(Accordion)`
  width: 100%;
  margin-bottom: ${Tokens.spacing.sm};
`;

export const CardContainer = styled(Card)`
  padding: ${Tokens.spacing.sm} ${Tokens.spacing.sm} ${Tokens.spacing.md};
  margin-bottom: ${Tokens.spacing.md};
`;

export const Label = styled(Text)`
  color: ${Tokens.colors.gray400};
  margin-bottom: 6px;
`;

export const TextValue = styled(Text)`
  font-size: ${Tokens.spacing.sm};
`;

export const FlexContainer = styled(Flex)`
  align-items: center;

  > div {
    margin-right: ${Tokens.spacing.sm};
  }
`;
