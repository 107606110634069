import PropTypes from 'prop-types';
import React from 'react';

import CheckIcon from 'assets/system/svgs/check.svg';
import CircleIcon from 'assets/system/svgs/list/circle.svg';
import CircleEmptyIcon from 'assets/system/svgs/list/circleEmpty.svg';
import SquareIcon from 'assets/system/svgs/list/square.svg';
import SquareEmptyIcon from 'assets/system/svgs/list/squareEmpty.svg';
import StarIcon from 'assets/system/svgs/star.svg';

import generateUUID from '../../util/generateUUID';

import Icon from '../icon';

import { Counter, ListItem, ListStyle } from './styles';

const List = ({ type, marker, listItems, ...attrs }) => {
  const typeList = () => {
    if (type === 'unnumbered') return 'ul';
    return 'ol';
  };

  const renderMarker = () => {
    switch (marker) {
      case 'circleEmpty':
        return <Icon mr="12px" size={16} src={CircleEmptyIcon} />;
      case 'square':
        return <Icon mr="12px" size={16} src={SquareIcon} />;
      case 'squareEmpty':
        return <Icon mr="12px" size={16} src={SquareEmptyIcon} />;
      case 'star':
        return <Icon mr="12px" size={16} src={StarIcon} />;
      case 'checkmark':
        return <Icon mr="12px" size={16} src={CheckIcon} />;
      default:
        return <Icon mr="12px" size={16} src={CircleIcon} />;
    }
  };

  return (
    <ListStyle as={typeList()} {...attrs} data-testid="ds-list">
      {listItems.map((item, i) => (
        <ListItem key={generateUUID()}>
          {type === 'unnumbered' ? renderMarker() : <Counter>{i + 1}</Counter>}
          {item}
        </ListItem>
      ))}
    </ListStyle>
  );
};

List.propTypes = {
  listItems: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string,
  marker: PropTypes.string,
};

List.defaultProps = {
  type: 'numbered',
  marker: 'circle',
  listItems: [],
};

export default List;
