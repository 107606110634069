import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'components/system/typography';

import * as utility from 'lib/core/utility';

import Tooltip from 'components/core/Tooltip';

const CellTruncateTable = ({
  cell: { value },
  row,
  numberTruncate,
  transformedData,
  onClick,
}) => {
  const { original } = row;
  const handleOnClick = () => {
    if (original) {
      onClick(original);
    }
    return null;
  };
  return (
    <Tooltip title={value} place="top">
      <Text
        size="medium"
        onClick={handleOnClick}
        data-testid={`cell-table-${value}`}
        style={{ cursor: 'pointer', width: '100%', fontWeight: '400' }}
      >
        {transformedData(utility.truncateString(value, numberTruncate) || '-')}
      </Text>
    </Tooltip>
  );
};

CellTruncateTable.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
    ]),
  }),
  numberTruncate: PropTypes.number,
  transformedData: PropTypes.func,
  onClick: PropTypes.func,
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

CellTruncateTable.defaultProps = {
  cell: {
    value: '',
  },
  numberTruncate: 10,
  onClick: () => {},
  transformedData: (text) => text,
};

export default CellTruncateTable;
