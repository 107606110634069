import React from 'react';
import { useDispatch } from 'react-redux';

import { Flex } from 'components/system/core';
import { Container, Content, Header } from 'components/system/interface';

import ClientList from 'components/contexts/notifications/client/ClientList/ClientList';
import Search from 'components/core/Search';

import useDimensions from 'hooks/useDimensions';

import { getFilterParams } from 'lib/contexts/notification';
import { EmailNotificationClientActions } from 'store/emailNotificationsClient/emailNotificationClient.ducks';

const breadcrumb = [
  {
    text: 'Notificações de Clientes',
    href: null,
  },
];

const EmailNotificationsClientList = () => {
  const dimensions = useDimensions();
  const dispatch = useDispatch();

  const listEmailNotificationClient = (payload) => {
    const params = getFilterParams(payload);
    dispatch(
      EmailNotificationClientActions.listEmailNotificationClientFilter(
        params,
        payload,
      ),
    );
  };

  const onChangeContract = (contractPayload) => {
    listEmailNotificationClient(contractPayload);
  };

  const onChangeClient = (clientPayload) => {
    listEmailNotificationClient(clientPayload);
  };

  return (
    <Content
      height={dimensions.height}
      data-testid="email-notification-client-page"
    >
      <Container>
        <Flex>
          <Header
            bread={breadcrumb}
            tag="header"
            title="Notificações de Clientes"
          />
        </Flex>
        <Search
          onChangeClient={onChangeClient}
          onChangeContract={onChangeContract}
        />
        <ClientList />
      </Container>
    </Content>
  );
};

export default EmailNotificationsClientList;
