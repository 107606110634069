import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { Button } from 'components/system/core';
import { Heading } from 'components/system/typography';

import { ReactivateItemsActions } from 'store/reactivateItems/reactivateItems.ducks';

import Modal from 'components/core/modal';
import TextArea from 'components/form/TextArea';

const ReactivationModal = ({ codes, ids, onSubmit }) => {
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    justification: Yup.string()
      .min(15, 'A justificativa deve ter minímo de 15 caracteres')
      .max(150, 'A justificativa deve ter maxima de 150 caracteres')
      .required('A justificativa é um campo requerido'),
  });

  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const closeModal = () => {
    dispatch(ReactivateItemsActions.openReactivateItemModal(false));
  };

  const callback = () => {
    onSubmit();
    dispatch(ReactivateItemsActions.reactivateItemsListRequest());
  };

  const submit = (data) => {
    const { justification } = data;

    const payload = {
      ids,
      justification,
    };

    dispatch(ReactivateItemsActions.reactivateItemRequest(payload, callback));

    closeModal();
  };

  const actions = () =>
    codes.length > 0 && (
      <Button
        data-testid="submit-button-test"
        variant="primary"
        name="CONFIRMAR"
        onClick={handleSubmit(submit)}
      />
    );

  return (
    <Modal
      className="modal"
      title={`Reativar itens (${codes.length})`}
      onClose={closeModal}
      actions={actions}
    >
      {!codes.length ? (
        <Heading tag="h5">Selecione pelo menos um item</Heading>
      ) : (
        <>
          <p>
            Itens:{' '}
            <strong data-testid="items-code-test">{codes.join(', ')}</strong>
          </p>
          <form>
            <Controller
              name="justification"
              defaultValue=""
              rules={{ required: true }}
              control={control}
              render={({ onChange, value }) => (
                <TextArea
                  label={
                    <span>
                      Justificativa <strong>(obrigatório)</strong>
                    </span>
                  }
                  value={value}
                  marginTop={10}
                  width={368}
                  heigth={126}
                  onChange={onChange}
                  hideCount
                  statusMessage={errors.justification?.message}
                  status={errors.justification && 'error'}
                />
              )}
            />
          </form>
        </>
      )}
    </Modal>
  );
};

ReactivationModal.defaultProps = {};

ReactivationModal.propTypes = {
  codes: PropTypes.array.isRequired,
  ids: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ReactivationModal;
