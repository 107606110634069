import React from 'react';

const NoResults = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5603 18.239C13.3277 18.239 14.9557 17.6418 16.2535 16.6382L21.3983 21.7842C21.781 22.167 22.4016 22.167 22.7843 21.7842C23.1671 21.4013 23.1671 20.7806 22.7843 20.3978L17.6396 15.2518C18.643 13.9537 19.24 12.3253 19.24 10.5575C19.24 6.31506 15.8017 2.87592 11.5603 2.87592C7.31894 2.87592 3.88062 6.31506 3.88062 10.5575C3.88062 14.7999 7.31894 18.239 11.5603 18.239ZM18.0876 10.557C18.0876 14.163 15.165 17.0863 11.5598 17.0863C7.95464 17.0863 5.03206 14.163 5.03206 10.557C5.03206 6.95092 7.95464 4.02764 11.5598 4.02764C15.165 4.02764 18.0876 6.95092 18.0876 10.557Z"
        fill="#949494"
      />
      <path
        d="M23.1585 0.886295L1.88672 22.1534"
        stroke="#949494"
        strokeWidth="2"
      />
      <path d="M24.0363 1.765L2.77222 23.0398" stroke="white" />
      <path d="M22.272 -2.27421e-05L1.00024 21.2671" stroke="white" />
    </svg>
  );
};

export default NoResults;
