import { useMsal } from '@azure/msal-react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Microsoft from 'assets/icons/microsoft.svg';

import Modal from 'components/core/modal';

import { AuthActions } from 'store/auth/auth.ducks';

import { history } from 'constants/routes';
import * as ROUTES from 'constants/urls';

import { SET } from 'lib/core/localStorage';
import { apiRequest } from 'lib/core/msalConfig';

import * as S from './styles';

export function AuthModal() {
  const { authModalOpen } = useSelector(({ auth }) => auth);
  const { instance } = useMsal();

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(AuthActions.closeAuthModal());
    history.push(ROUTES.LOGIN);
  };

  const SHOULD_RELOAD_WINDOW = true;

  const handleLogin = async () => {
    try {
      const response = await instance.acquireTokenPopup(apiRequest);
      SET('access-token', response.idToken);

      dispatch(AuthActions.signIn(SHOULD_RELOAD_WINDOW));
      dispatch(AuthActions.closeAuthModal());
    } catch (error) {
      history.push(ROUTES.LOGIN);
      dispatch(AuthActions.closeAuthModal());
    }
  };

  return (
    <>
      {authModalOpen && (
        <Modal title="Sessão expirada" onClose={handleCloseModal}>
          <S.TextModal>
            É preciso se autenticar novamente para continuar o uso do sistema.
          </S.TextModal>
          <S.LoginButton
            variant="primary"
            data-testid="btn-confirm"
            icon={Microsoft}
            name="Entrar com a conta da Microsoft"
            onClick={handleLogin}
          />
          <S.ExitButton
            variant="primary"
            data-testid="btn-confirm"
            name="Sair do sistema"
            onClick={handleCloseModal}
          />
        </Modal>
      )}
    </>
  );
}
