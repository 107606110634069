import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ReportsIcon from 'assets/system/svgs/reports.svg';

import { Icon } from 'components/system/core';

import PriceAndVolumeModal from '../../PriceAndVolumeModal';
import ServiceModal from '../../ServiceModal';

import * as S from './ItemStyles';

const Item = ({ proposalItem, hasServicesToDisplay }) => {
  const [isGroupedSalesModalOpen, setIsGroupedSaleModalOpen] = useState(false);
  const [isServicesModalOpen, setIsServicesModalOpen] = useState(false);

  const handleOpenGroupedSalesModal = () => {
    setIsGroupedSaleModalOpen(true);
  };

  const handleCloseGroupedSalesModal = () => {
    setIsGroupedSaleModalOpen(false);
  };

  const handleOpenServicesModal = () => {
    setIsServicesModalOpen(true);
  };

  const handleCloseServicesModal = () => {
    setIsServicesModalOpen(false);
  };

  return (
    <S.ItemLine>
      <S.Box>
        <S.Title>Produto</S.Title>
        <S.Content>{proposalItem.product || '-'}</S.Content>
      </S.Box>

      <S.Box>
        <S.Title>Origem</S.Title>
        <S.Content>{proposalItem.origin || '-'}</S.Content>
      </S.Box>

      <S.Box>
        <S.Title>Destino</S.Title>
        <S.Content>{proposalItem.destination || '-'}</S.Content>
      </S.Box>

      <S.Box>
        <S.Title>Base de diesel</S.Title>
        <S.Content>{proposalItem.dieselLocal || '-'}</S.Content>
      </S.Box>

      <S.Box>
        <S.Title>Curva preço e volume</S.Title>
        <S.Content>
          <S.Action type="button" onClick={handleOpenGroupedSalesModal}>
            <span>Ver curva</span>
            <Icon src={ReportsIcon} />
          </S.Action>
        </S.Content>
      </S.Box>

      <S.Box>
        <S.Title>Serviços</S.Title>
        <S.Content>
          {hasServicesToDisplay ? (
            <S.Action type="button" onClick={handleOpenServicesModal}>
              <span>Ver abertura</span>
              <Icon src={ReportsIcon} />
            </S.Action>
          ) : (
            <span>-</span>
          )}
        </S.Content>
      </S.Box>

      <PriceAndVolumeModal
        onClose={handleCloseGroupedSalesModal}
        isOpen={isGroupedSalesModalOpen}
        saleId={proposalItem.saleId}
      />

      <ServiceModal
        onClose={handleCloseServicesModal}
        isOpen={isServicesModalOpen}
        saleId={proposalItem.saleId}
      />
    </S.ItemLine>
  );
};

Item.propTypes = {
  proposalItem: PropTypes.object.isRequired,
};

export default Item;
