import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, Divider, EmptyState } from 'components/system/core';

import Loading from 'components/core/Loading';
import Table from 'components/core/Table';

import { ContractReportActions } from 'store/contractReport/contractReport.ducks';

import { GET } from 'lib/core/localStorage';
import { isFinished, isPending } from 'lib/core/request';

import Columns from './Columns';

import { Container, Title } from './styles';

const ContractReportList = () => {
  const dispatch = useDispatch();

  const { contractReportsRequests, contractReportsList } = useSelector(
    ({ contractReport }) => contractReport,
  );

  useEffect(() => {
    const { userId } = GET('userId');

    dispatch(ContractReportActions.listContractReports(userId));
  }, [dispatch]);

  const isLoading = isPending(contractReportsRequests.LIST_CONTRACT_REPORTS);

  return (
    <Container data-testid="table-test">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Title>Relatórios gerados</Title>
          <Divider position="center" />
          {contractReportsList.length > 0 ? (
            <Table
              data-testid="contractReportList-test"
              width="100%"
              footer={false}
              columns={Columns}
              data={contractReportsList}
            />
          ) : (
            isFinished(contractReportsRequests.LIST_CONTRACT_REPORTS) && (
              <Card data-testid="empty-card-test">
                <EmptyState
                  condensed={false}
                  title="Nenhum relatório gerado."
                />
              </Card>
            )
          )}
        </>
      )}
    </Container>
  );
};

export default ContractReportList;
