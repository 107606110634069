import styled from 'styled-components';

import Tokens from 'components/system/tokens';

export const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${Tokens.spacing.md};

  padding: ${Tokens.spacing.xs};
  border-radius: ${({ isRead }) => (isRead ? '8px' : '0')};
  border-bottom: ${({ isRead }) =>
    isRead ? 'none' : `1px solid ${Tokens.colors.blue200}`};
  margin: 0 ${Tokens.spacing.sm};

  background-color: ${({ isRead }) =>
    isRead ? Tokens.colors.gray100 : Tokens.colors.grayWhite};

  & + div {
    margin-top: ${Tokens.spacing.xs};
  }
`;

export const ItemTitle = styled.h4`
  width: 100%;

  font: ${Tokens.fonts.textBoldMedium};
  color: ${Tokens.colors.grayBlack};
`;

export const ItemDescription = styled.p`
  display: -webkit-box;

  text-overflow: ellipsis;
  overflow: hidden;

  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  font: ${Tokens.fonts.textRegularMedium};
  font-weight: 400;
  color: ${({ isRead }) =>
    isRead ? Tokens.colors.gray400 : Tokens.colors.accentPrimaryDark};
`;

export const ItemTime = styled.div`
  margin-top: ${Tokens.spacing.xs};

  font: ${Tokens.fonts.textRegularSmall};
  font-weight: 400;
  color: ${Tokens.colors.gray400};
`;

export const ItemButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonLink = styled.button`
  background: transparent;
  border: none;

  padding: ${Tokens.spacing.xs};

  display: flex;
  cursor: pointer;

  svg {
    transform: scale(1.5);
  }
`;

export const ButtonMark = styled.button`
  background: transparent;
  border: none;

  padding: ${Tokens.spacing.xs};

  display: flex;
  cursor: pointer;

  position: relative;

  &:disabled {
    cursor: unset;
  }

  [data-testid='ds-icon-test'] {
    svg {
      transform: scale(0.7);
    }

    & + [data-testid='ds-icon-test'] {
      position: absolute;
      left: 4px;
      opacity: 0.8;
    }
  }
`;
