import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Flex } from 'components/system/core';
import { Text } from 'components/system/typography';

import { DataFilter, getFilterParams } from 'lib/contexts/contracts';
import * as utility from 'lib/core/utility';
import { AccountManagerActions } from 'store/accountManager/accountManager.ducks';
import { ActiveContractActions } from 'store/activeContracts/activeContracts.ducks';
import { ContractActions } from 'store/contract/contract.ducks';
import { CustomerActions } from 'store/customer/customer.ducks';

import FilterDS from 'components/core/FilterDs';
import InputSelect from 'components/form/InputSelect';

import * as S from './FilterContracts.styles';

const FilterContracts = ({ options, totalTo, totalFrom }) => {
  const dispatch = useDispatch();
  const { accountManagerList } = useSelector(
    ({ accountManager }) => accountManager,
  );
  const { customerList } = useSelector(({ customer }) => customer);

  const { filter } = useSelector(({ contract }) => contract);

  const { activeContractList } = useSelector(
    ({ activeContracts }) => activeContracts,
  );

  useEffect(() => {
    dispatch(AccountManagerActions.listAccountManager());
    dispatch(CustomerActions.listCustomer());
    dispatch(ActiveContractActions.activeContract());
  }, [dispatch]);

  const [viewScenario, setViewScenario] = useState(options[0].value);
  const [dataFilter, setDataFilter] = useState(filter.filterData);

  const pluEntity = 'formalizações';
  const singEntity = 'formalização';
  const pluFromEntity = 'clientes';
  const singFromEntity = 'cliente';

  const filterAction = (filterData, viewScenarioData) => {
    const params = getFilterParams(filterData, viewScenarioData);
    const rawFilter = { filterData, viewScenarioData };

    dispatch(ContractActions.listContract(params, rawFilter));
    dispatch(AccountManagerActions.listAccountManager(viewScenarioData));
    dispatch(CustomerActions.listCustomer(viewScenarioData));
  };
  const onChange = (value) => {
    setViewScenario(value);
    filterAction(dataFilter, value);
  };
  const onConfirm = (data) => {
    setDataFilter(data);
    filterAction(data, viewScenario);
  };
  const onClean = () => {
    setDataFilter({});
    filterAction({}, viewScenario);
  };
  return (
    <Flex justifyContent="space-between">
      <S.WrapperSelectFilter data-testid="filter-contract-test">
        <Text
          size="large"
          style={{
            fontWeight: '400',
          }}
        >
          Total de
        </Text>
        <S.SpaceSelect>
          <InputSelect
            value={viewScenario}
            label=""
            options={options}
            placeholder={options[0].label}
            width="220px"
            onChange={onChange}
          />
        </S.SpaceSelect>
        <div>
          <Text
            size="large"
            style={{
              fontWeight: '400',
            }}
          >
            {utility.transformInfoLengthContracts(
              pluEntity,
              singEntity,
              totalTo,
              totalFrom,
              pluFromEntity,
              singFromEntity,
            )}
          </Text>
        </div>
      </S.WrapperSelectFilter>

      <S.WrapperFilter data-testid="dts-filter">
        <FilterDS
          defaultSelectedItems={dataFilter}
          listItems={DataFilter(
            customerList,
            accountManagerList,
            activeContractList,
          )}
          onConfirm={onConfirm}
          onClean={onClean}
        />
      </S.WrapperFilter>
    </Flex>
  );
};
FilterContracts.defaultProps = {
  totalTo: 0,
  totalFrom: 0,
  options: [
    {
      label: 'Formalizações Vigentes',
      value: 'CurrentContracts',
    },
    {
      label: 'Formalizações Encerradas',
      value: 'ClosedContracts',
    },
  ],
};
FilterContracts.propTypes = {
  totalFrom: PropTypes.number,
  totalTo: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};
export default FilterContracts;
