import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, EmptyState } from 'components/system/core';

import Loading from 'components/core/Loading';
import Table from 'components/core/Table';
import TableContainer from 'components/core/TableContainer';

import { isFinished, isPending } from 'lib/core/request';

import { FormulasActions } from 'store/formulas/formulas.ducks';

import Columns from './ColumnsFormulas';

const Formulas = () => {
  const { formulasRequests, formulasItems } = useSelector(
    ({ formulas }) => formulas,
  );
  const dispatch = useDispatch();

  const loading = isPending(formulasRequests.FORMULAS_REQUEST);

  useEffect(() => {
    dispatch(FormulasActions.formulasItemsRequest());
  }, [dispatch]);

  return (
    <TableContainer>
      {loading && <Loading />}
      {formulasItems?.length > 0 ? (
        <Table
          width="100%"
          columns={Columns}
          data={formulasItems}
          sortBy={['createdAt']}
        />
      ) : (
        isFinished(formulasRequests.FORMULAS_ITEMS_REQUEST) && (
          <Card>
            <EmptyState condensed={false} title="Nenhum item encontrado." />
          </Card>
        )
      )}
    </TableContainer>
  );
};

export default Formulas;
