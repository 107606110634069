import css from '@styled-system/css';
import styled from 'styled-components';
import { compose, flexbox, layout, space } from 'styled-system';

import theme from '../../../tokens';

const { md, xs } = theme.media;

export const MenuItemStyle = styled('li')(
  css({
    bg: theme.colors.grayWhite,
    font: theme.fonts.textRegularMedium,
    color: theme.colors.gray400,
    cursor: 'pointer',
    padding: '8px',
    borderRadius: '4px',
    display: 'flex',
    position: 'relative',
    transition: '0.2s',
    '&.sub-item > .tooltip-wrapper > span': {
      flexDirection: 'column',
    },
    '.tooltip-wrapper': {
      '.tooltip': {
        display: 'none',
        '& > span': {
          color: theme.colors.grayWhite,
          minWidth: '100%',
          position: 'relative',
        },
        [md]: {
          display: 'none',
        },
      },
      span: {
        display: 'flex',
        alignItems: 'center',

        'button.title-item': {
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          border: '0',
          background: 'transparent',
          outline: 'none',
          cursor: 'pointer',
          color: theme.colors.gray400,
        },
        'button.title-item > .sub-item-arrow svg': {
          position: 'absolute',
          right: '10px',
          marginRight: 0,
          transform: 'rotateX(180deg)',
          transition: '0.4s',
        },
        'span > a, button.title-item > span > a': {
          font: theme.fonts.textRegularMedium,
          color: theme.colors.gray400,
          textDecoration: 'none',
          [md]: {
            color: 'transparent',
            width: '100%',
            position: 'absolute',
            height: '100%',
          },
          [xs]: {
            color: theme.colors.gray400,
            position: 'relative',
            '&.active': {
              color: theme.colors.gray400,
            },
          },
        },
        '.item-icon': {
          marginRight: '10px',
          minWidth: '24px',
          [xs]: {
            marginRight: '10px',
          },
        },
        'span, button.title-item > span': {
          position: 'relative',
          font: theme.fonts.textRegularMedium,
          [md]: {
            color: 'transparent',
            width: '100%',
            height: '100%',
          },
          [xs]: {
            color: theme.colors.gray400,
            position: 'relative',
            textAlign: 'left',
            '&.active': {
              color: theme.colors.gray400,
            },
          },
        },
      },
    },
    '&.active': {
      background: theme.colors.accentPrimaryLight,
      color: theme.colors.accentPrimaryDark,
    },
    '&:hover': {
      boxShadow: 'inset 0px 0px 0px 2px #D7E5F4',
    },
    '&.open-sub-item': {
      position: 'absolute',
      top: '0',
      width: '100%',
      zIndex: '1',
      background: 'transparent',
      padding: '0',
      transition: '0.2s',
      display: 'flex',
      alignItems: 'center',
      '.tooltip-wrapper': {
        width: '100%',
        '& > span': {
          alignItems: 'inherit',
        },
      },
      '&:hover': {
        boxShadow: 'none',
      },
      '& > div > div.tooltip': {
        display: 'none',
      },
      '& > div > span > button.title-item': {
        padding: '8px',
        background: theme.colors.accentPrimaryLight,
        borderRadius: '4px',
        '& > span': {
          color: theme.colors.gray400,
          textAlign: 'left',
          marginLeft: '8px',
          position: 'relative',
        },
        '& > svg': {
          marginRight: '10px',
        },
      },
      '& > div > span > button.title-item > .sub-item-arrow svg': {
        transition: '0.4s',
        transform: 'rotateX(0deg)',
      },
      '& > div > span > div.sub-items': {
        width: '100%',
      },
      '& > div > span > div.sub-items > ul.sub-list': {
        display: 'block',
        height: '100%',
        '& > li > span, > li > button > span': {
          position: 'relative',
          color: theme.colors.gray400,
          textAlign: 'left',
        },
      },
      '& > div > span > div > ul > li': {
        color: theme.colors.gray400,
      },
      '& > div > span > div > ul > li.open-sub-item': {
        top: '4px',
        width: '100%',
        padding: '0',
        '& > button.title-item': {
          marginBottom: '7px',
          '& > span': {
            left: '2px',
          },
        },
        '& > span': {
          color: theme.colors.gray400,
          textAlign: 'left',
          marginLeft: '8px',
          position: 'relative',
        },
      },
    },
  }),
  compose(space, flexbox, layout),
);
