import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

export const IconSource = styled(ReactSVG)`
  & > div {
    width: ${(props) => `${props.size}px` || 'auto'};
    height: ${(props) => `${props.size}px` || ''};

    display: flex;

    path {
      fill: ${(props) => `${props.fill}` || 'unset'};
    }
  }
`;
