import styled from 'styled-components';

import { Scrollbar, Timeline } from 'components/system/core';

export const TimelineContainer = styled(Timeline)`
  .vertical-line {
    display: none;
  }
`;

export const ScrollBarContainer = styled(Scrollbar)`
  padding-bottom: 30px;
`;
