import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Flex from '../flex';
import Icon from '../icon';

import { ThemedButton } from './styles';

const Button = ({
  type,
  icon,
  name,
  action,
  variant,
  isLoading,
  cancel,
  isDisabled,
  width,
  children,
  ...attrs
}) => {
  const manageVariant = classNames(variant).split(' ');

  return (
    <ThemedButton
      id={`button-${name}`}
      data-testid={`ds-button-${name}`}
      type={type}
      className={cancel && 'cancel'}
      disabled={isDisabled}
      onClick={action}
      onlyIcon={!name && icon}
      tabIndex="0"
      variant={manageVariant}
      width={width}
      {...attrs}
    >
      <Flex className={!name && 'icon-button'} tabIndex="-1">
        {icon && <Icon src={icon} alt={name} mr={2} />}
        {name}
      </Flex>
      {children}
    </ThemedButton>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  cancel: PropTypes.bool,
  icon: PropTypes.any,
  action: PropTypes.func,
  variant: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  width: PropTypes.string,
  children: PropTypes.any,
};

Button.defaultProps = {
  type: 'button',
  name: '',
  icon: '',
  action: () => {},
  variant: [],
  cancel: false,
  isLoading: false,
  isDisabled: false,
  width: 'fit-content',
  children: '',
};

export default Button;
