import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Date as InputDate } from 'components/system/form';
import Tokens from 'components/system/tokens';

import { EditCellSchema } from 'lib/core/tableCellSchemas';

import * as S from './styles';

const CellEndPeriod = ({ row }) => {
  const { control, errors } = useFormContext();

  return (
    <Controller
      control={control}
      name={`recommendedPriceRequests.${row.index}.endPeriod`}
      render={({ ...props }) => (
        <S.InputContainer
          status={
            errors?.recommendedPriceRequests?.[row.index]?.endPeriod && 'error'
          }
        >
          <InputDate {...props} format="dd/MM/yyyy" locale="pt-BR" />
        </S.InputContainer>
      )}
    />
  );
};

CellEndPeriod.propTypes = EditCellSchema;

CellEndPeriod.defaultProps = {
  cell: {
    value: '',
  },
  row: {
    index: 0,
    original: {
      id: 0,
      editMode: false,
    },
  },
  color: Tokens.colors.grayBlack,
  transformedData: (text) => text,
  align: 'start',
};

export default CellEndPeriod;
