import PropTypes from 'prop-types';
import React from 'react';

import { ToastContainerStyle } from './styles';

const ToastContainer = ({ children, ...attrs }) => (
  <ToastContainerStyle {...attrs}>{children}</ToastContainerStyle>
);

ToastContainer.propTypes = {
  children: PropTypes.node,
};

ToastContainer.defaultProps = {
  children: '',
};

export default ToastContainer;
