import PropTypes from 'prop-types';
import React from 'react';

import Divider from '../../divider';

import { MenuItemContainerStyle, MenuItemStyle } from './styles';

const ItensProfile = ({ onClick, children, active, danger, ...attrs }) => (
  <MenuItemContainerStyle
    data-testid="ds-profile-item"
    className={danger && 'menu-danger'}
    {...attrs}
  >
    <Divider style={{ margin: '8px 0px 12px 0px' }} position="right" />
    <MenuItemStyle
      data-testid="ds-profile-item-button"
      className={danger && 'menu-danger--content'}
      onClick={() => onClick()}
    >
      {children}
    </MenuItemStyle>
  </MenuItemContainerStyle>
);

ItensProfile.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  danger: PropTypes.bool,
  onClick: PropTypes.func,
};

ItensProfile.defaultProps = {
  children: '',
  active: false,
  danger: false,
  onClick: () => {},
};

export default ItensProfile;
