import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Container, Content } from 'components/system/interface';

import Form from 'components/contexts/segments/form/Form';
import Loading from 'components/core/Loading';
import ModalConfirm from 'components/core/ModalConfirm';

import { isPending } from 'lib/core/request';

import useDimensions from 'hooks/useDimensions';

import { SegmentActions } from 'store/segment/segment.ducks';

import * as ROUTES from 'constants/urls';

const EditSegment = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const dimensions = useDimensions();
  const { details, segmentRequests } = useSelector(({ segment }) => segment);

  const [openModal, setOpenModal] = useState(false);
  const [values, setValues] = useState({});

  const breadcrumb = useMemo(() => {
    return [
      {
        text: 'Segmentos',
        href: `#${ROUTES.SEGMENT_LIST}`,
      },
      {
        text: 'Editar segmento',
        href: `#${ROUTES.EDIT_SEGMENT(id)}`,
      },
    ];
  }, [id]);

  useEffect(() => {
    dispatch(SegmentActions.getSegment(id));
  }, [dispatch, id]);

  const onSubmit = (payload) => {
    setValues(payload);
    setOpenModal(true);
  };

  const handleConfirmEdit = useCallback(() => {
    dispatch(SegmentActions.updateSegment({ ...values, id }));

    setOpenModal(false);
  }, [values, dispatch, id]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const isLoading =
    isPending(segmentRequests.GET_SEGMENT) ||
    isPending(segmentRequests.UPDATE_SEGMENT);

  return (
    <Content height={dimensions.height} data-testid="content">
      <Container>
        {isLoading && <Loading />}

        {!isLoading && (
          <Form breadcrumb={breadcrumb} onSubmit={onSubmit} segment={details} />
        )}
      </Container>

      {openModal && (
        <ModalConfirm
          title="Editar segmento"
          closeModal={handleCloseModal}
          confirmAction={handleConfirmEdit}
          message={`Deseja alterar o nome do segmento <b>${details.name}</b>?`}
        />
      )}
    </Content>
  );
};

export default EditSegment;
