import PropTypes from 'prop-types';
import React from 'react';

import BellIcon from 'assets/system/svgs/bell.svg';
import CloseIcon from 'assets/system/svgs/close.svg';

import { Icon, Scrollbar as ScrollBar } from 'components/system/core';

import NotifyItem from './Item';

import * as S from './NotifySidebarStyle';

const NotifySidebar = ({
  notifications,
  isOpen,
  onClose,
  onRedirectNotification,
  onMarkNotificationAsRead,
  unreadNotifications,
  ...attrs
}) => {
  const hasNotifications = notifications?.length > 0;

  return (
    <S.NotifySidebarWrapper
      isOpen={isOpen}
      data-testid="notify-sidebar-test"
      {...attrs}
    >
      <S.NotifySidebarHeader>
        <div>
          <button
            type="button"
            onClick={onClose}
            data-testid="notify-sidebar-button-test"
          >
            <Icon src={CloseIcon} />

            <span className="sr-only">Fechar</span>
          </button>

          <h3>Notificações</h3>
        </div>

        <div>
          <span>Não lidas</span>

          <S.NotifySidebarUnread>
            <Icon src={BellIcon} />
            {unreadNotifications > 0 && (
              <S.NotifySidebarUnreadNumber data-testid="notify-sidebar-unread-test">
                {unreadNotifications}
              </S.NotifySidebarUnreadNumber>
            )}
          </S.NotifySidebarUnread>
        </div>
      </S.NotifySidebarHeader>

      <S.NotifySidebarContent data-testid="notify-sidebar-content-test">
        {hasNotifications ? (
          <ScrollBar
            height="90vh"
            overflow={false}
            overflowX={false}
            overflowY
            width="100%"
          >
            {notifications?.map((notification) => (
              <NotifyItem
                key={notification.id}
                data={notification}
                onRedirectNotification={onRedirectNotification}
                onMarkNotificationAsRead={onMarkNotificationAsRead}
              />
            ))}
          </ScrollBar>
        ) : (
          <S.NotifySidebarEmpty data-testid="notify-sidebar-empty-test">
            <span>Você não tem nenhuma notificação</span>
          </S.NotifySidebarEmpty>
        )}
      </S.NotifySidebarContent>
    </S.NotifySidebarWrapper>
  );
};

NotifySidebar.propTypes = {
  notifications: PropTypes.array,
  unreadNotifications: PropTypes.number,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onRedirectNotification: PropTypes.func,
  onMarkNotificationAsRead: PropTypes.func,
};

NotifySidebar.defaultProps = {
  notifications: [],
  unreadNotifications: 0,
  isOpen: true,
  onClose: () => {},
  onMarkNotificationAsRead: () => {},
  onRedirectNotification: () => {},
};

export default NotifySidebar;
