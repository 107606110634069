import PropTypes from 'prop-types';
import React from 'react';

import { ReactComponent as ErrorIcon } from 'assets/icons/accordion/error.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/accordion/success.svg';

import { Text } from 'components/system/typography';

import Tooltip from 'components/core/Tooltip';

const CellDefaultFlag = ({ cell: { value }, row, onClick }) => {
  const { original } = row;

  const handleOnClick = () => {
    if (original) {
      onClick(original);
    }
  };

  const tooltipContent = original?.situationReason || '';

  return (
    <Tooltip title={tooltipContent} place="top" data-testid="flag-tooltip">
      <Text
        size="medium"
        data-testid="flag-tooltip-text"
        style={{ cursor: 'pointer', textAlign: 'center' }}
        onClick={handleOnClick}
      >
        {value ? <SuccessIcon /> : <ErrorIcon />}
      </Text>
    </Tooltip>
  );
};

CellDefaultFlag.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  onClick: PropTypes.func,
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

CellDefaultFlag.defaultProps = {
  cell: {
    value: '',
  },
  onClick: () => {},
};

export default CellDefaultFlag;
