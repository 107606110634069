import PropTypes from 'prop-types';
import React from 'react';

import Divider from '../../divider';

import { DialogContentStyle } from '../styles';

const DialogContent = ({ children, dialogBox, ...attrs }) => (
  <DialogContentStyle className="dialog-box-container" {...attrs}>
    <Divider
      style={{ marginBottom: '8px', marginTop: '0px' }}
      position="center"
    />
    {children}
  </DialogContentStyle>
);

DialogContent.propTypes = {
  children: PropTypes.node,
  dialogBox: PropTypes.bool,
};

DialogContent.defaultProps = {
  children: '',
  dialogBox: false,
};
export default DialogContent;
