import css from '@styled-system/css';
import styled from 'styled-components';

import Tokens from 'components/system/tokens';

export const CardHeader = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;
export const ContainerForm = styled.div`
  margin-top: ${Tokens.spacing.xs};
`;
export const ContainerButton = styled.div`
  margin-top: auto;
`;
export const GroupTitle = styled('span')(
  css({
    display: 'flex',
    userSelect: 'none',
    alignItems: 'center',
    font: Tokens.fonts.textRegularSmall,
    color: Tokens.colors.gray400,
    height: '32px',
  }),
);
export const Label = styled('span')(
  css({
    display: 'flex',
    userSelect: 'none',
    alignItems: 'center',
    font: Tokens.fonts.textRegularSmall,
    color: Tokens.colors.gray400,
    marginBottom: '5px',
  }),
);
export const Group = styled('ul')(
  css({
    position: 'relative',
    userSelect: 'none',
    listStyle: 'none inside none',
    margin: '0 0 16px 0',
    padding: 0,
    display: 'flex',
    paddingRight: 2,
  }),
);

export const MessageErrors = styled.div`
  position: absolute;
`;

export const ContainerDate = styled.div`
  width: 130px;
  .react-date-picker__wrapper {
    min-width: 130px !important;
  }
`;
