import css from '@styled-system/css';
import styled from 'styled-components';
import { compose, layout, variant } from 'styled-system';

import theme from '../../tokens';

export const DividerStyle = styled('div')(
  css({
    marginTop: '12px',
    marginBottom: '12px',
    hr: {
      width: 'calc(100% + 24px)',
      transform: 'translateX(-12px)',
      bg: theme.colors.gray200,
      border: 'none',
      opacity: '0.5',
      height: '1px',
    },
  }),
  variant({
    variants: {
      left: {
        hr: {
          width: 'calc(100% + 12px)',
        },
      },
      right: {
        hr: {
          width: 'calc(100% + 12px)',
          transform: 'translateX(0px)',
        },
      },
      center: {
        hr: {
          width: '100%',
          transform: 'translateX(0px)',
        },
      },
      horizontal: {
        hr: {
          height: '1px',
        },
      },
      vertical: {
        hr: {
          height: '100%',
          width: '1px',
        },
      },
    },
  }),
  compose(layout),
);
