import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Button, Card, Flex } from 'components/system/core';
import { Checkbox, Input } from 'components/system/form';
import { Column as Col, Header } from 'components/system/interface';

import { history } from 'constants/routes';
import * as ROUTES from 'constants/urls';

import * as utility from 'lib/core/utility';

import ModalConfirm from 'components/core/ModalConfirm';

const Form = ({ breadcrumb, onSubmit, supplier }) => {
  const { handleSubmit, errors, control, setValue, getValues } = useForm({
    defaultValues: {
      name: '',
      hasRefinary: false,
      hasStation: false,
    },
  });

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (supplier) {
      setValue('name', utility.capitalizeFirstString(supplier.name));
      setValue('hasRefinary', supplier.hasRefinary);
      setValue('hasStation', supplier.hasStation);
    }
  }, [supplier, setValue]);

  const handleNavigateBack = () => {
    history.push(ROUTES.SUPPLIER_LIST);
  };

  const handleOpenConfirmationModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleConfirm = () => {
    setModalOpen(false);
    const values = getValues();
    onSubmit(values);
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleOpenConfirmationModal)}>
        <Flex justifyContent="space-between" alignItems="center">
          <Header bread={breadcrumb} tag="header" title="Fornecedor" />

          <Flex justifyContent="space-between" alignItems="center">
            <Button
              onClick={handleNavigateBack}
              name="Cancelar"
              type="button"
              variant="tertiary"
              style={{ marginRight: '5px' }}
              data-testid="cancel-button"
            />
            <Button name="Salvar" type="submit" data-testid="save-button" />
          </Flex>
        </Flex>

        <Card>
          <Col desktop={4} style={{ marginRight: '10px' }}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="name"
              render={({ onChange, value }) => (
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="Nome do fornecedor"
                  label="Nome do fornecedor"
                  type="text"
                  maxLength="20"
                  required
                  status={errors.name && 'error'}
                  statusMessage={errors.name && 'Campo requerido'}
                  data-testid="input-name"
                />
              )}
            />
          </Col>

          <Col desktop={0.9}>
            <Controller
              control={control}
              name="hasStation"
              render={({ onChange, value }) => (
                <Checkbox
                  checked={value}
                  onChange={(evt) => onChange(evt.target.checked)}
                  label="Posto"
                  data-testid="checkbox-hasStation"
                  style={{ paddingTop: '1.8rem' }}
                />
              )}
            />
          </Col>

          <Col desktop={1.25}>
            <Controller
              control={control}
              name="hasRefinary"
              render={({ onChange, value }) => (
                <Checkbox
                  checked={value}
                  onChange={(evt) => onChange(evt.target.checked)}
                  label="Refinaria"
                  data-testid="checkbox-hasRefinary"
                  style={{ paddingTop: '1.8rem' }}
                />
              )}
            />
          </Col>
        </Card>
      </form>
      {modalOpen && (
        <ModalConfirm
          closeModal={handleCloseModal}
          confirmAction={handleConfirm}
          title={supplier ? 'Editar fornecedor' : 'Criar fornecedor'}
          message="Os impactos dessas informações serão refletidos na tela de cadastro de carta de diesel."
        />
      )}
    </>
  );
};

Form.propTypes = {
  breadcrumb: PropTypes.array,
  onSubmit: PropTypes.func,
  supplier: PropTypes.object,
};

Form.defaultProps = {
  breadcrumb: [{ href: '#' }],
  onSubmit: () => {},
  supplier: null,
};

export default Form;
