import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Card, Flex } from 'components/system/core';
import { Checkbox, Input } from 'components/system/form';
import { Column as Col, Row } from 'components/system/interface';
import Tokens from 'components/system/tokens';
import { Text } from 'components/system/typography';

import Loading from 'components/core/Loading';
import Multiselect from 'components/form/CustomMultiselect';
import InputDate from 'components/form/inputDate';

import { ContractReportActions } from 'store/contractReport/contractReport.ducks';
import { SegmentActions } from 'store/segment/segment.ducks';

import {
  getMultiselectOptions,
  validateDates,
} from 'lib/contexts/contractReport';
import { GET } from 'lib/core/localStorage';
import { isPending } from 'lib/core/request';

import {
  ButtonContainer,
  CheckboxContainer,
  ContainerInputDate,
  MessageError,
} from './styles';

const Form = () => {
  const dispatch = useDispatch();

  const { segmentProfileList: segmentList, segmentRequests } = useSelector(
    ({ segment }) => segment,
  );

  const { contractReportsRequests } = useSelector(
    ({ contractReport }) => contractReport,
  );

  const contractOptions = [
    { name: 'Contratos Ativos', id: 1 },
    { name: 'Contratos Encerrados', id: 2 },
  ];

  const [selectedSegments, setSelectedSegments] = useState([]);
  const [selectedContractOption, setSelectedContractOption] = useState([]);
  const [startDateInputValue, setStartDateInputValue] = useState(undefined);
  const [endDateInputValue, setEndDateInputValue] = useState(undefined);
  const [dateErrors, setDateErrors] = useState({});

  useEffect(() => {
    dispatch(SegmentActions.listSegmentActive());
  }, [dispatch]);

  const { handleSubmit, errors, control, reset } = useForm({
    defaultValues: {
      name: '',
      segmentIds: [],
      startDate: null,
      endDate: null,
      showInactiveItems: false,
      contractOptions: [],
    },
  });

  const submit = async (values) => {
    const valid = await validateDates({
      startDate: startDateInputValue,
      endDate: endDateInputValue,
    });

    if (!valid.isValid) {
      setDateErrors(valid.errors);
      return;
    }

    const showActiveContracts = !!values.contractOptions.find(
      ({ value }) => value.id === 1,
    );
    const showInactiveContracts = !!values.contractOptions.find(
      ({ value }) => value.id === 2,
    );

    reset({
      name: '',
      segmentIds: [],
      startDate: null,
      endDate: null,
      showInactiveItems: false,
      contractOptions: [],
    });

    setDateErrors({});
    setSelectedSegments([]);
    setSelectedContractOption([]);

    const { userId } = GET('userId');

    dispatch(
      ContractReportActions.createContractReport({
        ...values,
        segmentIds: selectedSegments.length <= 0 ? null : selectedSegments,
        showActiveContracts,
        showInactiveContracts,
        userId,
      }),
    );
  };

  const handleMultiSelectChange = (name, values, onChange) => {
    onChange(values);

    switch (name) {
      case 'segmentIds':
        setSelectedSegments(values.map(({ value }) => value.id));
        break;

      case 'contractOptions':
        setSelectedContractOption(values.map(({ value }) => value.id));
        break;

      default:
        break;
    }
  };

  const handleMinDate = () => {
    return new Date(startDateInputValue);
  };

  const handleMaxDate = () => {
    return new Date(endDateInputValue);
  };

  const segmentListItems = getMultiselectOptions(segmentList, selectedSegments);
  const contractListItems = getMultiselectOptions(
    contractOptions,
    selectedContractOption,
  );

  const isLoading =
    isPending(segmentRequests?.LIST_SEGMENT_ACTIVE) ||
    isPending(contractReportsRequests?.CREATE_CONTRACT_REPORT);

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && (
        <form onSubmit={handleSubmit(submit)} data-testid="form">
          <Card
            title="Gerar relatório"
            headerStyle={{
              color: Tokens.colors.grayBlack,
              fontSize: '1rem',
            }}
          >
            <Row>
              <Flex alignItems="start">
                <Col desktop={2.9}>
                  <Controller
                    control={control}
                    name="segmentIds"
                    render={({ onChange, name }) => (
                      <Multiselect
                        width="100%"
                        required
                        name="segmentIds"
                        keyValue="id"
                        placeholder="Insira os segmentos"
                        label="Segmento"
                        listItems={segmentListItems}
                        onChange={(values) =>
                          handleMultiSelectChange(name, values, onChange)
                        }
                      />
                    )}
                  />
                </Col>

                <Col desktop={2}>
                  <Controller
                    control={control}
                    name="startDate"
                    render={({ value, onChange, name }) => (
                      <ContainerInputDate
                        error={dateErrors && dateErrors[name]}
                      >
                        <InputDate
                          label="Período inicial"
                          format="dd/MM/yyyy"
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            setStartDateInputValue(e ? new Date(e) : e);
                          }}
                          maxDate={handleMaxDate()}
                          data-testid="startDate-input"
                        />
                        {dateErrors && dateErrors[name] && (
                          <MessageError data-testid="startDate-error-input">
                            <Text color={Tokens.colors.red400}>
                              Campo requerido
                            </Text>
                          </MessageError>
                        )}
                      </ContainerInputDate>
                    )}
                  />
                </Col>

                <Col desktop={2}>
                  <Controller
                    control={control}
                    name="endDate"
                    render={({ value, onChange, name }) => (
                      <ContainerInputDate
                        error={dateErrors && dateErrors[name]}
                      >
                        <InputDate
                          label="Período final"
                          format="dd/MM/yyyy"
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            setEndDateInputValue(e ? new Date(e) : e);
                          }}
                          minDate={handleMinDate()}
                        />
                        {dateErrors && dateErrors[name] && (
                          <MessageError>
                            <Text color={Tokens.colors.red400}>
                              Campo requerido
                            </Text>
                          </MessageError>
                        )}
                      </ContainerInputDate>
                    )}
                  />
                </Col>

                <Col desktop={2.9}>
                  <Controller
                    control={control}
                    name="name"
                    rules={{ required: true }}
                    render={({ onChange, value }) => (
                      <Input
                        value={value}
                        onChange={onChange}
                        placeholder="Nome do relatório"
                        label="Nome do relatório"
                        type="text"
                        maxLength="100"
                        required
                        status={errors.name && 'error'}
                        statusMessage={errors.name && 'Campo requerido'}
                        data-testid="input-name"
                      />
                    )}
                  />
                </Col>

                <Col desktop={2}>
                  <Controller
                    control={control}
                    name="contractOptions"
                    render={({ onChange, name }) => (
                      <Multiselect
                        width="100%"
                        required
                        name="contractOptions"
                        keyValue="id"
                        placeholder="Selecione o estado dos contratos"
                        label="Contratos"
                        listItems={contractListItems}
                        onChange={(values) =>
                          handleMultiSelectChange(name, values, onChange)
                        }
                      />
                    )}
                  />
                </Col>

                <Col desktop={2}>
                  <Controller
                    control={control}
                    name="showInactiveItems"
                    render={({ onChange, value }) => (
                      <CheckboxContainer>
                        <Checkbox
                          checked={value}
                          onChange={() => onChange(!value)}
                          label="Exibir itens inativos"
                          status={errors.showInactiveItems && 'error'}
                          statusMessage={
                            errors.showInactiveItems && 'Campo requerido'
                          }
                          data-testid="input-showInactiveItems"
                        />
                      </CheckboxContainer>
                    )}
                  />
                </Col>
              </Flex>
            </Row>

            <Row>
              <ButtonContainer desktop={1.25}>
                <Button
                  name="Exportar"
                  type="submit"
                  data-testid="submit-button"
                />
              </ButtonContainer>
            </Row>
          </Card>
        </form>
      )}
    </>
  );
};

export default Form;
