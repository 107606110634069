import styled from 'styled-components';

import Tokens from 'components/system/tokens';

export const Comment = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Tokens.spacing.xs};
  align-items: flex-start;

  padding-bottom: ${Tokens.spacing.sm};
  border-bottom: 1px solid ${Tokens.colors.gray100};

  &:last-child {
    border-bottom: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: ${Tokens.spacing.xs};
`;

export const User = styled.span`
  font: ${Tokens.fonts.textBoldSmall};
  color: ${Tokens.colors.gray500};
`;

export const Time = styled.span`
  font: ${Tokens.fonts.textRegularSmall};
  font-weight: 400;

  color: ${Tokens.colors.gray300};
`;

export const Activity = styled.span`
  font: ${Tokens.fonts.textRegularSmall};
  font-weight: 400;

  color: ${Tokens.colors.accentSecondaryDark};
`;

export const Message = styled.p`
  font: ${Tokens.fonts.textRegularMedium};
  font-weight: 400;
  word-break: break-all;

  color: ${Tokens.colors.gray400};

  button {
    appearance: none;
    background: transparent;
    border: none;

    font: ${Tokens.fonts.textBoldMedium};
    color: ${Tokens.colors.accentPrimaryDark};
    padding: ${Tokens.spacing.xss};
    text-transform: uppercase;
    cursor: pointer;
  }
`;
