import css from '@styled-system/css';
import styled from 'styled-components';
import { compose, flexbox, layout, space } from 'styled-system';

export const Container = styled('div')(
  css({
    position: 'fixed',
    margin: 'auto',
    left: 0,
    top: 0,
    height: '100vh',
    width: '100vw',
    zIndex: 1000,
    overflow: 'scroll',
    backgroundColor: 'rgba(0,0,0,0.4)',
  }),
  ({ open }) => (open ? 'display: block;' : 'display: none;'),
);

export const ItemContainer = styled('div')(
  css({
    position: 'relative',
    margin: '30px auto',
    height: 'fit-content',
    width: 'fit-content',
  }),
  compose(flexbox, layout, space),
);

export const CloseButton = styled('button')(
  css({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: '30px',
    left: '95%',
    cursor: 'pointer',
    width: '28px',
    height: '28px',
    border: 'none',
    borderRadius: '50%',
    filter: 'drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.12))',
    background: '#FFFFFF',
  }),
);
