import css from '@styled-system/css';
import styled from 'styled-components';

import theme from '../../../tokens';

export const ArrowButton = styled('button')(
  css({
    background: theme.colors.grayWhite,
    position: 'absolute',
    outline: 0,
    transition: 'all .5s',
    borderRadius: '35px',
    zIndex: 1000,
    border: 0,
    minWidth: '28px',
    minHeight: '28px',
    opacity: 1,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > svg > path': {
      stroke: theme.colors.accentPrimaryDark,
    },
    boxShadow: theme.effects.cardShadow,
    '&:hover': {
      boxShadow: theme.effects.buttonHover,
    },
    '&:focus': {
      boxShadow: `${theme.effects.buttonFocus}, ${theme.effects.cardShadow}`,
    },
    '&:hover:focus': {
      boxShadow: `${theme.effects.buttonFocus}, ${theme.effects.buttonHover}`,
    },
  }),
  ({ left }) => (left ? 'left: calc(4% + 1px)' : 'right: calc(4% + 1px)'),
);
