import PropTypes from 'prop-types';
import React from 'react';

import EmptyStateIcon from 'assets/system/svgs/emptyState';
import NoResultsIcon from 'assets/system/svgs/noResults';

import { Heading, Text } from '../../typography';

import {
  ContentStyle,
  EmptyStateBox,
  EmptyStateContainer,
  IconStyle,
} from './styles';

const EmptyState = ({ condensed, title, description, ...attrs }) => (
  <EmptyStateContainer id="empty-state" data-testid="ds-empty-state" {...attrs}>
    <EmptyStateBox condensed={condensed}>
      <IconStyle condensed={condensed}>
        {condensed ? <NoResultsIcon /> : <EmptyStateIcon />}
      </IconStyle>
      <ContentStyle condensed={condensed}>
        <Heading tag="h2">{title}</Heading>
        {!condensed && <Text>{description}</Text>}
      </ContentStyle>
    </EmptyStateBox>
  </EmptyStateContainer>
);

EmptyState.propTypes = {
  condensed: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
};

EmptyState.defaultProps = {
  condensed: false,
  title: 'Nenhum resultado encontrado.',
  description: '',
};

export default EmptyState;
