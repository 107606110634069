import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Flex } from 'components/system/core';
import {
  Column as Col,
  Container,
  Content,
  Header,
  Row,
} from 'components/system/interface';

import DieselCard from 'components/contexts/macroeconomicIndices/DieselCard';
import DieselForm from 'components/contexts/macroeconomicIndices/DieselForm';

import { DieselActions } from 'store/diesel/diesel.ducks';

import useDimensions from 'hooks/useDimensions';

import * as ROUTES from 'constants/urls';

const ReadjustmentDiesel = () => {
  const dimensions = useDimensions();
  const dispatch = useDispatch();
  const breadcrumb = [
    {
      text: 'Índices Macroeconômicos',
      href: `#${ROUTES.MACROECONOMIC_INDICES_HISTORY}`,
    },
  ];

  useEffect(() => {
    dispatch(DieselActions.listMacrobase());
  }, [dispatch]);

  const { suppliersList } = useSelector(({ diesel }) => diesel);

  return (
    <Content data-testid="content" height={dimensions.height}>
      <Container>
        <Flex justifyContent="space-between" alignItems="center">
          <Header
            bread={breadcrumb}
            tag="header"
            title="Atualização de índices"
          />
        </Flex>
        <Flex>
          <Row>
            <Col desktop="12">
              <DieselCard />
              <Flex>
                <Row>
                  <Col desktop="12">
                    {suppliersList.length > 0 && <DieselForm />}
                  </Col>
                </Row>
              </Flex>
            </Col>
          </Row>
        </Flex>
      </Container>
    </Content>
  );
};
export default ReadjustmentDiesel;
