import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Button } from 'components/system/core';
import { Input } from 'components/system/form';

import Modal from 'components/core/modal';
import { SpacingTop } from 'components/core/Spacing';

import { getFieldsEdit, isItemTypeInflation } from 'lib/contexts/indices';

const IndicesHistoryEdit = ({ callBack, item }) => {
  const [percentage, setPercentage] = useState(
    item.percentage ? item.percentage : '',
  );

  const callBackAction = async (actionParams) => {
    const fields = getFieldsEdit(item, percentage);
    callBack({
      fields,
      action: actionParams,
    });
  };

  const actions = () => (
    <>
      <Button
        variant="secondary"
        name="NÃO"
        onClick={() => {
          callBackAction(false);
        }}
      />

      <Button
        variant="primary"
        name="ATUALIZAR"
        disabled={percentage === ''}
        onClick={() => {
          callBackAction(true);
        }}
      />
    </>
  );

  return (
    <Modal
      className="modal"
      title="Editar Indíce"
      yesTitle="ATUALIZAR"
      onClose={callBackAction}
      actions={actions}
    >
      <div>
        É possível editar o indíce {item.name} de <b>{item.dateTooltip}</b>
        {isItemTypeInflation(item) && (
          <SpacingTop xs>
            <p>
              Ao editar Inflação, as formalizações poderão sofrer
              interferências.
              <p>
                Favor verificar se há a necessidade e estar ciente dos impactos
                para reajustes já gerados.
              </p>
            </p>
          </SpacingTop>
        )}
      </div>

      <SpacingTop md>
        <Input
          name="percentage"
          label={item.name}
          value={percentage}
          status={`${percentage === '' ? 'error' : 'success'}`}
          statusMessage={`${percentage === '' ? 'Campo obrigatório' : ''}`}
          onChange={setPercentage}
          required
        />
      </SpacingTop>
    </Modal>
  );
};

IndicesHistoryEdit.propTypes = {
  item: PropTypes.object,
  callBack: PropTypes.func,
};

IndicesHistoryEdit.defaultProps = {
  item: {},
  callBack: () => {},
};

export default IndicesHistoryEdit;
