import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Input } from 'components/system/form';

import { monthsData } from 'lib/contexts/newItems';
import handleInputMask from 'lib/core/handleInputMask';
import { optionsMonths } from 'lib/core/utility';

import { TableContainer } from './TableRate.styles';

const TableRate = ({ data, sendData }) => {
  const [months, setMonths] = useState(data.length > 0 ? data : monthsData);

  const handleChange = (e, i) => {
    setMonths(
      months.map((month) =>
        month.month === i ? { ...month, value: e } : month,
      ),
    );
  };

  sendData(months);

  return (
    <TableContainer width="100%" data-testid="table-rate">
      <thead>
        <tr>
          {optionsMonths.map((month) => (
            <th key={`th-month-${month.label}`}>{month.label}</th>
          ))}
        </tr>
      </thead>

      <tbody>
        <tr>
          {months.map((m) => (
            <td key={`td-month-${m.month}`}>
              <Input
                name={m.month.toString()}
                size="medium"
                data-testid={`cell-table-${m.month}`}
                style={{ cursor: 'pointer', textAlign: 'start' }}
                minLength={5}
                onChange={(e) => handleChange(e, m.month)}
                value={m.value}
                prefix="R$"
                type="input"
                onInput={(event) => handleInputMask(event, 'decimal')}
              />
            </td>
          ))}
        </tr>
      </tbody>
    </TableContainer>
  );
};

TableRate.propTypes = {
  data: PropTypes.array,
  sendData: PropTypes.func,
};

TableRate.defaultProps = {
  data: [],
  sendData: () => {},
};

export default TableRate;
