import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Flex } from 'components/system/core';

import { DataFilter, getFilterParams } from 'lib/contexts/user';

import { ProfileActions } from 'store/profile/profile.ducks';
import { SegmentActions } from 'store/segment/segment.ducks';
import { UserActions } from 'store/user/user.ducks';

import FilterDS from 'components/core/FilterDs';

import * as S from './FilterUsers.styles';

const FilterUsers = () => {
  const dispatch = useDispatch();
  const { segmentProfileList: segmentList } = useSelector(
    ({ segment }) => segment,
  );
  const { data: profiles } = useSelector(({ profile }) => profile);
  const { data: users } = useSelector(({ user }) => user);

  useEffect(() => {
    dispatch(ProfileActions.fetchProfiles());
    dispatch(SegmentActions.listSegmentActive());
  }, [dispatch]);

  const [dataFilter, setDataFilter] = useState({});

  const filterAction = (filterData) => {
    const { pageSize, pageIndex } = users;

    const data = getFilterParams({ ...filterData, pageIndex, pageSize });
    dispatch(UserActions.fetchUsers(data, filterData));
  };
  const onConfirm = (data) => {
    filterAction(data);
    setDataFilter(data);
  };
  const onClean = () => {
    setDataFilter({});
    filterAction({});
  };
  return (
    <Flex justifyContent="space-between">
      <S.WrapperFilter data-testid="dts-filter">
        <FilterDS
          defaultSelectedItems={dataFilter}
          listItems={DataFilter(segmentList, profiles)}
          onConfirm={onConfirm}
          onClean={onClean}
          btnMtop="0px"
          btnMright="8px"
          withText={false}
        />
      </S.WrapperFilter>
    </Flex>
  );
};
FilterUsers.defaultProps = {};
FilterUsers.propTypes = {};
export default FilterUsers;
