import styled from 'styled-components';

import { Column as Col } from 'components/system/interface';
import Tokens from 'components/system/tokens';

export const ContainerInputDate = styled.div`
  ${({ error }) =>
    error &&
    `div > div.react-date-picker__wrapper {
  border: 1px solid ${Tokens.colors.red400};
}`}
`;

export const MessageError = styled.div`
  position: absolute;
  margin-top: 0.35rem;
`;

export const ButtonContainer = styled(Col)`
  display: flex;
  justify-content: center;
  align-self: flex-end;
  padding-top: 1.6rem;
`;

export const CheckboxContainer = styled.div`
  margin-top: 1rem;
`;
