import css from '@styled-system/css';
import styled from 'styled-components';
import { variant } from 'styled-system';

import theme from '../../tokens';

export const BackToTopStyle = styled('div')(
  css({
    width: '48px',
    height: '48px',
    position: 'fixed',
    borderRadius: '100%',
    bg: theme.colors.grayWhite,
    boxShadow: theme.effects.cardShadow,
    cursor: 'pointer',
    transition: '0.3s',
    zIndex: '100',
    right: theme.spacing.md,
    top: `calc(100vh - ${theme.spacing.md})`,
    transform: 'translateY(-100%) scaleX(1) scaleY(1)',
    opacity: 1,

    svg: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  }),
  variant({
    variants: {
      active: {
        bg: 'rgba(0, 0, 0, 0.12)',
        cursor: 'not-allowed',
      },
      top: {
        opacity: 0,
        bg: 'rgba(0, 0, 0, 0.12)',
        transform: 'translateY(-100%) scaleX(0) scaleY(0)',
      },
    },
  }),
);
