import PropTypes from 'prop-types';
import React from 'react';

import LoadingPrimary from 'assets/system/svgs/loading/primary.svg';
import LoadingSecondary from 'assets/system/svgs/loading/secondary.svg';
import Logo from 'assets/system/svgs/logo.svg';

import { Heading } from '../../typography';
import Icon from '../icon';

import { Container, LoadingStyle, RotateContainerLoading } from './styles';

const Loading = ({ title, message, type, fitParent, size }) => {
  const renderType = () => {
    switch (type) {
      case 'primary':
        return (
          <>
            <RotateContainerLoading
              size={size || '80px'}
              data-testid="ds-loading-primary"
            >
              <Icon src={LoadingPrimary} size={size || 80} />
            </RotateContainerLoading>
            <Heading tag="h4" color="#336EAE" fontWeight="600">
              {title}
            </Heading>
            <Heading tag="span" size={14} color="#949494">
              {message}
            </Heading>
          </>
        );
      case 'secondary':
        return (
          <>
            <RotateContainerLoading
              size={size || '40px'}
              data-testid="ds-loading-secondary"
            >
              <Icon src={LoadingSecondary} size={size || 40} />
            </RotateContainerLoading>
          </>
        );
      default:
        return <Icon src={Logo} data-testid="ds-loading-default" />;
    }
  };

  return (
    <LoadingStyle data-testid="ds-loading" fitParent={fitParent}>
      <Container>{renderType()}</Container>
    </LoadingStyle>
  );
};

Loading.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.string,
  fitParent: PropTypes.bool,
  size: PropTypes.string,
};

Loading.defaultProps = {
  title: '',
  message: '',
  type: 'primary',
  fitParent: false,
  size: '',
};

export default Loading;
