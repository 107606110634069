import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as IcDownload } from 'assets/icons/download.svg';

import { Accordion, Button, Flex } from 'components/system/core';

import { REQUEST_PENDING } from 'constants/request';
import { ModelsActions, ModelsTypes } from 'store/model/model.ducks';

import FileInput from './FileInput';

const AccordionTemplateDownload = ({ name, templateType, fileName }) => {
  const dispatch = useDispatch();

  const { templatesRequests, templateLoading } = useSelector(
    ({ model }) => model,
  );

  const uploadCallback = () => dispatch(ModelsActions.templatesRequest());

  const handleUpload = (event) => {
    const [file] = event.target.files;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('templateType', templateType);
    dispatch(
      ModelsActions.templateUploadRequest(
        formData,
        templateType,
        uploadCallback,
      ),
    );
  };

  const handleDownload = () => {
    dispatch(ModelsActions.templateDownloadRequest({ templateType, fileName }));
  };

  return (
    <Accordion title={name} width="100%" mb={4}>
      <Flex justifyContent="space-between" alignItems="center">
        <FileInput
          id={`file_${templateType}`}
          fileName={fileName}
          onChange={handleUpload}
          isLoading={
            templateLoading === templateType &&
            templatesRequests[ModelsTypes.TEMPLATE_UPLOAD_REQUEST] ===
              REQUEST_PENDING
          }
        />
        <Button
          action={handleDownload}
          icon={IcDownload}
          name="Baixar planilha mais recente"
          type="button"
          variant="primary"
          width="fit-content"
        />
      </Flex>
    </Accordion>
  );
};

AccordionTemplateDownload.propTypes = {
  name: PropTypes.string.isRequired,
  templateType: PropTypes.number.isRequired,
  fileName: PropTypes.string.isRequired,
};

export default AccordionTemplateDownload;
