import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DownloadIcon from 'assets/icons/download.svg';
import EditItemIcon from 'assets/icons/edit-item.svg';
import ReportIcon from 'assets/icons/report.svg';
import OptionIcon from 'assets/icons/table/options.svg';
import CheckIcon from 'assets/system/svgs/check.svg';
import CloseIcon from 'assets/system/svgs/close.svg';
import TrashIcon from 'assets/system/svgs/trash.svg';

import { Button, Icon, Popover } from 'components/system/core';
import { Text } from 'components/system/typography';

import {
  canCloseContract,
  canEditContract,
  getContractStatus as contractIsClosed,
  getDieselReportParams,
  hasPermissionToDeleteContract,
  hasPermissionToDownloadContract,
} from 'lib/contexts/contracts';
import { ContractActions } from 'store/contract/contract.ducks';

import * as S from 'components/core/Table/CellWithActions/CellWithActions.styles';

const CellWithActions = ({ row }) => {
  const { original } = row;

  const dispatch = useDispatch();
  const { changeEdit } = useSelector(({ contract }) => contract);

  const handleEdit = () => {
    dispatch(ContractActions.setEditRow({ row: original, editMode: true }));
  };

  const confirmEdit = () => {
    if (changeEdit) {
      dispatch(
        ContractActions.setEditModalOpen({
          modalOpen: true,
        }),
      );

      return;
    }

    dispatch(ContractActions.setEditRow({ row: {}, editMode: false }));
  };

  const handleExportContract = (type) => {
    dispatch(ContractActions.exportContract({ row: original, type }));
  };

  const handleGrainReport = () => {
    const startDate = original?.startPeriod;
    const endDate = original?.endPeriod;
    const contractId = original?.id;

    if (startDate && endDate && contractId) {
      const params = getDieselReportParams(startDate, endDate, contractId);

      dispatch(
        ContractActions.exportDieselReport({
          row: {
            ...row.values,
            startDate: new Date(startDate),
            endDate: new Date(endDate),
          },
          type: 0,
          formParams: params,
        }),
      );
    }
  };

  const handleOpenDieselReportModal = () => {
    if (original?.isGrainSegment) {
      handleGrainReport();
    } else {
      dispatch(
        ContractActions.setReportModalOpen({ row: original, modalOpen: true }),
      );
    }
  };

  const handleOpenCloseContractModal = () => {
    dispatch(
      ContractActions.setJustifyModalOpen({
        row: original,
        modalOpen: true,
      }),
    );
  };

  const handleOpenDeleteModal = () => {
    dispatch(
      ContractActions.setDeleteModalOpen({
        row: original,
        modalOpen: true,
      }),
    );
  };

  const handleDownloadSpreadsheet = () => {
    dispatch(
      ContractActions.downloadSpreadsheet({
        contractId: original.id,
        filename: 'contratos.zip',
      }),
    );
  };

  const handleOpenDefaultFlag = () => {
    dispatch(
      ContractActions.setDefaultFlagModalOpen({
        row: original,
        modalOpen: true,
      }),
    );
  };

  const CellActions = {
    Edit: () => handleEdit(),
    DieselReport: () => handleOpenDieselReportModal(),
    InflationReport: () => handleExportContract('inflation'),
    Download: () => handleDownloadSpreadsheet(),
    Close: () => handleOpenCloseContractModal(),
    Delete: () => handleOpenDeleteModal(),
    DefaultFlag: () => handleOpenDefaultFlag(),
  };

  const handleOnClick = (action) => {
    if (original) {
      action();
    }
  };

  const Options = (
    <S.OptionsContainer>
      {canEditContract() && !contractIsClosed({ details: original }) && (
        <S.OptionItem
          data-testid="test-option-edit"
          onClick={() => handleOnClick(CellActions.Edit)}
        >
          <Icon src={EditItemIcon} />
          <Text size="medium" style={{ fontWeight: '400' }}>
            Editar
          </Text>
        </S.OptionItem>
      )}

      <S.OptionItem
        data-testid="test-option-diesel"
        onClick={() => handleOnClick(CellActions.DieselReport)}
      >
        <Icon src={ReportIcon} />
        <Text size="medium" style={{ fontWeight: '400' }}>
          Relatório detalhado do preço - Diesel
        </Text>
      </S.OptionItem>

      {original.showInflationReport && (
        <S.OptionItem
          data-testid="test-option-inflation"
          onClick={() => handleOnClick(CellActions.InflationReport)}
        >
          <Icon src={ReportIcon} />
          <Text size="medium" style={{ fontWeight: '400' }}>
            Relatório detalhado do preço - Inflação
          </Text>
        </S.OptionItem>
      )}

      {hasPermissionToDownloadContract() && (
        <S.OptionItem
          data-testid="test-option-download"
          onClick={() => handleOnClick(CellActions.Download)}
        >
          <Icon src={DownloadIcon} />
          <Text size="medium" style={{ fontWeight: '400' }}>
            Download de planilha
          </Text>
        </S.OptionItem>
      )}

      {canCloseContract({ details: original }) && (
        <S.OptionItem
          data-testid="test-option-close"
          onClick={() => handleOnClick(CellActions.Close)}
        >
          <Icon src={CloseIcon} />
          <Text size="medium" style={{ fontWeight: '400' }}>
            Encerrar formalização
          </Text>
        </S.OptionItem>
      )}

      {hasPermissionToDeleteContract() && original.canDeleteContract && (
        <S.OptionItem
          data-testid="test-option-delete"
          onClick={() => handleOnClick(CellActions.Delete)}
        >
          <Icon src={TrashIcon} />
          <Text size="medium" style={{ fontWeight: '400' }}>
            Deletar
          </Text>
        </S.OptionItem>
      )}

      {canEditContract() && (
        <S.OptionItem
          data-testid="test-option-flag-default"
          onClick={() => handleOnClick(CellActions.DefaultFlag)}
        >
          <Icon src={CheckIcon} />
          <Text size="medium" style={{ fontWeight: '400' }}>
            Padrão
          </Text>
        </S.OptionItem>
      )}
    </S.OptionsContainer>
  );

  return !row.original.editMode ? (
    <S.ContentCell data-testid="cell-with-action">
      <Popover content={Options} position="left center">
        <Button
          data-testid="cell-with-action-option-button"
          icon={OptionIcon}
          name=""
          variant="tertiary"
        />
      </Popover>
    </S.ContentCell>
  ) : (
    <S.Flex data-testid="cell-with-action-edit">
      <Button
        onClick={confirmEdit}
        data-testid="cell-with-edit-confirm-button"
        name="Ok"
        variant="primary"
      />
    </S.Flex>
  );
};

CellWithActions.propTypes = {
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      showInflationReport: PropTypes.bool.isRequired,
      endPeriod: PropTypes.string,
      editMode: PropTypes.bool,
      canDeleteContract: PropTypes.bool,
    }).isRequired,
  }),
};

CellWithActions.defaultProps = {
  row: {
    index: 0,
    original: {
      id: 0,
      editMode: false,
      endPeriod: null,
      canDeleteContract: false,
    },
  },
};

export default CellWithActions;
