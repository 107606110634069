import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { Button } from 'components/system/core';

import Modal from 'components/core/modal';

import { FormalizationRequestActions } from 'store/formalizationRequest/formalizationRequest.ducks';
import { getNetlexDocLink } from '../FormalizationCard/utils';

const NewDocumentModal = ({
  onClose,
  formalizationId,
  netlexId,
  netlexDoc,
}) => {
  const dispatch = useDispatch();

  const netlexUrl = getNetlexDocLink(netlexId);

  const handleGenerateNewDocument = () => {
    dispatch(
      FormalizationRequestActions.sendForFormalizationRequest(
        { id: formalizationId },
        () => {
          window.open(netlexUrl, '_blank');
        },
      ),
    );

    onClose();
  };

  const setModalActions = () => (
    <>
      <Button name="Cancelar" onClick={onClose} variant="secondary" />
      <Button name="Confirmar" onClick={handleGenerateNewDocument} />
    </>
  );

  return (
    <Modal
      width="100%"
      title="Gerar novo documento"
      onClose={onClose}
      actions={setModalActions}
    >
      Deseja desvincular o documento {netlexDoc} e gerar um novo?
    </Modal>
  );
};

NewDocumentModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  formalizationId: PropTypes.number.isRequired,
  netlexDoc: PropTypes.string.isRequired,
  netlexId: PropTypes.number.isRequired,
};

export default NewDocumentModal;
