import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Input } from 'components/system/form';
import { Column as Col, Row } from 'components/system/interface';

import InputSelect from 'components/form/InputSelect';

import { getOptionsActiveContracts } from 'lib/contexts/contracts';
import { setBaseDieselLocalTypeNameOptions } from 'lib/contexts/newItems';
import handleInputMask from 'lib/core/handleInputMask';
import * as utility from 'lib/core/utility';

import * as S from '../Form.styles';

const NewItemsSection = ({ setIsCurvedFare, setSegmentId }) => {
  const [hasTrigger, setHasTrigger] = useState(false);

  const { control, errors, setValue } = useFormContext();

  const { data } = useSelector(({ local }) => local);
  const { activeContractList } = useSelector(
    ({ activeContracts }) => activeContracts,
  );
  const { draftNewItem } = useSelector(({ newItems }) => newItems);

  const setFieldValueOrEmpty = useCallback(
    (key, value) => {
      setValue(key, value || '');
    },
    [setValue],
  );

  useEffect(() => {
    if (draftNewItem) {
      setValue('contract', draftNewItem.codeContract);
      setValue('railwayId', draftNewItem.codeItem);
      setFieldValueOrEmpty('cargoOrigin', draftNewItem?.origin);
      setFieldValueOrEmpty('originCode', draftNewItem?.originCode);
      setFieldValueOrEmpty('destination', draftNewItem?.destination);
      setFieldValueOrEmpty('destinationCode', draftNewItem?.destinationCode);
      setFieldValueOrEmpty('segment', draftNewItem?.segment?.name);
      setFieldValueOrEmpty('railwayInvolved', draftNewItem?.railwayInvolved);
      setFieldValueOrEmpty('placeOriginCargo', draftNewItem?.placeOriginCargo);
      setValue('trigger', draftNewItem?.hasTrigger ? 1 : 0);
      setFieldValueOrEmpty(
        'triggerPercentage',
        String(draftNewItem?.triggerPercentage),
      );
      setFieldValueOrEmpty(
        'product',
        draftNewItem?.productUnicomMerchandiseTacito,
      );
      setValue(
        'dataBase',
        draftNewItem?.dataBase ? new Date(draftNewItem?.dataBase) : '',
      );
      setFieldValueOrEmpty('diesel', draftNewItem?.local?.id);
      setFieldValueOrEmpty(
        'correctionStartDate',
        draftNewItem?.correctionStartDate,
      );
      setFieldValueOrEmpty(
        'correctionEndDate',
        draftNewItem?.correctionEndDate,
      );
    }
  }, [draftNewItem, setValue, setFieldValueOrEmpty]);

  const handleSelectActiveContract = useCallback(
    (code) => {
      const contractFiltered = activeContractList.filter(
        (contract) => contract.code === code,
      );

      setValue('fareType', contractFiltered[0]?.fareType);
      setValue('segment', contractFiltered[0]?.segment.name);
      setIsCurvedFare(contractFiltered[0]?.fareType !== 0);
      setSegmentId(contractFiltered[0]?.segment.id);
    },
    [setValue, activeContractList, setIsCurvedFare, setSegmentId],
  );

  return (
    <S.ExpandedAccordion title="Novos Itens" open>
      <Row>
        <Col desktop={2}>
          <Controller
            control={control}
            rules={{ required: true }}
            name="contract"
            render={({ onChange, value }) => (
              <InputSelect
                value={value}
                options={getOptionsActiveContracts(activeContractList)}
                onChange={(e) => {
                  onChange(e);
                  handleSelectActiveContract(e);
                }}
                placeholder="Selecione"
                label="Contrato*"
                type="text"
                maxLength="50"
                styles={{ marginRight: '10px' }}
                status={errors.contract && 'error'}
                statusMessage={errors.contract && 'Campo requerido'}
              />
            )}
          />
        </Col>
        <Col desktop={2}>
          <Controller
            control={control}
            rules={{ required: true }}
            name="railwayId"
            render={({ onChange, value }) => (
              <Input
                value={value}
                onChange={onChange}
                placeholder="Número item"
                label="Número item ferroviário*"
                type="input"
                onInput={(event) => handleInputMask(event, 'digits')}
                required
                status={errors.railwayId && 'error'}
                statusMessage={errors.railwayId && 'Campo requerido'}
              />
            )}
          />
        </Col>

        <Col desktop={2}>
          <Controller
            control={control}
            name="originCode"
            render={({ onChange, value }) => (
              <Input
                value={value}
                onChange={onChange}
                placeholder="Estação de origem"
                label="Código estação origem"
                type="text"
                maxLength="20"
                required
              />
            )}
          />
        </Col>

        <Col desktop={2}>
          <Controller
            control={control}
            name="cargoOrigin"
            render={({ onChange, value }) => (
              <Input
                value={value}
                onChange={onChange}
                placeholder="Origem da carga"
                label="Origem"
                type="text"
                maxLength="50"
                required
              />
            )}
          />
        </Col>

        <Col desktop={2}>
          <Controller
            control={control}
            name="destinationCode"
            render={({ onChange, value }) => (
              <Input
                value={value}
                onChange={onChange}
                placeholder="Estação de destino"
                label="Código estação destino"
                type="text"
                maxLength="20"
                required
                styles={{ marginRight: '10px' }}
              />
            )}
          />
        </Col>

        <Col desktop={2}>
          <Controller
            control={control}
            name="destination"
            render={({ onChange, value }) => (
              <Input
                value={value}
                onChange={onChange}
                placeholder="Destino da carga"
                label="Destino"
                type="text"
                maxLength="50"
                required
                styles={{ marginRight: '10px' }}
              />
            )}
          />
        </Col>
      </Row>

      <Row>
        <Col desktop={4}>
          <Controller
            control={control}
            name="segment"
            render={({ value }) => {
              return (
                <Input
                  value={value}
                  placeholder="Segmento"
                  label="Segmento"
                  type="text"
                  maxLength="50"
                  required
                  disabled
                />
              );
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col desktop={2}>
          <Controller
            control={control}
            name="railwayInvolved"
            render={({ onChange, value }) => (
              <S.Spacing>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="Ferrovias"
                  label="Ferrovias envolvidas"
                  type="text"
                  maxLength="50"
                  required
                  status={errors.railwayInvolved && 'error'}
                  statusMessage={errors.railwayInvolved && 'Campo requerido'}
                />
              </S.Spacing>
            )}
          />
        </Col>
        <Col desktop={2}>
          <Controller
            control={control}
            name="trigger"
            render={({ onChange, value }) => {
              return (
                <S.Spacing>
                  <InputSelect
                    value={value}
                    options={utility.optionsTrigger}
                    onChange={(e) => {
                      onChange(e);
                      setHasTrigger(e !== 0);
                    }}
                    placeholder="Selecione"
                    label="Gatilho"
                    type="text"
                    maxLength="50"
                    styles={{ marginRight: '10px' }}
                  />
                </S.Spacing>
              );
            }}
          />
        </Col>
        {hasTrigger && (
          <Col desktop={2}>
            <Controller
              control={control}
              rules={{ required: !!hasTrigger }}
              name="triggerPercentage"
              render={({ onChange, value }) => (
                <S.Spacing>
                  <Input
                    value={value}
                    onChange={onChange}
                    placeholder="%"
                    label="Percentual"
                    type="text"
                    maxLength="50"
                    required={!!hasTrigger}
                    status={errors.triggerPercentage && 'error'}
                    statusMessage={
                      errors.triggerPercentage && 'Campo requerido'
                    }
                  />
                </S.Spacing>
              )}
            />
          </Col>
        )}

        <Col desktop={2}>
          <Controller
            control={control}
            name="placeOriginCargo"
            render={({ onChange, value }) => (
              <S.Spacing>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="Observação"
                  label="Observação"
                  type="text"
                  maxLength="50"
                  required
                  status={errors.placeOriginCargo && 'error'}
                  statusMessage={errors.placeOriginCargo && 'Campo requerido'}
                />
              </S.Spacing>
            )}
          />
        </Col>
        <Col desktop={2}>
          <Controller
            control={control}
            name="product"
            render={({ onChange, value }) => (
              <S.Spacing>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="Produto"
                  label="Produto"
                  type="text"
                  maxLength="50"
                  required
                  status={errors.product && 'error'}
                  statusMessage={errors.product && 'Campo requerido'}
                />
              </S.Spacing>
            )}
          />
        </Col>
      </Row>

      <Row>
        <Col desktop={1}>
          <Controller
            control={control}
            name="dataBase"
            render={({ onChange, value }) => (
              <S.Spacing>
                <S.InputDateItem
                  label="Data base"
                  format="dd/MM"
                  selected={value}
                  value={value}
                  locale="pt-BR"
                  onChange={onChange}
                  width="100%"
                />
              </S.Spacing>
            )}
          />
        </Col>
        <Col desktop={2}>
          <Controller
            control={control}
            name="diesel"
            render={({ onChange, value }) => {
              return (
                <S.Spacing>
                  <InputSelect
                    value={value}
                    options={
                      data.length &&
                      utility.getOptions(
                        setBaseDieselLocalTypeNameOptions(data),
                      )
                    }
                    onChange={onChange}
                    placeholder="Selecione"
                    label="Base diesel"
                    type="text"
                    maxLength="50"
                    required
                    styles={{ marginRight: '10px' }}
                    status={errors.diesel && 'error'}
                    statusMessage={errors.diesel && 'Campo requerido'}
                  />
                </S.Spacing>
              );
            }}
          />
        </Col>

        <Col desktop={2}>
          <Controller
            control={control}
            name="correctionStartDate"
            render={({ onChange, value }) => (
              <S.Spacing>
                <InputSelect
                  value={value}
                  options={utility.optionsMonths}
                  onChange={onChange}
                  placeholder="Selecione"
                  label="Início da correção"
                  type="text"
                  maxLength="50"
                  styles={{ marginRight: '10px' }}
                  status={errors.correctionStartDate && 'error'}
                  statusMessage={
                    errors.correctionStartDate && 'Campo requerido'
                  }
                />
              </S.Spacing>
            )}
          />
        </Col>
        <Col desktop={2}>
          <Controller
            control={control}
            name="correctionEndDate"
            render={({ onChange, value }) => (
              <S.Spacing>
                <InputSelect
                  value={value}
                  options={utility.optionsMonths}
                  onChange={onChange}
                  placeholder="Selecione"
                  label="Término da correção"
                  type="text"
                  maxLength="50"
                  styles={{ marginRight: '10px' }}
                  status={errors.correctionEndDate && 'error'}
                  statusMessage={errors.correctionEndDate && 'Campo requerido'}
                />
              </S.Spacing>
            )}
          />
        </Col>
      </Row>
    </S.ExpandedAccordion>
  );
};

export default NewItemsSection;
