import css from '@styled-system/css';
import styled from 'styled-components';
import { compose, flexbox, layout, space } from 'styled-system';

import theme from '../../tokens';

export const TimelineStyle = styled('div')(
  css({
    background: theme.colors.grayWhite,
    display: 'flex',
    padding: '16px',
    paddingBottom: '22px',
    font: theme.fonts.textRegularSmall,
    width: '100%',
    boxSizing: 'border-box',
    '& > div.time': {
      color: theme.colors.accentPrimaryDark,
      width: '70px',
      minWidth: '70px',
      textAlign: 'right',
      '& > strong.date': {
        display: 'block',
      },
      '& > strong.hours': {
        display: 'block',
        marginTop: '4px',
      },
    },
    '& > div.line': {
      width: '52px',
      minWidth: '52px',
      position: 'relative',
      display: 'block',
      '& > svg': {
        background: theme.colors.accentPrimaryDark,
        borderRadius: '100%',
        left: '18px',
        position: 'absolute',
        cursor: 'pointer',
      },
      '& > div.vertical-line': {
        width: '4px',
        minWidth: '4px',
        height: '100%',
        minHeight: '68px',
        background: theme.colors.accentPrimaryDark,
        borderRadius: '4px',
        position: 'relative',
        margin: '0 auto',
        top: '27px',
      },
    },
    '&:first-of-type': {
      paddingBottom: '38px',
      '& > div.line > svg': {
        width: '32px',
        height: '32px',
        left: '10px',
        padding: '5px',
      },
      '& > div.line > div.vertical-line': {
        top: '42px',
      },
      '& > div.timeline-horizontal div.line > div.icon-line > svg': {
        width: '32px',
        height: '32px',
        left: '10px',
        padding: '5px',
        '& > path': {
          transform: 'none',
        },
      },
      '& > div.timeline-horizontal section.timeline-container': {
        '& > header > h1': {
          font: theme.fonts.textBoldMedium,
        },
      },
      '& > div.timeline-horizontal div.line > div.vertical-line': {
        height: '43px',
      },
    },
    '&:last-of-type': {
      paddingBottom: '44px',

      '& > div.timeline-horizontal > div.line > div.icon-line div.horizontal-line': {
        background: 'transparent',
      },
      '&.disabled > div.timeline-horizontal > div.line > div.icon-line div.horizontal-line': {
        background: 'transparent',
      },
      '&.finished > div.timeline-horizontal > div.line > div.icon-line div.horizontal-line': {
        background: 'transparent',
      },
      '& > div.timeline-horizontal > div.line > div.icon-line p': {
        display: 'none',
      },
      '& > div.timeline-horizontal section.timeline-container': {
        '& > header > h1': {
          font: theme.fonts.textBoldMedium,
        },
      },
      '& > div.timeline-horizontal div.line > div.icon-line > svg': {
        width: '32px',
        height: '32px',
        left: '10px',
        padding: '5px',
        '& > path': {
          transform: 'none',
        },
      },
      '& > div.timeline-horizontal div.line > div.vertical-line': {
        height: '43px',
      },
    },
    '& > section.timeline-container': {
      diplay: 'block',
      '& > header > h1': {
        font: theme.fonts.textBoldLarge,
        color: theme.colors.accentPrimaryDark,
        marginBottom: '8px',
      },
      '& > div p': {
        color: theme.colors.gray500,
        font: theme.fonts.textRegularSmall,
      },
    },
    '&.disabled': {
      '& > div.time strong': {
        color: theme.colors.gray200,
      },
      '& > div.timeline-horizontal div.time strong': {
        color: theme.colors.gray200,
      },
      '& > div.line > svg': {
        background: theme.colors.gray200,
      },
      '& > div.timeline-horizontal div.line div.icon-line > svg': {
        background: theme.colors.gray200,
      },
      '& > div.line > div.vertical-line': {
        background: theme.colors.gray200,
      },
      '& > div.timeline-horizontal > div.line > div.icon-line > div > p': {
        color: theme.colors.gray200,
      },
      '& > div.timeline-horizontal > div.line > div.icon-line > div > div.horizontal-line': {
        background: theme.colors.gray200,
      },
      '& > div.timeline-horizontal div.line > div.vertical-line': {
        background: theme.colors.gray200,
      },
      '& > section': {
        'header > h1, h2, h3, p': {
          color: theme.colors.gray200,
        },
        'div.content > p, a, span, strong, b, h2, h3, h4': {
          color: theme.colors.gray200,
        },
      },
      '& > div.timeline-horizontal > section.timeline-container': {
        'header > h1, h2, h3, p': {
          color: theme.colors.gray200,
        },
        'div.content > p, a, span, strong, b, h2, h3, h4': {
          color: theme.colors.gray200,
        },
      },
    },
    '&.active': {
      '& > div.timeline-horizontal div.line > div.icon-line > svg > path': {
        transform: 'none',
      },
    },
    '&.finished': {
      '& > div.time strong': {
        color: theme.colors.green300,
      },
      '& > div.timeline-horizontal div.time strong': {
        color: theme.colors.green300,
      },
      '& > div.line > svg': {
        background: theme.colors.green300,
      },
      '& > div.timeline-horizontal div.line div.icon-line > svg': {
        background: theme.colors.green300,
      },
      '& > div.line > div.vertical-line': {
        background: theme.colors.green300,
      },
      '& > div.timeline-horizontal > div.line > div.icon-line > div > p': {
        color: theme.colors.green300,
      },
      '& > div.timeline-horizontal > div.line > div.icon-line > div > div.horizontal-line': {
        background: theme.colors.green300,
      },
      '& > div.timeline-horizontal div.line > div.vertical-line': {
        background: theme.colors.green300,
      },
      '& > section': {
        'header > h1, h2, h3, p': {
          color: theme.colors.green300,
        },
      },
      '& > div.timeline-horizontal > section.timeline-container': {
        'header > h1, h2, h3, p': {
          color: theme.colors.green300,
        },
      },
    },
    '& > div.timeline-horizontal': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      '& > div.time': {
        color: theme.colors.gray500,
        width: '70px',
        minWidth: '70px',
        textAlign: 'center',
        '& > strong.date': {
          display: 'block',
        },
        '& > strong.hours': {
          display: 'block',
          marginTop: '4px',
        },
      },
      '& > div.line': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: '86px',
        width: '52px',
        minWidth: '52px',
        '& > div > svg': {
          background: theme.colors.accentPrimaryDark,
          borderRadius: '100%',
          width: '16px',
          height: '16px',
          cursor: 'pointer',
          '& > path': {
            transform: 'translate(-2px, -5px)',
          },
        },
        '& > div.vertical-line': {
          width: '2px',
          minWidth: '2px',
          minHeight: '32px',
          background: theme.colors.accentPrimaryDark,
          borderRadius: '4px',
          margin: '0 auto',
        },
        '& > div.icon-line': {
          display: 'flex',
          alignItems: 'center',
          transform: 'translateX(32px)',
        },
        '& > div.icon-line > div > p': {
          marginLeft: '13px',
          transform: 'translateY(-18px)',
          font: theme.fonts.textBoldSmall,
          color: theme.colors.accentPrimaryDark,
          textAlign: 'center',
        },
        '& > div.icon-line div.horizontal-line': {
          transform: 'translate(9px, -10px)',
          width: '63px',
          height: '4px',
          borderRadius: '4px',
          background: theme.colors.accentPrimaryDark,
        },
      },
      '& > section.timeline-container': {
        diplay: 'block',
        maxWidth: '78px',
        minHeight: '48px',
        '& > header > h1': {
          font: theme.fonts.textRegularSmall,
          color: theme.colors.accentPrimaryDark,
          minHeight: '32px',
          textAlign: 'center',
        },
        '& > div p': {
          color: theme.colors.gray500,
          font: theme.fonts.textRegularSmall,
        },
      },
      '& > div.content': {
        paddingTop: theme.spacing.sm,
        width: 70,
        display: 'flex',
        justifyContent: 'center',
      },
    },
  }),
  compose(space, flexbox, layout),
);
