import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Button, Card, Flex } from 'components/system/core';
import { Input } from 'components/system/form';
import { Column as Col, Header } from 'components/system/interface';

import { history } from 'constants/routes';
import * as ROUTES from 'constants/urls';
import * as utility from 'lib/core/utility';

const Form = ({ breadcrumb, onSubmit, segment }) => {
  const { handleSubmit, errors, control, setValue } = useForm({
    defaultValues: {
      name: '',
    },
  });

  useEffect(() => {
    if (segment) setValue('name', utility.capitalizeFirstString(segment.name));
  }, [segment, setValue]);

  const handleNavigateBack = () => {
    history.push(ROUTES.SEGMENT_LIST);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex justifyContent="space-between" alignItems="center">
        <Header bread={breadcrumb} tag="header" title="Segmento" />

        <Flex justifyContent="space-between" alignItems="center">
          <Button
            onClick={handleNavigateBack}
            name="Cancelar"
            type="button"
            variant="tertiary"
            style={{ marginRight: '5px' }}
            data-testid="cancel-button"
          />
          <Button name="Salvar" type="submit" data-testid="save-button" />
        </Flex>
      </Flex>

      <Card>
        <Col desktop={4}>
          <Controller
            control={control}
            rules={{ required: true }}
            name="name"
            render={({ onChange, value }) => (
              <Input
                value={value}
                onChange={onChange}
                placeholder="Nome do segmento"
                label="Nome do segmento"
                type="text"
                maxLength="30"
                required
                status={errors.name && 'error'}
                statusMessage={errors.name && 'Campo requerido'}
                data-testid="input-name"
              />
            )}
          />
        </Col>
      </Card>
    </form>
  );
};

Form.propTypes = {
  breadcrumb: PropTypes.array,
  onSubmit: PropTypes.func,
  segment: PropTypes.object,
};

Form.defaultProps = {
  breadcrumb: [{ href: '#' }],
  onSubmit: () => {},
  segment: null,
};

export default Form;
