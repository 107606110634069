import styled from 'styled-components';

import Tokens from 'components/system/tokens';

export const Sections = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: ${Tokens.spacing.md};

  margin-bottom: ${Tokens.spacing.md};
`;
