import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Input } from 'components/system/form';
import Tokens from 'components/system/tokens';

import handleInputMask from 'lib/core/handleInputMask';
import { EditCellSchema } from 'lib/core/tableCellSchemas';

const CellPriceRecommended = ({ row }) => {
  const { control, errors } = useFormContext();

  return (
    <Controller
      control={control}
      name={`recommendedPriceRequests.${row.index}.value`}
      render={({ ...props }) => (
        <Input
          {...props}
          type="text"
          prefix="R$"
          onInput={(event) => handleInputMask(event, 'currency')}
          status={
            errors?.recommendedPriceRequests?.[row.index]?.value && 'error'
          }
        />
      )}
    />
  );
};

CellPriceRecommended.propTypes = EditCellSchema;

CellPriceRecommended.defaultProps = {
  cell: {
    value: '',
  },
  row: {
    index: 0,
    original: {
      id: 0,
      editMode: false,
    },
  },
  color: Tokens.colors.grayBlack,
  transformedData: (text) => text,
  align: 'start',
};

export default CellPriceRecommended;
