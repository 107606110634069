/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import ChevronDownIcon from 'assets/system/svgs/chevron/down.svg';
import ClearIcon from 'assets/system/svgs/clear.svg';
import { Icon } from 'components/system/core';
import { Checkbox, Label } from 'components/system/form';
import Tokens from 'components/system/tokens';

import {
  Container,
  Group,
  GroupItem,
  OptionsList,
  SearchInput,
  Tag,
  Tags,
} from './styles';

const CustomMultiselect = ({
  listItems,
  label: labelValue,
  required,
  keyValue,
  onChange,
  canSelectAll = false,
  name,
  ...attrs
}) => {
  const tagsRef = useRef(null);
  const selectRef = useRef(null);
  const searchRef = useRef(null);
  const optionsRef = useRef(null);

  const [search, setSearch] = useState('');
  const [openOptions, setOpenOptions] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState(() => {
    if (listItems) {
      const selected = listItems
        .reduce(
          (acc, curr) => (curr?.data ? [...acc, ...curr.data] : [...acc, curr]),
          [],
        )
        .filter((item) => item.selected);

      return selected;
    }

    return [];
  });
  const [tags, setTags] = useState({
    freeSpace: null,
    itemsToFit: null,
    clippedItems: 0,
  });
  const [isSelectAll, setIsSelectAll] = useState(() => {
    const everyIsSelected = selectedItems.every((item) => item.selected);

    return !!(selectedItems.length && everyIsSelected);
  });

  const allValues = useMemo(() => {
    if (listItems && keyValue) {
      const values = [];

      listItems.forEach((listItem) => {
        if (listItem.data?.length) {
          listItem.data.forEach((item) => {
            values.push(item.value);
          });
        } else {
          values.push(listItem.value);
        }
      });

      return values;
    }

    return [];
  }, [listItems, keyValue]);

  const calculateFreeSpace = () => {
    const searchWidth = searchRef?.current?.getBoundingClientRect().width;
    const tagsWidth = tagsRef?.current?.getBoundingClientRect().width;

    if (search && tagsWidth) {
      return searchWidth - tagsWidth;
    }

    return 0;
  };

  const freeSpace = calculateFreeSpace();

  const calcTagsAmount = useCallback((selectedItemsParam) => {
    const searchWidth = searchRef?.current?.getBoundingClientRect().width;
    const tagsChildren = tagsRef?.current?.children;
    let tagsAmount = 0;
    let tagsWidthSum = 0;

    if (tagsChildren) {
      for (let i = 0; i < selectedItemsParam.length; i += 1) {
        if (tagsChildren[i]) {
          tagsWidthSum += tagsChildren[i].getBoundingClientRect().width;

          if (searchWidth - tagsWidthSum > 150) {
            tagsAmount += 1;
          }
        }
      }
    }

    return tagsAmount;
  }, []);

  const tagsAmount = calcTagsAmount(selectedItems);

  const fitTags = useCallback(() => {
    const { itemsToFit, clippedItems } = tags;

    if (selectedItems.length !== tagsAmount || clippedItems) {
      if (clippedItems) {
        if (selectedItems.length < itemsToFit + clippedItems) {
          setTags((prevState) => ({
            ...prevState,
            clippedItems: clippedItems - 1,
          }));
        } else {
          setTags((prevState) => ({
            ...prevState,
            clippedItems: clippedItems + 1,
          }));
        }
      } else {
        setTags((prevState) => ({
          ...prevState,
          itemsToFit: tagsAmount,
          clippedItems: selectedItems.length - tagsAmount,
        }));
      }
    }
  }, [calcTagsAmount, selectedItems, tags]);

  const addItem = (value) => {
    setSearch('');
    searchRef.current.focus();

    setSelectedItems((prevState) => [...prevState, value]);
  };

  const removeItem = (item) => {
    const filtered = selectedItems.filter(
      (selectedItem) => selectedItem.value[keyValue] !== item.value[keyValue],
    );

    setSelectedItems(filtered);
  };

  const formatCheckboxLabel = (label) => {
    if (search && label.toLowerCase().includes(search)) {
      const parsedLabel = label.toLowerCase();
      parsedLabel.charAt(0).toUpperCase();
      return parsedLabel;
    }
    return label;
  };

  const handleCheckboxChange = (event, item) => {
    if (event.target.checked) {
      addItem(item);
    } else {
      removeItem(item);
    }
  };

  const handleGroupItemClick = (itemSelected, item) => {
    if (!itemSelected) {
      addItem(item);
    } else {
      removeItem(item);
    }
  };

  const renderOption = (item, label, values) => {
    const itemSelected = selectedItems.some(
      ({ value: selectedItem }) =>
        selectedItem[keyValue] === item.value[keyValue],
    );
    const indexValue = values.findIndex(
      (value) => value[keyValue] === item.value[keyValue],
    );

    return (
      <GroupItem
        option
        aria-selected={itemSelected}
        tabIndex={indexValue}
        key={`group-item-${indexValue}`}
        id={`group-item-${indexValue}`}
        onClick={() => handleGroupItemClick(itemSelected, item)}
      >
        <Checkbox
          label={formatCheckboxLabel(label)}
          key={`${label}-${item.value[keyValue]}`}
          id={`${label}-${item.value[keyValue]}`}
          checked={itemSelected}
          group={label}
          onChange={(e) => handleCheckboxChange(e, item)}
        />
      </GroupItem>
    );
  };

  const handleSelectAll = (event) => {
    if (event.target.checked || !isSelectAll) {
      setIsSelectAll(true);
      const allSelectedItems = listItems.reduce(
        (acc, curr) => (curr?.data ? [...acc, ...curr.data] : [...acc, curr]),
        [],
      );

      setSelectedItems(allSelectedItems);
      onChange(allSelectedItems);
    } else {
      setIsSelectAll(false);
      setSelectedItems([]);
    }
  };

  const renderOptionAll = () => {
    return (
      <GroupItem
        option
        aria-selected={isSelectAll}
        tabIndex={0}
        key={`group-item-select-all-${name.toLowerCase()}`}
        id={`group-item-select-all-${name.toLowerCase()}`}
        onClick={handleSelectAll}
      >
        <Checkbox
          label="Selecionar todos"
          key={`select-all-options-${name.toLowerCase()}`}
          id={`select-all-options-${name.toLowerCase()}`}
          checked={isSelectAll}
          onChange={handleSelectAll}
        />
      </GroupItem>
    );
  };

  const renderOptions = ({ label, data, value }) => {
    if (data?.length) {
      return (
        <Group title={label} key={label}>
          {data.map((item) => renderOption(item, item.label, allValues))}
        </Group>
      );
    }

    if (value) {
      return renderOption({ label, value }, label, allValues);
    }

    return null;
  };

  const handleDisplayTags = useCallback(() => {
    onChange(selectedItems);

    setTags({
      clippedItems: selectedItems.length - tagsAmount - 1,
      itemsToFit: tagsAmount + 1,
      freeSpace,
    });
  }, [selectedItems, tagsAmount, freeSpace]);

  useEffect(() => {
    handleDisplayTags();
  }, [handleDisplayTags]);

  useEffect(() => {
    fitTags();
  }, []);

  const filtering = useCallback(
    ({ label, data, value }) => {
      if (data) {
        return {
          label,
          data: data.filter((item) =>
            item.label.toLowerCase().includes(search),
          ),
        };
      }
      if (label.toLowerCase().includes(search)) {
        return { label, value };
      }
      return null;
    },
    [search],
  );

  useEffect(() => {
    if (search.length > 0) {
      const filteredOptions = listItems
        .map(filtering)
        .filter((item) => item !== null);
      setFilteredItems(filteredOptions);
    }
  }, [search, listItems, filtering]);

  useEffect(() => {
    const selectNode = selectRef.current;

    const clickEventListener = (e) => {
      if (e.target !== selectNode && !selectNode.contains(e.target)) {
        setOpenOptions(false);
      }
    };

    if (selectNode) {
      document.addEventListener('click', clickEventListener);
    }

    return () => {
      document.removeEventListener('click', clickEventListener);
    };
  }, []);

  const handleSearchInputChange = (event) => {
    setSearch(event.target.value.toLowerCase());
  };

  const handleSearchInputFocus = () => {
    setOpenOptions(true);
  };

  const handleDropdownTriggerClick = () => {
    if (openOptions) {
      setOpenOptions(false);
    } else {
      searchRef.current.focus();
      setOpenOptions(true);
    }
  };

  const handleTagClick = () => {
    searchRef.current.focus();
  };

  const handleRemoveTag = (event, item) => {
    event.stopPropagation();
    removeItem(item);
  };

  return (
    <>
      <div>
        {labelValue && (
          <Label
            informative={!required && '(Opcional)'}
            style={{ marginBottom: Tokens.spacing.xss }}
          >
            {labelValue}
          </Label>
        )}
      </div>
      <Container
        role="combobox"
        aria-haspopup="listbox"
        aria-expanded={search.length > 0}
        ref={selectRef}
      >
        <Tags
          ref={tagsRef}
          spaceChildren={2}
          alignRight={search.length > 0}
          onClick={handleTagClick}
        >
          {search.length === 0 &&
            selectedItems.map((item, index) => {
              if (index < tags.itemsToFit || tags.itemsToFit === null) {
                return (
                  <Tag key={item.label} data-testid={`tag-${item.label}`}>
                    {item.label}
                    <Icon
                      data-testid={`remove-tag-${item.label}`}
                      className="clear-button"
                      src={ClearIcon}
                      size={16}
                      onClick={(e) => handleRemoveTag(e, item)}
                    />
                  </Tag>
                );
              }

              return null;
            })}

          {search.length > 0 && selectedItems.length > 0 && (
            <Tag>+{selectedItems.length}</Tag>
          )}
          {tags.clippedItems > 0 && search.length === 0 && (
            <Tag>+{tags.clippedItems}</Tag>
          )}
        </Tags>
        <SearchInput
          name={name}
          role="searchbox"
          aria-autocomplete="both"
          ref={searchRef}
          type="text"
          value={search}
          onFocus={handleSearchInputFocus}
          openOptions={openOptions}
          onChange={handleSearchInputChange}
          {...attrs}
        />
        <Icon
          className="drop-options--trigger"
          onClick={handleDropdownTriggerClick}
          src={ChevronDownIcon}
          size={16}
        />

        {openOptions && (
          <OptionsList ref={optionsRef} role="listbox">
            {canSelectAll && renderOptionAll()}
            {search.length === 0
              ? listItems.map(renderOptions)
              : filteredItems.map(renderOptions)}
          </OptionsList>
        )}
      </Container>
    </>
  );
};

CustomMultiselect.propTypes = {
  listItems: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        data: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.object,
          }),
        ),
      }),
    ),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.object,
      }),
    ),
  ]).isRequired,
  label: PropTypes.string,
  keyValue: PropTypes.string,
  required: PropTypes.bool,
  sections: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
};

CustomMultiselect.defaultProps = {
  label: '',
  required: false,
  sections: true,
  keyValue: 'id',
  onChange: () => {},
  name: '',
};

export default CustomMultiselect;
