import PropTypes from 'prop-types';
import React from 'react';

import Flex from '../../flex';

import { DialogFooterStyle } from '../styles';

const DialogFooter = ({ children, ...attrs }) => (
  <DialogFooterStyle {...attrs}>
    <Flex style={{ marginTop: '16px' }} justifyContent="flex-end">
      {children}
    </Flex>
  </DialogFooterStyle>
);

DialogFooter.propTypes = {
  children: PropTypes.node,
};

DialogFooter.defaultProps = {
  children: '',
};
export default DialogFooter;
