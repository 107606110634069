import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Button, Card, Flex, Tabs } from 'components/system/core';
import { Input } from 'components/system/form';
import { Header } from 'components/system/interface';

import TableUsers from 'components/contexts/user/userList/TableUsers/TableUsers';
import TableContainer from 'components/core/TableContainer';

import PermissionsTab from '../PermissionsTab';

import * as S from './Form.styles';

const Form = ({ breadcrumb, onSubmit, editProfile, method, roles }) => {
  const [reload, setReload] = useState(false);

  const { handleSubmit, errors, control, setValue } = useForm({
    defaultValues: {
      name: '',
    },
  });

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    if (method === 'edit' && editProfile) {
      const { name } = editProfile;
      setValue('name', name);

      roles.forEach((permission) => {
        permission.roles.forEach((role) => {
          role.actions.forEach((action) => {
            setValue(
              `roles.role_id_${role.id}.action_id_${action.id}`,
              action.checked,
            );
          });
        });
      });
    }
  }, [editProfile, method, setValue, roles, reload]);

  useEffect(() => {
    setReload((oldState) => !oldState);
  }, [editProfile]);

  const onTabChange = (tab) => {
    if (tab === 'Usuários vinculados') {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="form-test">
      <Flex justifyContent="space-between" alignItems="center">
        <Header bread={breadcrumb} tag="header" title="Perfil" />
        <div>
          <Button name="Salvar" type="submit" isDisabled={isButtonDisabled} />
        </div>
      </Flex>
      <S.NameGroup>
        <Card>
          <Flex>
            <Controller
              control={control}
              rules={{ required: true }}
              name="name"
              render={({ onChange, value }) => (
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="Insira um nome para o perfil que está criando"
                  label="Nome do perfil"
                  type="text"
                  maxLength="30"
                  required
                  width="501px"
                  status={errors.name && 'error'}
                  statusMessage={errors.name && 'Campo requerido'}
                />
              )}
            />
          </Flex>
        </Card>
      </S.NameGroup>

      <S.PermissionGroup>
        <Tabs position="start" onChange={onTabChange}>
          <li label="Permissões">
            <PermissionsTab control={control} roles={roles} />
          </li>

          <li label={method !== 'create' ? 'Usuários vinculados' : ''}>
            {method !== 'create' && (
              <TableContainer>
                <TableUsers columns={['active', 'userName', 'userEmail']} />
              </TableContainer>
            )}
          </li>
        </Tabs>
      </S.PermissionGroup>
    </form>
  );
};
Form.propTypes = {
  editProfile: PropTypes.object,
  breadcrumb: PropTypes.array,
  onSubmit: PropTypes.func,
  method: PropTypes.string,
  roles: PropTypes.array,
};

Form.defaultProps = {
  editProfile: null,
  breadcrumb: [{ href: '#' }],
  method: 'create',
  onSubmit: () => {},
  roles: [],
};

export default Form;
