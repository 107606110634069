import styled from 'styled-components';

import { Tabs } from 'components/system/core';
import Tokens from 'components/system/tokens';

export const ContainerTable = styled.div`
  position: relative;
`;

export const ContainerTableStick = styled.div`
  header {
    position: sticky;
    top: 60px;
    background-color: ${Tokens.colors.gray100};
  }
  table {
    thead,
    th {
      position: sticky;
      top: 130px;
    }
  }
`;

export const MainContainer = styled.div`
  min-height: 1000px;
`;

export const OverflowTabs = styled(Tabs)`
  ol {
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 8px;

    ::-webkit-scrollbar {
      height: 4px;
      border-radius: 2px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;

      &:hover {
        height: 2px;
        cursor: pointer;
        background: ${Tokens.colors.accentPrimaryLight};
      }
    }

    ::-webkit-scrollbar-thumb {
      background: ${Tokens.colors.accentPrimaryDark};
    }
  }
`;
