import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'components/system/typography';

import Tooltip from 'components/core/Tooltip';

import * as S from './CellGroupItemsStyle';

const CellGroupItems = ({ cell: { value }, transformedData, row, onClick }) => {
  const { original } = row;

  const handleOnClick = () => {
    if (original) {
      onClick(original);
    }
  };

  const tooltipContent = transformedData(value);

  if (value.length === 1) {
    return (
      <Text
        size="medium"
        data-testid={`cell-table-${value}`}
        style={{ fontWeight: '400' }}
        onClick={handleOnClick}
      >
        {transformedData(value)}
      </Text>
    );
  }

  return (
    <Tooltip
      title={tooltipContent}
      multiline
      place="top"
      data-testid="group-items-tooltip"
    >
      <Text data-testid="group-items-tooltip-text" onClick={handleOnClick}>
        <S.Trigger>Ver itens</S.Trigger>
      </Text>
    </Tooltip>
  );
};

CellGroupItems.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  transformedData: PropTypes.func,
  onClick: PropTypes.func,
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      statusTooltip: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

CellGroupItems.defaultProps = {
  cell: {
    value: '',
  },
  transformedData: (text) => text,
  onClick: () => {},
};

export default CellGroupItems;
