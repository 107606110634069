import styled from 'styled-components';

import Tokens from 'components/system/tokens';

export const NotifySidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;

  background-color: ${Tokens.colors.grayWhite};

  width: ${({ isOpen }) => (!isOpen ? 0 : '398px')};
  height: 100vh;

  border-top-left-radius: ${Tokens.spacing.xs};
  border-bottom-left-radius: ${Tokens.spacing.xs};

  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;

  box-shadow: ${Tokens.effects.cardShadow};
  transition: all 0.3s ease;

  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  pointer-events: ${({ isOpen }) => (isOpen ? 'all' : 'none')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
`;

export const NotifySidebarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${Tokens.spacing.md} ${Tokens.spacing.sm};
  gap: ${Tokens.spacing.md};

  width: 100%;

  > div {
    display: flex;
    align-items: center;
    gap: ${Tokens.spacing.sm};
  }

  h3 {
    font: ${Tokens.fonts.headingRegularH3};
    color: ${Tokens.colors.gray500};
  }

  span {
    font: ${Tokens.fonts.textRegularSmall};
    color: ${Tokens.colors.gray400};
  }

  button {
    border: none;
    margin: 0;
    padding: 0;
    background: transparent;
    height: auto;
    display: flex;

    cursor: pointer;
  }
`;

export const NotifySidebarUnread = styled.div`
  position: relative;
`;

export const NotifySidebarUnreadNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: -4px;
  right: -4px;
  z-index: 2;

  border-radius: ${Tokens.spacing.xss};
  padding: 0 ${Tokens.spacing.xss};

  width: auto;
  height: 16px;

  background-color: ${Tokens.colors.accentSecondaryDark};
  color: ${Tokens.colors.grayWhite};

  font: ${Tokens.fonts.textRegularSmall};
  font-size: 10px;
  text-align: center;
`;

export const NotifySidebarContent = styled.div`
  height: 100%;
`;

export const NotifySidebarEmpty = styled.div`
  width: 100%;

  padding: ${Tokens.spacing.sm};

  span {
    font: ${Tokens.fonts.textRegularMedium};
    color: ${Tokens.colors.gray500};
  }
`;
