import PropTypes from 'prop-types';
import React from 'react';

import Label from '../label';

import { Container, ContainerInput } from './styles';

const Switch = ({
  label,
  checked,
  disabled,
  labelAlign,
  onChange,
  ...attrs
}) => (
  <Container id="switch" data-testid="ds-switch">
    {labelAlign === 'left' && <Label>{label}</Label>}
    <ContainerInput>
      <input
        checked={checked}
        disabled={disabled}
        onChange={(e) => {
          onChange(e);
        }}
        {...attrs}
        type="checkbox"
      />
      <span className="slider round" />
    </ContainerInput>
    {labelAlign === 'right' && label && <Label>{label}</Label>}
  </Container>
);

Switch.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  labelAlign: PropTypes.string,
  onChange: PropTypes.func,
};

Switch.defaultProps = {
  label: '',
  checked: false,
  disabled: false,
  labelAlign: 'right',
  onChange: () => {},
};

export default Switch;
