import css from '@styled-system/css';
import styled from 'styled-components';
import { compose, layout, space, variant } from 'styled-system';

import theme from '../../tokens';

export const ContentTabs = styled('div')(
  css({
    color: theme.colors.gray400,
  }),
  compose(space, layout),
);

const halfOpacity = '80';

export const Ol = styled('ol')(
  css({
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: '0',
    div: { display: 'inline-block' },
    borderBottom: `1px solid ${theme.colors.gray200}${halfOpacity}`,
    li: {
      marginRight: theme.spacing.sm,
      filter: 'grayscale(100%)',
      font: theme.fonts.textRegularMedium,
      '&:last-of-type': {
        marginRight: '0',
      },
    },
    'li.tab-list-active': {
      font: theme.fonts.textBoldMedium,
      filter: 'none',
    },
  }),

  variant({
    variants: {
      center: {
        justifyContent: 'center',
      },
      end: {
        justifyContent: 'end',
      },
      start: {
        justifyContent: 'start',
      },
      spaceAround: {
        justifyContent: 'space-around',
      },
      spaceBetween: {
        justifyContent: 'space-between',
      },
    },
  }),
  variant({
    prop: 'activeColor',
    variants: {
      primaryDark: {
        'li.tab-list-active': {
          color: theme.colors.accentPrimaryDark,
          svg: {
            path: {
              fill: theme.colors.accentPrimaryDark,
            },
          },
        },
      },
      secondaryDark: {
        'li.tab-list-active': {
          color: theme.colors.accentSecondaryDark,
          svg: {
            path: {
              fill: theme.colors.accentSecondaryDark,
            },
          },
        },
      },
      darkGray: {
        'li.tab-list-active': {
          color: theme.colors.gray500,
          svg: {
            path: {
              fill: theme.colors.gray500,
            },
          },
        },
      },
      gray: {
        'li.tab-list-active': {
          color: theme.colors.gray300,
          svg: {
            path: {
              fill: theme.colors.gray300,
            },
          },
        },
      },
      green: {
        'li.tab-list-active': {
          color: theme.colors.green300,
          svg: {
            path: {
              fill: theme.colors.green300,
            },
          },
        },
      },
      lime: {
        'li.tab-list-active': {
          color: theme.colors.lime300,
          svg: {
            path: {
              fill: theme.colors.lime300,
            },
          },
        },
      },
      yellow: {
        'li.tab-list-active': {
          color: theme.colors.yellow300,
          svg: {
            path: {
              fill: theme.colors.yellow300,
            },
          },
        },
      },
      orange: {
        'li.tab-list-active': {
          color: theme.colors.orange300,
          svg: {
            path: {
              fill: theme.colors.orange300,
            },
          },
        },
      },
      red: {
        'li.tab-list-active': {
          color: theme.colors.red300,
          svg: {
            path: {
              fill: theme.colors.red300,
            },
          },
        },
      },
      pink: {
        'li.tab-list-active': {
          color: theme.colors.pink300,
          svg: {
            path: {
              fill: theme.colors.pink300,
            },
          },
        },
      },
      violet: {
        'li.tab-list-active': {
          color: theme.colors.violet300,
          svg: {
            path: {
              fill: theme.colors.violet300,
            },
          },
        },
      },
      blue: {
        'li.tab-list-active': {
          color: theme.colors.blue300,
          svg: {
            path: {
              fill: theme.colors.blue300,
            },
          },
        },
      },
      steel: {
        'li.tab-list-active': {
          color: theme.colors.steel300,
          svg: {
            path: {
              fill: theme.colors.steel300,
            },
          },
        },
      },
    },
  }),
);

export const ContainerBorder = styled('div')(
  css({
    width: '100%',
  }),
);

export const Span = styled('span')(
  css({
    marginBottom: '10px',
  }),
);

export const Li = styled('li')(
  css({
    position: 'relative',
    display: 'grid',
    alignContent: 'center',
    justifyItems: 'center',
    minWidth: '90px',
    ':hover': {
      cursor: 'pointer',
    },
  }),
  variant({
    variants: {
      bordeLine: {
        borderBottom: `1px solid  ${theme.colors.gray200}`,
      },
      disabled: {
        opacity: '0.3',
      },
      active: {
        borderColor: 'transparent',
        '&::after': {
          position: 'absolute',
          content: '""',
          width: '100%',
          bottom: '-1px',
          height: '3px',
          borderRadius: '3px 3px 0 0',
        },
      },
    },
  }),
  variant({
    prop: 'activeColor',
    variants: {
      primaryDark: {
        '&::after': {
          bg: theme.colors.accentPrimaryDark,
        },
      },
      secondaryDark: {
        '&::after': {
          bg: theme.colors.accentSecondaryDark,
        },
      },
      darkGray: {
        '&::after': {
          bg: theme.colors.gray500,
        },
      },
      gray: {
        '&::after': {
          bg: theme.colors.gray300,
        },
      },
      green: {
        '&::after': {
          bg: theme.colors.green300,
        },
      },
      lime: {
        '&::after': {
          bg: theme.colors.lime300,
        },
      },
      yellow: {
        '&::after': {
          bg: theme.colors.yellow300,
        },
      },
      orange: {
        '&::after': {
          bg: theme.colors.orange300,
        },
      },
      red: {
        '&::after': {
          bg: theme.colors.red300,
        },
      },
      pink: {
        '&::after': {
          bg: theme.colors.pink300,
        },
      },
      violet: {
        '&::after': {
          bg: theme.colors.violet300,
        },
      },
      blue: {
        '&::after': {
          bg: theme.colors.blue300,
        },
      },
      steel: {
        '&::after': {
          bg: theme.colors.steel300,
        },
      },
    },
  }),
);

export const ContainerTab = styled('div')(
  variant({
    variants: {
      disabled: {
        opacity: '0.3',
      },
    },
  }),
);

export const ContentTab = styled('div')(
  css({
    marginTop: '15px',
  }),
  compose(layout, space),
);
