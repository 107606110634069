import { format, isToday, isYesterday } from 'date-fns';
import { pt } from 'date-fns/locale';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Avatar } from 'components/system/core';

import * as S from './CommentItemStyle';

const CommentItem = ({ data, onlineUser, isCollapsible }) => {
  const [collapsed, setCollapsed] = useState(true);

  const isCommentAvatarOnline =
    onlineUser === data.userName ? 'online' : 'offline';

  const formatCommentDate = (commentDate, options) => {
    const date = new Date(commentDate);

    if (isYesterday(date)) {
      return format(date, "'Ontem', HH:mm", options);
    }
    if (isToday(date)) {
      return format(date, "'Hoje', HH:mm", options);
    }
    return format(date, "dd 'de' MMM, HH:mm", options);
  };

  const formattedDate = formatCommentDate(data.createAt, {
    locale: pt,
    addSuffix: false,
  });

  const getTextToShow = (text) => {
    if (collapsed) {
      return `${text.slice(0, 150)}...`;
    }

    return text;
  };

  const toggleCollapsed = () => {
    setCollapsed((prevState) => !prevState);
  };

  return (
    <S.Comment data-testid="comment-item">
      <S.Header data-testid={`comment-avatar-${isCommentAvatarOnline}`}>
        <Avatar userName={data.userName} status={isCommentAvatarOnline} />

        <S.User data-testid="comment-author">{data.userName}</S.User>

        {data?.activity && <S.Activity>{data.activity}</S.Activity>}

        <S.Time data-testid="comment-time">{formattedDate}</S.Time>
      </S.Header>

      {data?.commentDescription &&
        (isCollapsible && data.commentDescription.length > 150 ? (
          <S.Message data-testid="comment-message-collapsible">
            {getTextToShow(data.commentDescription)}

            <button
              type="button"
              data-testid="comment-collapse"
              onClick={toggleCollapsed}
            >
              {collapsed ? 'ver' : 'menos'}
            </button>
          </S.Message>
        ) : (
          <S.Message data-testid="comment-message">
            {data.commentDescription}
          </S.Message>
        ))}
    </S.Comment>
  );
};

CommentItem.propTypes = {
  data: PropTypes.shape({
    userName: PropTypes.string,
    createAt: PropTypes.string,
    commentDescription: PropTypes.string,
  }).isRequired,
  onlineUser: PropTypes.string.isRequired,
  isCollapsible: PropTypes.bool,
};

CommentItem.defaultProps = {
  isCollapsible: false,
};

export default CommentItem;
