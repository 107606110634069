import PropTypes from 'prop-types';
import React from 'react';

import Tokens from 'components/system/tokens';

import Headlight from 'components/core/headlight';
import Tooltip from 'components/core/Tooltip';

const StatusSegment = ({ cell: { value } }) => (
  <Tooltip title={value ? 'Ativado' : 'Desativado'}>
    <Headlight
      data-testid="cell-table"
      color={value ? Tokens.colors.green300 : Tokens.colors.red300}
      style={{ cursor: 'pointer' }}
    />
  </Tooltip>
);

StatusSegment.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

StatusSegment.defaultProps = {
  cell: {
    value: '',
  },
};

export default StatusSegment;
