import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, EmptyState } from 'components/system/core';
import { Date as InputDate } from 'components/system/form';

import ContractAdditionsList from 'components/contexts/contract/ContractList/AdditionsSublist/AdditionsSublist';
import { BlurBackground } from 'components/core/FilterDs/FilterDs.styles';
import Loading from 'components/core/Loading';
import ModalConfirm from 'components/core/ModalConfirm';
import Table from 'components/core/Table';
import TableContainer from 'components/core/TableContainer';

import { getDieselReportParams, getFilterParams } from 'lib/contexts/contracts';
import { isFinished, isPending } from 'lib/core/request';

import usePagination from 'hooks/usePagination';
import useSearchParams from 'hooks/useSearchParams';

import { ContractActions } from 'store/contract/contract.ducks';

import Columns from './Columns';
import DefaultFlagModal from './DefaultFlagModal/DefaultFlagModal';
import DieselReportDownload from './DieselReportDownload';
import FilterContracts from './FilterContracts';
import CloseContractModal from './Modals/CloseContractModal';

const ContractList = () => {
  const dispatch = useDispatch();
  const {
    contractRequests,
    filter,
    data,
    rowEdit,
    editMode,
    changeEditOpen,
    totalClient,
    isDieselReportModalOpen,
    rowReportDownload,
    isDeleteModalOpen,
    rowDelete,
    isJustifyModalOpen,
    isDefaultFlagModalOpen,
  } = useSelector(({ contract }) => contract);

  const { filterData, viewScenarioData } = filter;
  const { paginationSize, setValuePage } = usePagination();

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [errorMessage, setErrorMessage] = useState('');

  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');
  const size = searchParams.get('size');

  const closeModal = () => {
    dispatch(
      ContractActions.setEditModalOpen({
        modalOpen: false,
      }),
    );
    dispatch(ContractActions.resetContractRows());
  };

  const load = () => {
    const params = getFilterParams(
      {
        ...filterData,
      },
      viewScenarioData,
    );

    dispatch(ContractActions.listContract(params, filter));
  };

  const confirmChange = () => {
    const payload = {
      id: rowEdit.id,
      code: rowEdit.code,
      userId: rowEdit.accountManagerUserId,
    };
    dispatch(
      ContractActions.setEditModalOpen({
        modalOpen: false,
      }),
    );
    dispatch(ContractActions.updateContract(payload, load));
  };

  const closeDeleteModal = () => {
    dispatch(
      ContractActions.setDeleteModalOpen({
        modalOpen: false,
      }),
    );
  };

  const handleDeleteContract = () => {
    const params = getFilterParams(
      {
        ...filterData,
      },
      viewScenarioData,
    );

    const callback = () =>
      dispatch(ContractActions.listContract(params, filter));

    dispatch(
      ContractActions.setDeleteModalOpen({
        modalOpen: false,
      }),
    );

    dispatch(ContractActions.deleteContract(rowDelete.id, callback));
  };

  const handleDownloadDieselReport = () => {
    if (!startDate || !endDate) {
      setErrorMessage(
        'Os campos acima são obrigatórios para a geração do relatório.',
      );
    }
    if (startDate && endDate && rowReportDownload.id) {
      const formParams = getDieselReportParams(
        startDate,
        endDate,
        rowReportDownload.id,
      );
      dispatch(
        ContractActions.exportDieselReport({
          row: { ...rowReportDownload, startDate, endDate },
          type: 0,
          formParams,
        }),
      );
      dispatch(ContractActions.setReportModalOpen({ modalOpen: false }));
      setErrorMessage('');
      setStartDate(null);
      setEndDate(null);
    }
  };

  const handleCloseDieselReportModal = () => {
    dispatch(ContractActions.setReportModalOpen({ modalOpen: false }));
    setErrorMessage('');
    setStartDate(null);
    setEndDate(null);
  };

  const onPageChange = (PageIndex) => {
    setSearchParams('page', PageIndex);

    const params = getFilterParams(
      {
        ...filterData,
        pageIndex: PageIndex,
        pageSize: +size,
      },
      viewScenarioData,
    );

    dispatch(ContractActions.listContract(params, filter));
  };

  const onPerPageChange = (PageSize) => {
    setSearchParams('size', PageSize);
    setSearchParams('page', 1);
    setValuePage(PageSize);

    const params = getFilterParams(
      {
        ...filterData,
        pageIndex: 1,
        pageSize: PageSize,
      },
      viewScenarioData,
    );

    dispatch(ContractActions.listContract(params, filter));
  };

  useEffect(() => {
    const filterParams = { ...filterData };

    if (page) filterParams.pageIndex = +page;
    if (size) {
      filterParams.pageSize = +size;
      setValuePage(+size);
    }

    const params = getFilterParams(filterParams, viewScenarioData);

    dispatch(ContractActions.listContract(params, filter));
  }, []);

  const isLoading =
    isPending(contractRequests.LIST_CONTRACT) ||
    isPending(contractRequests.CLOSE_CONTRACT) ||
    isPending(contractRequests.EXPORT_DIESEL_REPORT) ||
    isPending(contractRequests.UPDATE_DEFAULT_FLAG);

  return (
    <TableContainer data-testid="contract-list-test">
      <FilterContracts totalTo={data.totalCount} totalFrom={totalClient} />

      {isLoading && <Loading />}

      {data.items?.length && !isLoading ? (
        <Table
          data-testid="contractlist-test"
          width="100%"
          isEditMode={editMode}
          columns={Columns}
          isSubComponent
          renderRowSubComponent={({ row }) => (
            <ContractAdditionsList data={row.original?.contractRelatives} />
          )}
          data={data.items}
          customList={paginationSize}
          sortBy={[{ id: 'accountManager', desc: false }]}
          onPageChange={onPageChange}
          onPerPageChange={onPerPageChange}
          footer
          pageIndex={data.currentPage}
          pageCount={data.totalPages}
          totalItems={data.totalCount}
        />
      ) : (
        isFinished(contractRequests.LIST_CONTRACT) &&
        !isLoading && (
          <Card>
            <EmptyState
              condensed={false}
              title="Nenhuma formalização encontrada."
            />
          </Card>
        )
      )}

      {changeEditOpen && rowEdit && (
        <ModalConfirm
          closeModal={closeModal}
          confirmAction={confirmChange}
          title="Editar formalização"
          message={`Tem certeza que deseja editar a formalização selecionada?
          <br>
          <br>
          Codigo: <b>${rowEdit.code}</b> <br>
          Gerente do contrato: <b>${rowEdit.accountManager}</b>`}
        />
      )}

      {isDieselReportModalOpen && (
        <>
          <BlurBackground />
          <DieselReportDownload
            title="Data de histórico diesel"
            onClose={handleCloseDieselReportModal}
            onConfirm={handleDownloadDieselReport}
            errorMessage={errorMessage}
          >
            <InputDate
              label="Período inicial"
              format="dd/MM/yyyy"
              value={startDate}
              onChange={(e) => setStartDate(e)}
              minDate={
                rowReportDownload && new Date(rowReportDownload.startPeriod)
              }
            />
            <InputDate
              label="Período final"
              format="dd/MM/yyyy"
              value={endDate}
              onChange={(e) => setEndDate(e)}
              minDate={startDate && new Date(startDate)}
              maxDate={
                rowReportDownload && new Date(rowReportDownload.endPeriod)
              }
            />
          </DieselReportDownload>
        </>
      )}

      {isDeleteModalOpen && (
        <ModalConfirm
          closeModal={closeDeleteModal}
          confirmAction={handleDeleteContract}
          title="Deletar formalização"
          message={`Tem certeza que deseja excluir a formalização? ${rowDelete.code}? Ao excluir não será mais efetuado o reajuste tarifário e não será possível enviar tarifas para o Flink.`}
        />
      )}

      {isJustifyModalOpen && <CloseContractModal />}

      {isDefaultFlagModalOpen && <DefaultFlagModal />}
    </TableContainer>
  );
};

export default ContractList;
