export const LOGIN = '/login';
export const HOME = '/';
export const DASHBOARD = `${HOME}dashboard`;
export const CONTRACTS = `${HOME}contratos-comerciais`;
export const CONTRACTS_CONTROL = `${HOME}controle-de-contratos`;
export const CONTRACT_DETAILS = (id, statusIsClosed) =>
  `${HOME}contratos-comerciais/${id}/${statusIsClosed ? 'encerrado' : 'ativo'}`;
export const CONTRACT = `${HOME}contrato`;
export const RAILWAY_ITEMS_HISTORY = `${CONTRACTS}/item-ferroviario`;
export const RAILWAY_ITEMS_HISTORY_PARAMS = (
  id,
  status = 'ativo',
  groupedItems = null,
) => {
  if (groupedItems) {
    return `${CONTRACTS}/item-ferroviario/${id}/${status}?groupedItems=${groupedItems}`;
  }

  return `${CONTRACTS}/item-ferroviario/${id}/${status}`;
};
export const MACROECONOMIC_INDICES_PARAMS = (indices) =>
  `${HOME}atualizacao-de-indices/${indices}`;
export const MACROECONOMIC_INDICES = `${HOME}atualizacao-de-indices`;
export const REAJUSTMENT_DIESEL_INDICES = `${HOME}atualizacao-de-indices/reajuste-de-diesel`;
export const REAJUSTMENT_INFLATION_INDICES = `${HOME}atualizacao-de-indices/reajuste-de-inflacao`;
export const MACROECONOMIC_INDICES_HISTORY = `${HOME}indices-macroeconomicos`;
export const SPREADSHEET_IMPORT = `${HOME}importar-contrato`;
export const STATUS = `${HOME}status`;
export const PROFILE_LIST = `${HOME}perfis`;
export const NEW_PROFILE = `${HOME}perfis/novo`;
export const EDIT_PROFILE = (id) => `${HOME}perfis/${id}/editar`;
export const FORBIDDEN = `${HOME}proibido-acesso`;
export const USER_LIST = `${HOME}usuarios`;
export const NEW_USER = `${HOME}usuarios/novo`;
export const EDIT_USER = (id) => `${HOME}usuarios/${id}/editar`;
export const LOCAL = `${HOME}lista-base-de-diesel`;
export const ITEMS = `${HOME}novos-itens`;
export const NEW_ITEM = `${HOME}novos-itens/novo`;
export const NEW_ITEM_DRAFT = (id) => `${HOME}novos-itens/novo/${id}`;
export const EMAIL_NOTIFICATIONS_CLIENT_LIST = `${HOME}email-notificacoes-clientes`;
export const EMAIL_NOTIFICATIONS_CLIENT_CREATE = (id) =>
  `${HOME}email-notificacoes-clientes/${id}`;
export const EMAIL_NOTIFICATIONS_MANAGER_LIST = `${HOME}email-notificacoes-gerentes`;
export const EMAIL_NOTIFICATIONS_MANAGER_CREATE = (id) =>
  `${HOME}email-notificacoes-gerentes/${id}`;
export const REPORT_CONTRACT_ITEMS = `${HOME}relatorios-contrato`;
export const REPORT_CONTRACT_ITEMS_UPDATE = (id) =>
  `${HOME}relatorios-contrato-itens/${id}`;
export const SEGMENT_LIST = `${HOME}segmentos`;
export const NEW_SEGMENT = `${HOME}segmentos/novo`;
export const EDIT_SEGMENT = (id) => `${HOME}segmentos/${id}/editar`;
export const CONTRACT_REPORT = `${HOME}gestao-contratual`;
export const RESEND_ITEMS = `${HOME}reenvio-de-itens`;
export const REACTIVATE_ITEMS = `${HOME}reativar-itens`;
export const SUPPLIER_LIST = `${HOME}fornecedores`;
export const MODELS = `${HOME}modelos`;
export const NEW_SUPPLIER = `${HOME}fornecedores/novo`;
export const EDIT_SUPPLIER = (id) => `${HOME}fornecedores/${id}/editar`;
export const FORMULAS = `${HOME}formulas`;
export const FORMALIZATION_REQUEST_PROPOSAL = (proposalCode) =>
  `${HOME}solicitacao-formalizacao/novo/${proposalCode}`;
export const FORMALIZATION_REQUEST = (action, formalizationId) =>
  `${HOME}solicitacao-formalizacao/${action}/${formalizationId}`;
export const REQUESTS_LIST = `${HOME}solicitacoes`;
export const CLIENT_LIST = `${HOME}clientes`;
export const COMMERCIAL_NEGOTIATIONS = `${HOME}negociacoes-comerciais`;
export const SYSTEM_PARAMETERS = `${HOME}parametros`;
export const PRICE_RECOMMENDATION = `${HOME}recomendacao-preco`;
export const WAITING_RECOMMENDATION = `${HOME}aguardando-recomendacao`;
