import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Multiselect from 'components/form/CustomMultiselect';
import { Tooltip } from 'components/system/core';
import { Input, Label } from 'components/system/form';

import { PARAMETERS_TYPE } from 'lib/contexts/systemParameters';

import * as S from './ParameterFieldStyles';

const ParameterField = ({
  id,
  parameterTypeId,
  parameterName,
  value,
  options,
  handleOnChangeField,
  parameterDescription,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(value.ids || []);
  const [timeIntervalInputs, setTimeIntervalInputs] = useState({
    Start: value?.start || null,
    End: value?.end || null,
  });

  const createListItems = (initialOptions) => {
    return initialOptions.map((option) => ({
      label: option.value,
      value: {
        id: option.key,
      },
      selected: !!selectedOptions?.find(
        (selectedOption) => selectedOption === option.key,
      ),
    }));
  };

  const handleTimeIntervalChange = (name, data) => {
    setTimeIntervalInputs((prevState) => ({
      ...prevState,
      [name]: `${data}:00.000`,
    }));
  };

  const handleMultiSelectChange = (inputOptions) => {
    const optionsIds = inputOptions?.map((option) => option.value.id);
    setSelectedOptions(optionsIds);
    handleOnChangeField(id, { Ids: optionsIds });
  };

  const handleNumberInput = (data) => {
    handleOnChangeField(id, { Number: Number(data) });
  };

  useEffect(() => {
    if (timeIntervalInputs.Start && timeIntervalInputs.End) {
      if (
        timeIntervalInputs.Start !== value.start ||
        timeIntervalInputs.End !== value.end
      ) {
        handleOnChangeField(id, timeIntervalInputs);
      }
    }
  }, [timeIntervalInputs, id]);

  const renderField = () => {
    switch (parameterTypeId) {
      case PARAMETERS_TYPE.TIME_INTERVAL:
        return (
          <>
            <Tooltip place="top" title={parameterDescription} multiline>
              <Label mb="4px">{parameterName}</Label>
            </Tooltip>

            <S.TimeIntervalContainer>
              <Input
                onChange={(data) => handleTimeIntervalChange('Start', data)}
                name={`parameter-${id}-start`}
                value={value.start}
                type="time"
              />
              <Input
                onChange={(data) => handleTimeIntervalChange('End', data)}
                name={`parameter-${id}-end`}
                value={value.end}
                type="time"
              />
            </S.TimeIntervalContainer>
          </>
        );

      case PARAMETERS_TYPE.SELECT:
        return (
          <>
            <Tooltip place="top" title={parameterDescription} multiline>
              <Label mb="4px">{parameterName}</Label>
            </Tooltip>

            <Multiselect
              onChange={(data) => handleMultiSelectChange(data)}
              name={`parameter-${id}-profile`}
              keyValue="id"
              placeholder="Selecione"
              listItems={createListItems(options)}
              required
              selected={value.ids}
            />
          </>
        );

      case PARAMETERS_TYPE.NUMBER:
        return (
          <>
            <Tooltip place="top" title={parameterDescription} multiline>
              <Label mb="4px">{parameterName}</Label>
            </Tooltip>

            <Input
              onChange={(data) => handleNumberInput(data)}
              name={`parameter-${id}-number`}
              value={String(value.number)}
              type="number"
              required
            />
          </>
        );

      default:
        return <></>;
    }
  };

  return <div>{renderField()}</div>;
};

ParameterField.propTypes = {
  id: PropTypes.number.isRequired,
  parameterTypeId: PropTypes.number.isRequired,
  parameterName: PropTypes.string.isRequired,
  parameterDescription: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  handleOnChangeField: PropTypes.func.isRequired,
};

export default ParameterField;
