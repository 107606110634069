import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import CloseIcon from 'assets/system/svgs/close.svg';

import { Heading } from '../../typography';
import Icon from '../icon';

import ModalContent from './content';
import ModalFooter from './footer';

import {
  Close,
  Header,
  ModalOverlay,
  ModalStyle,
  ModalWrapper,
} from './styles';

const Modal = ({
  isOpen,
  title,
  onClose,
  children,
  actions,
  width,
  ...attrs
}) => {
  const [isShowing, setIsShowing] = useState(isOpen);

  useEffect(() => setIsShowing(isOpen), [isOpen]);

  const toggle = () => {
    onClose(!isShowing);
    setIsShowing(!isShowing);
  };

  return (
    isShowing && (
      <ModalOverlay id="modal" data-testid="ds-modal">
        <ModalWrapper>
          <ModalStyle width={width} {...attrs}>
            <Header>
              <Heading tag="h5">{title}</Heading>
              <Close
                style={{ transform: 'translateY(1.5px)' }}
                onClick={toggle}
                data-testid="ds-modal-close"
              >
                <Icon src={CloseIcon} />
              </Close>
            </Header>
            <ModalContent>{children}</ModalContent>
            {actions && <ModalFooter>{actions}</ModalFooter>}
          </ModalStyle>
        </ModalWrapper>
      </ModalOverlay>
    )
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
  actions: PropTypes.node,
  width: PropTypes.string,
};

Modal.defaultProps = {
  isOpen: false,
  title: '',
  children: '',
  onClose: () => {},
  actions: '',
  width: '480px',
};

export default Modal;
