import PropTypes from 'prop-types';
import React from 'react';

import Label from '../label';

import { RadioContainer, RadioStyle } from './styles';

const Radio = ({ onChange, label, checked, disabled, ...attrs }) => (
  <RadioContainer>
    <input
      type="radio"
      disabled={disabled}
      checked={checked}
      onChange={(e) => onChange(e)}
      data-testid="ds-input-radio"
      {...attrs}
    />
    <RadioStyle
      className={(checked && 'checked') || (disabled && 'disabled')}
    />
    {label && <Label>{label}</Label>}
  </RadioContainer>
);

Radio.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};

Radio.defaultProps = {
  onChange: () => {},
  label: '',
  checked: false,
  disabled: false,
};

export default Radio;
