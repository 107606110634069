import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'components/system/typography';

import {
  enableDownload,
  enableEdit,
  isItemTypeInflation,
} from 'lib/contexts/indices';

import * as S from './IndicesHistoryTable.styles';

const IndicesHistoryTableOptions = ({
  item,
  letterIds,
  handleOpenAccumulateHistoryModal,
  handleOpenInflationHistoryAccumulateModal,
  handleOptionItemEditClick,
  handleOptionItemDownloadClick,
}) => {
  return (
    <S.OptionsContainer data-testid="indices-history-table-options-test">
      {enableEdit(item) && (
        <S.OptionItem
          onClick={() => handleOptionItemEditClick(item)}
          data-testid="indices-history-table-option-edit-test"
        >
          <Text
            size="medium"
            style={{
              fontWeight: '400',
            }}
          >
            Editar
          </Text>
        </S.OptionItem>
      )}
      {enableDownload(item, letterIds) && (
        <>
          <S.OptionItem
            onClick={() => handleOptionItemDownloadClick(item, letterIds)}
            data-testid="indices-history-table-option-download-test"
          >
            <Text
              size="medium"
              style={{
                fontWeight: '400',
              }}
            >
              Download
            </Text>
          </S.OptionItem>
          <S.OptionItem
            onClick={() => handleOpenAccumulateHistoryModal(item)}
            data-testid="indices-history-table-option-diesel-test"
          >
            <Text
              size="medium"
              style={{
                fontWeight: '400',
              }}
            >
              Acúmulo do diesel
            </Text>
          </S.OptionItem>
        </>
      )}
      {isItemTypeInflation(item) && (
        <S.OptionItem
          onClick={() => handleOpenInflationHistoryAccumulateModal(item)}
          data-testid="indices-history-table-option-inflation-test"
        >
          <Text
            size="medium"
            style={{
              fontWeight: '400',
            }}
          >
            Acúmulo da inflação
          </Text>
        </S.OptionItem>
      )}
    </S.OptionsContainer>
  );
};

IndicesHistoryTableOptions.propTypes = {
  item: PropTypes.object.isRequired,
  letterIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleOpenAccumulateHistoryModal: PropTypes.func.isRequired,
  handleOpenInflationHistoryAccumulateModal: PropTypes.func.isRequired,
  handleOptionItemEditClick: PropTypes.func.isRequired,
  handleOptionItemDownloadClick: PropTypes.func.isRequired,
};

export default IndicesHistoryTableOptions;
