import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, EmptyState } from 'components/system/core';

import Loading from 'components/core/Loading';
import ModalConfirm from 'components/core/ModalConfirm';
import Table from 'components/core/Table';
import TableContainer from 'components/core/TableContainer';

import { getFilterParams } from 'lib/contexts/newItems';
import { isFinished, isPending } from 'lib/core/request';

import { NewItemsActions } from 'store/newItems/newItems.ducks';

import Columns from './Columns/Columns';
import FilterNewItems from './FilterNewItems';

const NewItems = () => {
  const dispatch = useDispatch();
  const {
    newItemsRequests,
    newItemsList,
    filter,
    editMode,
    changeEditOpen,
    rowEdit,
  } = useSelector(({ newItems }) => newItems);
  const { localRequests } = useSelector(({ local }) => local);
  const { segmentRequests } = useSelector(({ segment }) => segment);
  const { activeContractRequests } = useSelector(
    ({ activeContracts }) => activeContracts,
  );

  const onPageChange = (PageIndex) => {
    const { pageSize } = newItemsList;
    const data = getFilterParams({ ...filter, pageIndex: PageIndex, pageSize });
    dispatch(NewItemsActions.newItemsListRequest(data, filter));
  };

  const onPerPageChange = (PageSize) => {
    const { pageIndex } = newItemsList;
    const data = getFilterParams({ ...filter, pageIndex, pageSize: PageSize });
    dispatch(NewItemsActions.newItemsListRequest(data, filter));
  };

  const reloadNewItems = () => {
    dispatch(NewItemsActions.newItemsListRequest());
  };

  const handleConfirmEdit = () => {
    const payload = {
      id: rowEdit.id,
      newContractCode: rowEdit.codeContract,
      codeItem: rowEdit.codeItem,
    };

    dispatch(NewItemsActions.setEditModalOpen({ modalOpen: false }));
    dispatch(NewItemsActions.linkNewContractRequest(payload, reloadNewItems));
  };

  const handleCloseModal = () => {
    dispatch(NewItemsActions.setEditModalOpen({ modalOpen: false }));
    dispatch(NewItemsActions.resetNewItemsRow());
  };

  const loading =
    isPending(localRequests.LOCAL_FILTERED_LIST) ||
    isPending(activeContractRequests.ACTIVE_CONTRACT) ||
    isPending(newItemsRequests.NEW_ITEMS_LIST_REQUEST) ||
    isPending(newItemsRequests.GET_ITEMS_FOR_FILTER_REQUEST) ||
    isPending(segmentRequests.LIST_SEGMENT_ACTIVE);

  const newItemsListFormatted = useMemo(() => {
    return newItemsList?.items?.map((newItem) => {
      return {
        ...newItem,
        origin: `${newItem.origin} (${newItem.originCode})`,
        destination: `${newItem.destination} (${newItem.destinationCode})`,
        updateAt: newItem.updateAt ? newItem.updateAt : newItem.createdAt,
      };
    });
  }, [newItemsList.items]);

  const previousContract = newItemsList?.items?.find(
    (newItem) => newItem?.codeItem === rowEdit?.codeItem,
  );

  return (
    <TableContainer>
      <FilterNewItems />
      {loading && <Loading />}

      {!loading && newItemsList?.items?.length > 0 ? (
        <Table
          data-testid="newItemlist-test"
          width="100%"
          onPageChange={onPageChange}
          onPerPageChange={onPerPageChange}
          footer
          pageIndex={newItemsList.currentPage}
          pageCount={newItemsList.totalPages}
          totalItems={newItemsList.totalCount}
          columns={Columns}
          data={newItemsListFormatted}
          sortBy={['createdAt']}
          isEditMode={editMode}
        />
      ) : (
        isFinished(newItemsRequests.NEW_ITEMS_LIST_REQUEST) &&
        !loading && (
          <Card>
            <EmptyState condensed={false} title="Nenhum item encontrado." />
          </Card>
        )
      )}

      {changeEditOpen && rowEdit && (
        <ModalConfirm
          closeModal={handleCloseModal}
          confirmAction={handleConfirmEdit}
          title="Alterar contrato"
          message={`Tem certeza que deseja desvincular item 
            <b>${rowEdit?.codeItem}</b> do contrato 
            <b>${previousContract?.codeContract}</b> e vincular ao contrato 
            <b>${rowEdit?.codeContract}</b> ?
          `}
        />
      )}
    </TableContainer>
  );
};

export default NewItems;
