import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Tokens from 'components/system/tokens';

import { ActiveContractActions } from 'store/activeContracts/activeContracts.ducks';
import { NewItemsActions } from 'store/newItems/newItems.ducks';

import CellEditAutocompleteWrapper from 'components/core/Table/CellEditAutocompleteWrapper/CellEditAutocompleteWrapper';
import { EditCellSchema } from 'lib/core/tableCellSchemas';

const CellEditAutocomplete = (props) => {
  const { cell, row } = props;

  const dispatch = useDispatch();

  const { activeContractList } = useSelector(
    ({ activeContracts }) => activeContracts,
  );

  const [field, setField] = useState(cell.value);
  const [contractList, setContractList] = useState(activeContractList);

  useEffect(() => {
    if (row.original.editMode) {
      dispatch(ActiveContractActions.activeContract());
    }
  }, []);

  const handleOnInputChange = (newValue) => {
    if (newValue) {
      const filteredContracts = activeContractList.filter((contract) =>
        contract.code.includes(newValue),
      );

      setContractList(filteredContracts);
    }
  };

  const handleOnChange = (e) => {
    setField(e);

    dispatch(
      NewItemsActions.setChangeNewItemsRow({
        field: 'codeContract',
        value: e.label,
      }),
    );
  };

  const selects = contractList?.map((contract) => {
    return {
      label: contract.code,
      value: contract.code,
    };
  });

  return (
    <CellEditAutocompleteWrapper
      {...props}
      onChange={handleOnChange}
      onInputChange={handleOnInputChange}
      selects={selects}
      field={field}
    />
  );
};

CellEditAutocomplete.propTypes = EditCellSchema;

CellEditAutocomplete.defaultProps = {
  cell: {
    value: '',
  },
  row: {
    index: 0,
    original: {
      id: 0,
      editMode: false,
    },
  },
  color: Tokens.colors.grayBlack,
  transformedData: (text) => text,
  align: 'start',
};

export default CellEditAutocomplete;
