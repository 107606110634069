import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';

import Card from '../card';

import Arrow from './arrow';
import Dot from './dot';

import generateUUID from '../../util/generateUUID';

import {
  CarouselContainer,
  Container,
  MainSlide,
  Miniature,
  MiniatureContainer,
} from './styles';

const Slider = ({
  slides,
  showMainPreview,
  darkenNotSelected,
  optionFullWidth,
  miniatures,
  cards,
  dots,
  responsiveMain,
  responsiveMiniature,
  mainAttrs,
  miniatureAttrs,
  ...attrs
}) => {
  const [refCarousel, setRefCarousel] = useState(null);
  const [focus, setFocus] = useState(2);

  const renderSlides = () =>
    slides.map((component) => (
      <MainSlide key={generateUUID()}>{component}</MainSlide>
    ));

  const renderMiniatures = () =>
    slides.map((component, index) => (
      <Miniature
        key={generateUUID()}
        isSelected={focus === index || !darkenNotSelected}
        optionFullWidth={optionFullWidth}
      >
        {component}
      </Miniature>
    ));

  const formatMiniature = (options) => {
    const config = { ...options };

    if (optionFullWidth) {
      Object.keys(config).forEach((propName) => {
        config[propName].items = 1;
        config[propName].partialVisibilityGutter = 0;
      });
    }

    return config;
  };
  const renderSecondSlide = () => {
    if (cards) {
      const shouldShowDots = dots && !showMainPreview && optionFullWidth;
      return (
        <Card title={slides[focus - 2] && slides[focus - 2].props.title}>
          <MiniatureContainer className="cards-carousel">
            <Carousel
              arrows
              infinite
              responsive={formatMiniature(responsiveMiniature)}
              slidesToSlide={1}
              focusOnSelect
              customLeftArrow={<Arrow left />}
              customRightArrow={<Arrow />}
              afterChange={(previousSlide, { currentSlide }) => {
                if (refCarousel) {
                  const selectedSlide = currentSlide % slides.length;
                  refCarousel.goToSlide(selectedSlide, {
                    skipAfterChange: true,
                    skipBeforeChange: true,
                  });
                  setFocus(selectedSlide);
                } else {
                  setFocus(currentSlide);
                }
              }}
              {...miniatureAttrs}
              showDots={shouldShowDots}
              renderDotsOutside={shouldShowDots}
              customDot={<Dot />}
            >
              {renderMiniatures()}
            </Carousel>
          </MiniatureContainer>
        </Card>
      );
    }
    if (miniatures) {
      const shouldShowDots = dots && !showMainPreview && optionFullWidth;
      return (
        <MiniatureContainer>
          <Carousel
            arrows
            infinite
            responsive={formatMiniature(responsiveMiniature)}
            slidesToSlide={1}
            focusOnSelect
            customLeftArrow={<Arrow left />}
            customRightArrow={<Arrow />}
            afterChange={(previousSlide, { currentSlide }) => {
              if (refCarousel) {
                const selectedSlide = currentSlide % slides.length;
                refCarousel.goToSlide(selectedSlide, {
                  skipAfterChange: true,
                  skipBeforeChange: true,
                });
                setFocus(selectedSlide);
              }
            }}
            {...miniatureAttrs}
            showDots={shouldShowDots}
            renderDotsOutside={shouldShowDots}
            customDot={<Dot />}
          >
            {renderMiniatures()}
          </Carousel>
        </MiniatureContainer>
      );
    }
    return null;
  };

  return (
    <Container {...attrs} id="carousel" data-testid="ds-carousel">
      {showMainPreview && (
        <CarouselContainer>
          <Carousel
            showDots={dots && !miniatures}
            renderDotsOutside={dots && !miniatures}
            customDot={<Dot />}
            arrows={!miniatures}
            ref={(el) => setRefCarousel(el)}
            responsive={responsiveMain}
            slidesToSlide={1}
            containerClass="container"
            additionalTransfrom={0}
            draggable={false}
            swipeable={false}
            customLeftArrow={<Arrow left />}
            customRightArrow={<Arrow />}
            {...mainAttrs}
          >
            {renderSlides()}
          </Carousel>
        </CarouselContainer>
      )}
      {renderSecondSlide()}
    </Container>
  );
};

Slider.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.node),
  dots: PropTypes.bool,
  showMainPreview: PropTypes.bool,
  miniatures: PropTypes.bool,
  cards: PropTypes.bool,
  darkenNotSelected: PropTypes.bool,
  responsiveMain: PropTypes.object,
  responsiveMiniature: PropTypes.object,
  mainAttrs: PropTypes.object,
  miniatureAttrs: PropTypes.object,
  optionFullWidth: PropTypes.bool,
};

Slider.defaultProps = {
  slides: [],
  dots: true,
  showMainPreview: true,
  miniatures: false,
  cards: false,
  darkenNotSelected: true,
  optionFullWidth: false,
  mainAttrs: {},
  miniatureAttrs: {},
  responsiveMain: {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 768,
      },
      items: 1,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 1,
    },
  },
  responsiveMiniature: {
    desktopL: {
      breakpoint: {
        max: 3000,
        min: 768,
      },
      items: 4,
      partialVisibilityGutter: 30,
    },
    desktop: {
      breakpoint: {
        max: 1024,
        min: 768,
      },
      items: 4,
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 2,
      partialVisibilityGutter: 30,
    },
  },
};

export default Slider;
