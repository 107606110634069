import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, EmptyState } from 'components/system/core';

import Loading from 'components/core/Loading';
import Table from 'components/core/Table';
import TableContainer from 'components/core/TableContainer';

import {
  getFilterItemParams,
  getFilterParams,
} from 'lib/contexts/requestsList';
import { handleIdPattern } from 'lib/core/idPattern';
import { isFinished, isPending } from 'lib/core/request';

import { RequestsListActions } from 'store/requestsList/requestsList.ducks';

import usePagination from 'hooks/usePagination';
import Columns from './Columns';

const INITIAL_SORT_STATE = {
  orderBy: 'id',
  direction: 'ASC',
};

const RequestsTable = ({ ...props }) => {
  const { paginationSize, setValuePage } = usePagination();

  const { requestsListRequests, requestsListItems, filter } = useSelector(
    ({ requestsList }) => requestsList,
  );

  const { filterData } = filter;
  const dispatch = useDispatch();

  const [sort, setSort] = useState(INITIAL_SORT_STATE);

  const loading = isPending(requestsListRequests.REQUESTS_LIST_REQUEST);

  useEffect(() => {
    const params = getFilterItemParams(filter);
    dispatch(RequestsListActions.requestsListRequest(params, filterData));
  }, [dispatch]);

  const onPageChange = (PageIndex) => {
    const { pageSize, orderBy, orderByDescending } = filter;
    const newFilter = {
      ...filter,
      pageIndex: PageIndex,
      pageSize,
      orderBy: orderBy ?? sort.orderBy,
      orderByDescending: orderByDescending ?? sort.orderBy !== 'ASC',
    };
    const data = getFilterParams(newFilter);

    dispatch(RequestsListActions.requestsListRequest(data, newFilter));
  };

  const onPerPageChange = (PageSize) => {
    const { pageIndex, orderBy, orderByDescending } = filter;
    const newFilter = {
      ...filter,
      pageIndex,
      pageSize: PageSize,
      orderBy: orderBy ?? sort.orderBy,
      orderByDescending: orderByDescending ?? sort.orderBy !== 'ASC',
    };
    const data = getFilterParams(newFilter);

    setValuePage(PageSize);

    dispatch(RequestsListActions.requestsListRequest(data, newFilter));
  };

  const onSorting = (orderBy, orderByDescending) => {
    const { pageIndex, pageSize } = filter;
    const newFilter = {
      ...filter,
      pageIndex,
      pageSize,
      orderBy,
      orderByDescending,
    };
    const data = getFilterParams(newFilter);

    dispatch(RequestsListActions.requestsListRequest(data, newFilter));
  };

  const handleColumnToSort = (columnId) => {
    if (sort.orderBy === columnId) {
      switch (sort.direction) {
        case 'DESC':
          setSort({
            orderBy: columnId,
            direction: 'ASC',
          });

          onSorting(columnId, true);
          break;

        case 'ASC':
          setSort({
            orderBy: columnId,
            direction: 'DESC',
          });

          onSorting(columnId, false);
          break;

        default:
          break;
      }
    } else {
      setSort({
        orderBy: columnId,
        direction: 'DESC',
      });

      onSorting(columnId, false);
    }
  };

  const tableData = requestsListItems?.items?.map((item) => {
    return {
      ...item,
      id: handleIdPattern(item.id, 'S'),
      proposalCode: handleIdPattern(item.proposalCode, 'P'),
    };
  });

  return (
    <TableContainer {...props}>
      {loading && <Loading />}

      {requestsListItems?.items?.length > 0 ? (
        <Table
          width="100%"
          columns={Columns}
          data={tableData}
          onPageChange={onPageChange}
          onPerPageChange={onPerPageChange}
          footer
          pageIndex={requestsListItems.currentPage}
          pageCount={requestsListItems.totalPages}
          totalItems={requestsListItems.totalCount}
          handleColumnToSort={handleColumnToSort}
          isServerSort
          customList={paginationSize}
        />
      ) : (
        isFinished(requestsListRequests.REQUESTS_LIST_REQUEST) && (
          <Card>
            <EmptyState
              condensed={false}
              title="Nenhuma solicitação foi encontrada."
            />
          </Card>
        )
      )}
    </TableContainer>
  );
};

export default RequestsTable;
