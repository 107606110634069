import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import CloseIcon from 'assets/system/svgs/close.svg';
import PlusCircleIcon from 'assets/system/svgs/plusCircle.svg';

import Button from '../../button';
import Icon from '../../icon';

import {
  Close,
  Container,
  Content,
  Footer,
  Header,
  MoreFilter,
  Title,
} from './styles';

const SidebarFilter = ({
  children,
  open,
  onClose,
  onApply,
  onClear,
  title,
  elementsToShow,
  ...attrs
}) => {
  const [isShowing, setIsShowing] = useState(open);
  const [dataSize, setDataSize] = useState(elementsToShow);

  useEffect(() => setIsShowing(open), [open]);

  const toggle = () => {
    onClose(!isShowing);
    setIsShowing(!isShowing);
  };

  return (
    <Container
      data-testid="ds-sidebar-filter"
      variant={[isShowing && 'open']}
      {...attrs}
    >
      <Header>
        <Title>{title}</Title>
        <Close data-testid="ds-sidebar-filter-close" onClick={toggle}>
          <Icon src={CloseIcon} />
        </Close>
      </Header>
      <Content>
        {children && children.slice(0, dataSize).map((element) => element)}

        {children.length >= elementsToShow && (
          <MoreFilter
            onClick={() => setDataSize(children.length)}
            style={{ display: children.length === dataSize && 'none' }}
          >
            <Icon src={PlusCircleIcon} />
            <p>Mais filtros</p>
          </MoreFilter>
        )}
      </Content>
      <Footer>
        <Button name="Aplicar" variant="secondary" onClick={onApply} />
        <Button name="Limpar tudo" variant="tertiary" onClick={onClear} />
      </Footer>
    </Container>
  );
};

SidebarFilter.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onApply: PropTypes.func,
  onClear: PropTypes.func,
  title: PropTypes.string,
  elementsToShow: PropTypes.number,
};

SidebarFilter.defaultProps = {
  children: '',
  open: false,
  onClose: () => {},
  onApply: () => {},
  onClear: () => {},
  title: '',
  elementsToShow: 5,
};

export default SidebarFilter;
