import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { DividerStyle } from './styles';

const Divider = ({ position, size, direction, ...attrs }) => {
  const dividerDirection = useMemo(
    () => ({
      vertical: 'vertical',
      horizontal: 'horizontal',
    }),
    [],
  );

  const width = useMemo(
    () => (dividerDirection.horizontal === direction ? size : '1px'),
    [direction, dividerDirection.horizontal, size],
  );

  const height = useMemo(
    () => (dividerDirection.vertical === direction ? size : '1px'),
    [direction, dividerDirection.vertical, size],
  );

  return (
    <DividerStyle
      id="divider"
      data-testid="ds-divider"
      width={width}
      height={height}
      variant={[position, direction]}
      direction={[direction]}
      {...attrs}
    >
      <hr />
    </DividerStyle>
  );
};

Divider.propTypes = {
  position: PropTypes.string,
  size: PropTypes.string,
  direction: PropTypes.string,
};

Divider.defaultProps = {
  position: 'none',
  size: '100%',
  direction: 'horizontal',
};

export default Divider;
