import React from 'react';

import { getContractStatus } from 'lib/contexts/contracts';
import * as utility from 'lib/core/utility';

import { history } from 'constants/routes';
import * as ROUTES from 'constants/urls';

import AdditionChevron from 'components/contexts/contract/ContractList/AdditionChevron/AdditionChevron';
import CellTable from 'components/core/Table/CellTable';

import CellDefaultFlag from '../CellDefaultFlag/CellDefaultFlag';
import CellEditAutocomplete from '../CellEditAutocomplete/CellEditAutocomplete';
import CellEditInput from '../CellEditInput/CellEditInput';
import CellWithActions from '../CellWithActions';

const clickRow = (row) => {
  const isClosed = getContractStatus({ details: row });

  history.push(`${ROUTES.CONTRACT_DETAILS(row.id, isClosed)}`);
};

const onAdditiveClick = (row) => {
  const { contractRelatives } = row.original;
  if (contractRelatives && contractRelatives.length > 0)
    row.toggleRowExpanded(!row.isExpanded);
};

const Columns = [
  {
    Header: 'Padrão',
    accessor: 'isDefault',
    align: 'center',
    width: 50,
    Cell: (props) => <CellDefaultFlag {...props} onClick={clickRow} />,
  },
  {
    Header: 'Inicial',
    accessor: 'startPeriod',
    align: 'start',
    width: 100,
    Cell: (props) => (
      <CellTable
        {...props}
        transformedData={utility.formatDate}
        onClick={clickRow}
      />
    ),
  },
  {
    Header: 'Final',
    accessor: 'endPeriod',
    align: 'start',
    width: 100,
    Cell: (props) => (
      <CellTable
        {...props}
        transformedData={utility.formatDate}
        onClick={clickRow}
      />
    ),
  },
  {
    Header: 'Código',
    accessor: 'code',
    align: 'start',
    width: 150,
    Cell: (props) => <CellEditInput {...props} onClick={clickRow} />,
  },
  {
    Header: 'Gerente',
    accessor: 'accountManager',
    align: 'start',
    width: 250,
    Cell: (props) => <CellEditAutocomplete {...props} onClick={clickRow} />,
  },
  {
    Header: 'Cliente',
    accessor: 'client',
    align: 'start',
    minWidth: 100,
    Cell: (props) => (
      <CellTable
        {...props}
        transformedData={utility.capitalizeString}
        onClick={clickRow}
      />
    ),
  },
  {
    Header: 'Produtos',
    accessor: 'products',
    align: 'start',
    minWidth: 50,
    Cell: (props) => (
      <CellTable
        {...props}
        onClick={clickRow}
        transformedData={utility.joinComma}
      />
    ),
  },
  {
    Header: 'Ações',
    accessor: 'action',
    align: 'start',
    width: 20,
    Cell: (props) => <CellWithActions {...props} />,
  },
  {
    Header: 'Aditivos',
    accessor: 'additions',
    align: 'end',
    width: 20,
    Cell: ({ row }) =>
      row.original.contractRelatives.length !== 0 ? (
        <AdditionChevron
          isInverted={row.isExpanded}
          onClick={() => onAdditiveClick(row)}
        />
      ) : null,
  },
];

export default Columns;
