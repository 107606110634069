import styled from 'styled-components';

import Tokens from 'components/system/tokens';

export const ActionButtons = styled.div`
  display: flex;
  gap: 15px;
`;

export const Form = styled.form`
  margin-bottom: 32px;
`;

export const Notice = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  margin-bottom: 16px;

  span {
    font: ${Tokens.fonts.textRegularMedium};
    font-weight: 400;
    color: ${Tokens.colors.gray400};
  }

  svg {
    path:first-of-type {
      fill: ${Tokens.colors.yellow300};
    }
  }
`;
