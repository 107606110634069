import PropTypes from 'prop-types';
import React from 'react';

import ShipIcon from 'assets/system/svgs/ship.svg';
import TrainIcon from 'assets/system/svgs/train.svg';

import { Flex, Icon } from 'components/system/core';

const CellModal = ({ cell: { value } }) => {
  return (
    <Flex>
      {value.includes('Ferrovia') && <Icon src={TrainIcon} />}
      {value.includes('Porto') && <Icon src={ShipIcon} />}
    </Flex>
  );
};

CellModal.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
    ]).isRequired,
  }).isRequired,
};

export default CellModal;
