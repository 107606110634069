import PropTypes from 'prop-types';
import React from 'react';

import Divider from '../divider';

import { CardStyle } from './styles';

const Card = ({
  tag,
  title,
  divider,
  dividerPosition,
  children,
  headerStyle,
  ...attrs
}) => (
  <CardStyle data-testid="ds-card" className="card-wrapper" as={tag} {...attrs}>
    {title && (
      <header
        className={`card-title ${divider && 'has-divider'}`}
        style={headerStyle}
      >
        {title}
      </header>
    )}
    {divider && <Divider position={dividerPosition} />}
    <div>{children}</div>
  </CardStyle>
);

Card.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  title: PropTypes.string,
  children: PropTypes.node,
  headerStyle: PropTypes.object,
  divider: PropTypes.bool,
  dividerPosition: PropTypes.string,
};

Card.defaultProps = {
  title: '',
  tag: 'div',
  children: '',
  headerStyle: {},
  divider: false,
  dividerPosition: 'none',
};

export default Card;
