import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';
import { createActions, createReducer } from 'reduxsauce';

export const {
  Types: PriceRecommendationTypes,
  Creators: PriceRecommendationActions,
} = createActions({
  priceRecommendationFilter: [],
  priceRecommendationFilterSuccess: ['payload'],
  priceRecommendationFilterFailure: ['error'],
  createPriceRecommendation: ['payload'],
  createPriceRecommendationSuccess: [],
  createPriceRecommendationFailure: ['error'],
  priceRecommendationRequests: ['params'],
  priceRecommendationRequestsSuccess: ['payload'],
  priceRecommendationRequestsFailure: ['error'],
  sendPriceRecommendationRequests: ['payload'],
  sendPriceRecommendationRequestsSuccess: [],
  sendPriceRecommendationRequestsFailure: ['error'],
});

const INITIAL_STATE = {
  filters: [],
  requests: {},
  error: null,
  priceRecommendationRequest: {
    [PriceRecommendationTypes.PRICE_RECOMMENDATION_FILTER]: REQUEST_NOT_STARTED,
    [PriceRecommendationTypes.CREATE_PRICE_RECOMMENDATION]: REQUEST_NOT_STARTED,
    [PriceRecommendationTypes.PRICE_RECOMMENDATION_REQUESTS]: REQUEST_NOT_STARTED,
    [PriceRecommendationTypes.SEND_PRICE_RECOMMENDATION_REQUESTS]: REQUEST_NOT_STARTED,
  },
};

const priceRecommendationFilter = (state) => ({
  ...state,
  priceRecommendationRequest: {
    ...state.priceRecommendationRequest,
    [PriceRecommendationTypes.PRICE_RECOMMENDATION_FILTER]: REQUEST_PENDING,
  },
});

const priceRecommendationFilterSuccess = (state, { payload }) => ({
  ...state,
  filters: payload,
  priceRecommendationRequest: {
    ...state.priceRecommendationRequest,
    [PriceRecommendationTypes.PRICE_RECOMMENDATION_FILTER]: REQUEST_RESOLVED,
  },
});

const priceRecommendationFilterFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  priceRecommendationRequest: {
    ...state.priceRecommendationRequest,
    [PriceRecommendationTypes.PRICE_RECOMMENDATION_FILTER]: REQUEST_REJECTED,
  },
});

const createPriceRecommendation = (state) => ({
  ...state,
  priceRecommendationRequest: {
    ...state.priceRecommendationRequest,
    [PriceRecommendationTypes.CREATE_PRICE_RECOMMENDATION]: REQUEST_PENDING,
  },
});

const createPriceRecommendationSuccess = (state) => ({
  ...state,
  priceRecommendationRequest: {
    ...state.priceRecommendationRequest,
    [PriceRecommendationTypes.CREATE_PRICE_RECOMMENDATION]: REQUEST_RESOLVED,
  },
});

const createPriceRecommendationFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  priceRecommendationRequest: {
    ...state.priceRecommendationRequest,
    [PriceRecommendationTypes.CREATE_PRICE_RECOMMENDATION]: REQUEST_REJECTED,
  },
});

const priceRecommendationRequests = (state) => ({
  ...state,
  priceRecommendationRequest: {
    ...state.priceRecommendationRequest,
    [PriceRecommendationTypes.PRICE_RECOMMENDATION_REQUESTS]: REQUEST_PENDING,
  },
});

const priceRecommendationRequestsSuccess = (state, { payload }) => ({
  ...state,
  requests: payload,
  priceRecommendationRequest: {
    ...state.priceRecommendationRequest,
    [PriceRecommendationTypes.PRICE_RECOMMENDATION_REQUESTS]: REQUEST_RESOLVED,
  },
});

const priceRecommendationRequestsFailure = (
  state,
  { error: { response } },
) => ({
  ...state,
  error: response,
  priceRecommendationRequest: {
    ...state.priceRecommendationRequest,
    [PriceRecommendationTypes.PRICE_RECOMMENDATION_REQUESTS]: REQUEST_REJECTED,
  },
});

const sendPriceRecommendationRequests = (state) => ({
  ...state,
  priceRecommendationRequest: {
    ...state.priceRecommendationRequest,
    [PriceRecommendationTypes.SEND_PRICE_RECOMMENDATION_REQUESTS]: REQUEST_PENDING,
  },
});

const sendPriceRecommendationRequestsSuccess = (state) => ({
  ...state,
  priceRecommendationRequest: {
    ...state.priceRecommendationRequest,
    [PriceRecommendationTypes.SEND_PRICE_RECOMMENDATION_REQUESTS]: REQUEST_RESOLVED,
  },
});

const sendPriceRecommendationRequestsFailure = (
  state,
  { error: { response } },
) => ({
  ...state,
  error: response,
  priceRecommendationRequest: {
    ...state.priceRecommendationRequest,
    [PriceRecommendationTypes.SEND_PRICE_RECOMMENDATION_REQUESTS]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [PriceRecommendationTypes.PRICE_RECOMMENDATION_FILTER]: priceRecommendationFilter,
  [PriceRecommendationTypes.PRICE_RECOMMENDATION_FILTER_SUCCESS]: priceRecommendationFilterSuccess,
  [PriceRecommendationTypes.PRICE_RECOMMENDATION_FILTER_FAILURE]: priceRecommendationFilterFailure,
  [PriceRecommendationTypes.CREATE_PRICE_RECOMMENDATION]: createPriceRecommendation,
  [PriceRecommendationTypes.CREATE_PRICE_RECOMMENDATION_SUCCESS]: createPriceRecommendationSuccess,
  [PriceRecommendationTypes.CREATE_PRICE_RECOMMENDATION_FAILURE]: createPriceRecommendationFailure,
  [PriceRecommendationTypes.PRICE_RECOMMENDATION_REQUESTS]: priceRecommendationRequests,
  [PriceRecommendationTypes.PRICE_RECOMMENDATION_REQUESTS_SUCCESS]: priceRecommendationRequestsSuccess,
  [PriceRecommendationTypes.PRICE_RECOMMENDATION_REQUESTS_FAILURE]: priceRecommendationRequestsFailure,
  [PriceRecommendationTypes.SEND_PRICE_RECOMMENDATION_REQUESTS]: sendPriceRecommendationRequests,
  [PriceRecommendationTypes.SEND_PRICE_RECOMMENDATION_REQUESTS_SUCCESS]: sendPriceRecommendationRequestsSuccess,
  [PriceRecommendationTypes.SEND_PRICE_RECOMMENDATION_REQUESTS_FAILURE]: sendPriceRecommendationRequestsFailure,
});
