import styled from 'styled-components';

import Tokens from 'components/system/tokens';

export const ParametersContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: ${Tokens.spacing.md};

  margin-bottom: ${Tokens.spacing.md};
`;

export const Fields = styled.div`
  width: 100%;
  max-width: 640px;

  display: flex;
  flex-direction: column;
  gap: ${Tokens.spacing.sm};
`;
