import { yupResolver } from '@hookform/resolvers/yup';
import { addYears, differenceInYears, isAfter, isEqual } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { Button } from 'components/system/core';
import { Input, Date as InputDate } from 'components/system/form';

import { ContractDetailActions } from 'store/contractDetail/contractDetail.ducks';
import { FormulasActions } from 'store/formulas/formulas.ducks';

import ModalConfirm from 'components/core/ModalConfirm';
import MultipleFileInput from 'components/form/MultipleFileInput';

import { history } from 'constants/routes';
import * as ROUTES from 'constants/urls';

import * as S from './ExtendStyles';

const schema = Yup.object().shape({
  extendContractHistoryType: Yup.number().required(
    'Tipo de prorrogação é um campo requerido',
  ),
  observations: Yup.string().required('Justificativa é um campo requerido'),
  newEndPeriod: Yup.string().required(
    'Nova vigência final é um campo requerido',
  ),
  inflationFormulaId: Yup.number().nullable(),
  files: Yup.array()
    .of(Yup.mixed())
    .required('É necessário anexar pelo menos um arquivo'),
});

const Extend = ({ isOpen, onClose }) => {
  const [showNotice, setShowNotice] = useState(false);
  const [showInflationField, setShowInflationField] = useState(false);
  const [formValues, setFormValues] = useState({});

  const dispatch = useDispatch();

  const { contractDetails } = useSelector(
    ({ contractDetail }) => contractDetail,
  );
  const { formulasItems } = useSelector(({ formulas }) => formulas);

  const { control, handleSubmit, errors, watch } = useForm({
    defaultValues: {
      extendContractHistoryType: 1,
      observations: '',
      newEndPeriod: '',
      inflationFormulaId: 0,
    },
    resolver: yupResolver(schema),
  });

  const { id: contractId } = useParams();

  const checkIfGoesThroughNextBaseDate = useCallback(
    (date, newDate) => {
      const baseDateToEnd = differenceInYears(
        date,
        new Date(contractDetails.details.startPeriod),
      );
      const nextBaseDate = addYears(
        new Date(contractDetails.details.startPeriod),
        baseDateToEnd + 1,
      );

      const goesThroughNextBaseDate =
        isAfter(newDate, nextBaseDate) || isEqual(newDate, nextBaseDate);

      return goesThroughNextBaseDate;
    },
    [contractDetails],
  );

  const handleSuccessCallback = () => {
    setShowNotice(false);

    const viewScenario = contractDetails.filter.viewScenario || 'CurrentPrice';

    dispatch(
      ContractDetailActions.getContractDetail({
        id: contractDetails.details.id,
        viewScenario,
      }),
    );

    history.push(`${ROUTES.CONTRACT_DETAILS(contractId, false)}`);
  };

  const sendExtendRequest = (payload) => {
    const formData = new FormData();
    const enPeriodToISO = new Date(payload.newEndPeriod).toISOString();

    formData.append('Id', payload.id);
    formData.append('NewEndPeriod', enPeriodToISO);
    formData.append(
      'ExtendContractHistoryType',
      payload.extendContractHistoryType,
    );

    payload.files.forEach((file) => formData.append('Files', file));

    formData.append('Observations', payload.observations);

    if (payload.inflationFormulaId) {
      formData.append('InflationFormulaId', payload.inflationFormulaId);
    }

    dispatch(
      ContractDetailActions.extendContract(formData, handleSuccessCallback),
    );

    onClose();
  };

  const onSubmit = (data) => {
    const {
      extendContractHistoryType,
      files,
      observations,
      newEndPeriod,
      inflationFormulaId,
    } = data;

    if (newEndPeriod && contractDetails.details.endPeriod && !showNotice) {
      const hasOneYearMore = checkIfGoesThroughNextBaseDate(
        new Date(contractDetails.details.endPeriod),
        new Date(newEndPeriod),
      );

      if (hasOneYearMore) {
        setShowNotice(true);
        setFormValues({
          extendContractHistoryType,
          files,
          observations,
          newEndPeriod,
          inflationFormulaId,
        });
      } else {
        sendExtendRequest({
          id: contractDetails.details.id,
          extendContractHistoryType,
          files,
          observations,
          newEndPeriod,
          inflationFormulaId,
        });
      }
    }
  };

  const handleConfirmModal = () => {
    sendExtendRequest({
      id: contractDetails.details.id,
      ...formValues,
    });
  };

  const selectContractTypeOptions = [
    {
      label: 'Aditivo',
      value: 1,
    },
    {
      label: 'Respaldo diretoria',
      value: 2,
    },
    {
      label: 'Prorrogação automática',
      value: 3,
    },
  ];

  const handleCloseNoticeModal = () => {
    setShowNotice(false);
  };

  useEffect(() => {
    dispatch(FormulasActions.formulasItemsRequest());
  }, [dispatch]);

  const selectFormulaOptions = formulasItems
    ?.filter((formula) => formula.formulaTypeId === 2)
    .map((formula) => ({
      value: formula.id,
      label: formula.name,
    }));

  const checkIfLessThanOneYear = useCallback(() => {
    const years = differenceInYears(
      new Date(contractDetails.details.endPeriod),
      new Date(contractDetails.details.startPeriod),
    );

    return years < 1;
  }, [contractDetails]);

  const newEnd = watch('newEndPeriod');

  useEffect(() => {
    const isLessThanOneYear = checkIfLessThanOneYear();

    if (contractDetails.details.endPeriod && newEnd) {
      const hasOneYearMore = checkIfGoesThroughNextBaseDate(
        new Date(contractDetails.details.endPeriod),
        new Date(newEnd),
      );

      if (isLessThanOneYear && hasOneYearMore) {
        setShowInflationField(true);
      } else {
        setShowInflationField(false);
      }
    }
  }, [
    checkIfLessThanOneYear,
    checkIfGoesThroughNextBaseDate,
    contractDetails,
    newEnd,
  ]);

  return (
    <S.CustomModal title="Prorrogar contrato" isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="extendContractHistoryType"
          control={control}
          render={({ ...field }) => (
            <Input
              {...field}
              type="select"
              options={selectContractTypeOptions}
              label="Tipo de prorogação"
              placeholder="Selecione"
              required
            />
          )}
        />

        {showInflationField && (
          <Controller
            name="inflationFormulaId"
            control={control}
            render={({ ...field }) => (
              <Input
                {...field}
                type="select"
                options={selectFormulaOptions}
                label="Fórmula"
                placeholder="Selecione"
                required
              />
            )}
          />
        )}

        <Controller
          name="newEndPeriod"
          control={control}
          render={({ ...field }) => (
            <InputDate
              {...field}
              label="Nova vigência final"
              format="dd/MM/yyyy"
              locale="pt-BR"
            />
          )}
        />

        <Controller
          name="files"
          control={control}
          render={({ ...field }) => (
            <MultipleFileInput
              {...field}
              label="Anexar evidência"
              placeholder="Selecione os arquivos"
              accept="image/jpg, image/jpeg, image/png, application/pdf"
              required
              error={errors?.files && 'error'}
              errorMessage={errors?.files && errors.files.message}
            />
          )}
        />

        <Controller
          name="observations"
          control={control}
          render={({ ...field }) => (
            <Input
              {...field}
              type="textarea"
              label="Justificativa"
              placeholder="Justifique aqui"
              required
              status={errors?.observations && 'error'}
              statusMessage={
                errors?.observations && errors.observations.message
              }
              maxLength={256}
            />
          )}
        />

        <S.Actions>
          <Button variant="secondary" name="cancelar" onClick={onClose} />

          <Button
            variant="primary"
            name="salvar"
            type="submit"
            onClick={onSubmit}
          />
        </S.Actions>
      </form>

      {showNotice && (
        <ModalConfirm
          closeModal={handleCloseNoticeModal}
          confirmAction={handleConfirmModal}
          title="Prorrogar contrato"
          message="Tem certeza que deseja prorrogar o contrato para este período? Identificamos que o contrato atingirá data base. Deseja continuar? <br/><br/>"
        />
      )}
    </S.CustomModal>
  );
};

Extend.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Extend;
