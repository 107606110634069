import styled, { css } from 'styled-components';

import { Input, Date as InputDate } from 'components/system/form';
import Tokens from 'components/system/tokens';

export const FormGrid = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 32px 12px;
`;

export const FormColumn = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Tokens.spacing.xss};

  label {
    font: ${Tokens.fonts.textRegularSmall};
    color: ${Tokens.colors.gray400};
  }

  & > div > div > div.react-date-picker__wrapper {
    min-width: 0;
  }

  textarea {
    min-height: 0;
    padding: 6px;
  }
`;

export const FieldContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 8px;

  & > span {
    font: ${Tokens.fonts.headingRegularH6};
    color: ${Tokens.colors.gray500};
  }
`;

export const DateInput = styled(InputDate)`
  ${(props) =>
    props.disabled &&
    css`
      cursor: unset;

      & > div > div.react-date-picker__wrapper {
        background-color: ${Tokens.colors.gray100};
      }

      & * {
        pointer-events: none;
      }
    `}
`;

export const TextArea = styled(Input)`
  width: 100%;
`;

export const DefaultInput = styled(Input)`
  width: 100%;
`;

export const ErrorMessage = styled.small`
  font: ${Tokens.fonts.textRegularSmall};
  color: ${Tokens.colors.red400};
`;

export const Table = styled.div`
  width: 100%;

  border: 1px solid ${Tokens.colors.gray200};
  border-radius: 4px;
`;

export const TableHead = styled.div`
  width: 100%;

  display: flex;

  border-bottom: 1px solid ${Tokens.colors.gray200};
`;

export const TableCell = styled.div`
  width: ${(props) => props.width}%;

  padding: ${Tokens.spacing.xs} ${Tokens.spacing.sm};

  font: ${Tokens.fonts.textRegularSmall};
  color: ${Tokens.colors.gray400};
`;

export const TableBody = styled.div`
  width: 100%;
`;

export const TableItem = styled.label`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  ${(props) => {
    if (props.isSelected) {
      return css`
        background-color: ${Tokens.colors.accentPrimaryLight};

        span {
          color: ${Tokens.colors.accentPrimaryDark};
        }
      `;
    }

    if (props.isDisabled) {
      return css`
        background-color: ${Tokens.colors.gray100};
        cursor: unset;

        span {
          color: ${Tokens.colors.gray400};
        }
      `;
    }

    return css`
      background-color: 'transparent';

      span {
        color: ${Tokens.colors.grayBlack};
      }
    `;
  }}

  & + label {
    border-top: 1px solid ${Tokens.colors.gray200};
  }

  input {
    width: 18px;
    height: 18px;

    accent-color: ${Tokens.colors.accentPrimaryDark};
  }

  & > div {
    width: 5%;

    padding: ${Tokens.spacing.xs} ${Tokens.spacing.sm};

    display: flex;
    align-items: center;
    justify-content: center;
  }

  span {
    padding: ${Tokens.spacing.xs} ${Tokens.spacing.sm};

    font: ${Tokens.fonts.textRegularMedium};
    font-weight: 400;

    &:first-of-type {
      width: 21%;
    }

    &:last-of-type {
      width: 79%;
    }
  }
`;

export const ButtonOpenModal = styled.button`
  appearance: none;
  border: none;

  background-color: transparent;

  font: ${Tokens.fonts.textRegularMedium};
  font-weight: 400;
  color: ${Tokens.colors.accentPrimaryDark};

  transition: all 0.3s;

  &:not(:disabled) {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &:disabled {
    color: ${Tokens.colors.gray300};
  }
`;
