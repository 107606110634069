import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Card, EmptyState, Flex } from 'components/system/core';
import { Input } from 'components/system/form';
import { Container, Content, Header } from 'components/system/interface';

import { Columns } from 'components/contexts/profile/profileList/Table/Columns/Columns';
import Loading from 'components/core/Loading';
import ModalConfirm from 'components/core/ModalConfirm';
import Table from 'components/core/Table/Table';

import useDebouncedValue from 'hooks/useDebouncedValue';
import useDimensions from 'hooks/useDimensions';

import { isFinished, isPending } from 'lib/core/request';

import { ProfileActions } from 'store/profile/profile.ducks';

import { history } from 'constants/routes';
import * as ROUTES from 'constants/urls';

import * as S from './ProfileList.styles';

const breadcrumb = [
  {
    text: 'Perfis',
    href: `#${ROUTES.PROFILE_LIST}`,
  },
];

const ProfileList = () => {
  const dimensions = useDimensions();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const {
    data: profiles,
    openDelete,
    profileDelete,
    profilesRequests,
  } = useSelector(({ profile }) => profile);
  const closeModal = () => {
    dispatch(
      ProfileActions.setDeleteProfile({
        profileDelete: null,
        modalOpen: false,
      }),
    );
  };
  const callback = () => {
    dispatch(ProfileActions.fetchProfiles());
  };
  const confirmDelete = () => {
    dispatch(ProfileActions.deleteProfile(profileDelete.id, callback));
    closeModal();
  };

  const debouncedValue = useDebouncedValue(searchTerm, 500);

  useEffect(() => {
    dispatch(ProfileActions.fetchProfiles());
  }, [dispatch]);

  useEffect(() => {
    if (!profiles.length) return;

    const filteredData = profiles.filter((profile) =>
      profile.name.toLowerCase().includes(debouncedValue.toLowerCase()),
    );

    setResults(filteredData);
  }, [profiles, debouncedValue]);
  const loading =
    isPending(profilesRequests.FETCH_PROFILES) ||
    isPending(profilesRequests.DELETE_PROFILE);

  return (
    <Content data-testid="content" height={dimensions.height}>
      <Container>
        <Flex justifyContent="space-between" alignItems="center">
          <Header bread={breadcrumb} tag="header" title="Perfil" />
          <div>
            <Button
              onClick={() => history.push(ROUTES.NEW_PROFILE)}
              name="NOVO PERFIL"
              type="button"
            />
          </div>
        </Flex>
        <S.NameGroup>
          <Card>
            <Flex>
              <Input
                value={searchTerm}
                data-testid="searchTest"
                onChange={(value) => setSearchTerm(value)}
                label="Pesquisa"
                type="text"
                required
                width="501px"
              />
            </Flex>
          </Card>
        </S.NameGroup>
        <S.TableContainer>
          {loading && <Loading />}
          {results.length > 0 ? (
            <Table
              width="100%"
              tableType="responsive"
              footer={false}
              sortBy={['name']}
              data={results}
              columns={Columns}
            />
          ) : (
            isFinished(profilesRequests.FETCH_PROFILES) && (
              <Card>
                <EmptyState
                  condensed={false}
                  title="Nenhum perfil encontrado."
                />
              </Card>
            )
          )}
        </S.TableContainer>
        {openDelete && (
          <ModalConfirm
            closeModal={closeModal}
            confirmAction={confirmDelete}
            title="Excluir perfil"
            message={`Tem certeza que deseja excluir o perfil <b>${profileDelete.name}</b>?`}
          />
        )}
      </Container>
    </Content>
  );
};

export default ProfileList;
