import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { LabelStyle } from './styles';

const Tag = ({ children, className, color, ...attrs }) => (
  <LabelStyle
    className={clsx(color, className)}
    {...attrs}
    data-testid="ds-tag"
  >
    {children}
  </LabelStyle>
);

Tag.propTypes = {
  children: PropTypes.string,
  className: PropTypes.any,
  color: PropTypes.string,
};

Tag.defaultProps = {
  children: '',
  className: '',
  color: '',
};

export default Tag;
