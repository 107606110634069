import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import OptionIcon from 'assets/icons/table/options.svg';

import { Button, Popover } from 'components/system/core';
import { Text } from 'components/system/typography';

import { LocalActions } from 'store/local/local.ducks';

import * as S from 'components/core/Table/CellWithActions/CellWithActions.styles';

const CellWithActions = ({ row }) => {
  const { original } = row;
  const dispatch = useDispatch();
  const edit = () => {
    if (original) {
      dispatch(LocalActions.setEditRow({ row: original, editMode: true }));
    }
  };
  const editConfirm = () => {
    if (original) {
      if (original.changeEdit) {
        dispatch(
          LocalActions.setEditModalOpen({
            modalOpen: true,
          }),
        );
      } else {
        dispatch(LocalActions.setEditRow({ row: {}, editMode: false }));
      }
    }
  };
  const editChange = () => {
    if (original) {
      dispatch(
        LocalActions.setChangeStatusModalOpen({
          changeStatusOpen: true,
          row: original,
        }),
      );
    }
  };

  const handleBaseDieselBondHistory = () => {
    if (original) {
      dispatch(LocalActions.getLocalRequest(original.id));
      dispatch(LocalActions.setHistoryModalOpen({ modalOpen: true }));
    }
  };

  const Options = (
    <S.OptionsContainer>
      {original.active && (
        <S.OptionItem
          data-testid={`test-option-edit-${row.index}`}
          onClick={edit}
        >
          <Text size="medium" style={{ fontWeight: '400' }}>
            Editar
          </Text>
        </S.OptionItem>
      )}
      <S.OptionItem data-testid="test-option-diesel" onClick={editChange}>
        <Text size="medium" style={{ fontWeight: '400' }}>
          {original.active ? 'Desativar' : 'Ativar'}
        </Text>
      </S.OptionItem>
      <S.OptionItem
        data-testid="test-option-history"
        onClick={handleBaseDieselBondHistory}
      >
        <Text size="medium" style={{ fontWeight: '400' }}>
          Histórico
        </Text>
      </S.OptionItem>
    </S.OptionsContainer>
  );

  return !row.original.editMode ? (
    <S.ContentCell data-testid="cell-with-action">
      <Popover content={Options} position="left center">
        <Button
          data-testid="test-button"
          icon={OptionIcon}
          name=""
          variant="tertiary"
        />
      </Popover>
    </S.ContentCell>
  ) : (
    <S.Flex direction="">
      <Button
        onClick={editConfirm}
        data-testid="test-button-ok"
        name="Ok"
        variant="primary"
      />
    </S.Flex>
  );
};

CellWithActions.propTypes = {
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      showInflationReport: PropTypes.bool.isRequired,
      editMode: PropTypes.bool.isRequired,
      changeEdit: PropTypes.bool.isRequired,
      active: PropTypes.bool.isRequired,
    }).isRequired,
  }),
};
CellWithActions.defaultProps = {
  row: {
    index: 0,
    original: {
      id: 0,
      editMode: false,
      changeEdit: false,
      active: false,
    },
  },
};

export default CellWithActions;
