import React from 'react';

import Tokens from 'components/system/tokens';

import CellTable from 'components/core/Table/CellTable';
import Multiselect from 'components/form/CustomMultiselect';

import { EditCellSchema } from 'lib/core/tableCellSchemas';

const CellEditMultiSelect = (props) => {
  const {
    cell: { value },
    row,
    align,
    transformedData,
    options,
    onChange,
  } = props;

  return row.original.editMode ? (
    <Multiselect
      width="100%"
      keyValue="id"
      selected={[]}
      listItems={options}
      sections
      size="medium"
      data-testid={`cell-table-${value.id}`}
      style={{ cursor: 'pointer', textAlign: align }}
      onChange={(option) => onChange(option)}
    />
  ) : (
    <CellTable {...props} transformedData={transformedData} />
  );
};

CellEditMultiSelect.propTypes = EditCellSchema;

CellEditMultiSelect.defaultProps = {
  cell: {
    value: '',
  },
  row: {
    index: 0,
    original: {
      id: 0,
      editMode: false,
    },
  },
  color: Tokens.colors.grayBlack,
  transformedData: (text) => text,
  align: 'start',
  options: [{ label: 'Teste', value: '1' }],
  defaultSelect: null,
  onChange: () => {},
};

export default CellEditMultiSelect;
