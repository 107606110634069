import styled from 'styled-components';

import Tokens from 'components/system/tokens';

export const Service = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${Tokens.spacing.xs};
  margin-top: ${Tokens.spacing.sm};

  span {
    color: ${Tokens.colors.gray400};
    font: ${Tokens.fonts.textRegularMedium};
    font-weight: 400;
  }
`;
