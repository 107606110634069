import React from 'react';

import { Flex } from 'components/system/core';
import { Container, Content, Header } from 'components/system/interface';

import ContractList from 'components/contexts/contract/ContractList/ContractList';

import * as ROUTES from 'constants/urls';

import useDimensions from 'hooks/useDimensions';

const Contract = () => {
  const dimensions = useDimensions();
  const breadcrumb = [
    {
      text: 'Formalizações',
      href: `#${ROUTES.CONTRACTS}`,
    },
  ];

  return (
    <Content height={dimensions.height}>
      <Container>
        <Flex>
          <Header bread={breadcrumb} tag="header" title="Formalizações" />
        </Flex>
        <ContractList />
      </Container>
    </Content>
  );
};

export default Contract;
