import PropTypes from 'prop-types';
import React from 'react';

import { WrapperStyle } from './styles';

const Wrapper = ({ children, width, height, ...attrs }) => (
  <WrapperStyle
    width={width}
    height={height}
    data-testid="ds-wrapper"
    {...attrs}
  >
    {children}
  </WrapperStyle>
);

Wrapper.propTypes = {
  children: PropTypes.node,
  width: PropTypes.string,
  height: PropTypes.string,
};

Wrapper.defaultProps = {
  children: '',
  width: '100%',
  height: '100%',
};

export default Wrapper;
