import PropTypes from 'prop-types';
import React from 'react';

import Divider from '../../divider';
import Flex from '../../flex';

import { ModalFooterStyle } from '../styles';

const ModalFooter = ({ children, ...attrs }) => (
  <ModalFooterStyle {...attrs}>
    <Divider
      style={{ marginBottom: '16px', marginTop: '16px' }}
      position="center"
    />
    <Flex justifyContent="flex-end">{children}</Flex>
  </ModalFooterStyle>
);

ModalFooter.propTypes = {
  children: PropTypes.node,
};

ModalFooter.defaultProps = {
  children: '',
};
export default ModalFooter;
