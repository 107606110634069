import * as ROUTES from 'constants/urls';

export const hasRole = (role, roles) => {
  const getRole = roles?.filter((r) => r.id === role);
  if (getRole?.length > 0) {
    return true;
  }
  return false;
};

export const canExecuteAction = (role, action, roles) => {
  const getActions = roles?.filter((r) => r.id === role);
  if (getActions?.length > 0) {
    const canExecute = getActions[0].actions.filter((a) => a.id === action);
    return canExecute.length > 0;
  }
  return false;
};

export const ROLES = {
  CONTRACT: 6,
  INDICES: 1,
  INFLACIONATY_INDICES: 5,
  USER: 7,
  PERMISSION: 8,
  ANP: 2,
  PETROBRAS: 4,
  VLI_SUPPLIER: 3,
  HEALTH_CHECK: 9,
  CONTRACT_UPLOAD: 10,
  NEW_ITEMS: 12,
  NOTIFICATION_CLIENT: 13,
  NOTIFICATION_MANAGER: 14,
  REPORT: 15,
  SEGMENT: 16,
  SUPPLIER: 17,
  CONTRACT_REPORT: 18,
  RAILWAY_ITEMS: 19,
  RESEND_ITEMS: 20,
  MODELS: 21,
  REACTIVATE_ITEMS: 22,
  FORMULAS: 23,
  CLIENT: 24,
  EXPIRATION_REMINDER: 25,
  REQUEST_FORMALIZATION: 26,
  ATTEND_FORMALIZATION: 27,
  PRICING_REVIEW: 28,
  COMMERCIAL_NEGOTIATIONS: 29,
  PRICE_RECOMMENDATION: 30,
};

export const ACTIONS = {
  READ: 1,
  CREATE: 2,
  UPDATE: 3,
  DELETE: 4,
  INACTIVE: 5,
  APPROVE_INAPPROVE_PRICE_SEGMENT: 6,
  APPROVE_INAPPROVE_PRICE_SELF: 7,
  MANAGER: 8,
  DOWNLOAD: 9,
  USER_CONTRACT: 10,
  USER_SEGMENT: 11,
  MANAGER_NOTIFICATION_LOCAL_DIESEL: 12,
  CLOSE: 13,
  UPLOAD: 14,
  MANAGE_SEGMENT_CONTRACT: 16,
  MANAGE_USER_CONTRACT: 17,
  MANAGE_SERVICE: 18,
  IMMEDIATE_SEND: 19,
};

export const REDIRECT_ROLE = (roles) => {
  if (
    canExecuteAction(ROLES.ATTEND_FORMALIZATION, ACTIONS.MANAGE_SERVICE, roles)
  ) {
    return ROUTES.REQUESTS_LIST;
  }
  if (canExecuteAction(ROLES.CONTRACT, ACTIONS.READ, roles)) {
    return ROUTES.CONTRACTS;
  }
  if (canExecuteAction(ROLES.INDICES, ACTIONS.READ, roles)) {
    return ROUTES.MACROECONOMIC_INDICES_HISTORY;
  }
  if (canExecuteAction(ROLES.HEALTH_CHECK, ACTIONS.READ, roles)) {
    return ROUTES.STATUS;
  }
  return ROUTES.FORBIDDEN;
};

export const getDataRoles = (getValues) => {
  const { name, roles } = getValues;
  const getRoles = [];
  Object.keys(roles).forEach((role) => {
    const getRoleId = role.split('role_id_')[1];
    const actions = [];
    Object.keys(roles[role]).forEach((action) => {
      const getActionId = action.split('action_id_')[1];
      if (roles[role][action]) {
        actions.push(parseInt(getActionId, 10));
      }
    });
    if (actions.length > 0) {
      getRoles.push({ roleId: parseInt(getRoleId, 10), actions });
    }
  });
  const payload = {
    name,
    roles: getRoles,
  };
  return payload;
};
