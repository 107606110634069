import { put, takeLatest } from 'redux-saga/effects';

import {
  CREATE_PRICE_RECOMMENDATION,
  PRICE_RECOMMENDATION_FILTER,
  PRICE_RECOMMENDATION_REQUESTS,
  SEND_RECOMMENDATION_REQUESTS,
} from 'constants/endpoints';
import { GET, POST } from 'constants/verbs';

import api from 'services/api';

import { MessagesActions } from 'store/messages/messages.ducks';
import {
  PriceRecommendationActions,
  PriceRecommendationTypes,
} from './priceRecommendation.ducks';

export function* priceRecommendationFilter() {
  try {
    const response = yield api({
      method: GET,
      url: PRICE_RECOMMENDATION_FILTER,
    });

    yield put(
      PriceRecommendationActions.priceRecommendationFilterSuccess(
        response.data.data,
      ),
    );
  } catch (error) {
    yield put(
      PriceRecommendationActions.priceRecommendationFilterFailure(error),
    );
  }
}

export function* createPriceRecommendation({ payload }) {
  try {
    yield api({
      method: POST,
      url: CREATE_PRICE_RECOMMENDATION,
      data: payload,
    });

    yield put(PriceRecommendationActions.createPriceRecommendationSuccess());

    yield put(
      MessagesActions.open({
        title: 'Sucesso ao criar recomendações',
        type: 'success',
        contentText: 'As recomendações de preço foram criadas com sucesso.',
      }),
    );
  } catch (error) {
    yield put(
      PriceRecommendationActions.createPriceRecommendationFailure(error),
    );

    yield put(
      MessagesActions.open({
        title: 'Erro ao criar recomendações',
        type: 'error',
        contentText:
          'Não foi possível finalizar a criação das recomendações de preço.',
      }),
    );
  }
}

export function* priceRecommendationRequests({ params }) {
  try {
    const response = yield api({
      method: GET,
      url: `${PRICE_RECOMMENDATION_REQUESTS}/${params}`,
    });

    yield put(
      PriceRecommendationActions.priceRecommendationRequestsSuccess(
        response.data.data,
      ),
    );
  } catch (error) {
    yield put(
      PriceRecommendationActions.priceRecommendationRequestsFailure(error),
    );
  }
}

export function* sendPriceRecommendationRequests({ payload }) {
  try {
    yield api({
      method: POST,
      url: SEND_RECOMMENDATION_REQUESTS,
      data: payload,
    });

    yield put(
      PriceRecommendationActions.sendPriceRecommendationRequestsSuccess(),
    );

    yield put(
      MessagesActions.open({
        title: 'Sucesso ao enviar recomendações',
        type: 'success',
        contentText: 'As recomendações de preço foram enviadas com sucesso.',
      }),
    );

    yield put(PriceRecommendationActions.priceRecommendationRequests('?'));
  } catch (error) {
    yield put(
      PriceRecommendationActions.sendPriceRecommendationRequestsFailure(error),
    );

    yield put(
      MessagesActions.open({
        title: 'Erro ao enviar recomendações',
        type: 'error',
        contentText:
          'Não foi possível finalizar o envio das recomendações de preço.',
      }),
    );
  }
}

export function* watchSagas() {
  yield takeLatest(
    PriceRecommendationTypes.PRICE_RECOMMENDATION_FILTER,
    priceRecommendationFilter,
  );
  yield takeLatest(
    PriceRecommendationTypes.CREATE_PRICE_RECOMMENDATION,
    createPriceRecommendation,
  );
  yield takeLatest(
    PriceRecommendationTypes.PRICE_RECOMMENDATION_REQUESTS,
    priceRecommendationRequests,
  );
  yield takeLatest(
    PriceRecommendationTypes.SEND_PRICE_RECOMMENDATION_REQUESTS,
    sendPriceRecommendationRequests,
  );
}
