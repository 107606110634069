import ContractIcon from 'assets/icons/menu/Contracts.svg';
import Graph from 'assets/icons/menu/Graph.svg';
import BellIcon from 'assets/system/svgs/bell.svg';
import EmailIcon from 'assets/system/svgs/email.svg';
import SettingsIcon from 'assets/system/svgs/settings.svg';
import TagIcon from 'assets/system/svgs/tag.svg';

import {
  ACTIONS,
  canExecuteAction,
  hasRole,
  ROLES,
} from 'lib/contexts/permissions';
import { isAdminProfile } from 'lib/contexts/systemParameters';

import * as urls from './urls';

const getSubItensManager = (roles) => {
  const subManager = [];
  if (hasRole(ROLES.USER, roles)) {
    subManager.push({
      name: 'Usuários',
      icon: null,
      iconActive: null,
      active: false,
      route: urls.USER_LIST,
      subItem: false,
    });
  }
  if (hasRole(ROLES.PERMISSION, roles)) {
    subManager.push({
      name: 'Perfil',
      icon: null,
      iconActive: null,
      active: false,
      route: urls.PROFILE_LIST,
      subItem: false,
    });
  }
  if (hasRole(ROLES.HEALTH_CHECK, roles)) {
    subManager.push({
      icon: null,
      iconActive: null,
      name: 'Status aplicação',
      route: urls.STATUS,
    });
  }
  if (
    canExecuteAction(ROLES.REPORT, ACTIONS.USER_CONTRACT, roles) ||
    canExecuteAction(ROLES.REPORT, ACTIONS.USER_SEGMENT, roles)
  ) {
    subManager.push({
      icon: null,
      iconActive: null,
      name: 'Relatórios',
      route: urls.REPORT_CONTRACT_ITEMS,
    });
  }
  if (hasRole(ROLES.SEGMENT, roles)) {
    subManager.push({
      icon: null,
      iconActive: null,
      name: 'Segmentos',
      route: urls.SEGMENT_LIST,
    });
  }
  if (hasRole(ROLES.SUPPLIER, roles)) {
    subManager.push({
      icon: null,
      iconActive: null,
      name: 'Fornecedores',
      route: urls.SUPPLIER_LIST,
    });
  }
  if (hasRole(ROLES.CLIENT, roles)) {
    subManager.push({
      icon: null,
      iconActive: null,
      name: 'Clientes',
      route: urls.CLIENT_LIST,
    });
  }
  if (
    canExecuteAction(ROLES.MODELS, ACTIONS.DOWNLOAD, roles) &&
    canExecuteAction(ROLES.MODELS, ACTIONS.UPLOAD, roles)
  ) {
    subManager.push({
      icon: null,
      iconActive: null,
      name: 'Modelos',
      route: urls.MODELS,
    });
  }
  if (canExecuteAction(ROLES.REACTIVATE_ITEMS, ACTIONS.UPDATE, roles)) {
    subManager.push({
      icon: null,
      iconActive: null,
      name: 'Reativar itens',
      route: urls.REACTIVATE_ITEMS,
    });
  }
  if (canExecuteAction(ROLES.FORMULAS, ACTIONS.READ, roles)) {
    subManager.push({
      icon: null,
      iconActive: null,
      name: 'Fórmulas',
      route: urls.FORMULAS,
    });
  }
  if (
    canExecuteAction(
      ROLES.COMMERCIAL_NEGOTIATIONS,
      ACTIONS.MANAGE_SERVICE,
      roles,
    )
  ) {
    subManager.push({
      icon: null,
      iconActive: null,
      name: 'Negociações Comerciais',
      route: urls.COMMERCIAL_NEGOTIATIONS,
    });
  }
  if (isAdminProfile()) {
    subManager.push({
      icon: null,
      iconActive: null,
      name: 'Parâmetros',
      route: urls.SYSTEM_PARAMETERS,
    });
  }
  return subManager;
};

const getSubItensEmails = (roles) => {
  const subManager = [];

  if (
    canExecuteAction(ROLES.NOTIFICATION_CLIENT, ACTIONS.USER_CONTRACT, roles) ||
    canExecuteAction(ROLES.NOTIFICATION_CLIENT, ACTIONS.USER_SEGMENT, roles)
  ) {
    subManager.push({
      name: 'Clientes',
      icon: null,
      iconActive: null,
      active: false,
      route: urls.EMAIL_NOTIFICATIONS_CLIENT_LIST,
      subItem: false,
    });
  }
  if (
    canExecuteAction(
      ROLES.NOTIFICATION_MANAGER,
      ACTIONS.MANAGER_NOTIFICATION_LOCAL_DIESEL,
      roles,
    )
  ) {
    subManager.push({
      name: 'Gerentes',
      icon: null,
      iconActive: null,
      active: false,
      route: urls.EMAIL_NOTIFICATIONS_MANAGER_LIST,
      subItem: false,
    });
  }
  return subManager;
};

const getSubItensContract = (roles) => {
  const subContract = [];
  subContract.push({
    icon: null,
    iconActive: null,
    name: 'Listagem',
    route: urls.CONTRACTS,
  });
  subContract.push({
    icon: null,
    iconActive: null,
    name: 'Solicitações',
    route: urls.REQUESTS_LIST,
  });
  if (canExecuteAction(ROLES.NEW_ITEMS, ACTIONS.READ, roles)) {
    subContract.push({
      icon: null,
      iconActive: null,
      name: 'Novos itens',
      route: urls.ITEMS,
    });
  }
  if (canExecuteAction(ROLES.RESEND_ITEMS, ACTIONS.READ, roles))
    subContract.push({
      icon: null,
      iconActive: null,
      name: 'Reenvio de itens',
      route: urls.RESEND_ITEMS,
    });
  if (canExecuteAction(ROLES.CONTRACT_REPORT, ACTIONS.READ, roles)) {
    subContract.push({
      icon: null,
      iconActive: null,
      name: 'Gestão contratual',
      route: urls.CONTRACT_REPORT,
    });
  }
  if (canExecuteAction(ROLES.CONTRACT_UPLOAD, ACTIONS.CREATE, roles)) {
    subContract.push({
      icon: null,
      iconActive: null,
      name: 'Upload de contrato',
      route: urls.SPREADSHEET_IMPORT,
    });
  }
  if (canExecuteAction(ROLES.CONTRACT, ACTIONS.READ, roles)) {
    subContract.push({
      icon: null,
      iconActive: null,
      name: 'Controle de contratos',
      route: urls.CONTRACTS_CONTROL,
    });
  }

  return subContract;
};

const getSubItensIndices = (roles) => {
  const subIndices = [];
  subIndices.push({
    icon: null,
    name: 'Pesquisa',
    route: urls.MACROECONOMIC_INDICES_HISTORY,
  });
  if (canExecuteAction(ROLES.VLI_SUPPLIER, ACTIONS.MANAGER, roles)) {
    subIndices.push({
      icon: null,
      name: 'Lista base de diesel',
      route: urls.LOCAL,
    });
  }
  if (
    hasRole(ROLES.ANP, roles) ||
    hasRole(ROLES.VLI_SUPPLIER, roles) ||
    hasRole(ROLES.PETROBRAS, roles)
  ) {
    subIndices.push({
      icon: null,
      name: 'Cadastro de diesel',
      route: urls.REAJUSTMENT_DIESEL_INDICES,
    });
  }
  if (canExecuteAction(ROLES.INFLACIONATY_INDICES, ACTIONS.CREATE, roles)) {
    subIndices.push({
      icon: null,
      name: 'Cadastro de inflação',
      route: urls.REAJUSTMENT_INFLATION_INDICES,
    });
  }

  return subIndices;
};

const getSubItensPricing = (roles) => {
  const subIndices = [];

  if (
    canExecuteAction(ROLES.PRICE_RECOMMENDATION, ACTIONS.MANAGE_SERVICE, roles)
  ) {
    subIndices.push({
      icon: null,
      name: 'Preço Recomendado',
      route: urls.PRICE_RECOMMENDATION,
    });

    subIndices.push({
      icon: null,
      name: 'Aguardando recomendação',
      route: urls.WAITING_RECOMMENDATION,
    });
  }

  return subIndices;
};

export const menuContract = (roles) => ({
  name: 'Formalizações',
  icon: ContractIcon,
  iconActive: ContractIcon,
  active: false,
  route: null,
  subItem: true,
  subItems: getSubItensContract(roles),
});

export const menuIndicesWithOptions = (roles) => ({
  name: 'Índices',
  icon: Graph,
  iconActive: Graph,
  active: false,
  route: urls.MACROECONOMIC_INDICES_HISTORY,
  subItem: true,
  subItems: getSubItensIndices(roles),
});

export const menuIndices = {
  name: 'Índices',
  icon: Graph,
  iconActive: Graph,
  active: false,
  route: urls.MACROECONOMIC_INDICES_HISTORY,
  subItem: false,
};

export const menuNotifications = {
  name: 'Notificações',
  icon: BellIcon,
  iconActive: BellIcon,
  active: false,
  route: null,
  subItem: false,
};

export const menuManager = (roles) => ({
  name: 'Gestão',
  icon: SettingsIcon,
  iconActive: SettingsIcon,
  active: false,
  route: null,
  subItem: true,
  subItems: getSubItensManager(roles),
});

export const menuEmail = (roles) => ({
  name: 'Envio de email',
  icon: EmailIcon,
  iconActive: EmailIcon,
  active: false,
  route: null,
  subItem: true,
  subItems: getSubItensEmails(roles),
});

export const menuPricing = (roles) => ({
  name: 'Pricing',
  icon: TagIcon,
  iconActive: TagIcon,
  active: false,
  route: null,
  subItem: true,
  subItems: getSubItensPricing(roles),
});

export const makeMenu = (roles) => {
  const menu = [];

  if (hasRole(ROLES.CONTRACT, roles) || hasRole(ROLES.CONTRACT_REPORT, roles)) {
    menu.push(menuContract(roles));
  }

  if (
    hasRole(ROLES.INFLACIONATY_INDICES, roles) ||
    hasRole(ROLES.ANP, roles) ||
    hasRole(ROLES.VLI_SUPPLIER, roles)
  ) {
    menu.push(menuIndicesWithOptions(roles));
  } else if (hasRole(ROLES.INDICES, roles)) {
    menu.push(menuIndices);
  }

  if (
    hasRole(ROLES.HEALTH_CHECK, roles) ||
    hasRole(ROLES.USER, roles) ||
    hasRole(ROLES.PROFILE, roles) ||
    hasRole(ROLES.REPORT, roles) ||
    hasRole(ROLES.SEGMENT, roles) ||
    hasRole(ROLES.SUPPLIER, roles)
  ) {
    menu.push(menuManager(roles));
  }

  if (
    hasRole(ROLES.NOTIFICATION_CLIENT, roles) ||
    hasRole(ROLES.NOTIFICATION_MANAGER, roles)
  ) {
    menu.push(menuEmail(roles));
  }

  menu.push(menuNotifications);
  menu.push(menuPricing(roles));

  return menu;
};
