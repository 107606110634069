import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { Button } from 'components/system/core';
import { Checkbox, Input, Select, Switch } from 'components/system/form';
import Tokens from 'components/system/tokens';
import { Text } from 'components/system/typography';

import InputDate from 'components/form/inputDate';
import Multiselect from 'components/form/multiselect';

import FilterIcon from '../FilterIcon';

import {
  BlurBackground,
  ButtonContent,
  ButtonFilter,
  ContainerCheckBox,
  ContainerSwitchBox,
  FilterStyle,
  GridContainer,
  Group,
  GroupTitle,
  ItemCounter,
  OptionsList,
  WrapperButtons,
} from './FilterDs.styles';

const FilterDS = ({
  defaultSelectedItems,
  listItems,
  onChange,
  checkedOptions,
  onConfirm,
  onClean,
  btnMtop,
  btnMright,
  withText,
}) => {
  const optionsRef = useRef(null);
  const [openOptions, setOpenOptions] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});

  useEffect(() => {
    setSelectedItems(defaultSelectedItems);
  }, [defaultSelectedItems]);

  const addItem = (name, value) => {
    setSelectedItems({
      ...selectedItems,
      [name]: value,
    });
  };

  const removeItem = (value) => {
    setSelectedItems((olders) => olders.filter((item) => item !== value));
  };

  const handleItem = (name, value, isSelected) => {
    if (isSelected) removeItem(value);
    else addItem(name, value);
    onChange(value);
  };

  const handleOnClickCancel = () => {
    setOpenOptions(false);
  };

  const handleOnClickClean = () => {
    onClean();
    setOpenOptions(false);
    setSelectedItems([]);
  };

  const handleOnClickConfirm = () => {
    onConfirm(selectedItems);
    setOpenOptions(false);
  };

  const filterSelect = () => {
    let count = 0;
    Object.values(selectedItems).forEach((obj) => {
      if (Array.isArray(obj)) {
        if (obj.length > 0) count += 1;
      } else if (obj !== '' && !!obj) count += 1;
    });
    return count;
  };

  const actions = [
    {
      'data-testid': 'test-clear',
      name: 'limpar filtros',
      variant: 'tertiary',
      action: handleOnClickClean,
    },
    {
      'data-testid': 'test-cancel',
      name: 'cancelar',
      variant: 'secondary',
      action: handleOnClickCancel,
    },
    {
      'data-testid': 'test-apply',
      name: 'aplicar',
      variant: 'primary',
      action: handleOnClickConfirm,
    },
  ];

  const getData = (data, name) => {
    const normalize = data.map((newData) => ({
      ...newData,
      selected:
        selectedItems[name]?.filter((f) => f.value.id === newData.value.id)
          .length > 0,
    }));
    return normalize;
  };

  const renderType = (type, data, name, label) => {
    switch (type) {
      case 'date':
        return (
          <InputDate
            name={name}
            value={
              selectedItems[name] instanceof Date ? selectedItems[name] : null
            }
            onChange={(value) => {
              handleItem(name, value);
            }}
            format="dd/MM/y"
          />
        );
      case 'dateYearMonth':
        return (
          <InputDate
            name={name}
            value={
              selectedItems[name] instanceof Date ? selectedItems[name] : null
            }
            onChange={(value) => {
              handleItem(name, value);
            }}
            format="MM/y"
            locale="pt-BR"
            maxDetail="year"
          />
        );
      case 'input':
        return (
          <Input
            name={name}
            width="100%"
            value={selectedItems[name]}
            onChange={(value) => {
              handleItem(name, value);
            }}
          />
        );
      case 'checkbox':
        return data.map((item) => (
          <ContainerCheckBox key={`${label}-${item.data}`}>
            <Checkbox
              label={item.label}
              key={`${label}-${item.label}`}
              id={`${label}-${item.label}`}
              checked={selectedItems[`${name}_${item.data}`] === item.data}
              group={label}
              onChange={() =>
                handleItem(
                  `${name}_${item.data}`,
                  item.data,
                  selectedItems[`${name}_${item.data}`] === item.data,
                )
              }
            />
          </ContainerCheckBox>
        ));
      case 'switch':
        return data.map((item) => (
          <ContainerSwitchBox key={`${name}-${item.data}`}>
            <Switch
              label={item.label}
              key={`${name}-${item.data}`}
              id={`${name}-${item.data}`}
              checked={selectedItems[`${name}`] === item.data}
              group={label}
              onChange={() => {
                handleItem(
                  `${name}`,
                  item.data,
                  selectedItems[`${name}`] === item.data,
                );
              }}
            />
          </ContainerSwitchBox>
        ));
      case 'select':
        return (
          <Select
            isSearchable
            width="264px"
            options={data}
            onChange={(value) => handleItem(name, value.value)}
            selected={selectedItems[name]}
            placeholder=""
          />
        );
      default:
        return (
          <Multiselect
            width="264px"
            keyValue="id"
            name={name}
            selected={selectedItems[name]}
            listItems={getData(data, name)}
            sections
            onChange={(value) => handleItem(name, value)}
          />
        );
    }
  };

  const renderOptions = ({ label, data, type, name }) => {
    renderOptions.propTypes = {
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(PropTypes.object),
      name: PropTypes.string.isRequired,
    };

    return (
      <div key={`frag-${label}`}>
        <GroupTitle key={`title-${label}`}>{label}</GroupTitle>
        <Group key={label}>{renderType(type, data, name)}</Group>
      </div>
    );
  };

  return (
    <>
      <FilterStyle data-testid="dts-filtermain">
        <ButtonFilter
          data-testid="btn-filter"
          btnMtop={btnMtop}
          btnMright={btnMright}
          variant={[(openOptions || selectedItems.length > 0) && 'focus']}
          onClick={() => {
            setOpenOptions(!openOptions);
            checkedOptions(selectedItems);
          }}
        >
          <ButtonContent>
            <FilterIcon />
            {withText && (
              <Text
                color={Tokens.colors.accentPrimaryDark}
                size="large"
                style={{ marginLeft: '6px' }}
              >
                Filtros
              </Text>
            )}
          </ButtonContent>
          {filterSelect() > 0 && <ItemCounter>{filterSelect()}</ItemCounter>}
        </ButtonFilter>
      </FilterStyle>
      {openOptions && (
        <>
          <BlurBackground />
          <OptionsList ref={optionsRef}>
            <GridContainer>{listItems.map(renderOptions)}</GridContainer>

            <WrapperButtons>
              <div>
                {actions.map((props) => (
                  <Button {...props} />
                ))}
              </div>
            </WrapperButtons>
          </OptionsList>
        </>
      )}
    </>
  );
};

FilterDS.propTypes = {
  defaultSelectedItems: PropTypes.object,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.object),
    }),
  ).isRequired,
  onChange: PropTypes.func,
  checkedOptions: PropTypes.func,
  onConfirm: PropTypes.func,
  onClean: PropTypes.func,
  btnMright: PropTypes.string,
  btnMtop: PropTypes.string,
  withText: PropTypes.bool,
};

FilterDS.defaultProps = {
  defaultSelectedItems: [],
  onChange: () => {},
  checkedOptions: () => {},
  onConfirm: () => {},
  onClean: () => {},
  btnMtop: '16px',
  btnMright: '0px',
  withText: true,
};

export default FilterDS;
