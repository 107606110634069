import { ConnectedRouter } from 'connected-react-router';
import { createHashHistory } from 'history';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Loading } from 'components/system/core';

import { GET } from 'lib/core/localStorage';
import { isPending } from 'lib/core/request';

import Dashboard from './dashboard';
import Public from './public';

export const history = createHashHistory();

const Routes = () => {
  const { roles, authRequests } = useSelector(({ auth }) => auth);

  const getRoutes = useCallback(() => {
    const { roles: storageRoles } = GET('roles');

    if (roles.length > 0) return Dashboard(roles);

    if (storageRoles?.length > 0) return Dashboard(storageRoles);

    return Public();
  }, [roles]);

  if (isPending(authRequests.SIGN_IN)) {
    return (
      <Loading
        message="Por favor, espere alguns segundos..."
        title="Carregando"
        type="primary"
      />
    );
  }

  return <ConnectedRouter history={history}>{getRoutes()}</ConnectedRouter>;
};

export default Routes;
