import styled from 'styled-components';

import Tokens from 'components/system/tokens';

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Title = styled.span`
  font: ${Tokens.fonts.textRegularSmall};
  font-weight: 400;
  color: ${Tokens.colors.gray400};
`;

export const Content = styled.div`
  font: ${Tokens.fonts.textRegularMedium};
  font-weight: 400;
  color: ${Tokens.colors.gray500};
`;
