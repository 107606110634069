import styled from 'styled-components';

import Tokens from 'components/system/tokens';

export const Field = styled.div`
  cursor: pointer;
  input[type='file'] {
    display: none;
  }
  label {
    background-color: white;
    border-radius: 4px;
    height: 40px;
    border: 1px solid ${Tokens.colors.gray200};
    color: ${Tokens.colors.gray500};
    font: ${Tokens.fonts.textRegularMedium};
    cursor: pointer;
    position: relative;
    padding: 7px 6px 7px 12px;
    display: flex;
    ${({ width }) => width && `min-width: ${width};`}
    align-items: center;
    span {
      ${({ width }) => width && `width: ${width};`}
      margin-right: 30px;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      span {
        font-weight: 400;
        color: ${Tokens.colors.gray200};
      }
    }
    svg {
      position: absolute;
      right: 8px;
    }
  }
`;
