import React, { useState } from 'react';

import { Card, Flex } from 'components/system/core';
import { Radio } from 'components/system/form';
import { Container, Content, Header } from 'components/system/interface';

import useDimensions from 'hooks/useDimensions';

import DefaultForm from 'components/contexts/newItems/form';
import GrainForm from 'components/contexts/newItems/GrainForm';

import * as ROUTES from 'constants/urls';

const breadcrumb = [
  {
    text: 'Contratos',
    href: `#${ROUTES.CONTRACTS}`,
  },
  {
    text: 'Novos itens',
    href: `#${ROUTES.ITEMS}`,
  },
];

const CreateNewItem = () => {
  const dimensions = useDimensions();

  const [selected, setSelected] = useState('new-item');

  return (
    <Content height={dimensions.height} data-testid="create-new-item">
      <Container>
        <Flex>
          <Header bread={breadcrumb} tag="header" title="Novos itens" />
        </Flex>

        <Card marginBottom="16px">
          <Flex justifyContent="flex-start" spaceChildren="16px">
            <div>
              <Radio
                label="Item existente"
                id="existing-item"
                checked={selected === 'existing-item'}
                group="form-type"
                onChange={() => setSelected('existing-item')}
              />
            </div>

            <div>
              <Radio
                label="Novo item"
                id="new-item"
                checked={selected === 'new-item'}
                group="form-type"
                onChange={() => setSelected('new-item')}
              />
            </div>
          </Flex>
        </Card>

        {selected === 'new-item' ? <DefaultForm /> : <GrainForm />}
      </Container>
    </Content>
  );
};

export default CreateNewItem;
