import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Flex } from 'components/system/core';
import {
  Column as Col,
  Container,
  Content,
  Header,
  Row,
} from 'components/system/interface';

import InflationaryCard from 'components/contexts/macroeconomicIndices/InflationaryCard';

import { IndicesActions } from 'store/indices/indices.ducks';

import useDimensions from 'hooks/useDimensions';

import * as ROUTES from 'constants/urls';

const ReadjustmentInflation = () => {
  const dimensions = useDimensions();
  const dispatch = useDispatch();
  const breadcrumb = [
    {
      text: 'Índices Macroeconômicos',
      href: `#${ROUTES.MACROECONOMIC_INDICES_HISTORY}`,
    },
  ];

  useEffect(() => {
    dispatch(IndicesActions.listInflationary());
  }, [dispatch]);

  return (
    <Content data-testid="content" height={dimensions.height}>
      <Container>
        <Flex justifyContent="space-between" alignItems="center">
          <Header
            bread={breadcrumb}
            tag="header"
            title="Atualização de índices"
          />
        </Flex>
        <Flex>
          <Row>
            <Col desktop="12">
              <InflationaryCard />
            </Col>
          </Row>
        </Flex>
      </Container>
    </Content>
  );
};
export default ReadjustmentInflation;
