import styled from 'styled-components';

import { Button } from 'components/system/core';
import Tokens from 'components/system/tokens';

export const FormContainer = styled.div`
  width: 100%;
  margin: ${Tokens.spacing.sm} 0;

  #accordion {
    width: 100%;
  }
`;

export const FieldsWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: ${Tokens.spacing.sm};

  margin-bottom: ${Tokens.spacing.md};
`;

export const ActionsWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-end;
`;

export const RecommendationLine = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  gap: ${Tokens.spacing.sm};
`;

export const InputGrid = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: ${Tokens.spacing.xs};

  .react-date-picker__wrapper {
    min-width: 100% !important;
  }

  button > input {
    width: 100%;
  }
`;

export const DeleteButton = styled(Button)`
  background: ${Tokens.colors.red300};

  svg {
    height: 20px;
  }
`;
