import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import { Button, Flex, Tabs } from 'components/system/core';
import { Header } from 'components/system/interface';

import { history } from 'constants/routes';
import * as ROUTES from 'constants/urls';

import ModalConfirm from 'components/core/ModalConfirm';
import ModalConfirmOnly from 'components/core/ModalConfirmOnly';
import Table from 'components/core/Table/Table';
import TableContainer from 'components/core/TableContainer';

import Columns from './Columns';

import * as S from './styles';

const ReportContractItemsForm = ({ data, submit }) => {
  const [railwayItems, setRailwayItems] = useState(data.railwayItems);
  const [openModal, setOpenModal] = useState(false);
  const [loseDataModalOpen, setLoseDataModalOpen] = useState(true);

  const breadcrumb = useMemo(() => {
    return [
      {
        text: 'Relatórios',
        href: `#${ROUTES.REPORT_CONTRACT_ITEMS}`,
      },
      {
        text: `${data?.customerName} - ${data?.contractCode}`,
        href: null,
      },
    ];
  }, [data]);

  function handleChangeRowSelection(row, checked) {
    const updatedRailwayItems = railwayItems.map((x, index) => {
      if (row === index) x.selected = checked;
      return x;
    });

    setRailwayItems(updatedRailwayItems);
  }

  function handleSubmit() {
    setOpenModal(true);
  }

  const closeModal = () => {
    setOpenModal(false);
  };

  const confirmEdit = () => {
    setOpenModal(false);

    const submitData = {
      contractId: data.contractId,
      railwayItems: railwayItems.map((x) => {
        return { railwayItemId: x.railwayItemId, selected: x.selected };
      }),
    };

    submit(submitData);
  };

  const handleChangeTab = (tabLabel) => {
    if (tabLabel && tabLabel !== data.contractCode) {
      const relative = data?.contractRelatives.find(
        (x) => x.contractCode === tabLabel,
      );

      history.push(
        `${ROUTES.REPORT_CONTRACT_ITEMS_UPDATE(relative.contractId)}`,
      );
    }
  };

  const handleCloseModalLoseData = () => {
    setLoseDataModalOpen(false);
  };

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <Header
          bread={breadcrumb}
          tag="header"
          title={`${data?.customerName} - ${data?.contractCode}`}
        />
        <Flex justifyContent="space-between" alignItems="center">
          <Button
            onClick={() => history.push(ROUTES.REPORT_CONTRACT_ITEMS)}
            name="Cancelar"
            type="button"
            variant="tertiary"
            style={{ marginRight: '5px' }}
            data-testid="button-cancel-test"
          />
          <Button
            name="Salvar"
            type="submit"
            data-testid="buttonSubmit-test"
            // eslint-disable-next-line react/jsx-no-bind
            onClick={handleSubmit}
          />
        </Flex>
      </Flex>
      <S.CardContainer>
        <S.FlexContainer>
          <div>
            <S.Label>Cliente</S.Label>
            <S.TextValue>{data?.customerName}</S.TextValue>
          </div>
          <div>
            <S.Label>Contrato</S.Label>
            <S.TextValue>{data?.contractCode}</S.TextValue>
          </div>
        </S.FlexContainer>
      </S.CardContainer>

      <TableContainer data-testid="table-test">
        <Tabs position="start" onChange={handleChangeTab}>
          {data.contractRelatives.map((x) => (
            <li
              key={x.contractId}
              label={x.contractCode}
              active={data.contractId === x.contractId}
            >
              {data.contractId === x.contractId && (
                <Table
                  data={railwayItems}
                  width="100%"
                  columns={Columns(railwayItems, handleChangeRowSelection)}
                />
              )}
            </li>
          ))}
        </Tabs>
      </TableContainer>

      {openModal && (
        <ModalConfirm
          title="Itens no relatório"
          closeModal={closeModal}
          confirmAction={confirmEdit}
          message="Ao vincular ou desvincular itens ferroviários ao relatório, 
                  os itens vinculados serão exibidos e os desvinculados não aparecerão no relatório de envio ao cliente."
        />
      )}

      {loseDataModalOpen && data.contractRelatives.length > 1 && (
        <ModalConfirmOnly
          title="Atenção"
          closeModal={handleCloseModalLoseData}
          confirmAction={handleCloseModalLoseData}
          message="Ao trocar de abas todas as ações não salvas serão perdidas."
        />
      )}
    </>
  );
};

ReportContractItemsForm.propTypes = {
  data: PropTypes.object,
  submit: PropTypes.func.isRequired,
};

ReportContractItemsForm.defaultProps = {
  data: {
    contractId: '',
    contractCode: '',
    customerName: '',
    railwayItems: [],
    contractRelatives: [],
  },
};

export default ReportContractItemsForm;
