import React from 'react';
import { useDispatch } from 'react-redux';

import { EditCellSchema } from 'lib/core/tableCellSchemas';
import { ContractActions } from 'store/contract/contract.ducks';

import Tokens from 'components/system/tokens';

import CellEditInputWrapper from 'components/core/Table/CellEditInput/CellEditInput';

const CellEditInput = (props) => {
  const dispatch = useDispatch();

  const change = (newValue) => {
    dispatch(
      ContractActions.setChangeContractRow({ field: 'code', value: newValue }),
    );
  };

  return <CellEditInputWrapper {...props} onChange={change} />;
};

CellEditInput.propTypes = EditCellSchema;

CellEditInput.defaultProps = {
  cell: {
    value: '',
  },
  row: {
    index: 0,
    original: {
      id: 0,
      editMode: false,
    },
  },
  color: Tokens.colors.grayBlack,
  transformedData: (text) => text,
  align: 'start',
};

export default CellEditInput;
