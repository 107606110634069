import styled from 'styled-components';

import Tokens from 'components/system/tokens';

export const DashboardContainer = styled.div`
  display: flex;
  position: relative;
`;

export const CardHeader = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: ${Tokens.spacing.xs};

  display: flex;
  align-items: center;
  justify-content: space-between;
`;
