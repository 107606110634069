import PropTypes from 'prop-types';
import React from 'react';

import ArrowRightIcon from 'assets/system/svgs/right.svg';
import ShipIcon from 'assets/system/svgs/ship.svg';
import TrainIcon from 'assets/system/svgs/train.svg';

import { Icon } from 'components/system/core';

import * as S from '../TakeOrPayModalStyles';

const Flow = ({ salesCommitments }) => {
  return (
    <S.FlowContainer data-testid="flow-test">
      <S.GridFlow>
        {salesCommitments?.map((sale) => {
          return (
            <S.FlowByModal
              key={`flow-${sale.modalId}`}
              hasDivider={salesCommitments.length > 1}
            >
              <div>
                {sale.modalId === 1 ? (
                  <Icon src={TrainIcon} />
                ) : (
                  <Icon src={ShipIcon} />
                )}

                <S.Title>Fluxo de {sale.modalName}:</S.Title>
              </div>

              <S.DataColumn>
                <p>Take or Pay para os fluxos de {sale.modalName}</p>

                <S.DataLine justifyContent="space-between">
                  <S.DataLineItem>
                    <span>Volume</span>
                    <small>
                      {sale?.volumeFactor ? `${sale.volumeFactor}%` : '-'}
                    </small>
                  </S.DataLineItem>
                  <S.DataLineItem>
                    <span>Tarifa do cliente</span>
                    <small>
                      {sale?.contractingTariffFactor
                        ? `${sale.contractingTariffFactor}%`
                        : '-'}
                    </small>
                  </S.DataLineItem>
                  <S.DataLineItem>
                    <span>Tarifa da VLI</span>
                    <small>
                      {sale?.contractedTariffFactor
                        ? `${sale.contractedTariffFactor}%`
                        : '-'}
                    </small>
                  </S.DataLineItem>
                </S.DataLine>
              </S.DataColumn>

              <S.DataColumn>
                <p>Fluxo de {sale.modalName} que serão cobertos por ToP:</p>

                {sale?.salesCommitmentFlows?.map((flow) => (
                  <S.FlowCard
                    key={`flow-${sale.modalId}-${flow.originName}-${flow.destinyName}`}
                  >
                    <S.FlowCardItem>
                      <span>Origem e destino:</span>
                      <small>{flow.originName}</small>
                      <Icon src={ArrowRightIcon} />
                      <small>{flow.destinyName}</small>
                    </S.FlowCardItem>

                    <S.FlowCardItem>
                      <span>Cliente:</span>
                      <small>{flow?.customer}</small>
                    </S.FlowCardItem>

                    <S.FlowCardItem>
                      <span>Produto:</span>
                      <small>{flow?.product}</small>
                    </S.FlowCardItem>
                  </S.FlowCard>
                ))}
              </S.DataColumn>
            </S.FlowByModal>
          );
        })}
      </S.GridFlow>
    </S.FlowContainer>
  );
};

Flow.propTypes = {
  salesCommitments: PropTypes.array.isRequired,
};

export default Flow;
