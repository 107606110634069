import PropTypes from 'prop-types';
import React from 'react';

import Tokens from 'components/system/tokens';

import Headlight from 'components/core/headlight';
import Tooltip from 'components/core/Tooltip';

const StatusSupplier = ({
  cell: {
    row: { original },
  },
}) => (
  <Tooltip title={!original.deleteAt ? 'Ativado' : 'Desativado'}>
    <Headlight
      data-testid="cell-table"
      color={!original.deleteAt ? Tokens.colors.green300 : Tokens.colors.red300}
      style={{ cursor: 'pointer' }}
    />
  </Tooltip>
);

StatusSupplier.propTypes = {
  cell: PropTypes.shape({
    row: PropTypes.shape({
      original: PropTypes.shape({
        deleteAt: PropTypes.any,
      }),
    }),
  }),
};

StatusSupplier.defaultProps = {
  cell: {
    value: '',
  },
};

export default StatusSupplier;
