import styled from 'styled-components';

import TableContainer from 'components/core/TableContainer';
import Tokens from 'components/system/tokens';

export const Title = styled.span`
  font: ${Tokens.fonts.textBoldLarge};
`;

export const Container = styled(TableContainer)`
  margin-top: ${Tokens.spacing.md};
`;
