import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Input, Label } from 'components/system/form';

import handleInputMask from 'lib/core/handleInputMask';

import * as S from '../RegisterStyles';

const DefaultSales = () => {
  const { selectedContract } = useSelector(
    ({ contractUpload }) => contractUpload,
  );
  const { register, control, errors } = useFormContext();

  return (
    <S.Sales data-testid="default-sales-test">
      <h3>
        Vendas referentes ao contrato selecionado (
        {selectedContract.sales.length})
      </h3>

      <S.SalesList>
        {selectedContract.sales.map((sale, index) => {
          const fieldName = `items[${index}]`;

          return (
            <S.SaleItem key={`sale-key-${sale.saleId}`}>
              <input
                style={{ display: 'none' }}
                type="number"
                name={`${fieldName}.saleId`}
                value={sale.saleId}
                ref={register}
                data-testid="input-sale-id"
              />

              <S.SaleItemColumn>
                <Label>Origem/Destino</Label>
                <S.SaleItemValue>{sale.originDestination}</S.SaleItemValue>
              </S.SaleItemColumn>

              <S.SaleItemColumn>
                <Label>Produto</Label>
                <S.SaleItemValue>{sale.product}</S.SaleItemValue>
              </S.SaleItemColumn>

              <S.SaleItemColumn>
                <Label>Base de diesel</Label>
                <S.SaleItemValue>{sale.local}</S.SaleItemValue>
              </S.SaleItemColumn>

              <S.SaleItemColumn>
                <Label>Modal</Label>
                <S.SaleItemValue>{sale.modal}</S.SaleItemValue>
              </S.SaleItemColumn>

              <S.SaleItemColumnInput>
                <Controller
                  control={control}
                  name={`${fieldName}.itemCode`}
                  defaultValue=""
                  render={({ ...field }) => (
                    <Input
                      {...field}
                      width="250px"
                      label="Item ferroviário"
                      placeholder="Digite..."
                      onInput={(e) => handleInputMask(e, 'digits')}
                      required
                    />
                  )}
                />

                {errors?.items && (
                  <span>{errors.items[index]?.itemCode.message}</span>
                )}
              </S.SaleItemColumnInput>
            </S.SaleItem>
          );
        })}
      </S.SalesList>
    </S.Sales>
  );
};

export default DefaultSales;
