import PropTypes from 'prop-types';
import React from 'react';

import ChevronDownIcon from 'assets/system/svgs/chevron/down.svg';
import ChevronUpIcon from 'assets/system/svgs/chevron/up.svg';
import { Icon } from 'components/system/core';

import * as S from './ExpanderCell.styles';

const ExpanderCell = ({ row }) => {
  const { items } = row.original;
  return (
    <S.Cell {...row.getToggleRowExpandedProps()} data-testid="expander-cell">
      {items &&
        items.length > 0 &&
        (row.isExpanded ? (
          <Icon src={ChevronUpIcon} />
        ) : (
          <Icon src={ChevronDownIcon} />
        ))}
    </S.Cell>
  );
};

ExpanderCell.propTypes = {
  row: PropTypes.shape({
    isExpanded: PropTypes.bool,
    original: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.object),
      id: PropTypes.string.isRequired,
    }).isRequired,
    getToggleRowExpandedProps: PropTypes.func.isRequired,
  }),
};

ExpanderCell.defaultProps = {
  row: PropTypes.shape({
    isExpanded: false,
  }),
};

export default ExpanderCell;
