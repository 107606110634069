import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import CloseIcon from 'assets/system/svgs/close.svg';
import ErrorIcon from 'assets/system/svgs/error.svg';
import SuccessIcon from 'assets/system/svgs/success.svg';
import WarningIcon from 'assets/system/svgs/warning.svg';

import Heading from '../../../typography/heading';
import Flex from '../../flex';
import Icon from '../../icon';

import {
  CloseStyle,
  ToastChildren,
  ToastContentContainer,
  ToastContentStyle,
  ToastHeader,
  ToastItemStyle,
} from './styles';

const Toast = ({
  children,
  error,
  warning,
  success,
  isOpen,
  title,
  autoClose,
  delay,
  onClose,
  ...attrs
}) => {
  useEffect(() => {
    const interval = isOpen && autoClose && setTimeout(onClose, delay);
    return () => isOpen && autoClose && clearTimeout(interval);
  }, [isOpen, autoClose, delay, onClose]);
  const toastType =
    (error && 'error') || (success && 'success') || (warning && 'warning');
  const toastIcons = {
    error: ErrorIcon,
    success: SuccessIcon,
    warning: WarningIcon,
  };
  return (
    <ToastItemStyle className={isOpen && 'open'} data-testid="ds-toast">
      <ToastContentStyle {...attrs} className={toastType}>
        <Flex
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="flex-start"
          width="100%"
        >
          {toastType && (
            <Icon style={{ marginRight: '12px' }} src={toastIcons[toastType]} />
          )}
          <ToastContentContainer variant={toastType && 'withIcon'}>
            <ToastHeader>
              <Heading size={16}>{title}</Heading>
              <CloseStyle onClick={onClose}>
                <Icon src={CloseIcon} />
              </CloseStyle>
            </ToastHeader>
            <ToastChildren>{children}</ToastChildren>
          </ToastContentContainer>
        </Flex>
      </ToastContentStyle>
    </ToastItemStyle>
  );
};

Toast.propTypes = {
  children: PropTypes.node,
  error: PropTypes.bool,
  warning: PropTypes.bool,
  success: PropTypes.bool,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  autoClose: PropTypes.bool,
  delay: PropTypes.number,
};

Toast.defaultProps = {
  children: '',
  error: false,
  warning: false,
  success: false,
  onClose: () => {},
  isOpen: false,
  title: '',
  autoClose: false,
  delay: 3000,
};

export default Toast;
