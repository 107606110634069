import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, Flex } from 'components/system/core';
import { Radio } from 'components/system/form';
import Tokens from 'components/system/tokens';
import { Heading } from 'components/system/typography';

import { getSuppliersParams } from 'lib/contexts/indices';
import { DieselActions } from 'store/diesel/diesel.ducks';

import * as S from './DieselCard.styles';

const DieselCard = () => {
  const dispatch = useDispatch();
  const { groupMacrobaseList, suppliersList } = useSelector(
    ({ diesel }) => diesel,
  );

  const addItem = (value, isSupplierVli) => {
    dispatch(
      DieselActions.getSuppliers(getSuppliersParams(value), isSupplierVli),
    );
  };

  const renderRadio = ({ item, name }) => {
    const ids = suppliersList.map((s) => s.id);
    const isSupplierVli = !!item.ids;
    const isSelected = item.ids
      ? ids.includes(item.ids[0])
      : ids.includes(item.id);
    const value = item.ids ? [...item.ids] : [item.id];
    return (
      <S.ContainerRadio key={`${name}-${item.id}`}>
        <Radio
          label={item.name}
          key={item.id}
          id={`${name}-${item.id}`}
          checked={isSelected}
          group={item.name}
          onChange={() => addItem(value, isSupplierVli)}
        />
      </S.ContainerRadio>
    );
  };

  return (
    <Card style={{ height: '150px' }} data-testid="card-test">
      <S.CardHeader>
        <Heading tag="h5" color={Tokens.colors.gray300} variant={['bold']}>
          Reajuste de Diesel
        </Heading>
      </S.CardHeader>
      <S.BoxContainer>
        <S.GroupTitle>Selecione a macro-base</S.GroupTitle>
        <S.BoxContainer>
          <Flex>
            {groupMacrobaseList.map((item) => (
              <S.Group key={item.id}>
                {renderRadio({ item, name: 'diesel' })}
              </S.Group>
            ))}
          </Flex>
        </S.BoxContainer>
      </S.BoxContainer>
    </Card>
  );
};

DieselCard.propTypes = {};

DieselCard.defaultProps = {};

export default DieselCard;
