import { addDays, addMonths, format, toDate } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Card, Flex } from 'components/system/core';
import { Date as InputDate } from 'components/system/form';
import { Column as Col, Row } from 'components/system/interface';
import Tokens from 'components/system/tokens';
import { Heading, Text } from 'components/system/typography';

import { ReactComponent as IcInfo } from 'assets/icons/ic-info.svg';

import Loading from 'components/core/Loading';
import { SpacingTop } from 'components/core/Spacing';
import Tooltip from 'components/core/Tooltip';
import InputFile from 'components/form/InputFile/InputFile';

import {
  getFormDataVli,
  getTitleTooltipPosto,
  getTitleTooltipRefinaria,
  SupplierHasLetterToUpload,
} from 'lib/contexts/indices';
import { maxDateNextMonth } from 'lib/core/date';
import { isObjectEmpty } from 'lib/core/object';
import { isPending } from 'lib/core/request';
import { simpleId } from 'lib/core/utility';

import { DieselActions } from 'store/diesel/diesel.ducks';
import { IndicesActions } from 'store/indices/indices.ducks';

import CONSTANTS from 'constants/constants';

import ModalDoubleCheck from './Modals/ModalDoubleCheck';

import * as S from './DieselForm.styles';

const INITIAL_MODAL_STATE = {
  isVisible: false,
  data: null,
};

const DieselSupplierVli = () => {
  const dispatch = useDispatch();

  const { suppliersList } = useSelector(({ diesel }) => diesel);
  const { indicesRequests } = useSelector(({ indices }) => indices);

  const [endPeriod, setEndPeriod] = useState('');
  const [keyFile, setKeyFile] = useState(simpleId());
  const [doubleCheckModal, setDoubleCheckModal] = useState(INITIAL_MODAL_STATE);

  const {
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
    reset,
  } = useForm();

  const reload = () => {
    dispatch(DieselActions.clearSupplierList());
  };

  const onSubmit = (data) => {
    if (data) {
      setDoubleCheckModal({
        isVisible: true,
        data,
      });
    }
  };

  const setEndDate = (value) => {
    const newDate = toDate(
      addDays(addMonths(value, -1), CONSTANTS.START_DAY - 1),
    );

    const endDate = toDate(addDays(addMonths(newDate, 1), -1));

    setEndPeriod(
      `Período de ${format(toDate(newDate), 'dd/MM')} até ${format(
        toDate(endDate),
        'dd/MM',
      )}`,
    );
  };

  const handleSaveData = () => {
    const data = getValues();

    const formData = getFormDataVli(data);

    dispatch(
      IndicesActions.dieselReadjustmentStart(formData, true, null, reload),
    );

    setValue('DieselExcel', null);
    setKeyFile(simpleId());
    setDoubleCheckModal(INITIAL_MODAL_STATE);
    reset();
    setEndPeriod('');
  };

  const handleCloseModal = (clear = false) => {
    setDoubleCheckModal(INITIAL_MODAL_STATE);

    if (clear) {
      reset();
      setEndPeriod('');
    }
  };

  useEffect(() => {
    if (!isObjectEmpty(errors)) {
      setDoubleCheckModal(INITIAL_MODAL_STATE);
    }
  }, [errors]);

  const isLoading = isPending(indicesRequests.DIESEL_READJUSTMENT_START);

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="form-test">
      {!isLoading && (
        <SpacingTop md>
          <Card style={{ height: 'auto' }}>
            <S.CardHeader>
              <Heading
                tag="h5"
                color={Tokens.colors.gray300}
                variant={['bold']}
              >
                Fornecedores VLI
              </Heading>
            </S.CardHeader>

            <S.ContainerForm>
              <Row>
                <Col desktop="12">
                  <Flex>
                    <S.GroupTitle>Período de apuração</S.GroupTitle>
                  </Flex>

                  <Row>
                    <Col desktop="12">
                      <Flex>
                        <S.Group>
                          <div>
                            <div>
                              <S.Label>Data</S.Label>
                            </div>

                            <div>
                              <Controller
                                data-testid="DieselDate"
                                name="DieselDate"
                                rules={{ required: true }}
                                defaultValue={null}
                                control={control}
                                render={({ onChange, value }) => (
                                  <S.ContainerDate>
                                    <InputDate
                                      format="MM/y"
                                      selected={value}
                                      value={value}
                                      width="255px"
                                      maxDate={maxDateNextMonth()}
                                      locale="pt-BR"
                                      maxDetail="year"
                                      onChange={(e) => {
                                        onChange(e);
                                        setEndDate(e);
                                      }}
                                    />
                                  </S.ContainerDate>
                                )}
                              />

                              <S.MessageErrors>
                                <Text color={Tokens.colors.yellow400}>
                                  {endPeriod}
                                </Text>
                              </S.MessageErrors>

                              {errors.DieselDate && (
                                <S.MessageErrors>
                                  <Text color={Tokens.colors.red400}>
                                    Campo requerido
                                  </Text>
                                </S.MessageErrors>
                              )}
                            </div>
                          </div>
                        </S.Group>

                        <S.Group>
                          <div>
                            <div>
                              <S.Label>Upload de índices</S.Label>
                            </div>

                            <div>
                              <Controller
                                key={keyFile}
                                name="DieselExcel"
                                rules={{ required: true }}
                                defaultValue={null}
                                control={control}
                                render={({ onChange, value }) => (
                                  <InputFile
                                    key={keyFile}
                                    type="file"
                                    width="230px"
                                    value={value}
                                    name="DieselExcel"
                                    accept=".xlsx, .xls"
                                    onChange={(e) => {
                                      onChange(e.target.files[0]);
                                    }}
                                  />
                                )}
                              />

                              {errors.DieselExcel && (
                                <S.MessageErrors>
                                  <Text color={Tokens.colors.red400}>
                                    Campo requerido
                                  </Text>
                                </S.MessageErrors>
                              )}
                            </div>
                          </div>
                        </S.Group>
                      </Flex>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <S.Separate />
              </Row>

              <Row>
                <Col desktop="12">
                  {suppliersList.map((supplier) => {
                    if (!SupplierHasLetterToUpload(supplier)) return <></>;

                    return (
                      <Row key={supplier.id}>
                        <Col desktop="2">
                          <S.TitleSupplier>
                            <Text size="large">{supplier.name}</Text>
                          </S.TitleSupplier>
                        </Col>

                        <Col desktop="10">
                          <Flex>
                            {supplier.hasRefinary && (
                              <S.Group>
                                <div>
                                  <div>
                                    <S.Label>
                                      Upload carta refinaria
                                      <Tooltip
                                        multiline
                                        title={getTitleTooltipRefinaria()}
                                      >
                                        <IcInfo />
                                      </Tooltip>
                                    </S.Label>
                                  </div>

                                  <div>
                                    <Controller
                                      name={`file.${supplier.id}.refinary`}
                                      rules={{ required: true }}
                                      defaultValue={null}
                                      control={control}
                                      render={({ onChange, value }) => (
                                        <InputFile
                                          type="file"
                                          value={value}
                                          name={`file.${supplier.id}.refinary`}
                                          accept=".pdf"
                                          onChange={(e) => {
                                            onChange(e.target.files[0]);
                                          }}
                                        />
                                      )}
                                    />

                                    {errors.file && (
                                      <S.MessageErrors>
                                        <Text color={Tokens.colors.red400}>
                                          Campo requerido
                                        </Text>
                                      </S.MessageErrors>
                                    )}
                                  </div>
                                </div>
                              </S.Group>
                            )}

                            {supplier.hasStation && (
                              <S.Group>
                                <div>
                                  <div>
                                    <S.Label>
                                      Upload carta posto
                                      <Tooltip
                                        multiline
                                        title={getTitleTooltipPosto()}
                                      >
                                        <IcInfo />
                                      </Tooltip>
                                    </S.Label>
                                  </div>

                                  <div>
                                    <Controller
                                      name={`file.${supplier.id}.station`}
                                      rules={{ required: true }}
                                      defaultValue={null}
                                      control={control}
                                      render={({ onChange, value }) => (
                                        <InputFile
                                          type="file"
                                          value={value}
                                          name={`file.${supplier.id}.station`}
                                          accept=".pdf"
                                          onChange={(e) => {
                                            onChange(e.target.files[0]);
                                          }}
                                        />
                                      )}
                                    />

                                    {errors.file && (
                                      <S.MessageErrors>
                                        <Text color={Tokens.colors.red400}>
                                          Campo requerido
                                        </Text>
                                      </S.MessageErrors>
                                    )}
                                  </div>
                                </div>
                              </S.Group>
                            )}
                          </Flex>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
              </Row>

              <SpacingTop md>
                <Button
                  name="Salvar"
                  type="submit"
                  data-testid="open-diesel-modal-test"
                />
              </SpacingTop>
            </S.ContainerForm>
          </Card>
        </SpacingTop>
      )}

      {isLoading && <Loading />}

      {doubleCheckModal.isVisible && (
        <ModalDoubleCheck
          isOpen={doubleCheckModal.isVisible}
          onClose={handleCloseModal}
          data={doubleCheckModal.data}
          onConfirm={handleSaveData}
        />
      )}
    </form>
  );
};

DieselSupplierVli.propTypes = {};

DieselSupplierVli.defaultProps = {};

export default DieselSupplierVli;
