import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Container, Content } from 'components/system/interface';

import Form from 'components/contexts/suppliers/Form';
import Loading from 'components/core/Loading';

import { isPending } from 'lib/core/request';

import useDimensions from 'hooks/useDimensions';

import { SupplierActions } from 'store/supplier/supplier.ducks';

import * as ROUTES from 'constants/urls';

const UpdateSupplier = () => {
  const dimensions = useDimensions();
  const dispatch = useDispatch();
  const { supplierRequests, details } = useSelector(({ supplier }) => supplier);

  const { id } = useParams();

  const breadcrumb = useMemo(() => {
    return [
      {
        text: 'Fornecedores',
        href: `#${ROUTES.SUPPLIER_LIST}`,
      },
      {
        text: 'Editar fornecedor',
        href: `#${ROUTES.EDIT_SUPPLIER(id)}`,
      },
    ];
  }, [id]);

  useEffect(() => {
    dispatch(SupplierActions.getSupplier(id));
  }, [id, dispatch]);

  const onSubmit = (payload) => {
    dispatch(SupplierActions.updateSupplier({ id, ...payload }));
  };

  const isLoading = isPending(supplierRequests.UPDATE_SUPPLIER);

  return (
    <Content height={dimensions.height} data-testid="content">
      <Container>
        {isLoading && <Loading />}
        <Form breadcrumb={breadcrumb} onSubmit={onSubmit} supplier={details} />
      </Container>
    </Content>
  );
};

export default UpdateSupplier;
