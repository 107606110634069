import styled from 'styled-components';

import Tokens from 'components/system/tokens';

export const Spacing = styled.div`
  margin-top: ${Tokens.spacing.sm};
  margin-bottom: ${Tokens.spacing.sm};
`;
export const SpacingMultselect = styled.div`
  margin-top: ${Tokens.spacing.sm};
  margin-bottom: ${Tokens.spacing.sm};
  div {
    div {
      margin-bottom: 4px;
    }
  }
  ul li label label {
    width: 400px !important;
  }
`;
