import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import CloseIcon from 'assets/system/svgs/close.svg';
import ErrorIcon from 'assets/system/svgs/error.svg';
import SuccessIcon from 'assets/system/svgs/success.svg';
import WarningIcon from 'assets/system/svgs/warning.svg';

import { Heading } from '../../typography';
import Icon from '../icon';

import DialogContent from './content';
import DialogFooter from './footer';

import {
  Close,
  DialogOverlay,
  DialogStyle,
  DialogWrapper,
  Header,
} from './styles';

const Dialog = ({
  isOpen,
  title,
  onClose,
  children,
  dialogBox,
  warning,
  success,
  error,
  actions,
  ...attrs
}) => {
  const [isShowing, setIsShowing] = useState(isOpen);

  useEffect(() => {
    setIsShowing(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (isShowing && !isOpen) {
      onClose();
    }
  }, [isOpen, isShowing, onClose]);

  const closeDialog = () => {
    onClose();
    setIsShowing(false);
  };

  return (
    isShowing && (
      <DialogOverlay data-testid="ds-dialog">
        <DialogWrapper>
          <DialogStyle {...attrs}>
            <Header className="dialog-box">
              {dialogBox && error && <Icon src={ErrorIcon} />}
              {dialogBox && warning && <Icon src={WarningIcon} />}
              {dialogBox && success && <Icon src={SuccessIcon} />}
              <Heading tag="h6" variant={[{ bold: true }]}>
                {title}
              </Heading>
              <Close onClick={closeDialog} data-testid="ds-dialog-close">
                <Icon src={CloseIcon} />
              </Close>
            </Header>
            <DialogContent>{children}</DialogContent>
            {actions && <DialogFooter>{actions}</DialogFooter>}
          </DialogStyle>
        </DialogWrapper>
      </DialogOverlay>
    )
  );
};

Dialog.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
  dialogBox: PropTypes.bool,
  error: PropTypes.bool,
  warning: PropTypes.bool,
  success: PropTypes.bool,
  actions: PropTypes.node,
};

Dialog.defaultProps = {
  isOpen: false,
  title: '',
  children: '',
  onClose: () => {},
  dialogBox: false,
  error: false,
  warning: false,
  success: false,
  actions: '',
};

export default Dialog;
