import PropTypes from 'prop-types';
import React from 'react';

import Logo from 'assets/system/svgs/logo.svg';
import MenuIcon from 'assets/system/svgs/menu.svg';

import Icon from '../../../core/icon';

import { MenuHeaderStyle } from './styles';

const MenuHeader = ({ children, logo, brandTitle, onClick, ...attrs }) => (
  <MenuHeaderStyle {...attrs}>
    {!logo && Object.keys(logo).length === 0 ? (
      <>
        <Icon size={64} src={Logo} />
        <span>{brandTitle}</span>
      </>
    ) : (
      <>
        <Icon size={64} src={logo} />
        <span>{brandTitle}</span>
      </>
    )}
    <button type="button" onClick={onClick}>
      <Icon size={34} src={MenuIcon} />
      <span className="sr-only">Menu</span>
    </button>
    {children}
  </MenuHeaderStyle>
);

MenuHeader.propTypes = {
  children: PropTypes.node,
  logo: PropTypes.any,
  brandTitle: PropTypes.string,
  onClick: PropTypes.func,
};

MenuHeader.defaultProps = {
  children: '',
  logo: '',
  brandTitle: 'Centro de \n Inteligência',
  onClick: () => {},
};

export default MenuHeader;
