import styled from 'styled-components';

import Tokens from 'components/system/tokens';

const Spacing = styled.div`
  padding: ${Tokens.spacing.lg};
`;
export const SpacingTop = styled.div`
  ${({ xs }) => xs && `padding-top:  ${Tokens.spacing.xs}`}
  ${({ sm }) => sm && `padding-top:  ${Tokens.spacing.sm}`}
  ${({ md }) => md && `padding-top:  ${Tokens.spacing.md}`}
  ${({ lg }) => lg && `padding-top:  ${Tokens.spacing.lg}`}
`;
export default Spacing;
