import styled, { css } from 'styled-components';

import { Flex } from 'components/system/core';
import Tokens from 'components/system/tokens';

export const Container = styled.div`
  position: relative;
  user-select: none;

  .drop-options--trigger {
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 11px;
  }
`;

export const Tags = styled(Flex)`
  position: absolute;
  top: 8px;
  z-index: 1;

  .clear-button {
    cursor: pointer;
    transition: transform 0.2s ease opacity 0.1s linear;

    &:hover {
      transform: scale(0.9);
      opacity: 0.5;
    }
  }

  ${(props) =>
    props.alignRight ? css({ right: '32px' }) : css({ left: '8px' })}
`;

export const Tag = styled.div`
  display: flex;
  position: relative;
  background-color: ${Tokens.colors.accentPrimaryLight};
  font: ${Tokens.fonts.textRegularSmall};
  color: ${Tokens.colors.accentPrimaryDark};
  border-radius: 2px;
  padding: 4px;

  svg {
    margin-left: 4px;
    path:nth-child(1) {
      fill: transparent;
    }
    path:nth-child(2) {
      stroke: ${Tokens.colors.accentPrimaryDark};
    }
  }
`;

export const SearchInput = styled.input`
  position: relative;
  padding: 8px;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  border: 1px solid;
  border-color: ${Tokens.colors.gray200};
  border-radius: 4px;
  &::-webkit-search-decoration
    &::-webkit-search-cancel-button
    &::-webkit-search-results-button
    &::-webkit-search-results-decoration {
    display: none;
  }
  &:focus {
    border-color: ${Tokens.colors.accentPrimaryDark};
    box-shadow: 0 0 0 2px ${Tokens.colors.accentPrimaryLight};
  }

  ${(props) =>
    props.openOptions && css({ borderColor: Tokens.colors.accentPrimaryDark })}
`;

export const OptionsList = styled.ul`
  z-index: 10;
  padding: 0;
  position: absolute;
  user-select: none;
  top: 40px;
  left: 0;
  width: 100%;
  background-color: ${Tokens.colors.grayWhite};
  margin-top: 1px;
  border-radius: 4px;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.08), 0px 2px 3px rgba(0, 0, 0, 0.12);
  max-height: 264px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 4px;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    &:hover {
      width: 10px;
      cursor: pointer;
      background-color: ${Tokens.colors.accentPrimaryLight};
    }
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Tokens.colors.accentPrimaryDark};
  }
`;

export const Group = styled.ul`
  position: relative;
  user-select: none;
  list-style: none inside none;
  margin: 0 0 16px 0;
  padding: 0;
  &[title]::before {
    content: attr(title);
    display: flex;
    user-select: none;
    align-items: center;
    font: ${Tokens.fonts.textBoldSmall};
    text-transform: uppercase;
    color: ${Tokens.colors.gray300};
    height: 32px;
    padding-left: 8px;
  }
`;

export const GroupItem = styled.li`
  position: relative;
  user-select: none;
  display: flex;
  align-items: center;
  padding: 11px;
  cursor: pointer;

  &:hover {
    background-color: ${Tokens.colors.accentPrimaryLight};
  }

  & > label > label {
    margin: 0;
  }

  &[aria-selected='true'] > label > label > span {
    color: ${Tokens.colors.accentPrimaryDark};
  }

  ${(props) =>
    props.focused && css({ backgroundColor: Tokens.colors.accentPrimaryLight })}
`;
