import React from 'react';

import { Wrapper } from 'components/system/interface';

import { Redirect, Route } from 'react-router';

import * as ROUTES from 'constants/urls';
import { isAuthenticated } from 'lib/contexts/auth';

import { Notification } from 'components/notification/Notification';
import Sidebar from 'components/structure/Sidebar';

const PrivateRoute = ({ canAccess, roles, component: Component, ...rest }) => {
  const render = (location, props) => {
    if (isAuthenticated()) {
      if (canAccess) {
        return (
          <Wrapper>
            <Sidebar roles={roles} />
            <Notification />
            <Component {...props} location={location} />
          </Wrapper>
        );
      }
      return (
        <Redirect
          to={{ pathname: ROUTES.FORBIDDEN, state: { from: location } }}
        />
      );
    }
    return (
      <Redirect to={{ pathname: ROUTES.LOGIN, state: { from: location } }} />
    );
  };
  return (
    <Route
      {...rest}
      render={({ location, ...props }) => render(location, props)}
    />
  );
};

export default PrivateRoute;
