/* eslint-disable no-dupe-keys */
import css from '@styled-system/css';
import styled from 'styled-components';
import { compose, flexbox, layout, space } from 'styled-system';

import theme from '../../tokens';

export const Container = styled('div')(
  css({}),
  compose(space, flexbox, layout),
);

export const Table = styled('table')(
  css({
    width: '100%',
    height: 'auto',
    position: 'relative',
    borderSpacing: 0,
    minWidth: '320px !important',
    background: theme.colors.grayWhite,
    border: `1px solid ${theme.colors.gray200}`,
    borderRadius: '4px',
    overflow: 'scroll',
    '[data-sticky-td]': {
      position: 'sticky',
      zIndex: '1 !important',
    },
    '[data-sticky-last-left-td]': {
      borderRight: `1px solid ${theme.colors.gray200}`,
    },
    '[data-sticky-first-right-td]': {
      borderLeft: `1px solid ${theme.colors.gray200}`,
    },
    resizer: {
      display: 'inline-block',
      width: '5px',
      height: '100%',
      position: 'absolute',
      right: '0',
      top: '0',
      transform: 'translateX(50%)',
      zIndex: 1,
      isResizing: {
        background: 'red',
      },
    },
  }),
  compose(space, flexbox, layout),
);

export const Thead = styled('thead')(
  css({
    maxHeight: '40px',
    width: 'auto',
    '& tr': {
      position: 'sticky',
      top: 0,
      zIndex: 2,
    },
  }),
);

export const Tbody = styled('tbody')(
  css({
    zIndex: 0,
    maxWidth: '100%',
    overflowX: 'auto',
  }),
  ({ onHover }) =>
    onHover &&
    css({
      'tr:hover': {
        td: {
          bg: '#f7fbfd',
          'div > div': {
            bg: 'transparent',

            input: {
              bg: 'transparent',
            },
          },
        },
      },
    }),
);

export const Th = styled('th')(
  css({
    display: 'inline-flex !important',
    flexFlow: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: 0,
    padding: '10px',
    borderBottom: `1px solid ${theme.colors.gray200}`,
    font: theme.fonts.textRegularSmall,
    fontSize: '12px',
    lineHeight: '16px',
    background: theme.colors.grayWhite,
    color: theme.colors.gray400,
    wordBreak: 'break-word',
    ':last-child': {
      borderRight: 0,
    },
  }),
);

export const Td = styled('td')(
  css({
    display: 'inline-flex !important',
    margin: 0,
    background: theme.colors.grayWhite,
    borderBottom: `1px solid ${theme.colors.gray200}`,
    font: theme.fonts.textRegularMedium,
    lineHeight: '20px',
    padding: '4px 8px',
    color: theme.colors.grayBlack,
    overflow: 'hidden',
    ':last-child': {
      borderRight: 0,
    },
    '& > input': {
      border: 'none',
      position: 'unset',
    },
    '& > *': {
      position: 'unset',
      width: 'auto',
    },
  }),
);

export const Tr = styled('tr')(
  css({
    ':last-child': {
      td: {
        borderBottom: 0,
      },
    },
  }),
);

export const IconContainer = styled('div')(
  css({
    display: 'inline-flex',
    alignItems: 'center',
  }),
  ({ align }) =>
    align ? `justify-content: flex-${align};` : 'justify-content: center;',
);

export const Icon = styled('span')(
  css({
    marginLeft: '10px',
  }),
);

export const FixedIcon = styled('div')(
  css({
    display: 'inline-flex',
    cursor: 'pointer',
    marginLeft: '10px',
    zIndex: 2,
  }),
);
