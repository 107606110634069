import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';

import { Card, EmptyState, Flex } from 'components/system/core';
import { Checkbox } from 'components/system/form';
import Tokens from 'components/system/tokens';
import { Text } from 'components/system/typography';

import * as S from './PermissionsTab.styles';

const PermissionsTab = ({ control, roles }) => {
  if (!roles.length) {
    return (
      <Card>
        <EmptyState condensed={false} title="Nada encontrado" />
      </Card>
    );
  }

  return (
    <S.ContainerTab data-testid="tab-test">
      {roles.map((permission) => (
        <S.ExpandedAccordion
          key={permission.name}
          open
          title={permission.name}
          style={{ width: '100%' }}
        >
          {permission.roles.map((role) => (
            <S.RowAction key={`role-${role.id}`}>
              <Flex justifyContent="space-between" alignItems="center">
                <Text
                  size="medium"
                  color={Tokens.colors.grayBlack}
                  style={{ fontWeight: '400' }}
                >
                  {role.role}
                </Text>

                <Flex>
                  {role.actions.map((action) => (
                    <Controller
                      key={`role-${role.id}-action-${action.id}`}
                      name={`roles.role_id_${role.id}.action_id_${action.id}`}
                      control={control}
                      defaultValue={false}
                      render={({ onChange }) => (
                        <S.FormGroup key={`${role.role}.${action.id}`}>
                          <Checkbox
                            id={`roles.${role.id}.${action.id}`}
                            group={`${role.id}.${action.id}`}
                            checked={action.checked}
                            onChange={(evt) => onChange(evt.target.checked)}
                            label={action.description}
                          />
                        </S.FormGroup>
                      )}
                    />
                  ))}
                </Flex>
              </Flex>
            </S.RowAction>
          ))}
        </S.ExpandedAccordion>
      ))}
    </S.ContainerTab>
  );
};

PermissionsTab.propTypes = {
  control: PropTypes.any.isRequired,
  roles: PropTypes.array.isRequired,
};

PermissionsTab.defaultValue = {
  roles: [],
};

export default PermissionsTab;
